import { Component, OnInit } from '@angular/core';
import { RefreshTokenModel } from './models/RefreshTokenModel';
import { AuthService } from './services/auth.service';
import { SessionDataService } from './session-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  refreshTokenModel: RefreshTokenModel = {
    RefreshToken: '',
  }

  constructor(public sessionData: SessionDataService, public authService: AuthService) {
    this.refreshTokenModel.RefreshToken = localStorage.getItem("RefreshToken") || '';
   }

  ngOnInit(): void {
    this.authService.loginByRefreshToken(this.refreshTokenModel);
  }

}
