import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabloterie-produse',
  templateUrl: './tabloterie-produse.component.html',
  styleUrls: ['./tabloterie-produse.component.css']
})
export class TabloterieProduseComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  panelId: any;
  panelName: any;

  tabloterieProdus: any = {
    tabloterieGestiuneTabloterieId: '',
    tabloterieTablouId: '',
    cantitate: '',
    data: '',
    userId: ''
  }

  tabloterieProdusUpdate: any = {
    id: '',
    tabloterieGestiuneTabloterieId: '',
    tabloterieTablouId: '',
    cantitate: '',
    data: '',
    userId: ''
  }
  today: any;
  todayInput: any;
  yesterday: any;

  prezentaModel: any = {
    data: '',
    tablouId: '',
    oreLucrate: '',
    userId: ''
  }
  oreLucrate: number = 8;
  fileId: any;
  codIntern: any;
  projectId: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.panelId = localStorage.getItem('PanelId');
    this.panelName = localStorage.getItem('PanelName');
    this.projectId = localStorage.getItem('ProjectId');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title =  this.panelName;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieProdus/getTabloterieTablouInventarById/' + this.panelId, header).subscribe((data) => {
       this.sessionData.listaProduseTablou = data;
    });
  }
  
  ngOnDestroy() {
    localStorage.removeItem('PanelId');
    localStorage.removeItem('PanelName');
  }

  //#region Terminare tablou
addFinishModal(content: any) {
  this.modalService.open(content);
}

AddFinishResource(fAddFinishCategory: NgForm){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/TabloterieProdus/UpdateTablouByIdGPrincipala/' + this.panelId, '', header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}

AddFinishPartialResource(fAddPartial: NgForm){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/TabloterieProdus/UpdateTablouById/' + this.panelId, '', header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  //#region Adauga produs
  async addModal(content: any) {

    const gestiuneTabloterie = this.authService.getTabloterieGestiuneTabloterieByProjectId(this.projectId);
    await lastValueFrom(gestiuneTabloterie);
    this.modalService.open(content);
  }

  AddResource(fAddCategory: NgForm){

    let myDate = new Date(Date.now());
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.tabloterieProdus = {
      tabloterieGestiuneTabloterieId: fAddCategory.value.DenumireProiect,
      tabloterieTablouId: this.panelId,
      cantitate: fAddCategory.value.Cantitate,
      data: myDate,
      userId: this.sessionData.user.id
    }

    return this.http.post(this.appUrl + 'api/TabloterieProdus/addProductToPanelModel', this.tabloterieProdus, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  
  }
  //#endregion

  //#region Editare
 editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.tabloterieProdusUpdate = {
    id: gp.id,
    tabloterieGestiuneTabloterieId: gp.tabloterieGestiuneTabloterieId,
    tabloterieTablouId: this.panelId,
    cantitate: gp.cantitate,
    data: gp.data,
    userId: this.sessionData.user.id
  }
}

EditResource(fEditCategory: NgForm){
  
  this.tabloterieProdusUpdate.cantitate = fEditCategory.value.Cantitate;

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/TabloterieProdus/' + this.tabloterieProdusUpdate.id, this.tabloterieProdusUpdate, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Retur Produs
returModal(content: any, gp: any) {
  this.modalService.open(content);
  this.tabloterieProdusUpdate = {
    id: gp.id,
    tabloterieGestiuneTabloterieId: gp.tabloterieGestiuneTabloterieId,
    tabloterieTablouId: this.panelId,
    cantitate: gp.cantitate,
    data: gp.data
  }
  this.codIntern = gp.gestiunePrincipala.codIntern + " - " + gp.gestiunePrincipala.denumire;
}
DeleteResource(fReturProdus: any){
  this.tabloterieProdusUpdate.cantitate = fReturProdus.value.Cantitate;
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
    this.http.post(this.appUrl + 'api/TabloterieProdus/retur/',  this.tabloterieProdusUpdate, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

}
//#endregion

  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

  //#region Adauga fisier
  addPictureModal(content: any) {
    this.modalService.open(content);
  }

  AddPictureResource(fAddPictureCategory: NgForm){

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    let myDate = new Date(Date.now());
    fAddPictureCategory.value.File = "file";
    fAddPictureCategory.value.Files = this.selectedfile;
    const formData = new FormData();
    formData.append("Denumire", "ImagineTablou");
    formData.append("Data", myDate.toDateString());
    formData.append("File", fAddPictureCategory.value.File.toString());
    formData.append("Files",  fAddPictureCategory.value.Files);  
    formData.append("UserId", this.sessionData.user.id);
    formData.append("TabloterieTablouId", this.panelId);

    return this.http.post(this.appUrl + 'api/TabloterieFisiereTablou', formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  
  }
  //#endregion

  //#region Retur fisier
    returFileModal(content: any, gp: any) {
      this.modalService.open(content);
      this.fileId = gp.id
    }
    DeleteFileResource(fReturProdus: any){

      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.delete(this.appUrl + 'api/TabloterieFisiereTablou/' + this.fileId, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );

    }
    //#endregion

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  //generare excel
  exportExcel() : void {

    let url = this.appUrl + 'api/TabloterieProdus/Raport/' + this.panelId;
    window.open(url);
  }
  openImage(item: any){
    window.open(this.appUrl + "/assets/" + item.file);
  }
}
