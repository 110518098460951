import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fotovoltaice-proiecte',
  templateUrl: './fotovoltaice-proiecte.component.html',
  styleUrls: ['./fotovoltaice-proiecte.component.css']
})
export class FotovoltaiceProiecteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
