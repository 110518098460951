import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-roluri',
  templateUrl: './lista-roluri.component.html',
  styleUrls: ['./lista-roluri.component.css']
})
export class RoluriComponent implements OnInit {

  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  rolId: any;

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "ROLURI"
      this.http.get<any>(this.appUrl + 'api/Role').subscribe((data) => {
        this.sessionData.roles = data;
        console.log(data);
      });
  }

  

  Search(){
    if(this.denumire != ""){
      this.sessionData.users = this.sessionData.users.filter((res: { nume: string; prenume: string; }) => {
        return res.nume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.prenume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
      });
    }else if(this.denumire == ""){
      this.authService.getAllUsers();
    }
  
  }
  
  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

  goToEdit(rol: any){
    this.sessionData.rolId = rol.id;
    localStorage.setItem('RolId', rol.id);
    this.router.navigate(['/edit-rol']);
  }

  //#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.rolId = category.id;
  }
  delete(event: any){
    this.http.delete(this.appUrl + 'api/Role/' + this.rolId).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion


}
