import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-edit-fisa-releu',
  templateUrl: './proiecte-edit-fisa-releu.component.html',
  styleUrls: ['./proiecte-edit-fisa-releu.component.css']
})
export class ProiecteEditFisaReleuComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  fisaId: any;
  routeSub: any;
  buttonDisable: boolean = false;
  proiectModelId: any;
  dataCompletare: any;
  denumireTablou: string = "";
  denumireEtaj: string = "";
  denumireProiect: string = "";
  numarCanale: string = "";
  denumireAparat: string = "";
  codAparat: string = "";
  idReleu: string = "";
  tabel: any = [];
  denumireFisaConexiuni: string = "";
  canalTabel: any = {
    idCanal: '',
    functie: '',
    idProtectie: ''
  }
  creareFisaReleuModel: any = {
    denumireDocument: '',
    proiect:'',
    etaj: '',
    tablou: '',
    idReleu: '',
    codAparat: '',
    denumireAparat: '',
    numarCanale: '',
    tabel: '',
    mentiuni: '',
    userId: '',
    proiectId: ''
  }
  fisaModel: any;
  mentiuni: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService, private route: ActivatedRoute) {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectName = params['projectName'];
      this.fisaId = params['fisaId'];
    });
  }

  ngOnInit(): void {
    this.dataCompletare = this.datepipe.transform(new Date(Date.now()), 'dd-MM-yyyy hh:mm:ss');
    this.sessionData.current_page_title = "CREARE FISA RELEU" + " - " +  this.projectName;
    this.buttonDisable = false;
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/proiectFisier/getFisaReleu/' + this.fisaId, header).subscribe((data) => {
      this.fisaModel = data;
      console.log(data);
      this.denumireFisaConexiuni = data.denumireDocument;
      this.denumireProiect = data.proiect;
      this.denumireEtaj = data.etaj;
      this.denumireTablou = data.tablou;
      this.tabel = data.tabel;
      this.numarCanale = data.numarCanale;
      this.denumireAparat = data.denumireAparat;
      this.codAparat = data.codAparat;
      this.idReleu = data.idReleu;
      this.mentiuni = data.mentiuni;
    });
  }

  addCanal(){
    this.canalTabel = {
      idCanal: '',
      functie: '',
      idProtectie: ''
    }
    this.tabel.push(this.canalTabel);
  }

  onSubmit(fEditCategory: any){

    this.creareFisaReleuModel = {
      id: this.fisaId,
      denumireDocument: this.denumireFisaConexiuni,
      proiect: this.denumireProiect,
      etaj: this.denumireEtaj,
      tablou: this.denumireTablou,
      idReleu: this.idReleu,
      codAparat: this.codAparat,
      denumireAparat: this.denumireAparat,
      numarCanale: this.numarCanale,
      tabel: this.tabel,
      mentiuni: fEditCategory.value.DescriereSesizare,
      userId: this.sessionData.user.id,
      proiectId: this.projectId
    }

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/ProiectFisier/editareFisaReleu', this.creareFisaReleuModel, header).subscribe(
      (response) => {    
        this.router.navigate(['/proiecte-fise-relee/' + this.projectId + '/' + this.projectName]);
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }

}