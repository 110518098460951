import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-fise-relee',
  templateUrl: './proiecte-fise-relee.component.html',
  styleUrls: ['./proiecte-fise-relee.component.css']
})
export class ProiecteFiseReleeComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  routeSub: any;
  buttonDisable: boolean = false;
  proiectModelId: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService, private route: ActivatedRoute) {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectName = params['projectName']
    });
  }


  ngOnInit(): void {
    this.sessionData.current_page_title = "FISA RELEU" + " - " +  this.projectName;
    this.buttonDisable = false;
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/proiectFisier/getFisiereByTip/FisaReleu', header).subscribe((data) => {
      this.sessionData.fisierePlanArhitectura = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
    });
  }

    //#region Stergere plan
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModelId = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/ProiectFisier/' + this.proiectModelId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

goToEditareFiseRelee(project: any){
  this.sessionData.productId = this.projectId;
  this.sessionData.fisaReleuId = project.id;
  this.router.navigate(['/proiecte-edit-fisa-releu/' + this.projectId + '/' + this.projectName + '/' + project.id]);
}

}
