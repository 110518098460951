import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ofertare-gestiune-vizualizari',
  templateUrl: './ofertare-gestiune-vizualizari.component.html',
  styleUrls: ['./ofertare-gestiune-vizualizari.component.css']
})
export class OfertareGestiuneVizualizariComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  ofertareModel: any = {
    id: '',
    images: '',
    image: '',
    nume: '',
    descriere:''
  }

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  ngOnInit(): void {
    this.sessionData.current_page_title = "OFERTARE - VIZUALIZARI";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareGestiuneVizualizare/', header).subscribe((data) => {
      this.sessionData.ofertareGestiuneVizualizari = data;
    });
  }

  //functie pentru fisierul incarcat
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

//#region Adauga descriere
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("Nume", fAddCategory.value.Denumire.toString());
  formData.append("Descriere", fAddCategory.value.Descriere.toString());
  formData.append("Image", fAddCategory.value.File.toString());
  formData.append("Images",  fAddCategory.value.Files);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/OfertareGestiuneVizualizare/', formData, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Editare produs

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.ofertareModel.id = gp.id;
    this.ofertareModel.nume = gp.nume;
    this.ofertareModel.images = gp.images;
    this.ofertareModel.image = gp.image;
    this.ofertareModel.descriere = gp.descriere;
  }

  EditResource(fEditCategory: NgForm){
    fEditCategory.value.File = "file";
    const formData1 = new FormData();
    if(fEditCategory.value.Files != ""){
      fEditCategory.value.Files = this.selectedfile;
      formData1.append("Id", this.ofertareModel.id);
      formData1.append("Nume", fEditCategory.value.Denumire.toString());
      if(fEditCategory.value.Descriere){
        formData1.append("Descriere", fEditCategory.value.Descriere.toString());
      }
      else{
        formData1.append("Descriere", "");
      }
      formData1.append("Image", fEditCategory.value.File.toString());
      formData1.append("Images",  fEditCategory.value.Files);
    }
    else{
      formData1.append("Id", this.ofertareModel.id);
      formData1.append("Nume", fEditCategory.value.Denumire.toString());
      if(fEditCategory.value.Descriere){
        formData1.append("Descriere", fEditCategory.value.Descriere.toString());
      }
      else{
        formData1.append("Descriere", "");
      }      formData1.append("Image", this.ofertareModel.image);
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/OfertareGestiuneVizualizare/' + this.ofertareModel.id, formData1, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.ofertareModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/OfertareGestiuneVizualizare/' + this.ofertareModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}