import { GestiuneProiect2Component } from './components/materiale/gestiune-proiect2/gestiune-proiect2.component';
import { RapoarteSituatieSaptamanalaComponent } from './components/rapoarte-proiecte/rapoarte-situatie-saptamanala/rapoarte-situatie-saptamanala.component';
import { ServiciiIndirecteComponent } from './components/ofertare2/servicii-indirecte/servicii-indirecte.component';
import { ServiciiSecundareComponent } from './components/ofertare2/servicii-secundare/servicii-secundare.component';
import { ServiciiPrincipaleComponent } from './components/ofertare2/servicii-principale/servicii-principale.component';
import { RapoarteUserPrezentaPdfPeriodiceComponent } from './components/rapoarte-user-prezenta-pdf-periodice/rapoarte-user-prezenta-pdf-periodice.component';
import { AccesoriiUnitatiFunctionaleComponent } from './components/ofertare2/accesorii-unitati-functionale/accesorii-unitati-functionale.component';
import { RapoarteUserPrezentaPdfComponent } from './components/rapoarte-user-prezenta-pdf/rapoarte-user-prezenta-pdf.component';
import { AccesoriiMontajTablouComponent } from './components/ofertare2/accesorii-montaj-tablou/accesorii-montaj-tablou.component';
import { ServiciiComponent } from './components/ofertare2/servicii/servicii.component';
import { ProfitabilitateProiecteComponent } from './components/profitabilitate-proiecte/profitabilitate-proiecte.component';
import { DashboardFinanciarProiectComponent } from './components/proiecte/dashboard-financiar-proiect/dashboard-financiar-proiect.component';
import { GestiuneProtectiaMunciiComponent } from './components/gestiune-protectia-muncii/gestiune-protectia-muncii.component';
import { GestiuneAlteAnunturiComponent } from './components/gestiune-alte-anunturi/gestiune-alte-anunturi.component';
import { AlteAnunturiComponent } from './components/alte-anunturi/alte-anunturi.component';
import { AccesoriiMontajComponent } from './components/ofertare2/accesorii-montaj/accesorii-montaj.component';
import { OfertareCreareOferta2Component } from './components/ofertare2/ofertare-creare-oferta2/ofertare-creare-oferta2.component';
import { UseriTelefonComponent } from './components/useri-telefon/useri-telefon.component';
import { RapoartePersonalizateComponent } from './components/rapoarte-proiecte/rapoarte-personalizate/rapoarte-personalizate.component';
import { RapoartePeriodiceComponent } from './components/rapoarte-proiecte/rapoarte-periodice/rapoarte-periodice.component';
import { RapoarteCompleteComponent } from './components/rapoarte-proiecte/rapoarte-complete/rapoarte-complete.component';
import { RapoarteProiectComponent } from './components/rapoarte-proiect/rapoarte-proiect.component';
import { TablouriFinalizateComponent } from './components/tabloterie/tablouri-finalizate/tablouri-finalizate.component';
import { ProtectiaMunciiComponent } from './components/protectia-muncii/protectia-muncii.component';
import { AcceptaAnuntComponent } from './components/accepta-anunt/accepta-anunt.component';
import { GestiuneAnunturiComponent } from './components/gestiune-anunturi/gestiune-anunturi.component';
import { ProiecteFinalizateComponent } from './components/clienti/proiecte-finalizate/proiecte-finalizate.component';
import { TabelSesizariTehniceComponent } from './components/procese/tabel-sesizari-tehnice/tabel-sesizari-tehnice.component';
import { SesizareTehnicaComponent } from './components/procese/sesizare-tehnica/sesizare-tehnica.component';
import { DashboardTaskUserComponent } from './components/dashboard-task-user/dashboard-task-user.component';
import { DashboardGrupuriComponent } from './components/dashboard-grupuri/dashboard-grupuri.component';
import { DashboardTaskComponent } from './components/dashboard-task/dashboard-task.component';
import { DashboardProiecteComponent } from './components/dashboard-proiecte/dashboard-proiecte.component';
import { TaskChatComponent } from './components/proiecte/task-chat/task-chat.component';
import { InterventiiTaskComponent } from './components/proiecte/interventii-task/interventii-task.component';
import { TaskComponent } from './components/proiecte/task/task.component';
import { EditRolComponent } from './components/roluri/edit-rol/edit-rol.component';
import { AdaugaRolComponent } from './components/roluri/adauga-rol/adauga-rol.component';
import { RoluriComponent } from './components/roluri/lista-roluri/lista-roluri.component';
import { FisiereClientComponent } from './components/clienti/fisiere-client/fisiere-client.component';
import { DocumentatieTehnicaDashboardComponent } from './components/clienti/documentatie-tehnica-dashboard/documentatie-tehnica-dashboard.component';
import { CreareProcesVerbalReceptieComponent } from './components/clienti/creare-proces-verbal-receptie/creare-proces-verbal-receptie.component';
import { CreareAvizLivrareComponent } from './components/clienti/creare-aviz-livrare/creare-aviz-livrare.component';
import { ProceseVerbaleComponent } from './components/clienti/procese-verbale/procese-verbale.component';
import { StocCriticComponent } from './components/aprovizionare/stoc-critic/stoc-critic.component';
import { PrezentaFotovoltaiceComponent } from './components/prezenta/prezenta-fotovoltaice/prezenta-fotovoltaice.component';
import { GestiuneFotovoltaiceComponent } from './components/fotovoltaice/gestiune-fotovoltaice/gestiune-fotovoltaice.component';
import { FotovoltaiceProiecteComponent } from './components/fotovoltaice/fotovoltaice-proiecte/fotovoltaice-proiecte.component';
import { FotovoltaiceProduseComponent } from './components/fotovoltaice/fotovoltaice-produse/fotovoltaice-produse.component';
import { RapoartedelucruDashboardComponent } from './components/clienti/rapoartedelucru-dashboard/rapoartedelucru-dashboard.component';
import { OferteDashboardComponent } from './components/clienti/oferte-dashboard/oferte-dashboard.component';
import { FacturiDashboardComponent } from './components/clienti/facturi-dashboard/facturi-dashboard.component';
import { DiverseDashboardComponent } from './components/clienti/diverse-dashboard/diverse-dashboard.component';
import { ContracteDashboardComponent } from './components/clienti/contracte-dashboard/contracte-dashboard.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GestiuneAprovizionareRezervariComponent } from './components/aprovizionare/gestiune-aprovizionare-rezervari/gestiune-aprovizionare-rezervari.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { ProiecteClientiComponent } from './components/proiecte-clienti/proiecte-clienti.component';
import { DashboardClientComponent } from './components/clienti/dashboard-client/dashboard-client.component';
import { DatePersonaleComponent } from './components/date-personale/date-personale.component';
import { SolicitaServiciileNoastreComponent } from './components/solicita-serviciile-noastre/solicita-serviciile-noastre.component';
import { ListaProduseDecomandatComponent } from './components/aprovizionare/lista-produse-decomandat/lista-produse-decomandat.component';
import { PrezentaTabloterieComponent } from './components/prezenta/prezenta-tabloterie/prezenta-tabloterie.component';
import { TabloterieTablouriComponent } from './components/tabloterie/tabloterie-tablouri/tabloterie-tablouri.component';
import { TabloterieProiecteComponent } from './components/tabloterie/tabloterie-proiecte/tabloterie-proiecte.component';
import { TabloterieProduseComponent } from './components/tabloterie/tabloterie-produse/tabloterie-produse.component';
import { GestiuneComandaComponent } from './components/aprovizionare/gestiune-comanda/gestiune-comanda.component';
import { GestiuneAprovizionareComponent } from './components/aprovizionare/gestiune-aprovizionare/gestiune-aprovizionare.component';
import { GestiuneAprovizionareComandaComponent } from './components/aprovizionare/gestiune-aprovizionare-comanda/gestiune-aprovizionare-comanda.component';
import { OfertareEditareOfertaComponent } from './components/ofertare/ofertare-editare-oferta/ofertare-editare-oferta.component';
import { OfertareGestiuneVizualizariComponent } from './components/ofertare/ofertare-gestiune-vizualizari/ofertare-gestiune-vizualizari.component';
import { OfertareGestiuneOferteComponent } from './components/ofertare/ofertare-gestiune-oferte/ofertare-gestiune-oferte.component';
import { OfertareGestiuneEchipamenteComponent } from './components/ofertare/ofertare-gestiune-echipamente/ofertare-gestiune-echipamente.component';
import { OfertareGestiuneDescrieriComponent } from './components/ofertare/ofertare-gestiune-descrieri/ofertare-gestiune-descrieri.component';
import { OfertareCreareOfertaComponent } from './components/ofertare/ofertare-creare-oferta/ofertare-creare-oferta.component';
import { ListaClientiComponent } from './components/clienti/lista-clienti/lista-clienti.component';
import { ArticoleManoperaComponent } from './components/ofertare/articole-manopera/articole-manopera.component';
import { AddPrezentaBirouComponent } from './components/prezenta/add-prezenta-birou/add-prezenta-birou.component';
import { AddPrezentaAtelierComponent } from './components/prezenta/add-prezenta-atelier/add-prezenta-atelier.component';
import { TabelPrezentaComponent } from './components/prezenta/tabel-prezenta/tabel-prezenta.component';
import { ListaAngajatiComponent } from './components/proiecte/lista-angajati/lista-angajati.component';
import { ProiecteComponent } from './components/clienti/proiecte/proiecte.component';
import { ProductDetailComponent } from './components/materiale/product-detail/product-detail.component';
import { RezervareProiecteComponent } from './components/materiale/rezervare-proiecte/rezervare-proiecte.component';
import { GestiuneVanzariComponent } from './components/materiale/gestiune-vanzari/gestiune-vanzari.component';
import { GestiuneTransferComponent } from './components/materiale/gestiune-transfer/gestiune-transfer.component';
import { GestiuneProiecteComponent } from './components/materiale/gestiune-proiecte/gestiune-proiecte.component';
import { GestiunePrincipalaComponent } from './components/materiale/gestiune-principala/gestiune-principala.component';
import { GestiunePersonalaComponent } from './components/materiale/gestiune-personala/gestiune-personala.component';
import { GestiuneAngajatiComponent } from './components/materiale/gestiune-angajati/gestiune-angajati.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AddPrezentaProiectComponent } from './components/prezenta/add-prezenta-proiect/add-prezenta-proiect.component';
import { OfertareGestiuneProduseComponent } from './components/ofertare/ofertare-gestiune-produse/ofertare-gestiune-produse.component';
import { AuthGuard } from './guard/auth-guard.service';
import { GestiuneTabloterieComponent } from './components/tabloterie/gestiune-tabloterie/gestiune-tabloterie.component';
import { RapoarteCompletePeProiectComponent } from './components/proiecte/rapoarte-complete-pe-proiect/rapoarte-complete-pe-proiect.component';
import { OfertareGestiuneOfertare2Component } from './components/ofertare2/ofertare-gestiune-ofertare2/ofertare-gestiune-ofertare2.component';
import { ListaClientiOfertareComponent } from './components/clienti/lista-clienti-ofertare/lista-clienti-ofertare.component';
import { OfertareEditareOferta2Component } from './components/ofertare2/ofertare-editare-oferta2/ofertare-editare-oferta2.component';
import { Proiecte2Component } from './components/proiecte2/proiecte2/proiecte2.component';
import { DashboardContracteComponent } from './components/proiecte2/dashboard-contracte/dashboard-contracte.component';
import { DashboardProiectComponent } from './components/proiecte2/dashboard-proiect/dashboard-proiect.component';
import { DashboardSectiuneComponent } from './components/proiecte2/dashboard-sectiune/dashboard-sectiune.component';
import { Proiect2SectiuneManoperaComponent } from './components/proiecte2/proiect2-sectiune-manopera/proiect2-sectiune-manopera.component';
import { Proiect2SectiuneMaterialeComponent } from './components/proiecte2/proiect2-sectiune-materiale/proiect2-sectiune-materiale.component';
import { RezervareProiecte2Component } from './components/materiale/rezervare-proiecte2/rezervare-proiecte2.component';
import { AddPrezentaProiect2Component } from './components/prezenta/add-prezenta-proiect2/add-prezenta-proiect2.component';
import { TabelPrezenta2Component } from './components/prezenta/tabel-prezenta2/tabel-prezenta2.component';
import { GestiuneComponenteComponent } from './components/productie/gestiune-componente/gestiune-componente.component';
import { GestiuneRetetarComponent } from './components/productie/gestiune-retetar/gestiune-retetar.component';
import { GestiuneRetetarComponenteComponent } from './components/productie/gestiune-retetar-componente/gestiune-retetar-componente.component';
import { GestiuneProductieComponent } from './components/productie/gestiune-productie/gestiune-productie.component';
import { ProiecteFiseReleeComponent } from './components/proiecte/proiecte-fise-relee/proiecte-fise-relee.component';
import { ProiecteFisiereComponent } from './components/proiecte/proiecte-fisiere/proiecte-fisiere.component';
import { ProiectePlanuriArhitecturaComponent } from './components/proiecte/proiecte-planuri-arhitectura/proiecte-planuri-arhitectura.component';
import { ProiecteSchemeMonofilareComponent } from './components/proiecte/proiecte-scheme-monofilare/proiecte-scheme-monofilare.component';
import { ProiecteSchemeTablouriComponent } from './components/proiecte/proiecte-scheme-tablouri/proiecte-scheme-tablouri.component';
import { ProiecteCreareFisaReleuComponent } from './components/proiecte/proiecte-creare-fisa-releu/proiecte-creare-fisa-releu.component';
import { ProiecteEditFisaReleuComponent } from './components/proiecte/proiecte-edit-fisa-releu/proiecte-edit-fisa-releu.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent},
  { path: 'register-user', component: RegisterUserComponent},
  { path: 'gestiune-angajati', component: GestiuneAngajatiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-personala', component: GestiunePersonalaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-principala', component: GestiunePrincipalaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-proiecte', component: GestiuneProiecteComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-proiecte2', component: GestiuneProiect2Component, canActivate: [AuthGuard]},
  { path: 'gestiune-transfer', component: GestiuneTransferComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-vanzari', component: GestiuneVanzariComponent, canActivate: [AuthGuard]},
  { path: 'rezervare-proiecte', component: RezervareProiecteComponent, canActivate: [AuthGuard]},
  { path: 'rezervare-proiecte2', component: RezervareProiecte2Component, canActivate: [AuthGuard]},
  { path: 'product-detail', component: ProductDetailComponent, canActivate: [AuthGuard]},
  { path: 'proiecte', component: ProiecteComponent, canActivate: [AuthGuard]},
  { path: 'lista-angajati', component: ListaAngajatiComponent, canActivate: [AuthGuard]},
  { path: 'tabel-prezenta', component: TabelPrezentaComponent, canActivate: [AuthGuard]},
  { path: 'tabel-prezenta2', component: TabelPrezenta2Component, canActivate: [AuthGuard]},
  { path: 'add-prezenta-atelier', component: AddPrezentaAtelierComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-birou', component: AddPrezentaBirouComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-proiect', component: AddPrezentaProiectComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-proiect2', component: AddPrezentaProiect2Component, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-produse', component: OfertareGestiuneProduseComponent, canActivate: [AuthGuard]},
  { path: 'articole-manopera', component: ArticoleManoperaComponent, canActivate: [AuthGuard]},
  { path: 'lista-clienti', component: ListaClientiComponent, canActivate: [AuthGuard]},
  { path: 'lista-clienti-ofertare', component: ListaClientiOfertareComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-creare-oferta', component: OfertareCreareOfertaComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-descrieri', component: OfertareGestiuneDescrieriComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-echipamente', component: OfertareGestiuneEchipamenteComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-oferte', component: OfertareGestiuneOferteComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-vizualizari', component: OfertareGestiuneVizualizariComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-editare-oferta', component: OfertareEditareOfertaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare', component: GestiuneAprovizionareComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare-comanda', component: GestiuneAprovizionareComandaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-comanda', component: GestiuneComandaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-tabloterie', component: GestiuneTabloterieComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-produse', component: TabloterieProduseComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-proiecte', component: TabloterieProiecteComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-tablouri', component: TabloterieTablouriComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-prezenta', component: PrezentaTabloterieComponent, canActivate: [AuthGuard]},
  { path: 'lista-produse-decomandat', component: ListaProduseDecomandatComponent, canActivate: [AuthGuard]},
  { path: 'solicita-serviciile-noastre', component: SolicitaServiciileNoastreComponent, canActivate: [AuthGuard]},
  { path: 'date-personale', component: DatePersonaleComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-client', component: DashboardClientComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-clienti', component: ProiecteClientiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare-rezervari', component: GestiuneAprovizionareRezervariComponent, canActivate: [AuthGuard]},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'change-password/:email/:token', component: ChangePasswordComponent},
  { path: 'contracte-dashboard', component: ContracteDashboardComponent},
  { path: 'diverse-dashboard', component: DiverseDashboardComponent},
  { path: 'facturi-dashboard', component: FacturiDashboardComponent},
  { path: 'oferte-dashboard', component: OferteDashboardComponent},
  { path: 'rapoartedelucru-dashboard', component: RapoartedelucruDashboardComponent},
  { path: 'fotovoltaice-produse', component: FotovoltaiceProduseComponent},
  { path: 'fotovoltaice-proiecte', component: FotovoltaiceProiecteComponent},
  { path: 'gestiune-fotovoltaice', component: GestiuneFotovoltaiceComponent},
  { path: 'prezenta-fotovoltaice', component: PrezentaFotovoltaiceComponent},
  { path: 'stoc-critic', component: StocCriticComponent, canActivate: [AuthGuard]},
  { path: 'procese-verbale', component: ProceseVerbaleComponent, canActivate: [AuthGuard]},
  { path: 'creare-aviz-livrare', component: CreareAvizLivrareComponent, canActivate: [AuthGuard]},
  { path: 'creare-proces-verbal-receptie', component: CreareProcesVerbalReceptieComponent, canActivate: [AuthGuard]},
  { path: 'documentatie-tehnica-dashboard', component: DocumentatieTehnicaDashboardComponent},
  { path: 'fisiere-client', component: FisiereClientComponent},
  { path: 'lista-roluri', component: RoluriComponent, canActivate: [AuthGuard]},
  { path: 'adauga-rol', component: AdaugaRolComponent, canActivate: [AuthGuard]},
  { path: 'edit-rol', component: EditRolComponent, canActivate: [AuthGuard]},
  { path: 'task/:id/:projectName', component: TaskComponent, canActivate: [AuthGuard]},
  { path: 'interventii-task', component: InterventiiTaskComponent},
  { path: 'task-chat', component: TaskChatComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-proiecte', component: DashboardProiecteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-task', component: DashboardTaskComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-grupuri', component: DashboardGrupuriComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-task-user', component: DashboardTaskUserComponent, canActivate: [AuthGuard]},
  { path: 'sesizare-tehnica', component: SesizareTehnicaComponent, canActivate: [AuthGuard]},
  { path: 'tabel-sesizari-tehnice', component: TabelSesizariTehniceComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-finalizate', component: ProiecteFinalizateComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-anunturi', component: GestiuneAnunturiComponent, canActivate: [AuthGuard]},
  { path: 'accepta-anunt', component: AcceptaAnuntComponent},
  { path: 'protectia-muncii', component: ProtectiaMunciiComponent, canActivate: [AuthGuard]},
  { path: 'tablouri-finalizate', component: TablouriFinalizateComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-proiect', component: RapoarteProiectComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-proiect-pe-proiect', component: RapoarteCompletePeProiectComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-complete', component: RapoarteCompleteComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-periodice', component: RapoartePeriodiceComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-personalizate', component: RapoartePersonalizateComponent, canActivate: [AuthGuard]},
  { path: 'useri-telefon', component: UseriTelefonComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-creare-oferta2', component: OfertareCreareOferta2Component, canActivate: [AuthGuard]},
  { path: 'accesorii-montaj', component: AccesoriiMontajComponent, canActivate: [AuthGuard]},
  { path: 'accesorii-montaj-tablou', component: AccesoriiMontajTablouComponent, canActivate: [AuthGuard]},
  { path: 'alte-anunturi', component: AlteAnunturiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-alte-anunturi', component: GestiuneAlteAnunturiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-protectia-muncii', component: GestiuneProtectiaMunciiComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-financiar-proiect/:id/:projectName', component: DashboardFinanciarProiectComponent, canActivate: [AuthGuard]},
  { path: 'profitabilitate-proiecte', component: ProfitabilitateProiecteComponent, canActivate: [AuthGuard]},
  { path: 'servicii', component: ServiciiComponent, canActivate: [AuthGuard]},
  { path: 'servicii-principale', component: ServiciiPrincipaleComponent, canActivate: [AuthGuard]},
  { path: 'servicii-secundare', component: ServiciiSecundareComponent, canActivate: [AuthGuard]},
  { path: 'servicii-indirecte', component: ServiciiIndirecteComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-user-prezenta-pdf', component: RapoarteUserPrezentaPdfComponent, canActivate: [AuthGuard]},
  { path: 'accesorii-unitati-functionale', component: AccesoriiUnitatiFunctionaleComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-user-prezenta-pdf-periodice', component: RapoarteUserPrezentaPdfPeriodiceComponent, canActivate: [AuthGuard]},
  { path: 'rapoarte-situatie-saptamanala', component: RapoarteSituatieSaptamanalaComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-ofertare2', component: OfertareGestiuneOfertare2Component, canActivate: [AuthGuard]},
  { path: 'ofertare-editare-oferta2', component: OfertareEditareOferta2Component, canActivate: [AuthGuard]},
  { path: 'proiecte2', component: Proiecte2Component, canActivate: [AuthGuard]},
  { path: 'dashboard-contracte', component: DashboardContracteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-proiect', component: DashboardProiectComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-sectiune', component: DashboardSectiuneComponent, canActivate: [AuthGuard]},
  { path: 'proiect2-sectiune-manopera', component: Proiect2SectiuneManoperaComponent, canActivate: [AuthGuard]},
  { path: 'proiect2-sectiune-materiale', component: Proiect2SectiuneMaterialeComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-componente', component: GestiuneComponenteComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-retetar', component: GestiuneRetetarComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-retetar-componente', component: GestiuneRetetarComponenteComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-productie', component: GestiuneProductieComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-fise-relee/:id/:projectName', component: ProiecteFiseReleeComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-fisiere', component: ProiecteFisiereComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-planuri-arhitectura/:id/:projectName', component: ProiectePlanuriArhitecturaComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-scheme-monofilare/:id/:projectName', component: ProiecteSchemeMonofilareComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-scheme-tablouri/:id/:projectName', component: ProiecteSchemeTablouriComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-creare-fisa-releu/:id/:projectName', component: ProiecteCreareFisaReleuComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-edit-fisa-releu/:id/:projectName/:fisaId', component: ProiecteEditFisaReleuComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
