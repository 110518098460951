import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-retetar-componente',
  templateUrl: './gestiune-retetar-componente.component.html',
  styleUrls: ['./gestiune-retetar-componente.component.css']
})
export class GestiuneRetetarComponenteComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  buttonDisable: boolean = false;
  gRetetarComponenteModel: any = {
    id: '',
    cantitate: '',
    gestiuneRetetarId: '',
    gestiuneComponente: ''
  }
  addGTransferModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    userId: '',
    numeUser: '',
    proiectId: ''
  };
  addRezervareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    proiectId: '',
    numeUser: ''
  };
  addGVanzareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    numeUser: '',
    mentiuni: ''
  };
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  file : any = 'file';
  buffer: any;
  gestiunePrincipalaAux: any;
  idProiectAles: any;
  idContractAles: any;
  proiectFlag: any = false;
  idSectiuneAleasa: any;
  contractFlag: any = false;
  searchModel: any = {
    cod: ''
  };
  produseOfertare: any;
  updateAccesoriuModel: any = {
    id: '',
    cod: '',
    denumire: '',
    producator: '',
    cantitate: ''
  }
  addAccesoriuModel: any = {
    gestiuneComponenteId: '',
    gestiuneRetetarId: '',
    cantitate: ''
  }
  updateGestiuneRetetarComponente: any = {
    id: '',
    gestiuneComponenteId: '',
    gestiuneRetetarId: '',
    cantitate: ''
  }
//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    sessionData.productId = localStorage.getItem('ProductId');
    sessionData.productName = localStorage.getItem('ProductName');
  }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "RETETAR - " + this.sessionData.productName;
    this.buttonDisable = false;

    const gestiunePrincipala = this.authService.getGestiuneRetetarComponente(this.sessionData.productId);
    await lastValueFrom(gestiunePrincipala);
    
    this.gestiunePrincipalaAux = this.sessionData.gestiunePrincipalaInventory;
  }

   //functie pentru fisierul incarcat
   onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

//#region Editare produs

  editModal(content: any, gp: any) {
    console.log(gp);
    this.modalService.open(content);
    this.gRetetarComponenteModel = {
      id: gp.id,
      cantitate: gp.cantitate,
      gestiuneRetetarId: gp.gestiuneRetetarId,
      gestiuneComponente: gp.gestiuneComponente
    }
  }

  EditResource(fEditCategory: NgForm){
    this.buttonDisable = true;
    
    this.updateGestiuneRetetarComponente = {
      id: this.gRetetarComponenteModel.id,
      gestiuneComponenteId: this.gRetetarComponenteModel.gestiuneComponente.id,
      gestiuneRetetarId: this.gRetetarComponenteModel.gestiuneRetetarId,
      cantitate: fEditCategory.value.Cantitate,
    }
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/GestiuneRetetarComponente/' + this.updateGestiuneRetetarComponente.id, this.updateGestiuneRetetarComponente, header).subscribe(
      (response) => {    
        // this.message = "Produsul a fost editat!"
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.updateGestiuneRetetarComponente.id = category.id;
  }
  delete(event: any){
    this.buttonDisable = true;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/GestiuneRetetarComponente/' + this.updateGestiuneRetetarComponente.id, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

addModal(content: any, currentTable: any, tableType: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.searchModel.cod = this.denumire;
console.log(this.searchModel);
  this.http.post<any>(this.appUrl + 'api/GestiuneComponente/getProduseByCod', this.searchModel, header).subscribe((data) => {
    this.produseOfertare = data;
    console.log(data);
  });
}

addProductFromSearch(content: any, gp: any) {
  this.modalService.open(content);
  this.updateAccesoriuModel = {
    id: gp.id,
    cod: gp.codIntern,
    denumire: gp.denumire,
    producator: gp.producator,
    cantitate: 1,
  }
}

AddProductResource(fEditCategory: NgForm){

  this.addAccesoriuModel = {
    gestiuneComponenteId: this.updateAccesoriuModel.id,
    gestiuneRetetarId: this.sessionData.productId,
    cantitate: fEditCategory.value.Cantitate,
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/GestiuneRetetarComponente/', this.addAccesoriuModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
