import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-angajati',
  templateUrl: './gestiune-angajati.component.html',
  styleUrls: ['./gestiune-angajati.component.css']
})
export class GestiuneAngajatiComponent implements OnInit {

  //#region Declaration
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  proiectRezerva: boolean = false;
  p: number = 1;
  addGPersonalaModel: any = {
    gestiunePersonalaId: '',
    cantitate: '',
    userId: '',
    numeUser: '',
    userIdTransfer: '',
    numeUserTransfer: '',
    gestiunePrincipalaId: ''
  };
  addGProiectModel: any = {
    gestiunePersonalaId: '',
    gestiunePrincipalaId: '',
    cantitate: '',
    proiectId: '',
    numeUser: '',
    userId: ''
  };
  gPersonalaModel: any = {
    id: '',
    activ: '',
    cantitate: '',
    data: '',
    gestiunePrincipala: '',
    numeUser: '',
    userId: '',
  };
  returGTransferModel: any = {
    gestiunePrincipalaId: '',
    gestiunePersonalaId: '',
    numeUser: '',
    userId: '',
    cantitate: ''
  };
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  buffer: any;
  gestiuneAux: any;
  excelUserId: any;
  excelAngajatModel: any;
  buttonDisable: boolean = false;
//#endregion

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.buttonDisable = false;
    this.sessionData.current_page_title = "GESTIUNE ANGAJATI"
    this.nav.show();
    var header = {
          headers: new HttpHeaders()
            .set('Authorization',  `Bearer ${this.authService.getToken()}`)
        }
    
    this.http.get<any>(this.appUrl + 'api/GestiunePersonala/getGestiunePersonala', header).subscribe((data) => {
      this.sessionData.gestiunePersonala = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      this.gestiuneAux = this.sessionData.gestiunePersonala;
    });
  }


//#region Retur Produs
returModal(content: any, gp: any) {
  console.log(gp);
  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId
  }
}
DeleteResource(fReturProdus: any){

  this.buttonDisable = true;

    this.returGTransferModel = {
      gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
      gestiunePersonalaId: this.gPersonalaModel.id,
      numeUser: this.gPersonalaModel.numeUser,
      userId: this.gPersonalaModel.userId,
      cantitate: this.gPersonalaModel.cantitate
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.post(this.appUrl + 'api/GestiunePersonala/deleteToGestiuneTransfer/' + this.gPersonalaModel.id,  this.returGTransferModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );



}
//#endregion

//#region Transfera in Gestiune Personala

addToGUserModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId
  }
}

AddGUserResource(fAddGTransfer: NgForm){

  this.buttonDisable = true;
  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPersonalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.sessionData.users.forEach((element: { id: any; nume: any; prenume: any; }) => {
      if(element.id == fAddGTransfer.value.UserName){
        this.userName = element.nume + " " + element.prenume;
      }
    });


    this.addGPersonalaModel = {
      gestiunePersonalaId: this.gPersonalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      userId: this.gPersonalaModel.userId,
      numeUser: this.gPersonalaModel.numeUser,
      userIdTransfer: fAddGTransfer.value.UserName,
      numeUserTransfer: this.userName,
      gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiunePersonala/addGestiunePersonalaToGestiunePersonala', this.addGPersonalaModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Transfera in Gestiune Proiect

async addToGProiectModal(content: any, gp: any) {
  if(gp.denumireProiect == "Rezerva"){
    this.proiectRezerva = true;
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
  }
  
  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId,
    proiectId: gp.proiectId
  }

}

AddGProiectResource(fAddGTransfer: NgForm){

  this.buttonDisable = true;
  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPersonalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    if(this.proiectRezerva == true){
      this.addGProiectModel = {
        gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
        gestiunePersonalaId: this.gPersonalaModel.id,
        cantitate: fAddGTransfer.value.Cantitate,
        proiectId: fAddGTransfer.value.Proiect,
        numeUser:  this.gPersonalaModel.numeUser,
        userId: this.gPersonalaModel.userId,
      }
    }
    else{
      this.addGProiectModel = {
        gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
        gestiunePersonalaId: this.gPersonalaModel.id,
        cantitate: fAddGTransfer.value.Cantitate,
        proiectId: this.gPersonalaModel.proiectId,
        numeUser:  this.gPersonalaModel.numeUser,
        userId: this.gPersonalaModel.userId,
      }
    }    

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/GestiunePersonala/addGestiuneProiect', this.addGProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region excel angajat
exportExcelAngajat(content: any) : void {
  this.modalService.open(content);
}

ExportAngajatExcelResource(fexportAngajatExcel: any){
 
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportAngajatExcel.value.exampleDataListUsers){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: this.adjustDateForTimeOffset(fexportAngajatExcel.value.DataInceput),
    dataSfarsit: this.adjustDateForTimeOffset(fexportAngajatExcel.value.DataSfarsit)
  }
  this.http.post(this.appUrl + 'api/GestiunePersonala/raportGestiunePersonalaDupaAngajat', this.excelAngajatModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
}

Search(){
  this.sessionData.gestiunePersonala = this.gestiuneAux;

  if(this.denumire != ""){
    this.sessionData.gestiunePersonala = this.sessionData.gestiunePersonala.filter((res: any) => {
      return res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.numeUser.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.ngOnInit();
  }

}
refresh(){
  this.proiectRezerva = false;
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}

//generare excel
exportExcel() : void {

  let url = this.appUrl + 'api/GestiunePersonala/GenerateReportUserValoareProduse';
  window.open(url);
}
//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/GestiunePersonala/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion


}
