import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-fotovoltaice',
  templateUrl: './gestiune-fotovoltaice.component.html',
  styleUrls: ['./gestiune-fotovoltaice.component.css']
})
export class GestiuneFotovoltaiceComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  p: number = 1;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  ngOnInit(): void {
    this.sessionData.current_page_title = "GESTIUNE FOTOVOLTAICE";
    
  }

  Search(){
    // if(this.denumire != ""){
    //   this.sessionData.gestiuneTabloterie = this.sessionData.gestiuneTabloterie.filter((res: any) => {
    //     return res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    //   });
    // }else if(this.denumire == ""){
    //   this.authService.getTabloterieGestiuneTabloterie();
    // }
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

}
