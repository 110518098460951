import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabloterie-tablouri',
  templateUrl: './tabloterie-tablouri.component.html',
  styleUrls: ['./tabloterie-tablouri.component.css']
})
export class TabloterieTablouriComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;

  panelModal: any = {
    id: '',
    denumire: '',
    serie: '',
    status: '',
    userId: '',
    tabloterieProiectId: '',
  }

  panelModelAdd: any = {
    denumire: '',
    serie: '',
    status: '',
    userId: '',
    tabloterieProiectId: '',
    isFinished: ''
  }

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.projectId = localStorage.getItem('ProjectId');
    this.projectName = localStorage.getItem('ProjectName');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title =  this.projectName;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieTablou/GetTabloterieTablouByProjectId/' + this.projectId, header).subscribe((data) => {
       this.sessionData.listaTablouri = data;
       console.log(data);
    });
  }

  goToProducts(comand: any){
    this.sessionData.panelId = comand.id;
    localStorage.setItem('PanelId',comand.id);
    localStorage.setItem('PanelName',comand.denumire);
    this.router.navigate(['/tabloterie-produse']);
  }

  //#region Editare
  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.panelModal = {
      id: gp.id,
      denumire: gp.denumire,
      serie: gp.serie,
      status: gp.status,
      userId: gp.userId,
      tabloterieProiectId: gp.tabloterieProiectId,
      isFinished: false
    }
  }

  EditResource(fEditCategory: NgForm){
    this.panelModal.status = fEditCategory.value.Status;
    this.panelModal.denumire = fEditCategory.value.Denumire;
    this.panelModal.serie = fEditCategory.value.Serie;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/TabloterieTablou/' + this.panelModal.id, this.panelModal, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

  //#region Stergere Tablou
  returModal(content: any, category: any) {
    this.modalService.open(content);
    this.panelModal.id = category.id;
  }
  delete(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/TabloterieTablou/' + this.panelModal.id, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Adauga Tablou
  addModal(content: any) {
    this.modalService.open(content);
  }

  AddResource(fAddCategory: NgForm){

    this.panelModelAdd= {
      denumire: fAddCategory.value.Denumire,
      serie: fAddCategory.value.Serie,
      status: fAddCategory.value.Status,
      userId: this.sessionData.user.id,
      tabloterieProiectId: this.projectId,
      isFinished: false
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/TabloterieTablou/', this.panelModelAdd, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion
  
  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

}
