import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  appUrl: string = environment.appUrl;
  message: string | undefined;
  constructor(public authService: AuthService, public toastr: ToastrService, public router: Router, public sessionData: SessionDataService, private http: HttpClient,) { }

  ngOnInit(): void {
  }

  ResetPassword(f: NgForm) {
    this.message = "Te rugăm să iți verifici mail-ul!"
    return this.http.post(this.appUrl + 'api/User/ForgetPassword/' + f.value.email, f.value.email).subscribe();
  }
}
