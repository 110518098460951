import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-solicita-serviciile-noastre',
  templateUrl: './solicita-serviciile-noastre.component.html',
  styleUrls: ['./solicita-serviciile-noastre.component.css']
})
export class SolicitaServiciileNoastreComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  emailModel: any = {
    toEmail: '',
    subject: '',
    content: ''
  }
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "SOLICITĂ MENTENANȚĂ"
  }


//#region mentenanta
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){
  this.emailModel = {
    toEmail: this.sessionData.user.email,
    subject: 'SOLICITĂ MENTENANȚĂ',
    content: fAddCategory.value.Detalii
  }
  return this.http.post(this.appUrl + 'api/Mail/SolicitaMentenanta', this.emailModel).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region upgrade
upgradeModal(content: any) {
  this.modalService.open(content);
}

UpgradeResource(fUpgradeCategory: NgForm){
  this.emailModel = {
    toEmail: this.sessionData.user.email,
    subject: 'SOLICITĂ UPGRADE',
    content: fUpgradeCategory.value.Detalii
  }
  return this.http.post(this.appUrl + 'api/Mail/SolicitaUpgrade', this.emailModel).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  
}
//#endregion

//#region interventie
interventieModal(content: any) {
  this.modalService.open(content);
}

InterventieResource(fInterventieCategory: NgForm){
  this.emailModel = {
    toEmail: this.sessionData.user.email,
    subject: 'SOLICITĂ INTERVENTIE',
    content: fInterventieCategory.value.Detalii
  }
  return this.http.post(this.appUrl + 'api/Mail/SolicitaAjutor', this.emailModel).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  
}
//#endregion


adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
