import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stoc-critic',
  templateUrl: './stoc-critic.component.html',
  styleUrls: ['./stoc-critic.component.css']
})
export class StocCriticComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  }

  ngOnInit(): void {
    this.sessionData.current_page_title =  'LISTĂ PRODUSE "STOC CRITIC"';
  }

}
