import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-servicii-secundare',
  templateUrl: './servicii-secundare.component.html',
  styleUrls: ['./servicii-secundare.component.css']
})
export class ServiciiSecundareComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  addAccesoriuModel: any = {
    cod: '',
    denumire: '',
    descriere: '',
    cantitate: '',
    pretZona1:'',
    pretZona2: '',
    pretZona3: ''
  }
  updateAccesoriuModel: any = {
    id: '',
    denumire: '',
    descriere: '',
    cantitate: '',
    pretZona1:'',
    pretZona2: '',
    pretZona3: ''
  }
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  ngOnInit(): void {
    this.sessionData.current_page_title = "OFERTARE - SERVICII SECUNDARE";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareServiciiSecundare/', header).subscribe((data) => {
      this.sessionData.ofertareServicii = data;
    });
  }

    
//#region Adauga descriere
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.addAccesoriuModel = {
    cod: fAddCategory.value.Cod,
    denumire: fAddCategory.value.Denumire,
    descriere: fAddCategory.value.Descriere,
    cantitate: fAddCategory.value.Cantitate,
    pretZona1: fAddCategory.value.PretZona1,
    pretZona2: fAddCategory.value.PretZona2,
    pretZona3: fAddCategory.value.PretZona3,
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/OfertareServiciiSecundare/', this.addAccesoriuModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Editare produs

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.updateAccesoriuModel = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      descriere: gp.descriere,
      cantitate: gp.cantitate,
      pretZona1: gp.pretZona1,
      pretZona2: gp.pretZona2,
      pretZona3: gp.pretZona3,
    }
  }

  EditResource(fEditCategory: NgForm){

    this.updateAccesoriuModel.cod = fEditCategory.value.Cod;
    this.updateAccesoriuModel.denumire = fEditCategory.value.Denumire;
    this.updateAccesoriuModel.descriere = fEditCategory.value.Descriere;
    this.updateAccesoriuModel.cantitate = fEditCategory.value.Cantitate;
    this.updateAccesoriuModel.pretZona1 = fEditCategory.value.PretZona1;
    this.updateAccesoriuModel.pretZona2 = fEditCategory.value.PretZona2;
    this.updateAccesoriuModel.pretZona3 = fEditCategory.value.PretZona3;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/OfertareServiciiSecundare/' + this.updateAccesoriuModel.id, this.updateAccesoriuModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.updateAccesoriuModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/OfertareServiciiSecundare/' + this.updateAccesoriuModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
