import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tabel-prezenta2',
  templateUrl: './tabel-prezenta2.component.html',
  styleUrls: ['./tabel-prezenta2.component.css']
})
export class TabelPrezenta2Component implements OnInit {


  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  p: number = 1;
  userPrezentaModel: any = {
    id: '',
    proiect: '',
    data: '',
    oreLucrate: '',
    user: '',
    serviciuId: '',
    detaliiProiect: ''
  }
  excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }

   editUserPrezentaModel: any = {
    id: '',
    proiectId: '',
    data: '',
    oreLucrate: '',
    userId: '',
    serviciuId: '',
    detaliiProiect: ''
   }

   excelAngajatModel: any = {
    userId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  proiectId: any;
  excelUserId: any;
  isChecked: any = false;
  isChecked1: any = false;
  today: any;
  todayInput: any;
  yesterday: any;

  prezentaModelPdf: any = {
    id: '',
    proiect: '',
    data: '',
    oreLucrate: '',
    user: '',
    detaliiProiect: '',
    listaImagini: ''
  }
  prezentaModelPdfList: any = [];
  imageBase64: any;

  serviciiDeConsultanta: any = 0;
  serviciiDeProiectare: any = 0;
  serviciiDeMontaj: any = 0;
  serviciiDeProgramareSisteme: any = 0;
  serviciiDeVizualizareSiConfigurareAplicatii: any = 0;
  serviciiDeMontajDiverseEchipamente: any = 0;
  idProiectAles: any;
  idContractAles: any;
  proiectFlag: any = false;
  idSectiuneAleasa: any;
  contractFlag: any = false;
  sectiuneFlag: any = false;
//#endregion


constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
}

ngOnInit(): void {

  this.sessionData.current_page_title = "TABEL PREZENȚĂ"
  this.nav.show();
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.get<any>(this.appUrl + 'api/UserPrezenta2/getPrezentaUser', header).subscribe((data) => {
    this.sessionData.userPrezenta = data.sort((a: { data: number; }, b: { data: number; }) => (a.data > b.data ? -1 : 1));
  });
}
 //functie pentru fisierul incarcat
 onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}

refresh(){
  if(this.message == null){
    this.prezentaModelPdfList = [];
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

//#region Editare produs

async editModal(content: any, gp: any) {
  const proiecte = this.authService.getProiecte2();
  await lastValueFrom(proiecte);

  this.modalService.open(content);
  this.userPrezentaModel = {
    id: gp.id,
    proiectId: gp.proiectId,
    contractId: gp.contractId,
    sectiuneId: gp.sectiuneId,
    data: gp.data,
    oreLucrate: gp.oreLucrate,
    userId: gp.userId,
    serviciuId: gp.serviciuId,
    detaliiProiect: gp.detaliiProiect
  }

  this.idProiectAles = this.userPrezentaModel.proiectId;
  this.idContractAles = this.userPrezentaModel.contractId;
  this.idSectiuneAleasa = this.userPrezentaModel.sectiuneId;

  const contracte = this.authService.getProiecte2Contracte(this.idProiectAles);
  await lastValueFrom(contracte);

  const sectiuni = this.authService.getProiecte2ContracteSectiuni(this.idContractAles);
  await lastValueFrom(sectiuni);

  const manopera = this.authService.getProiecte2ContracteSectiuniManopera(this.idSectiuneAleasa);
  await lastValueFrom(manopera);

  this.todayInput = new Date;
  this.today = new Date;
  this.yesterday = new Date;
  this.yesterday.setDate(this.today.getDate() - 1);
  
}

EditResource(fEditCategory: NgForm){

  this.editUserPrezentaModel = {
    id: this.userPrezentaModel.id,
    proiectId: Number(this.idProiectAles),
    contractId: Number(this.idContractAles),
    sectiuneId: Number(this.idSectiuneAleasa),
    data: new Date(fEditCategory.value.Data),
    oreLucrate: fEditCategory.value.OreLucrate,
    userId: this.userPrezentaModel.userId,
    serviciuId: Number(fEditCategory.value.DenumireServiciu),
    detaliiProiect: fEditCategory.value.Mentiuni
   }
   var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/UserPrezenta2/updatePrezenta', this.editUserPrezentaModel, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

async changeProiect(){
  const proiecte = this.authService.getProiecte2Contracte(this.idProiectAles);
  await lastValueFrom(proiecte);
  this.proiectFlag = true;
}

async changeContract(){
  const proiecte = this.authService.getProiecte2ContracteSectiuni(this.idContractAles);
  await lastValueFrom(proiecte);
  this.contractFlag = true;
}

async changeSectiune(){
  const proiecte = this.authService.getProiecte2ContracteSectiuniManopera(this.idSectiuneAleasa);
  await lastValueFrom(proiecte);
  this.sectiuneFlag = true;
}


//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.userPrezentaModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.post(this.appUrl + 'api/UserPrezenta/deletePrezenta/' + this.userPrezentaModel.id, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion


Search(){
if(this.denumire != ""){
  this.sessionData.userPrezenta = this.sessionData.userPrezenta.filter(((res: { data: string; user: { nume: string; }; }) => {
    return res.data.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.user.nume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
  }));
}else if(this.denumire == ""){
  this.ngOnInit();
}

}
 //enable proiecte
 checkValue(event: any){
}

}
