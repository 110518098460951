import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ofertare-gestiune-ofertare2',
  templateUrl: './ofertare-gestiune-ofertare2.component.html',
  styleUrls: ['./ofertare-gestiune-ofertare2.component.css']
})
export class OfertareGestiuneOfertare2Component implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  numarOferta: any;
  listaClienti: any = [];
  numeClientAles: any;
  idClientAles: any;
  listaProiecte: any = [];
  numeProiectAles: any;
  ofertaId: any;
  denumireOferta: any;
  denumire: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  async ngOnInit(): Promise<void> {
    localStorage.removeItem('NumarOferta');
    localStorage.removeItem('NumeClient');
    localStorage.removeItem('NumeProiect');
    localStorage.removeItem('DenumireOferta');
    this.sessionData.current_page_title = "BAZA DE DATE - OFERTE";
    
    const oferte = this.authService.getOferte2();
    await lastValueFrom(oferte);
  }

  //#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.ofertaId = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/OfertareGestiuneOferte/deleteOfertare2/' + this.ofertaId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

editModal(item: any){
  console.log(item);
  this.sessionData.ofertaId = item.id;
  localStorage.setItem('OfertaId',item.id);
  localStorage.setItem('NumarOferta',item.numar);
  localStorage.setItem('DenumireOferta',item.denumireOferta);
  localStorage.setItem('NumeProiect', item.proiect.denumireProiect);
  this.router.navigate(['/ofertare-editare-oferta2']);
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
