import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-productie',
  templateUrl: './gestiune-productie.component.html',
  styleUrls: ['./gestiune-productie.component.css']
})
export class GestiuneProductieComponent implements OnInit {

   //#region Declarari
   userId: number | undefined;
   appUrl: string = environment.appUrl;
   selectedfile: File | undefined;
   message: string | undefined;
   denumire: any;
   gPrincipala : any;
   p: number = 1;
   buttonDisable: boolean = false;
   gRetetarModel: any = {
    gestiuneRetetar: '',
    gestiuneRetetarId: '',
    cantitate: '',
    userId: '',
    data: ''
   }
   gPrincipalaModel: any;
   addGTransferModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     userId: '',
     numeUser: '',
     proiectId: ''
   };
   addRezervareModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     proiectId: '',
     numeUser: ''
   };
   addGVanzareModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     numeUser: '',
     mentiuni: ''
   };
   fileName= 'ExcelSheet.xlsx';
   userName : any;
   file : any = 'file';
   buffer: any;
   gestiuneRetetarAux: any;
   idProiectAles: any;
   idContractAles: any;
   proiectFlag: any = false;
   idSectiuneAleasa: any;
   contractFlag: any = false;
   addGProductieModel: any;

   gProductieModel: any = {
    id: '',
    cantitate: '',
    data: '',
    numeUser: '',
    userId: '',
    gestiuneRetetar: ''
  };
  returGTransferModel: any = {
    gestiunePrincipalaId: '',
    gestiunePersonalaId: '',
    numeUser: '',
    userId: '',
    cantitate: ''
  };
 //#endregion
 
   constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
   }
 
   async ngOnInit(): Promise<void> {
     this.sessionData.current_page_title = "GESTIUNE PRODUCTIE";
     this.buttonDisable = false;
 
     const gestiunePrincipala = this.authService.getGestiuneProductie();
     await lastValueFrom(gestiunePrincipala);
     
     this.gestiuneRetetarAux = this.sessionData.gestiuneRetetar;
   }

   //#region Retur Produs
returModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gProductieModel = {
    id: gp.id,
    cantitate: gp.cantitate,
    data: gp.data,
    numeUser: gp.numeUser,
    userId: gp.userId,
    gestiuneRetetar: gp.gestiuneRetetar
  }
}
DeleteResource(fReturProdus: any){

  this.buttonDisable = true;

    this.returGTransferModel = {
      id: this.gProductieModel.id,
      cantitate: fReturProdus.value.Cantitate
    }

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.post(this.appUrl + 'api/GestiuneProductie/returnGestiuneProductie/', this.returGTransferModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Cantitate incorecta!"
      }
    );
}
//#endregion

  //#region Transfera in Gestiune Principala

  async addToGProductieModal(content: any, gp: any) {

    this.modalService.open(content);
    this.gRetetarModel = {
      gestiuneRetetar: gp.gestiuneRetetar,
      gestiuneRetetarId: gp.id,
      cantitate: gp.cantitate,
      userId: this.sessionData.user.id,
      data: null
     }
  }
  
  AddGProductieResource(fAddGProductie: NgForm){
  
    this.buttonDisable = true;
  
    if(fAddGProductie.value.Cantitate < 0){
      this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
      return;
    }
    else{
  
      this.addGProductieModel = {
        gestiuneProductieId: this.gRetetarModel.gestiuneRetetarId,
        cantitate: fAddGProductie.value.Cantitate,
      }
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
  
      console.log(this.addGProductieModel);
  
    return this.http.post(this.appUrl + 'api/GestiuneProductie/addToGestiunePrincipala', this.addGProductieModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        this.message = "Cantitate incorecta"
      }
    );
    }
  
  
  }
  
  //#endregion
  
  

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
