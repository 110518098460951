import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-aprovizionare',
  templateUrl: './gestiune-aprovizionare.component.html',
  styleUrls: ['./gestiune-aprovizionare.component.css']
})
export class GestiuneAprovizionareComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  aprovizionareModelUpdate: any = {
    id: '',
    denumire: '',
    status: '',
    ofertaId: ''
  }

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "APROVIZIONARE - LISTĂ COMENZI";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizionareComanda/', header).subscribe((data) => {
       this.sessionData.listaComenzi = data;
    });
  }

  goToProducts(comand: any){
    this.sessionData.comandId = comand.id;
    localStorage.setItem('ComandId',comand.id);
    localStorage.setItem('ComandName',comand.denumire);
    localStorage.setItem('ComandStatus',comand.status);
    this.router.navigate(['/gestiune-aprovizionare-comanda']);
  }

 //#region Editare status
 editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.aprovizionareModelUpdate = {
    id: gp.id,
    denumire: gp.denumire,
    status: gp.status,
    ofertaId: gp.ofertaId
  }
  
}

EditResource(fEditCategory: NgForm){
  this.aprovizionareModelUpdate.status = fEditCategory.value.Status;
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/AprovizionareComanda/' + this.aprovizionareModelUpdate.id, this.aprovizionareModelUpdate, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.aprovizionareModelUpdate.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/AprovizionareComanda/' + this.aprovizionareModelUpdate.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

Search(){
  if(this.denumire != ""){
    this.sessionData.listaComenzi = this.sessionData.listaComenzi.filter((res: any) => {
      return res.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.status.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.ngOnInit();
  }
}

}
