import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-protectia-muncii',
  templateUrl: './gestiune-protectia-muncii.component.html',
  styleUrls: ['./gestiune-protectia-muncii.component.css']
})
export class GestiuneProtectiaMunciiComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  protectiaMuncii: any;
  protectiaMunciiAux: any;
  denumire: any;
  anunt: any;
  anuntModel: any; 
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
   }

  ngOnInit(): void {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.sessionData.current_page_title = "LISTA ANUNTURI - PROTECTIA MUNCII"

    this.http.get<any>(this.appUrl + 'api/GestiuneAnunturi/getAnunturiObligatorii', header).subscribe((data) => {
      this.protectiaMuncii = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      this.protectiaMunciiAux = this.protectiaMuncii;
    });

  }

  Search(){
    this.protectiaMuncii = this.protectiaMunciiAux;
    if(this.denumire != ""){
      this.protectiaMuncii = this.protectiaMuncii.filter((res: any) => {
        return res.id.toString().match(this.denumire);
      });
    }else if(this.denumire == ""){
      this.ngOnInit();
    }
  }

  openModal(content: any, gp: any){
    this.modalService.open(content);
    this.anunt = gp.descriere;
  }

   //#region Adauga Anunt
   async addAnuntModal(content: any) {
    this.modalService.open(content);
  }
 
  AddAnuntResource(fAddAnunt: NgForm){
    if(fAddAnunt.value.anuntInformativ == true && fAddAnunt.value.anuntObligatoriu == true){
      this.message = "Alege o singura optiune!"
    }
    this.anuntModel = {
      userId: this.sessionData.user.id,
      descriere: fAddAnunt.value.DescriereAnunt,
      data: new Date(fAddAnunt.value.Data)
    }
    
    fAddAnunt.value.File = "file";
    fAddAnunt.value.Files = this.selectedfile;
    const formData = new FormData();
    formData.append("UserId", this.sessionData.user.id);
    formData.append("Descriere", fAddAnunt.value.DescriereAnunt);
    formData.append("Data", this.anuntModel.data.toDateString());
    if(fAddAnunt.value.anuntInformativ == true){
      formData.append("Tip", "AnuntInformativ");
    }
    else if(fAddAnunt.value.anuntObligatoriu == true){
      formData.append("Tip", "AnuntObligatoriu");
    }
    formData.append("File", fAddAnunt.value.File.toString());
    formData.append("Files",  fAddAnunt.value.Files);

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.post(this.appUrl + 'api/GestiuneAnunturi',  formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
     );
  }
//#endregion



  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
}
