import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiect2-sectiune-materiale',
  templateUrl: './proiect2-sectiune-materiale.component.html',
  styleUrls: ['./proiect2-sectiune-materiale.component.css']
})
export class Proiect2SectiuneMaterialeComponent implements OnInit {

  //#region Declarari
  projectOption: any = "Toate";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id: '',
    numeSectiune: '',
    proiect2ContractId: ''
  }

  addProiectModel: any ={
    cod: '',
    denumire: '',
    cantitate: '',
    pretVanzare: '',
    pretTotalVanzare: '',
    pretAchizitie: '',
    pretTotalAchizitie: '',
    adaos: '',
    adaosTotal: '',
    cantitateStoc: '',
    cantitateRezervaProiect: '',
    cantitateDescarcata: '',
    proiect2ContractSectiuneId: ''
  }

  editProiectModel: any = {
    id: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretVanzare: '',
    pretTotalVanzare: '',
    pretAchizitie: '',
    pretTotalAchizitie: '',
    adaos: '',
    adaosTotal: '',
    cantitateStoc: '',
    cantitateRezervaProiect: '',
    cantitateDescarcata: '',
    proiect2ContractSectiuneId: ''
  }
  
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  searchModel: any = {
    cuvant: ''
  };
  produseOfertare: any;
  stocModel: any = {
    cantitateStoc: '',
    cantitateRezervata: '',
    cantitateDescarcata: ''
  };
  stocModelFlag: any = false;
  codModel: any = {
    cod: '',
    sectiuneId: ''
  }
  updateAccesoriuModel: any = {
    id: '',
    cod: '',
    denumire: '',
    pretUnitarAchizitie: ''
  }
//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    sessionData.sectiuneId = localStorage.getItem('SectiuneId');
  }
  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "MATERIALE - " + localStorage.getItem('SectiuneName');
    
    const proiecte = this.authService.getProiecte2ContracteSectiuniMateriale(this.sessionData.sectiuneId);
    await lastValueFrom(proiecte);
  }

  ngOnDestroy() {
    localStorage.removeItem('SectiuneId');
    localStorage.removeItem('SectiuneName');
  }


  goToManopera(project: any){
    this.sessionData.sectiuneId = project.id;
    localStorage.setItem('SectiuneId',project.id);
    localStorage.setItem('SectiuneName',project.numeSectiune);
    
    this.router.navigate(['/proiect2-sectiune-manopera']);
  }

  goToMateriale(project: any){
    this.sessionData.sectiuneId = project.id;
    localStorage.setItem('SectiuneId',project.id);
    localStorage.setItem('SectiuneName',project.numeSectiune);
    
    this.router.navigate(['/proiect2-sectiune-materiale']);
  }

  //#region Editare proiect

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectModel = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      cantitate: gp.cantitate,
      pretVanzare: gp.pretVanzare,
      pretTotalVanzare: gp.pretTotalVanzare,
      pretAchizitie: gp.pretAchizitie,
      pretTotalAchizitie: gp.pretTotalAchizitie,
      adaos: gp.adaos,
      adaosTotal: gp.adaosTotal,
      cantitateStoc: gp.cantitateStoc,
      cantitateRezervaProiect: gp.cantitateRezervaProiect,
      cantitateDescarcata: gp.cantitateDescarcata,
      proiect2ContractSectiuneId: gp.proiect2ContractSectiuneId,
    }
  }

  EditResource(fEditCategory: NgForm){

    let flag = false;
    if(this.stocModel.CantitateDescarcata == fEditCategory.value.Cantitate){
      flag = true;
    }
    this.editProiectModel = {
      id: this.proiectModel.id,
      proiect2ContractSectiuneId: this.proiectModel.proiect2ContractSectiuneId,
      cod: fEditCategory.value.Cod,
      denumire: fEditCategory.value.Denumire,
      cantitate: fEditCategory.value.Cantitate,
      pretVanzare: fEditCategory.value.PretVanzare,
      pretTotalVanzare: fEditCategory.value.PretTotalVanzare,
      pretAchizitie: fEditCategory.value.PretAchizitie,
      pretTotalAchizitie: fEditCategory.value.PretTotalAchizitie,
      adaos: fEditCategory.value.Adaos,
      adaosTotal: fEditCategory.value.AdaosTotal,
      cantitateStoc: fEditCategory.value.CantitateStoc,
      cantitateRezervaProiect: fEditCategory.value.CantitateRezervaProiect,
      cantitateDescarcata: fEditCategory.value.CantitateDescarcata,
      flag: flag
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/Proiect2/UpdateProiect2ContractSectiuneMateriale/' + this.editProiectModel.id, this.editProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

  //#region Adauga Produs
  addModal(content: any, currentTable: any, tableType: any) {
    this.modalService.open(content);
  }
  
  AddResource(fAddCategory: NgForm){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.searchModel.cuvant = this.denumire;
  
    this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProduseBySearch', this.searchModel, header).subscribe((data) => {
      this.produseOfertare = data;
    });
  }
  
  addProductFromSearch(content: any, gp: any) {
    this.modalService.open(content);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.codModel = {
      cod: gp.cod,
      sectiuneId: this.sessionData.sectiuneId
    }
    this.http.post<any>(this.appUrl + 'api/Proiect2/getStocuriByCod', this.codModel, header).subscribe((data) => {
      this.stocModel = data;
      this.stocModelFlag = true;
    });
    this.updateAccesoriuModel = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      pretUnitarAchizitie: gp.pretUnitarAchizitie
    }
  }
  
  AddProductResource(fEditCategory: NgForm){
  
    let flag = false;
    if(this.stocModel.CantitateDescarcata == fEditCategory.value.Cantitate){
      flag = true;
    }

    this.addProiectModel = {
      cod: fEditCategory.value.Cod,
      denumire: fEditCategory.value.Denumire,
      cantitate: fEditCategory.value.Cantitate,
      pretVanzare: fEditCategory.value.PretVanzare,
      pretTotalVanzare: fEditCategory.value.PretTotalVanzare,
      pretAchizitie: fEditCategory.value.PretUnitarAchizitie,
      pretTotalAchizitie: fEditCategory.value.PretTotalAchizitie,
      adaos: fEditCategory.value.Adaos,
      adaosTotal: fEditCategory.value.AdaosTotal,
      cantitateStoc: fEditCategory.value.CantitateStoc,
      cantitateRezervaProiect: fEditCategory.value.CantitateRezervaProiect,
      cantitateDescarcata: fEditCategory.value.CantitateDescarcata,
      proiect2ContractSectiuneId: this.sessionData.sectiuneId,
      flag: flag
    }
  
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  
    return this.http.post(this.appUrl + 'api/proiect2/addProiect2ContractSectiuneMateriale', this.addProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

    //#region Stergere Proiect
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/Proiect2/deleteProiect2ContractSectiuneMateriale/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
}}
}
