import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-clienti',
  templateUrl: './proiecte-clienti.component.html',
  styleUrls: ['./proiecte-clienti.component.css']
})
export class ProiecteClientiComponent implements OnInit {
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  clientId: any;
  clientName: any;
  isCheckOferta: boolean = false;
  isCheckContracte: boolean = false;
  isCheckFacturi: boolean = false;
  isCheckRapoarte: boolean = false;
  isCheckDiverse: boolean = false;
  isCheckDocumentatieTehnica: boolean = false;
  checkNext1: boolean = false;
  tipFisier: string = '';
 
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.clientId = localStorage.getItem('ClientId');
    this.clientName = localStorage.getItem('ClientName');

  }
  ngOnInit(): void {
    this.sessionData.current_page_title = "PROIECTE";
  }
  //functie pentru fisierul incarcat
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }
  selectArea(area: any){
    this.sessionData.clientProiectId = area.id;
    let item: any;
    this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
      if(element.id == area.id){
        item = element;
      }
    });
    this.sessionData.stareOferte = item.stareOferte;
    this.sessionData.stareContracte = item.stareContracte;
    this.sessionData.stareFacturi = item.stareFacturi;
    this.sessionData.stareRapoarteDeLucru = item.stareRapoarteDeLucru;
    this.sessionData.stareDiverse = item.stareDiverse;
    this.sessionData.stareDocumentatieTehnica = item.stareDocumentatieTehnica;
  }
  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  
  goToOferte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/oferte-dashboard']);
  }
  goToContracte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/contracte-dashboard']);
  }
  goToFacturi(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/facturi-dashboard']);
  }
  goToRapoarte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/rapoartedelucru-dashboard']);
  }
  goToDiverse(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/diverse-dashboard']);
  }
  goToDocumentatieTehnica(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/documentatie-tehnica-dashboard']);
  }
  goToFisiereClient(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/fisiere-client']);
  }

  //#region Adauga fisier
 addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("Data", myDate.toDateString());
  formData.append("File", fAddCategory.value.File.toString());
  formData.append("Files",  fAddCategory.value.Files);
  formData.append("UserId",  this.sessionData.user.id);
  formData.append("IdTipFisier",  "7");
  formData.append("ProiectId",  this.sessionData.clientProiectId);
  formData.append("Stare",  "false");

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ClientFisier/', formData, header).subscribe(
   (response) => {    
      window.location.reload();
   },
   (error) => { 
     console.error('error caught in component')
     this.message = "Eroare"
   }
  );
}
//#endregion


}
