import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-articole-manopera',
  templateUrl: './articole-manopera.component.html',
  styleUrls: ['./articole-manopera.component.css']
})
export class ArticoleManoperaComponent implements OnInit {

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  denumire: any;
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  serviciuModel : any = {
    denumireServiciu : '',
    idServiciu : '',
    costServiciu : ''
  }
  serviciuModelUpdate : any = {
    id: '',
    denumireServiciu : '',
    idServiciu : '',
    costServiciu : ''
  }
  p: number = 1;

  ngOnInit(): void {
    this.sessionData.current_page_title = "ARTICOLE MANOPERA"


  }

//#region Adauga serviciu
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.serviciuModel = {
    denumireServiciu : fAddCategory.value.Denumire,
    idServiciu : fAddCategory.value.IdServiciu,
    costServiciu : fAddCategory.value.Cost
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ServiciuManopera/', this.serviciuModel, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Editare produs

editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.serviciuModelUpdate = {
    id: gp.id,
    denumireServiciu : gp.denumireServiciu,
    idServiciu : gp.idServiciu,
    costServiciu : gp.costServiciu
  }
}

EditResource(fEditCategory: NgForm){

 this.serviciuModelUpdate.denumireServiciu = fEditCategory.value.Denumire;
 this.serviciuModelUpdate.idServiciu = fEditCategory.value.IdServiciu;
 this.serviciuModelUpdate.costServiciu = fEditCategory.value.Cost;
 var header = {
  headers: new HttpHeaders()
    .set('Authorization',  `Bearer ${this.authService.getToken()}`)
}
  return this.http.put(this.appUrl + 'api/ServiciuManopera/' + this.serviciuModelUpdate.id, this.serviciuModelUpdate, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.serviciuModelUpdate.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/ServiciuManopera/' + this.serviciuModelUpdate.id, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}


}
