import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-tabel-prezenta',
  templateUrl: './tabel-prezenta.component.html',
  styleUrls: ['./tabel-prezenta.component.css']
})
export class TabelPrezentaComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  p: number = 1;
  userPrezentaModel: any = {
    id: '',
    proiect: '',
    data: '',
    oreLucrate: '',
    user: '',
    serviciuId: '',
    detaliiProiect: ''
  }
  excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }

   editUserPrezentaModel: any = {
    id: '',
    proiectId: '',
    data: '',
    oreLucrate: '',
    userId: '',
    serviciuId: '',
    detaliiProiect: ''
   }

   excelAngajatModel: any = {
    userId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  proiectId: any;
  excelUserId: any;
  isChecked: any = false;
  isChecked1: any = false;
  today: any;
  todayInput: any;
  yesterday: any;

  prezentaModelPdf: any = {
    id: '',
    proiect: '',
    proiectId: '',
    data: '',
    oreLucrate: '',
    user: '',
    detaliiProiect: '',
    listaImagini: ''
  }
  prezentaModelPdfList: any = [];
  imageBase64: any;

  serviciiDeConsultanta: any = 0;
  serviciiDeProiectare: any = 0;
  serviciiDeMontaj: any = 0;
  serviciiDeProgramareSisteme: any = 0;
  serviciiDeVizualizareSiConfigurareAplicatii: any = 0;
  serviciiDeMontajDiverseEchipamente: any = 0;

//#endregion


constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
}

ngOnInit(): void {

  this.sessionData.current_page_title = "TABEL PREZENȚĂ"
  this.nav.show();
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.get<any>(this.appUrl + 'api/UserPrezenta/getPrezentaUser', header).subscribe((data) => {
    this.sessionData.userPrezenta = data.sort((a: { data: number; }, b: { data: number; }) => (a.data > b.data ? -1 : 1));
    console.log(this.sessionData.userPrezenta);
  });
}
 //functie pentru fisierul incarcat
 onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}

//#region Editare produs

async editModal(content: any, gp: any) {
  const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);

  this.modalService.open(content);
  this.userPrezentaModel = {
    id: gp.id,
    proiect: gp.proiect,
    data: gp.data,
    oreLucrate: gp.oreLucrate,
    user: gp.user,
    userId: gp.userId,
    serviciuId: gp.serviciuId,
    detaliiProiect: gp.detaliiProiect
  }
  this.todayInput = new Date;
  this.today = new Date;
  this.yesterday = new Date;
  this.yesterday.setDate(this.today.getDate() - 1);

}

EditResource(fEditCategory: NgForm){

  this.editUserPrezentaModel = {
    id: this.userPrezentaModel.id,
    proiectId: fEditCategory.value.DenumireProiect,
    data: new Date(fEditCategory.value.Data),
    oreLucrate: fEditCategory.value.OreLucrate,
    userId: this.userPrezentaModel.userId,
    serviciuId: this.userPrezentaModel.serviciuId,
    detaliiProiect: fEditCategory.value.Mentiuni
   }
   var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/UserPrezenta/' + this.editUserPrezentaModel.id, this.editUserPrezentaModel, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.userPrezentaModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/UserPrezenta/' + this.userPrezentaModel.id, header).subscribe(
    (response) => {
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion


Search(){
if(this.denumire != ""){
  this.sessionData.userPrezenta = this.sessionData.userPrezenta.filter(((res: { data: string; user: { nume: string; }; }) => {
    return res.data.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.user.nume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
  }));
}else if(this.denumire == ""){
  this.ngOnInit();
}

}
 //enable proiecte
 checkValue(event: any){
}

//#region excel proiect
async exportExcelProiect(content: any) : Promise<void> {
  const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
  this.modalService.open(content);

}

ExportExcelProiectResource(fexportExcelProiect: any){
  if(fexportExcelProiect.value.gestiunePrincipala == true && fexportExcelProiect.value.rezervareProiect == true){
    this.message = "Alege o singura optiune!"
  }

  else if(fexportExcelProiect.value.gestiunePrincipala == true){
    this.excelProiectModel = {
      proiectId: 0,
      dataInceput: fexportExcelProiect.value.DataInceput,
      dataSfarsit: fexportExcelProiect.value.DataSfarsit
    }

  }
  else if(fexportExcelProiect.value.rezervareProiect == true){

    this.sessionData.userPrezenta.forEach((element: any) => {
      if(element.proiect == fexportExcelProiect.value.Proiect){
        this.proiectId = element.proiectId;
      }
    });
    this.excelProiectModel = {
      proiectId: this.proiectId,
      dataInceput: fexportExcelProiect.value.DataInceput,
      dataSfarsit: fexportExcelProiect.value.DataSfarsit
    }
  }
  this.http.post(this.appUrl + 'api/UserPrezenta/raportUserPrezentaDupaProiect', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion

//#region excel angajat
exportExcelAngajat(content: any) : void {
  this.modalService.open(content);
}

ExportExcelAngajatResource(fexportExcelAngajat: any){
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportExcelAngajat.value.User){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: fexportExcelAngajat.value.DataInceput,
    dataSfarsit: fexportExcelAngajat.value.DataSfarsit
  }

  this.http.post(this.appUrl + 'api/UserPrezenta/raportUserPrezentaDupaAngajat', this.excelAngajatModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
//#endregion

//#region excel angajat PDF
exportExcelAngajatPDF(content: any) : void {
  this.modalService.open(content);
}

ExportExcelAngajatPDFResource(fexportExcelAngajatPDF: any){
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportExcelAngajatPDF.value.User){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: fexportExcelAngajatPDF.value.DataInceput,
    dataSfarsit: fexportExcelAngajatPDF.value.DataSfarsit
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/UserPrezenta/RaportPdfUserPrezenta', this.excelAngajatModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region excel angajat complet
async exportExcelProiectComplet(content: any){
  const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
  this.modalService.open(content);
}

async ExportExcelProiectCompletResource(fexportExcelAngajat: any){

  for(let element of this.sessionData.userPrezenta){
    if(element.proiectId == fexportExcelAngajat.value.Proiect){
      if(fexportExcelAngajat.value.rezervareProiect == true){
        if(element.userId == fexportExcelAngajat.value.UserName){
          if(element.data >= fexportExcelAngajat.value.DataInceput.toISOString() && element.data <= fexportExcelAngajat.value.DataSfarsit.toISOString()){
            var imageBase64List: any[] = [];
            for(let element1 of element.listaImagini){
              this.imageBase64 = await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element1);
              imageBase64List.push(this.imageBase64);
            }
            if(element.serviciuId == 1){
              this.serviciiDeConsultanta = this.serviciiDeConsultanta + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 2){
              this.serviciiDeProiectare = this.serviciiDeProiectare + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 3){
              this.serviciiDeMontaj = this.serviciiDeMontaj + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 4){
              this.serviciiDeProgramareSisteme = this.serviciiDeProgramareSisteme + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 5){
              this.serviciiDeVizualizareSiConfigurareAplicatii = this.serviciiDeVizualizareSiConfigurareAplicatii + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 6){
              this.serviciiDeMontajDiverseEchipamente = this.serviciiDeMontajDiverseEchipamente + parseFloat(element.oreLucrate);
            }

            this.prezentaModelPdf = {
              id: element.id,
              proiect: element.proiect,
              proiectId: element.proiectId,
              data: element.data,
              oreLucrate: element.oreLucrate,
              user: element.user,
              detaliiProiect: element.detaliiProiect,
              listaImagini: imageBase64List
            }
            this.prezentaModelPdfList.push(this.prezentaModelPdf);
          }

        }
      }
      else{
        if(element.data >= fexportExcelAngajat.value.DataInceput.toISOString() && element.data <= fexportExcelAngajat.value.DataSfarsit.toISOString()){
          var imageBase64List: any[] = [];

          for(let element1 of element.listaImagini){
            this.imageBase64 = await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element1)
            imageBase64List.push(this.imageBase64);
          }
            if(element.serviciuId == 1){
              this.serviciiDeConsultanta = this.serviciiDeConsultanta + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 2){
              this.serviciiDeProiectare = this.serviciiDeProiectare + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 3){
              this.serviciiDeMontaj = this.serviciiDeMontaj + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 4){
              this.serviciiDeProgramareSisteme = this.serviciiDeProgramareSisteme + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 5){
              this.serviciiDeVizualizareSiConfigurareAplicatii = this.serviciiDeVizualizareSiConfigurareAplicatii + parseFloat(element.oreLucrate);
            }
            else if(element.serviciuId == 6){
              this.serviciiDeMontajDiverseEchipamente = this.serviciiDeMontajDiverseEchipamente + parseFloat(element.oreLucrate);
            }
          this.prezentaModelPdf = {
            id: element.id,
            proiect: element.proiect,
            proiectId: element.proiectId,
            data: element.data,
            oreLucrate: element.oreLucrate,
            user: element.user,
            detaliiProiect: element.detaliiProiect,
            listaImagini: imageBase64List
          }
          this.prezentaModelPdfList.push(this.prezentaModelPdf);
        }
    }
    }

  }
  this.sessionData.userPrezenta.forEach(async (element: any) => {
  });
  var pdf: any = {
    content: [
      {text: 'RAPORT DE LUCRU', style: 'header'},
      {text: 'Denumire proiect: ' + this.prezentaModelPdf.proiect.denumireProiect, style: 'subheader'},
      {text: 'Data emitere: ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy'), style: 'subheader'},
      {text: 'Interval de raportare: ' + this.datepipe.transform(fexportExcelAngajat.value.DataInceput, 'dd-MM-yyyy') + " - " + this.datepipe.transform(fexportExcelAngajat.value.DataSfarsit, 'dd-MM-yyyy'), style: 'subheader1'},
      this.getTablePdf(),
      {text: 'TOTAL ORE LUCRATE', style: 'subheader'},
      this.getTotalOre()
    ],
    styles: {
      subheader: {
        fontSize: 10,
        margin: [0, 0, 0, 0],
        bold: true,
        alignment: 'center',
      },
      subheader1: {
        fontSize: 10,
        margin: [0, 0, 0, 9],
        bold: true,
        alignment: 'center',
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: 'black'
      },
      tableBody: {
        fontSize: 7
      },
      footer: {
        fontSize: 8,
        bold: true
      },
      tableBody1: {
        fontSize: 7,
        bold: true
      },
      tableBody2: {
        fontSize: 9,
        bold: true
      },
      header: {
        fontSize: 15,
        bold: true,
        alignment: 'center',
        margin: [0, 5, 0, 5]
      },
      titluDescriere: {
        fontSize: 15,
        bold: true,
      },
      textDescriere: {
        fontSize: 12,
        bold: true,
      },
      textEchipament: {
        fontSize: 9,
        bold: true,
      }
    },
  }

  pdfMake.createPdf(pdf).open();
  //window.location.reload();
}
//#endregion

getBase64ImageFromURL(url: any): Promise<any> {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}

getTablePdf(){
  var table1: any = [];
  var header = [{style:'tableHeader', colSpan: 2, alignment: 'center', text: 'Denumire Proiect'},
                {text: ''},
                {style:'tableHeader', colSpan: 1, alignment: 'center', text: 'Data'},
                {style:'tableHeader', colSpan: 1, alignment: 'center', text: 'Ore Lucrate'},
                {style:'tableHeader', colSpan: 2, alignment: 'center', text: 'Nume User'},
                {text: ''},
              ];
  table1.push(header);
  this.prezentaModelPdfList.forEach((element: any) => {
    var line1 = [{style:'tableBody', colSpan: 2, alignment: 'center', text: element.proiect},
                {text: ''},
                {style:'tableBody', colSpan: 1, alignment: 'center', text: this.datepipe.transform(element.data, 'dd-MM-yyyy')},
                {style:'tableBody', colSpan: 1, alignment: 'center', text: element.oreLucrate},
                {style:'tableBody', colSpan: 2, alignment: 'center', text: element.user},
                {text: ''}
    ];
    table1.push(line1);
    var line2 = [{style:'tableBody', colSpan: 6, alignment: 'center', text: element.detaliiProiect},
                {text: ''},
                {text: ''},
                {text: ''},
                {text: ''},
                {text: ''}
                ];
    table1.push(line2);
    if(element.listaImagini.length == 0){
      var line3_0 = [{text: ''},
                  {text: ''},
                  {text: ''},
                  {text: ''},
                  {text: ''},
                  {text: ''}
                  ];
      table1.push(line3_0);
    }
    else if(element.listaImagini.length == 1){
      var line3_1 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''}
                  ];
      table1.push(line3_1);
    }
    else if(element.listaImagini.length == 2){
      var line3_2 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[1], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {text: ''},
                      {text: ''},
                      {text: ''},
                      {text: ''}
                  ];
      table1.push(line3_2);
    }
    else if(element.listaImagini.length == 3){
      var line3_3 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[1], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[2], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {text: ''},
                      {text: ''},
                      {text: ''}
                  ];
      table1.push(line3_3);
    }
    else if(element.listaImagini.length == 4){
      var line3_4 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[1], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[2], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[3], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {text: ''},
                      {text: ''}
                  ];
      table1.push(line3_4);
    }
    else if(element.listaImagini.length == 5){
      var line3_5 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[1], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[2], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[3], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[4], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {text: ''}
                  ];
      table1.push(line3_5);
    }
    else if(element.listaImagini.length == 6){
      var line3_6 = [{image: element.listaImagini[0], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[1], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[2], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[3], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[4], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                      {image: element.listaImagini[5], width: 80, height: 80,  alignment: 'center', border: [false, false, false, false]},
                  ];
      table1.push(line3_6);
    }

  });
  return { style: 'tableExample', table: { body: table1, widths: ['16%', '16%', '16%', '16%', '16%', '16%']}, layout: 'lightHorizontalLines',}

}

getTotalOre(){
  let table: any = [];
  
  let tablePush: any;
  if(this.serviciiDeConsultanta != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE CONSULTANTA'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeConsultanta}]
    table.push(tablePush);
  }
  if(this.serviciiDeProiectare != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROIECTARE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeProiectare}]
    table.push(tablePush);
  }
  if(this.serviciiDeMontaj != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeMontaj}]
    table.push(tablePush);
  }
  if(this.serviciiDeProgramareSisteme != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROGRAMARE SISTEME'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeProgramareSisteme}]
    table.push(tablePush);
  }
  if(this.serviciiDeVizualizareSiConfigurareAplicatii != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE VIZUALIZARE SI CONFIGURARE APLICATII'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeVizualizareSiConfigurareAplicatii}]
    table.push(tablePush);
  }
  if(this.serviciiDeMontajDiverseEchipamente != 0){
    tablePush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ DIVERSE ECHIPAMENTE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiDeMontajDiverseEchipamente}]
    table.push(tablePush);
  }

  return { style: 'tableExample', table: { body: table, widths: ['75%', '25%'] }, layout: 'lightHorizontalLines'};

}

refresh(){
  if(this.message == null){
    this.prezentaModelPdfList = [];
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}

