import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-dashboard-task-user',
  templateUrl: './dashboard-task-user.component.html',
  styleUrls: ['./dashboard-task-user.component.css']
})
export class DashboardTaskUserComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  token: any;
  denumire: any;
  taskId: any;
  messageModelRequest: any = {
    data: '',
    mesaj: '',
    userId: '',
    proiectTaskId: ''
  }
  mesajTaskId: any;
  taskUpdateModel: any = {
    id: '',
    titlu: '',
    descriere: '',
    prioritate: ''
  }
  taskStatusUpdateModel: any = {
    id: '',
    status: '',
    userId: '',
  }

  taskModel: any = {
    titlu: '',
    descriere: '',
    userIdCreareTask: '',
    dataCreare: '',
    status: '',
    proiectId: '',
    tipTask: '',
    deadLine: '',
    userIdTargetat: '',
    prioritate: ''
  }

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    
  }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "TASK-URI PERSONALE";

    this.token = localStorage.getItem('Token');
    var decoded = jwt_decode(this.token) as any;
    
    const roleUser = this.authService.getRoleByUserId(decoded.Id);
    await lastValueFrom(roleUser);
    
    const tasksUser = this.authService.getTasksBuUserId(decoded.Id);
    await lastValueFrom(tasksUser);
  }

  //#region Adauga Mesaj
  addModal(content: any, item: any) {
    this.modalService.open(content);
    this.taskId = item.id;
  }

  AddResource(fAddCategory: NgForm){

    let myDate = new Date(Date.now());

    this.messageModelRequest = {
      data: myDate,
      mesaj: fAddCategory.value.Titlu,
      userId: this.sessionData.user.id,
      proiectTaskId: this.taskId
    }

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    const formData = new FormData();
    formData.append("Mesaj",  this.messageModelRequest.mesaj);
    formData.append("Data", myDate.toDateString());
    formData.append("File", "");
    formData.append("Files", "");  
    formData.append("UserId", this.sessionData.user.id);
    formData.append("ProiectTaskId", this.taskId);
    return this.http.post(this.appUrl + 'api/ProiectTaskChat/', formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion

  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

  //#region Stergere Mesaj Task
 deleteMesajTaskModal(content: any, mesaj: any) {
  this.modalService.open(content);
  this.mesajTaskId = mesaj.id;
}
deleteMesajTask(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/ProiectTaskChat/' + this.mesajTaskId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Adauga fisier
addPictureModal(content: any, item: any) {
  this.modalService.open(content);
  this.taskId = item.id;
}

AddPictureResource(fAddPictureCategory: NgForm){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  let myDate = new Date(Date.now());
  fAddPictureCategory.value.File = "file";
  fAddPictureCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("Mesaj", "");
  formData.append("Data", myDate.toDateString());
  formData.append("File", fAddPictureCategory.value.File.toString());
  formData.append("Files",  fAddPictureCategory.value.Files);  
  formData.append("UserId", this.sessionData.user.id);
  formData.append("ProiectTaskId", this.taskId);

  return this.http.post(this.appUrl + 'api/ProiectTaskChat/', formData, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  //#region Editare Task
editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.taskUpdateModel = {
    id: gp.id,
    titlu: gp.numeTask,
    descriere: gp.detaliiTask,
    deadLine: gp.deadLine,
    numeUserTargetat: gp.userIdTargetat,
    numeUserTargetat2: gp.userIdTargetat2,
    prioritate: gp.prioritate
  }
}

EditResource(fEditCategory: NgForm){
  
  this.taskUpdateModel.titlu = fEditCategory.value.Titlu;
  this.taskUpdateModel.descriere = fEditCategory.value.Descriere;
  this.taskUpdateModel.deadLine = fEditCategory.value.DataInceput;
  this.taskUpdateModel.userIdTargetat = fEditCategory.value.UserName;
  this.taskUpdateModel.userIdTargetat2 = fEditCategory.value.UserName2;
  this.taskUpdateModel.prioritate = fEditCategory.value.Prioritate;

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.put(this.appUrl + 'api/ProiectTask/updateTask', this.taskUpdateModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Editare Status Task
  editStatusModal(content: any, gp: any) {
    this.modalService.open(content);
    this.projectId = gp.proiectId;
    this.taskStatusUpdateModel = {
      id: gp.id,
      status: gp.status
    }
  }

  EditStatusResource(fEditStatusCategory: NgForm){
    
    if(fEditStatusCategory.value.Status == "InchidereDirecta"){
      this.taskStatusUpdateModel.status = "Inchis";
    }
    else{
      this.taskStatusUpdateModel.status = fEditStatusCategory.value.Status;
    }
    this.taskStatusUpdateModel.userId = this.sessionData.user.id;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/ProiectTask/editStatus', this.taskStatusUpdateModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Adauga Task
addAfterModal(content: any) {
  this.modalService.open(content);
}

AddAfterResource(fAddAfterCategory: NgForm){

  this.taskStatusUpdateModel.status = "Inchis";
  this.taskStatusUpdateModel.userId = this.sessionData.user.id;

  let myDate = new Date(Date.now());

  this.taskModel = {
    titlu: fAddAfterCategory.value.Titlu,
    descriere: fAddAfterCategory.value.Descriere,
    userIdCreareTask: this.sessionData.user.id,
    dataCreare: myDate,
    status: 'Activ',
    proiectId: this.projectId,
    tipTask: fAddAfterCategory.value.TipTask,
    deadLine: new Date(fAddAfterCategory.value.DataInceput),
    userIdTargetat: fAddAfterCategory.value.UserName,
    userIdTargetat2: fAddAfterCategory.value.UserName2,
    prioritate: fAddAfterCategory.value.Prioritate
  }
  this.taskModel.deadLine.setDate(this.taskModel.deadLine.getDate() + 1);
  console.log(this.taskModel)
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ProiectTask/', this.taskModel, header).subscribe(
    (response) => {    
       return this.http.put(this.appUrl + 'api/ProiectTask/editStatus', this.taskStatusUpdateModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }


}
