import { SessionDataService } from './../session-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { formatDate } from '@angular/common';
import { firstValueFrom, lastValueFrom, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OfertareService {
  appUrl: string = environment.appUrl;
  Nume: string | undefined;
  Prenume: string | undefined;
  Email: string | undefined;
  Id: number | undefined;
  token: any | undefined;
  flag : boolean = false;
  format = 'dd-MM-yyyy';
  locale = 'en-US';
  userRole: any;
  constructor(private sessionData: SessionDataService, private http: HttpClient) { }

  getCoduriOfertareProduse(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareGestiuneProduse/getCoduriProduse/', header).pipe(
      map((data) => {
        this.sessionData.coduriOfertareProduse = data;
    }));
  }

  getOfertareAccesoriiMontaj(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareAccesoriiMontaj', header).pipe(
      map((data) => {
        this.sessionData.ofertareAccesoriiMontaj = data;
    }));
  }

  getOfertareAccesoriiMontajTablou(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareAccesoriiMontajTablou', header).pipe(
      map((data) => {
        this.sessionData.ofertareAccesoriiMontajTablou = data;
    }));
  }

  getOfertareAccesoriiUnitatiFunctionaleTablou(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareAccesoriiUnitatiFunctionaleTablou', header).pipe(
      map((data) => {
        this.sessionData.ofertareAccesoriiUnitatiFunctionaleTablou = data;
    }));
  }

  public getToken(): any {
    return localStorage.getItem('Token');
  }

  getOfertareServicii(i: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareServicii/getServicii/' + i, header).pipe(
      map((data) => {
        this.sessionData.serviciiTable = data;
    }));
  }

  getOfertaCompleta2ById(i: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getOfertaCompleta2ById/' + i, header).pipe(
      map((data) => {
        this.sessionData.ofertaCompleta2 = data;
    }));
  }

  getOfertareServiciiIndirecte(i: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareServiciiIndirecte/getServicii/' + i, header).pipe(
      map((data) => {
        this.sessionData.serviciiIndirecteTable = data;
    }));
  }

  getOfertareServiciiPrincipale(i: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareServiciiPrincipale/getServicii/' + i, header).pipe(
      map((data) => {
        this.sessionData.serviciiPrincipaleTable = data;
    }));
  }

  getOfertareServiciiSecundare(i: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/OfertareServiciiSecundare/getServicii/' + i, header).pipe(
      map((data) => {
        this.sessionData.serviciiSecundareTable = data;
    }));
  }
}