import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-planuri-arhitectura',
  templateUrl: './proiecte-planuri-arhitectura.component.html',
  styleUrls: ['./proiecte-planuri-arhitectura.component.css']
})
export class ProiectePlanuriArhitecturaComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  routeSub: any;
  buttonDisable: boolean = false;
  proiectModelId: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService, private route: ActivatedRoute) {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectName = params['projectName']
    });
  }


  ngOnInit(): void {
    this.sessionData.current_page_title = "PLANURI ARHITECTURA" + " - " +  this.projectName;
    this.buttonDisable = false;
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/proiectFisier/getFisiereByTip/PlanArhitectura', header).subscribe((data) => {
      this.sessionData.fisierePlanArhitectura = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
    });
  }

   //functie pentru fisierul incarcat
   onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

  //#region Adauga plan
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.buttonDisable = true;
  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("Data", myDate.toDateString());
  formData.append("UserId", (this.sessionData.user.id).toString());
  formData.append("ProiectId", this.projectId);
  formData.append("Tip", "PlanArhitectura");
  formData.append("DenumireFisier", "Den");
  formData.append("File", fAddCategory.value.File.toString());
  formData.append("Files",  fAddCategory.value.Files);

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/proiectFisier/', formData, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  //#region Stergere plan
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModelId = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/ProiectFisier/' + this.proiectModelId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}


}
