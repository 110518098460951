import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-dashboard-client',
  templateUrl: './dashboard-client.component.html',
  styleUrls: ['./dashboard-client.component.css']
})
export class DashboardClientComponent implements OnInit {
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  clientId: any;
  clientName: any;
  proiect: any;
  isCheckOferta: boolean = false;
  isCheckContracte: boolean = false;
  isCheckFacturi: boolean = false;
  isCheckRapoarte: boolean = false;
  isCheckDiverse: boolean = false;
  isCheckDocumentatieTehnica: boolean = false;
  checkNext1: boolean = false;
  tipFisier: string = '';

  oferteCheck: any;
  contracteCheck: any;
  facturiCheck: any;
  rapoarteDeLucruCheck: any;
  diverseCheck: any;
  documentatieTehnicaCheck: any;
  
  proiectFisierMailModel: any;

  stareOferte: any;
  stareContracte: any;
  stareFacturi: any;
  stareRapoarteDeLucru: any;
  stareDiverse: any;
  stareDocumentatieTehnica: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.clientId = localStorage.getItem('ClientId');
    this.clientName = localStorage.getItem('ClientName');

  }
  ngOnInit(): void {
    this.sessionData.current_page_title = "CLIENȚI";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/User/getProjectsByUserId/' + this.clientId, header).subscribe((data) => {
      this.sessionData.dashboardClientInventar = data;
      if(data.proiectList[0]){
        console.log(data);
        this.proiect = data.proiectList[0].id;
        this.stareOferte = data.proiectList[0].stareOferte;
        this.stareContracte = data.proiectList[0].stareContracte;
        this.stareFacturi = data.proiectList[0].stareFacturi;
        this.stareRapoarteDeLucru = data.proiectList[0].stareRapoarteDeLucru;
        this.stareDiverse = data.proiectList[0].stareDiverse;
        this.stareDocumentatieTehnica = data.proiectList[0].stareDocumentatieTehnica;
      }
      else{
        this.proiect = 0;
      }
     // this.asignCategoryVariables(data);
    });
  }

  selectArea(area: any){
    this.proiect = area.id;
    let item: any;
    this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
      if(element.id == area.id){
        item = element;
      }
    });
    this.stareOferte = item.stareOferte;
    this.stareContracte = item.stareContracte;
    this.stareFacturi = item.stareFacturi;
    this.stareRapoarteDeLucru = item.stareRapoarteDeLucru;
    this.stareDiverse = item.stareDiverse;
    this.stareDocumentatieTehnica = item.stareDocumentatieTehnica
  }

//functie pentru fisierul incarcat
onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}
//#region Adauga fisier
 addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("Data", myDate.toDateString());
  formData.append("File", fAddCategory.value.File.toString());
  formData.append("Files",  fAddCategory.value.Files);
  formData.append("UserId",  this.clientId);
  formData.append("IdTipFisier",  fAddCategory.value.IdTipFisier);
  formData.append("ProiectId",  this.proiect);
  formData.append("Stare",  "false");

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ClientFisier/', formData, header).subscribe(
   (response) => {    
      window.location.reload();
   },
   (error) => { 
     console.error('error caught in component')
     this.message = "Eroare"
   }
  );
}
//#endregion

//#region Show Categories
showOferte(){
  if(this.isCheckOferta == true){
    this.isCheckOferta = false;
  }
  else{
    this.isCheckOferta = true;
  }
}
showContracte(){
  if(this.isCheckContracte == true){
    this.isCheckContracte = false;
  }
  else{
    this.isCheckContracte = true;
  }
}
showFacturi(){
  if(this.isCheckFacturi == true){
    this.isCheckFacturi = false;
  }
  else{
    this.isCheckFacturi = true;
  }
}
showRapoarte(){
  if(this.isCheckRapoarte == true){
    this.isCheckRapoarte = false;
  }
  else{
    this.isCheckRapoarte = true;
  }
}
showDiverse(){
  if(this.isCheckDiverse == true){
    this.isCheckDiverse = false;
  }
  else{
    this.isCheckDiverse = true;
  }
}
showDocumentatieTehnica(){
  if(this.isCheckDocumentatieTehnica == true){
    this.isCheckDocumentatieTehnica = false;
  }
  else{
    this.isCheckDocumentatieTehnica = true;
  }
}
//#endregion

//#region Alege categoriile
chooseCategoryModal(content: any) {
  this.modalService.open(content);
  this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
    if(element.id == this.proiect){
      this.proiectFisierMailModel = element;
      this.oferteCheck = element.oferteCheck;
      this.contracteCheck = element.contracteCheck;
      this.facturiCheck = element.facturiCheck;
      this.rapoarteDeLucruCheck = element.rapoarteDeLucruCheck;
      this.diverseCheck = element.diverseCheck;
      this.documentatieTehnicaCheck = element.documentatieTehnicaCheck;
    }
  });
}

ChooseCategoryResource(fChooseCategoryCategory: NgForm){

  this.proiectFisierMailModel.oferteCheck = fChooseCategoryCategory.value.oferteCheck;
  this.proiectFisierMailModel.contracteCheck = fChooseCategoryCategory.value.contracteCheck;
  this.proiectFisierMailModel.diverseCheck = fChooseCategoryCategory.value.diverseCheck;
  this.proiectFisierMailModel.facturiCheck = fChooseCategoryCategory.value.facturiCheck;
  this.proiectFisierMailModel.rapoarteDeLucruCheck = fChooseCategoryCategory.value.rapoarteDeLucruCheck;
  this.proiectFisierMailModel.documentatieTehnicaCheck = fChooseCategoryCategory.value.documentatieTehnicaCheck;

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ClientTipFisierEmail/UpdateFisier', this.proiectFisierMailModel, header).subscribe(
   (response) => {    
     this.message = "Categoriile au fost modificate!"
   },
   (error) => { 
     console.error('error caught in component')
     this.message = "Eroare"
   }
  );
}
//#endregion

  asignCategoryVariables(data: any){
    data.clientTipFisierEmailList.forEach((element: any) => {
      if(element.idProiect == this.proiect){
        for(let i = 0; i < data.clientTipFisierList.length; i++){
          if(data.clientTipFisierList[i].id == element.idClientTipFisier){
            this.tipFisier = data.clientTipFisierList[i].denumire;
          }
        }

      }
    });
  }

  changeClassNext1(){
    if(this.checkNext1 == true){
      this.checkNext1 = false;
    }
    else{
      this.checkNext1 = true;
    }
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

  goToOferte(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/oferte-dashboard']);
  }
  goToContracte(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/contracte-dashboard']);
  }
  goToFacturi(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/facturi-dashboard']);
  }
  goToRapoarte(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/rapoartedelucru-dashboard']);
  }
  goToDiverse(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/diverse-dashboard']);
  }
  goToDocumentatieTehnica(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/documentatie-tehnica-dashboard']);
  }
  goToFisiereClient(){
    localStorage.setItem('ProjectId', this.proiect);
    this.router.navigate(['/fisiere-client']);
  }
  
}
