import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-date-personale',
  templateUrl: './date-personale.component.html',
  styleUrls: ['./date-personale.component.css']
})
export class DatePersonaleComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  isChecked: any = false;
  model: any = {
    Nume: null,
    Prenume: null,
    Cost: null,
    Email: null,
    Password: null,
    ConfirmPassword: null,
    Rol: '',
    CI: null,
    Companie: null,
    CUI: null,
    NORC: null,
    AdresaBeneficiar: null,
    Telefon: null,
    IBAN: null
  };
  userModel: any = {
    id: '',
    nume: '',
    prenume: '',
    email: '',
    cost: '',
    ci: '',
    companie: '',
    cui: '',
    norc: '',
    adresaBeneficiar: '',
    telefon: '',
    iban: '',
  }
  updatePasswordMdel: any = {
    id: '',
    password: '',
    confirmPassword: ''
  }
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "DATE PERSONALE";
   
  }
  checkValue(){

  }

  onSubmit(fEditCategory: any) {
    this.userModel.id = this.sessionData.user.id;
    this.userModel.nume = fEditCategory.value.Nume;
    this.userModel.prenume = fEditCategory.value.Prenume;
    this.userModel.email = this.sessionData.user.email;
    this.userModel.cost =  this.sessionData.user.cost;
    this.userModel.ci = fEditCategory.value.CI;
    this.userModel.companie = fEditCategory.value.Companie;
    this.userModel.cui = fEditCategory.value.CUI;
    this.userModel.norc = fEditCategory.value.NORC;
    this.userModel.adresaBeneficiar = fEditCategory.value.AdresaBeneficiar;
    this.userModel.telefon = fEditCategory.value.Telefon;
    this.userModel.iban = fEditCategory.value.IBAN;

    return this.http.put(this.appUrl + 'api/User/updateUser', this.userModel).subscribe(
      (response) => {    
        this.message = "Datele au fost editate!";
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

  changePassword(fChangePassword: any){
    this.updatePasswordMdel = {
      id: this.sessionData.user.id,
      password: fChangePassword.value.Password,
      confirmPassword: fChangePassword.value.ConfirmPassword
    }
    
    return this.http.put(this.appUrl + 'api/User/password', this.updatePasswordMdel).subscribe(
      (response) => {    
        this.message = "Parola a fost schimbată!";
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

}
