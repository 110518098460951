import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-protectia-muncii',
  templateUrl: './protectia-muncii.component.html',
  styleUrls: ['./protectia-muncii.component.css']
})
export class ProtectiaMunciiComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  protectiaMuncii: any;
  denumire: any;
  anunt: any;
  excelAnuntModel: any;
  protectiaMunciiAux: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
   }

  ngOnInit(): void {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.sessionData.current_page_title = "CONFIRMARI CITIRI"

    this.http.get<any>(this.appUrl + 'api/GestiuneAnunturi/getUserGestiuneAnunturiAcceptObligatorii', header).subscribe((data) => {
      this.protectiaMuncii = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      this.protectiaMunciiAux = this.protectiaMuncii;
    });

  }

  Search(){
    this.protectiaMuncii = this.protectiaMunciiAux;
    if(this.denumire != ""){
      this.protectiaMuncii = this.protectiaMuncii.filter((res: any) => {
        return res.numeUser.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiuneAnunturiId.toString().match(this.denumire);
      });
    }else if(this.denumire == ""){
      this.ngOnInit();
    }
  
  }

  openModal(content: any, gp: any){
    this.modalService.open(content);
    this.anunt = gp.descriere;
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

//#region excel proiect
async exportExcelProiect(content: any) : Promise<void> {
  this.modalService.open(content);

}

ExportExcelProiectResource(fexportExcelProiect: any){
  
  this.excelAnuntModel = {
    dataInceput: fexportExcelProiect.value.DataInceput,
    dataSfarsit: fexportExcelProiect.value.DataSfarsit
  }
 
  this.http.post(this.appUrl + 'api/GestiuneAnunturi/generateReportAnunturiObligatorii', this.excelAnuntModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion


}
