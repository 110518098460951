import { SessionDataService } from './../session-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { formatDate } from '@angular/common';
import { firstValueFrom, lastValueFrom, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  appUrl: string = environment.appUrl;
  //helper = new JwtHelperService();
  Nume: string | undefined;
  Prenume: string | undefined;
  Email: string | undefined;
  Id: number | undefined;
  token: any | undefined;
  flag : boolean = false;
  format = 'dd-MM-yyyy';
  locale = 'en-US';
  userRole: any;
  constructor(private sessionData: SessionDataService, private http: HttpClient) { }


  login(model: any){

    return this.http.post(this.appUrl + 'api/user/login', model).pipe(
      map(async (response: any) => {
        var decoded = jwt_decode(response.token) as any;
        //const decodedToken = this.helper.decodeToken(response.token);
        this.Nume = decoded.Nume;
        this.Prenume = decoded.Prenume;
        this.Email = decoded.Email;
        this.Id = decoded.Id;
        this.sessionData.user = this.Nume + " " + this.Prenume;
        this.sessionData.anuntObligatoriuId = decoded.AnuntAccept;
        localStorage.setItem('Token', response.token);
        localStorage.setItem('RefreshToken', response.refreshToken);
        localStorage.setItem('AnuntObligatoriuId', decoded.AnuntAccept);
        const roleUser = this.assignUserValues(decoded.Id);
        await lastValueFrom(roleUser);
        this.getGestiuneAnuntObligatoriu(this.Id);
        this.getTotalStocksValue();
       // this.getProjectProfitability();
        // this.getTotalValue();
        this.getProiecteByStatus();
        this.getCriticStocks();
        this.getAllUsers();
        this.getServiciiManopera();
        this.getGestiunePersonalaByUserId(this.Id);
        //this.getProiecteByClientId(this.Id)
        // this.getFotovoltaiceGestiuneFotovoltaice();
      })
    );
  }

 async loginByRefreshToken(model: any){
      this.http.post(this.appUrl + 'api/user/loginByRefreshToken', model).subscribe(async (response: any) => {
        var decoded = jwt_decode(response.token) as any;
        //const decodedToken = this.helper.decodeToken(response.token);
        this.Nume = decoded.Nume;
        this.Prenume = decoded.Prenume;
        this.Email = decoded.Email;
        this.Id = decoded.Id;
        this.sessionData.user = this.Nume + " " + this.Prenume;
        this.sessionData.anuntObligatoriuId = decoded.AnuntAccept;
        localStorage.setItem('Token', response.token);
        localStorage.setItem('RefreshToken', response.refreshToken);
        localStorage.setItem('AnuntObligatoriuId', decoded.AnuntAccept);
        const roleUser = this.assignUserValues(decoded.Id);
        await lastValueFrom(roleUser);
        this.getGestiuneAnuntObligatoriu(this.Id);
        this.getTotalStocksValue();
        //this.getProjectProfitability();
        this.getProiecteByStatus();
        // this.getTotalValue();
        this.getCriticStocks();
        this.getAllUsers();
        this.getServiciiManopera();
        this.getGestiunePersonalaByUserId(this.Id);
        //this.getProiecteByClientId(this.Id);
        // this.getFotovoltaiceGestiuneFotovoltaice();
      });
  }

  assignUserValues(id: number): any{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/user/getUserById/' + id, header).subscribe((data) => {
      this.sessionData.user = data;
      this.userRole = data.rol;
      this.getProiecteUser(this.sessionData.user.id);
    });
    return of(this.userRole);
  }
  getTasksBuUserId(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/ProiectTask/getTasksByUserId/' + id, header).pipe(
      map((data) => {
        console.log(data);
        this.sessionData.userTasks = data.sort((a: { prioritate: Number; }, b: { prioritate: Number; }) => (a.prioritate < b.prioritate ? -1 : 1));
    }));
  }
  getGestiuneAnuntObligatoriu(id: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/GestiuneAnunturi/getGestiuneAnuntObligatoriu/' + id, header).subscribe((data) => {
        this.sessionData.anuntObligatoriu = data;
    });
  }
  getGestiuneAnunturi(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/GestiuneAnunturi/getGestiuneAnunturi/', header).pipe(
      map((data) => {
        this.sessionData.gestiuneAnunturi = data;
    }));
  }
  getAllTasks(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/ProiectTask/getAllTasks/', header).pipe(
      map((data) => {
        this.sessionData.userTasks = data.sort((a: { dataTaskuriComplete: string; }, b: { dataTaskuriComplete: string; }) => (a.dataTaskuriComplete > b.dataTaskuriComplete ? -1 : 1));
      }));
  }
  getProiecteByClientId(id: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
      this.http.get<any>(this.appUrl + 'api/User/getProjectsByUserId/' + id, header).subscribe((data) => {
        if(this.userRole && this.userRole.tip == "Client"){
          this.sessionData.dashboardClientInventar = data;  
          this.sessionData.clientProiectId = data.proiectList[0].id;
          let item: any;
          this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
            if(element.id == this.sessionData.clientProiectId){
              item = element;
            }
          });
          this.sessionData.stareOferte = item.stareOferte;
          this.sessionData.stareContracte = item.stareContracte;
          this.sessionData.stareFacturi = item.stareFacturi;
          this.sessionData.stareRapoarteDeLucru = item.stareRapoarteDeLucru;
          this.sessionData.stareDiverse = item.stareDiverse;
          this.sessionData.stareDocumentatieTehnica = item.stareDocumentatieTehnica;
        }
      });
  }
  getProiecteUser(id: any){
    setTimeout(function(){
    }, 1000);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    let aux = [];
    this.http.get<any>(this.appUrl + 'api/ProiectUser/getProiectUser', header).subscribe((data) => {
      for(let i = 0; i < data.length; i++){
        if(this.userRole.asignareProiectUserDashboard == true){

          if(data[i].imagine == "file"){
            this.sessionData.resourceData= [...this.sessionData.resourceData, {
              Id: data[i].id,
              Subject: data[i].denumireProiect,
              StartTime: data[i].dataInceput,
              EndTime: data[i].dataSfarsit,
              IsAllDay: true,
              ResourceId: data[i].userId,
              Description: data[i].descriere,
              Imagine: data[i].imagine
            }];
          }
          else{
            let image = "<a href='" + environment.appUrl + "assets/" + data[i].imagine + "'>Produse</a>";
            this.sessionData.resourceData= [...this.sessionData.resourceData, {
              Id: data[i].id,
              Subject: data[i].denumireProiect,
              StartTime: data[i].dataInceput,
              EndTime: data[i].dataSfarsit,
              IsAllDay: true,
              ResourceId: data[i].userId,
              Description: data[i].descriere + " - " + image,
              Imagine: data[i].imagine
            }];
          }
          
        }
        else{
          if(data[i].imagine == "file"){
            this.sessionData.resourceData= [...this.sessionData.resourceData, {
              Id: data[i].id,
              Subject: data[i].denumireProiect,
              StartTime: data[i].dataInceput,
              EndTime: data[i].dataSfarsit,
              IsAllDay: true,
              ResourceId: data[i].userId,
              Description: data[i].descriere,
              Imagine: data[i].imagine
            }];
          }
          else{
            let image = "<a href='" + environment.appUrl + "assets/" + data[i].imagine + "'>Produse</a>";
            this.sessionData.resourceData= [...this.sessionData.resourceData, {
              Id: data[i].id,
              Subject: data[i].denumireProiect,
              StartTime: data[i].dataInceput,
              EndTime: data[i].dataSfarsit,
              IsAllDay: true,
              ResourceId: data[i].userId,
              Description: data[i].descriere + " - " + image,
              Imagine: data[i].imagine
            }];
          }
          
        }
      }
      const colors: string[] = [
        '#ff8787', '#9775fa', '#748ffc', '#3bc9db', '#69db7c', '#fdd835', '#748ffc',
        '#9775fa', '#df5286', '#7fa900', '#fec200', '#5978ee', '#00bdae', '#ea80fc'
      ];
      for(let i = 0; i < data.length; i++){
        if(this.userRole.asignareProiectUserDashboard == true){
          const n: number = Math.floor(Math.random() * colors.length);
          this.sessionData.resourceDataSource= [...this.sessionData.resourceDataSource, {
            Text: data[i].numeUser,
            Id: data[i].userId,
            Color: colors[n],
          }];
        }
        else{
          const n: number = Math.floor(Math.random() * colors.length);
          this.sessionData.resourceDataSource= [...this.sessionData.resourceDataSource, {
            Text: data[i].numeUser,
            Id: data[i].userId,
            Color: colors[n],
          }];
        }
        
      }

      aux = this.sessionData.resourceDataSource.reduce((acc: any[], val: { Id: any; }) => {
        if (!acc.find(el => el.Id === val.Id)) {
          acc.push(val);
        }
        return acc;
      }, []);

      this.sessionData.resourceDataSource = aux;
      if(this.userRole.asignareProiectUserDashboard == true){
        this.sessionData.eventSettings = {
          dataSource:  this.sessionData.resourceData as Record<string, any>[]
        };
      }
      else{
        this.sessionData.eventSettings = {
          allowAdding: false, 
          allowEditing: false, 
          allowDeleting: false, 
          dataSource:  this.sessionData.resourceData as Record<string, any>[]
        };
      }
    });

  }
  getTotalStocksValue(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getTotalStocksValue', header).subscribe((data) => {
      this.sessionData.totalStocksValue = data;
      this.sessionData.single = [
        {
          "name": "G. Principala",
          "value": this.sessionData.totalStocksValue[0]
          
        },
      ];
      this.sessionData.singleTabloterie = [
        {
          "name": "G. Tabloterie",
          "value": this.sessionData.totalStocksValue[1]
          
        },
      ];
      this.sessionData.singleRezervare = [
        {
          "name": "G. Rezervare Proiecte",
          "value": this.sessionData.totalStocksValue[2]
          
        },
      ];
      this.sessionData.singlePersonala = [
        {
          "name": "G. Personala",
          "value": this.sessionData.totalStocksValue[3]
          
        },
      ];
      this.sessionData.singleTransfer = [
        {
          "name": "G. Transfer",
          "value": this.sessionData.totalStocksValue[4]
          
        },
      ];
      this.sessionData.singleTotal = [
        {
          "name": "Stocuri Totale",
          "value": this.sessionData.totalStocksValue[5]
          
        },
      ];
    });

  }
  getServiciiManopera(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/ServiciuManopera/', header).subscribe((data) => {
      this.sessionData.serviciiManopera = data;
  });
  }
  getGestiunePersonalaByUserId(id: any): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePersonala/getGestiunePersonalaById/' + id, header).subscribe((data) => {
      this.sessionData.getGestiunePersonalaById = data;
    });
  }
  getProjectProfitability(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getProjectProfitability', header).subscribe((data) => {
      this.sessionData.projectProfitability = data;
    });
  }
  getListaProducatori(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }

    return this.http.get<any>(this.appUrl + 'api/AprovizionareComandaProduse/getListaProducatori', header).pipe(
      map((data) => {
        this.sessionData.listaProducatori = data;
    }));
  }
  getProiecteByStatus(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getProiecteByStatus', header).subscribe((data) => {
      this.sessionData.projectsByStatus = data;
      this.sessionData.single3 = [
        {
          "name": "Proiecte Active",
          "value": this.sessionData.projectsByStatus.proiecteActive
        },
        {
          "name": "Proiecte Inactive",
          "value": this.sessionData.projectsByStatus.proiecteInactive
        },
        {
          "name": "Proiecte Finalizate",
          "value": this.sessionData.projectsByStatus.proiecteFinalizate
        }
      ];
    });

  }
  getCriticStocks(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getCriticStocks', header).subscribe((data) => {
      this.sessionData.criticStocks = data;
    });
  }
  // getTotalValue(): void{
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization',  `Bearer ${this.getToken()}`)
  //   }
  //   this.http.get<any>(this.appUrl + 'api/Proiect/getTotalValue', header).subscribe((data) => {
  //     this.sessionData.totalProjectsValue = data;
  //     this.sessionData.single1 = [
  //       {
  //         "name": "Valoare Proiecte Active",
  //         "value": this.sessionData.totalProjectsValue
  //       },
  //     ];
  //   });
  // }
  getTabloterieGestiuneTabloterie(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/TabloterieGestiuneTabloterie/getTabloterieGestiuneTabloterieInventory', header).pipe(
      map((data) => {
        this.sessionData.gestiuneTabloterie = data.sort((a: any, b: any) => (a.data < b.data) ? 1 : -1);
      }));
  }
  getTabloterieGestiuneTabloterieByProjectId(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/TabloterieGestiuneTabloterie/getTabloterieGestiuneTabloterieInventoryByProjectId/' + id, header).pipe(
      map((data) => {
        this.sessionData.gestiuneTabloterie = data.sort((a: any, b: any) => (a.data < b.data) ? 1 : -1);
      }));
  }
  getFotovoltaiceGestiuneFotovoltaice(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/FotovoltaiceGestiuneFotovoltaice/getFotovoltaiceGestiuneFotovoltaiceInventory', header).subscribe((data) => {
      this.sessionData.gestiuneFotovoltaiceInventory = data;
    });
  }
  getAllUsers(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/User/getUsers', header).subscribe((data) => {
      // this.sessionData.users = data.sort((a: any, b: any) => (a.nume > b.nume) ? 1 : -1);
      data = data.sort((a: any, b: any) => (a.nume > b.nume) ? 1 : -1);
      data.forEach((element: any) => {
        if(element.rol.tip != 'Client'){
          this.sessionData.users.push(element);
        }
      });
      this.sessionData.users = this.sessionData.users.sort((a: any, b: any) => (a.nume > b.nume) ? 1 : -1);      
      data.forEach((element: any) => {
        if(element.rol.tip == 'Client'){
          this.sessionData.clienti.push(element);
        }
      });
      let countArray = 0;
      let countClients = 0;
      data.forEach((element: any) => {
        if(element.rol.tip != 'Client'){
          countArray++;
        }
        else{
          countClients++;
        }
      });
      this.sessionData.single2 = [
        {
          "name": "Număr Angajați",
          "value": countArray
        },
      ];
      this.sessionData.single4 = [
        {
          "name": "Număr Clienti",
          "value": countClients
        },
      ];
    });
  }
  getProiecte(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect/getProiecteActive', header).pipe(
      map((data) => {
        this.sessionData.proiecte = data.sort((a: any, b: any) => (a.denumireProiect > b.denumireProiect) ? 1 : -1);
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getProiecte2(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect2/', header).pipe(
      map((data) => {
        this.sessionData.proiecte2 = data.sort((a: any, b: any) => (a.numeProiect > b.numeProiect) ? 1 : -1);
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getProiecte2Contracte(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect2/getProiect2ContractById/' + id, header).pipe(
      map((data) => {
        this.sessionData.proiecte2Contracte = data.sort((a: any, b: any) => (a.numeContract > b.numeContract) ? 1 : -1);
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getProiecte2ContracteSectiuni(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect2/getProiect2ContractSectiuneById/' + id, header).pipe(
      map((data) => {
        this.sessionData.proiecte2ContracteSectiuni = data.sort((a: any, b: any) => (a.numeSectiune > b.numeSectiune) ? 1 : -1);
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getProiecte2ContracteSectiuniMateriale(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect2/getProiect2ContractSectiuneMaterialeById/' + id, header).pipe(
      map((data) => {
        this.sessionData.proiecte2ContracteSectiuniMateriale = data.sort((a: any, b: any) => (a.cod > b.cod) ? 1 : -1);
      }));
  }

  getProiecte2ContracteSectiuniManopera(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect2/getProiect2ContractSectiuneManoperaById/' + id, header).pipe(
      map((data) => {
        this.sessionData.proiecte2ContracteSectiuniManopera = data.sort((a: any, b: any) => (a.cod > b.cod) ? 1 : -1);
      }));
  }

  getClientiOfertare(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/User/getUsersOfertare', header).pipe(
      map((data) => {
        this.sessionData.clientiOfertare = data;
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getProiecteFinalizate(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/Proiect/getProiecteFinalizate', header).pipe(
      map((data) => {
        this.sessionData.proiecteFinalizate = data.sort((a: any, b: any) => (a.denumireProiect > b.denumireProiect) ? 1 : -1);
        // for(let i = 0; i < data.length; i++){
        //   this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
        // }
      }));
  }

  getGestiunePrincipala(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    
    return this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getGestiunePrincipalaGestiuneTranzitii', header).pipe(
      map((data) => {
        this.sessionData.gestiunePrincipalaInventory = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      })
    )
  }
  getGestiuneComponente(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    
    return this.http.get<any>(this.appUrl + 'api/GestiuneComponente/', header).pipe(
      map((data) => {
        this.sessionData.gestiuneComponente = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      })
    )
  }
  getGestiuneRetetar(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    
    return this.http.get<any>(this.appUrl + 'api/GestiuneRetetar/', header).pipe(
      map((data) => {
        this.sessionData.gestiuneRetetar = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      })
    )
  }
  getGestiuneProductie(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    
    return this.http.get<any>(this.appUrl + 'api/GestiuneProductie/getGestiuneProductie', header).pipe(
      map((data) => {
        this.sessionData.gestiuneProductie = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
        console.log(data);
      })
    )
  }
  getGestiuneRetetarComponente(id: any): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    
    return this.http.get<any>(this.appUrl + 'api/GestiuneRetetarComponente/getGestiuneRetetarComponente/' + id, header).pipe(
      map((data) => {
        this.sessionData.gestiuneRetetarComponente = data;
      })
    )
  }
  getOferte(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getOferte', header).pipe(
      map((data) => {
        this.sessionData.listaOferte = data;
      }));
  }
  getOferte2(): Observable<any>{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    return this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getOferte2', header).pipe(
      map((data) => {
        this.sessionData.listaOferte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));;
      }));
  }

  public getToken(): any {
    return localStorage.getItem('Token');
  }

  loggedIn(): boolean{
    this.token = localStorage.getItem("RefreshToken");
    if (this.token) {
      return true;
    }
    else {
      return false;
    }
  }
  logout() {
    localStorage.clear();
  }
  register(model: any){
    return  this.http.post(this.appUrl + 'api/user/register', model);
  }

  getRoleByUserId(id: any): Observable<any>{
    return this.http.get<any>(this.appUrl + 'api/Role/getRoleByUserId/' + id).pipe(
      map((data) => {
        this.sessionData.roleByUserId = data;
      }));
  }

}
