import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte2',
  templateUrl: './proiecte2.component.html',
  styleUrls: ['./proiecte2.component.css']
})
export class Proiecte2Component implements OnInit {
  //#region Declarari
  projectOption: any = "Toate";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id: '',
    denumireProiect: '',
    locatie: '',
    numeUseri: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: ''
  }

  addProiectModel: any ={
    denumireProiect: '',
    locatie: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: '',
    numarContract: '',
    numePersoanaContact: '',
    prenumePersoanaContact: '',
    telefon: '',
    email: '',
    userId: ''
  }

  editProiectModel: any = {
    id: '',
    clientId: '',
    numeProiect: '',
    managerId: '',
    numeManager: '',
    stare: '',
    valoareVanzareMateriale: '',
    valoareVanzareManopera: '',
    valoareTotala: '',
    valoareMaterialeDescarcate: '',
    valoareManoperaUtilizata: '',
    diferentaValoareMateraile: '',
    diferentaValoareManopera: ''
  }
  
  fileName= 'ExcelSheet.xlsx';
  userName : any;
//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    sessionData.clientId = localStorage.getItem('ClientId');
  }
  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "PROIECTE";
    
    const proiecte = this.authService.getProiecte2();
    await lastValueFrom(proiecte);
  }

  goToContracte(project: any){
    this.sessionData.proiect2Id = project.id;
    localStorage.setItem('ProjectId',project.id);
    localStorage.setItem('ProjectName',project.numeProiect);
    
    this.router.navigate(['/dashboard-proiect']);
  }

  //#region Editare proiect

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectModel = {
      id: gp.id,
      clientId: gp.clientId,
      numeProiect: gp.numeProiect,
      managerId: gp.managerId,
      numeManager: gp.numeManager,
      stare: gp.stare,
      valoareVanzareMateriale: gp.valoareVanzareMateriale,
      valoareVanzareManopera: gp.valoareVanzareManopera,
      valoareTotala: gp.valoareTotala,
      valoareMaterialeDescarcate: gp.valoareMaterialeDescarcate,
      valoareManoperaUtilizata: gp.valoareManoperaUtilizata,
      diferentaValoareMateraile: gp.diferentaValoareMateraile,
      diferentaValoareManopera: gp.diferentaValoareManopera,
    }
  }

  EditResource(fEditCategory: NgForm){

    let numeUser = "";
    this.sessionData.users.forEach((element: any) => {
      if(element.id == fEditCategory.value.Manager){
        numeUser = element.prenume + " " + element.nume;
      }
    });

    this.editProiectModel = {
      id: this.proiectModel.id,
      clientId: this.proiectModel.clientId,
      numeProiect: fEditCategory.value.NumeProiect,
      managerId: fEditCategory.value.Manager,
      numeManager: numeUser,
      stare: fEditCategory.value.Stare,
      valoareVanzareMateriale: fEditCategory.value.ValoareVanzareMateriale,
      valoareVanzareManopera: fEditCategory.value.ValoareVanzareManopera,
      valoareTotala: fEditCategory.value.ValoareTotala,
      valoareMaterialeDescarcate: fEditCategory.value.ValoareMaterialeDescarcate,
      valoareManoperaUtilizata: fEditCategory.value.ValoareManoperaUtilizata,
      diferentaValoareMateraile: fEditCategory.value.DiferentaValoareMateraile,
      diferentaValoareManopera: fEditCategory.value.DiferentaValoareManopera,
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/Proiect2/' + this.editProiectModel.id, this.editProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

  //#region Stergere Proiect
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/Proiect2/deleteProiect2/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
}}

}
