import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  appUrl: string = environment.appUrl;
  product : any;
  format = 'MM-dd-yyyy';
  locale = 'en-US';
  userId: number | undefined;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  gPrincipalaModel: any = {
    id: '',
    codIntern: '',
    codExtern: '',
    denumire: '',
    producator: '',
    cantitate: '',
    cantitateReferinta: '',
    locatie: '',
    critic: '',
    comandat: '',
    images: '',
    image: '',
    numarFacturaAchizitie: ''
  }
  addGTransferModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    userId: '',
    numeUser: ''
  };
  addRezervareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    proiectId: '',
    numeUser: ''
  };
  addGVanzareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    numeUser: '',
    mentiuni: ''
  };
  userName : any;
  cantitateTotala: any;
  buttonDisable: boolean = false;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    sessionData.productId = localStorage.getItem('ProductId');

   }

  async ngOnInit(): Promise<void> {
    this.buttonDisable = false;
    this.sessionData.current_page_title = localStorage.getItem('ProductName');
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/gestiuneprincipala/getProdusGP/' + this.sessionData.productId, header).subscribe((data) => {
      this.gPrincipalaModel = data;
      this.cantitateTotala = data.cantitateGestiuneAngajati + data.cantitateGestiunePrincipala + data.cantitateGestiuneRezervate + data.cantitateGestiuneTabloterie + data.cantitateGestiuneTransfer;
    });
  }



//#region Editare produs

editModal(content: any, gp: any) {
  this.modalService.open(content);
}

EditResource(fEditCategory: NgForm){
  this.buttonDisable = true;

  fEditCategory.value.File = "file";
  const formData1 = new FormData();
  if(fEditCategory.value.Files != ""){
    fEditCategory.value.Files = this.selectedfile;
    formData1.append("Id", this.gPrincipalaModel.id);
    formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
    formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
    formData1.append("Denumire", fEditCategory.value.Denumire.toString());
    formData1.append("Producator", fEditCategory.value.Producator.toString());
    formData1.append("Cantitate", fEditCategory.value.Cantitate);
    formData1.append("Data", this.gPrincipalaModel.data);
    formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
    formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
    formData1.append("Locatie", fEditCategory.value.Locatie.toString());
    if(fEditCategory.value.Critic == true){
      formData1.append("Critic", "true");
    }
    else{
      formData1.append("Critic", "false");
    }
    if(fEditCategory.value.Comandat == true){
      formData1.append("Comandat", "true");
    }
    else{
      formData1.append("Comandat", "false");
    }
    formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
    formData1.append("Image", fEditCategory.value.File.toString());
    formData1.append("Images",  fEditCategory.value.Files);
  }
  else{
    formData1.append("Id", this.gPrincipalaModel.id);
    formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
    formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
    formData1.append("Denumire", fEditCategory.value.Denumire.toString());
    formData1.append("Producator", fEditCategory.value.Producator.toString());
    formData1.append("Data", this.gPrincipalaModel.data);
    formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
    formData1.append("Cantitate", fEditCategory.value.Cantitate);
    formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
    formData1.append("Locatie", fEditCategory.value.Locatie.toString());
    if(fEditCategory.value.Critic == true){
      formData1.append("Critic", "true");
    }
    else{
      formData1.append("Critic", "false");
    }
    if(fEditCategory.value.Comandat == true){
      formData1.append("Comandat", "true");
    }
    else{
      formData1.append("Comandat", "false");
    }
    formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
    formData1.append("Image", this.gPrincipalaModel.image);
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.put(this.appUrl + 'api/GestiunePrincipala/' + this.gPrincipalaModel.id, formData1, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}


//#endregion

//#region Adauga Cantitate

addQuantityModal(content: any, gp: any) {
this.modalService.open(content);
}

AddQuantityResource(fEditCategory: NgForm){
  this.buttonDisable = true;

fEditCategory.value.File = "file";
const formData1 = new FormData();
  formData1.append("Id", this.gPrincipalaModel.id);
  formData1.append("CodIntern", this.gPrincipalaModel.codIntern);
  formData1.append("CodExtern", this.gPrincipalaModel.codExtern);
  formData1.append("Denumire", this.gPrincipalaModel.denumire);
  formData1.append("Producator", this.gPrincipalaModel.producator);
  formData1.append("NumeUser", this.sessionData.user.nume + " " + this.sessionData.user.prenume);
  formData1.append("Cantitate", fEditCategory.value.Cantitate);
  formData1.append("CantitateReferinta", this.gPrincipalaModel.cantitateReferinta);
  formData1.append("Locatie", fEditCategory.value.Locatie);
  formData1.append("Critic", this.gPrincipalaModel.critic);
  formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie);
  formData1.append("Image", this.gPrincipalaModel.image);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/AddCantiate', formData1, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
}
delete(event: any){
  this.buttonDisable = true;

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/GestiunePrincipala/' + this.gPrincipalaModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Transfera in Gestiune Transfer

addToGTransferModal(content: any, gp: any) {
this.modalService.open(content);
}

AddGTransferResource(fAddGTransfer: NgForm){
  this.buttonDisable = true;

if(fAddGTransfer.value.Cantitate < 0){
  this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
  return;
}
else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
  this.message = "Valoarea introdusa este prea mare";
  return;
}
else{

  this.userName = this.sessionData.user.nume + " " + this.sessionData.user.prenume;

  this.addGTransferModel = {
    gestiunePrincipalaId: this.gPrincipalaModel.id,
    cantitate: fAddGTransfer.value.Cantitate,
    userId: fAddGTransfer.value.UserName,
    numeUser: this.userName
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneTransfer', this.addGTransferModel, header).subscribe(
  (response) => {    
    window.location.reload();
  },
  (error) => { 
    console.error('error caught in component')
    this.message = "Eroare"
  }
);
}


}

//#endregion

//#region Transfera in Rezervare pe Proiect

async addToRezervareModal(content: any, gp: any) {
  const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
  this.modalService.open(content);
}

AddRezervareResource(fAddGTransfer: NgForm){
  this.buttonDisable = true;

if(fAddGTransfer.value.Cantitate < 0){
  this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
  return;
}
else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
  this.message = "Valoarea introdusa este prea mare";
  return;
}
else{

  this.userName = this.sessionData.user.nume + " " + this.sessionData.user.prenume;

  this.addRezervareModel = {
    gestiunePrincipalaId: this.gPrincipalaModel.id,
    cantitate: fAddGTransfer.value.Cantitate,
    proiectId: fAddGTransfer.value.Proiect,
    numeUser: this.userName
  }


this.message = "Fisierul a fost incarcat!"
var header = {
  headers: new HttpHeaders()
    .set('Authorization',  `Bearer ${this.authService.getToken()}`)
}
return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneRezervare', this.addRezervareModel, header).subscribe(
  (response) => {    
    window.location.reload();
  },
  (error) => { 
    console.error('error caught in component')
    this.message = "Eroare"
  }
);
}


}

//#endregion

//#region Transfera in Gestiune Vanzari

addToGVanzareModal(content: any, gp: any) {
  this.modalService.open(content);

}

AddGVanzareResource(fAddGTransfer: NgForm){
  this.buttonDisable = true;

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.userName = this.sessionData.user.nume + " " + this.sessionData.user.prenume;

    this.addGVanzareModel = {
      gestiunePrincipalaId: this.gPrincipalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      numeUser: this.userName,
      mentiuni: fAddGTransfer.value.Mentiuni,
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  this.message = "Fisierul a fost incarcat!";
  return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneVanzare', this.addGVanzareModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

  addModal(content: any) {
    this.modalService.open(content);
  }
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }
  Search(){
    if(this.denumire != ""){
      this.gPrincipala = this.gPrincipala.filter((res: { denumire: string; }) => {
        return res.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
      });
    }else if(this.denumire == ""){
      this.ngOnInit();
    }

  }

  //generare excel
exportExcel() : void {

  let url = this.appUrl + 'api/GestiunePrincipala/RaportProdus/' + this.sessionData.productId;
  window.open(url);
}
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
