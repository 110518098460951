import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-aprovizionare-rezervari',
  templateUrl: './gestiune-aprovizionare-rezervari.component.html',
  styleUrls: ['./gestiune-aprovizionare-rezervari.component.css']
})
export class GestiuneAprovizionareRezervariComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  comandId: any;
  comandName: any;
  model: any = {
    id: '',
    cod: '',
    cantitate: '',
  }
  modelGestiuneComenzi: any;
  addRezervareModel: any = {
    aprovizionareRezervari: '',
    cantitate: ''
  };
  returGPrincipalaModel: any = {
    gestiunePrincipalaId: '',
    aprovizionareRezervariId: '',
    cantitate: ''
  };
  gRezervareProiectModel: any = {
    gestiunePrincipalaId: '',
    aprovizionareRezervariId: '',
    cantitate: '',
    produs: ''
  }
  produsGestiuneComandaId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  }
  
  ngOnInit(): void {
    this.sessionData.current_page_title =  "GESTIUNE APROVIZIONARE REZERVARI";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizionareRezervari/getAprovizionareRezervari', header).subscribe((data) => {
       this.sessionData.listaGestiuneAprovizionareRezervari = data;
    });
  }

  //#region Transfera in Rezervare pe Proiect

  addToRezervareModal(content: any, gp: any) {
    this.modalService.open(content);
    console.log(gp);
    this.model = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      cantitate: gp.aprovizionareRezervari.cantitate,
      proiect: gp.denumireProiect,
      aprovizionareRezervari: gp.aprovizionareRezervari
    }

  }

  AddRezervareResource(fAddGTransfer: NgForm){

    this.addRezervareModel = {
      aprovizionareRezervari: this.model.aprovizionareRezervari,
      cantitate: fAddGTransfer.value.Cantitate
    };
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/AprovizionareRezervari/addToGestiuneRezervari', this.addRezervareModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        this.message = "Cantitatea este prea mare";
      }
    );

  }

//#endregion

  //#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.gRezervareProiectModel = {
    gestiunePrincipalaId: category.aprovizionareRezervari.gestiunePrincipalaId,
    aprovizionareRezervariId: category.aprovizionareRezervari.id,
    cantitate: category.aprovizionareRezervari.cantitate,
    produs: category.cod + " - " + category.denumire
  }
}
DeleteResource(fReturProdus: any){
  this.returGPrincipalaModel = {
    gestiunePrincipalaId: this.gRezervareProiectModel.gestiunePrincipalaId,
    aprovizionareRezervariId: this.gRezervareProiectModel.aprovizionareRezervariId,
    cantitate: fReturProdus.value.Cantitate
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.post(this.appUrl + 'api/AprovizionareRezervari/deleteToGestiuneComenzi/', this.returGPrincipalaModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
