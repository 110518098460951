import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-clienti',
  templateUrl: './lista-clienti.component.html',
  styleUrls: ['./lista-clienti.component.css']
})
export class ListaClientiComponent implements OnInit {

  //#region Declarari
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  userModel: any = {
    id: '',
    nume: '',
    prenume: '',
    email: '',
    cost: '',
    cnp: '',
    companie: '',
    cui: '',
    norc: '',
    adresaBeneficiar: '',
    adresaCompanie: '',
    banca: '',
    sediuCentral: '',
    judet: '',
    localitate: '',
    telefon: '',
    iban: '',
  }

  getEditUser: any;
  getUserRole: any;

  editUserModel: any = {
    id: '',
    nume: '',
    prenume: '',
    email: '',
    cost: '',
    password: '',
    refreshToken: '',
    refreshTokenExpiryTime: '',
    client: {
      id: '',
      cnp: '',
      companie: '',
      cui: '',
      norc: '',
      adresaBeneficiar: '',
      adresaCompanie: '',
      banca: '',
      telefon: '',
      iban: '',
      judet: '',
      localitate: '',
      sediuCentral: '',
      userId: ''
    }
  }

  addProiectModel: any ={
    denumireProiect: '',
    locatie: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: '',
    numarContract: '',
    numePersoanaContact: '',
    prenumePersoanaContact: '',
    telefon: '',
    email: '',
  }
  isChecked: any = false;

  changeRoleModel: any = {
    id: '',
    roleId: '',
    userId: ''
  }

  fileName= 'ExcelSheet.xlsx';
  userName : any;
  clientId: any;
//#endregion
constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
}
  ngOnInit(): void {
    this.sessionData.current_page_title = "CLIENȚI"
    this.nav.show();
    this.http.get<any>(this.appUrl + 'api/Role').subscribe((data) => {
      this.sessionData.roles = data;  
    });
  }
  

//#region Editare user

editModal(content: any, gp: any) {
  console.log(gp);
  this.modalService.open(content);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.get<any>(this.appUrl + 'api/user/' + gp.id, header).subscribe((data) => {
    this.getEditUser = data;
  });
  this.userModel = {
    id: gp.id,
    nume: gp.nume,
    prenume: gp.prenume,
    email: gp.email,
    cost: gp.cost,
    cnp: gp.client.cnp,
    companie: gp.client.companie,
    cui: gp.client.cui,
    norc: gp.client.norc,
    adresaBeneficiar: gp.client.adresaBeneficiar,
    adresaCompanie: gp.client.adresaCompanie,
    banca: gp.client.banca,
    sediuCentral: gp.client.sediuCentral,
    judet: gp.client.judet,
    localitate: gp.client.localitate,
    telefon: gp.client.telefon,
    iban: gp.client.iban,
  }
}
checkValue(){

}
EditResource(fEditCategory: NgForm){

  this.userModel.nume = fEditCategory.value.Nume;
  this.userModel.prenume = fEditCategory.value.Prenume;
  this.userModel.email = fEditCategory.value.Email;
  this.userModel.cost = fEditCategory.value.Cost;
  this.userModel.cnp = fEditCategory.value.CNP;
  this.userModel.companie = fEditCategory.value.Companie;
  this.userModel.cui = fEditCategory.value.CUI;
  this.userModel.norc = fEditCategory.value.NORC;
  this.userModel.adresaBeneficiar = fEditCategory.value.Adresa;
  this.userModel.adresaCompanie = fEditCategory.value.AdresaCompanie;
  this.userModel.banca = fEditCategory.value.Banca,
  this.userModel.sediuCentral = fEditCategory.value.SediuCentral,
  this.userModel.judet = fEditCategory.value.Judet,
  this.userModel.localitate = fEditCategory.value.Localitate,
  this.userModel.telefon = fEditCategory.value.Telefon;
  this.userModel.iban = fEditCategory.value.IBAN;


  return this.http.put(this.appUrl + 'api/User/updateUser', this.userModel).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Stergere user
deleteModal(content: any, category: any) {
this.modalService.open(content);
this.userModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
this.http.delete(this.appUrl + 'api/User/' + this.userModel.id, header).subscribe(
  (response) => {    
    window.location.reload();
  },
  (error) => { 
    console.error('error caught in component')
    this.message = "Eroare"
  }
);
}
//#endregion

//#region Editare Rol User

changeRoleModal(content: any, gp: any) {
  this.modalService.open(content);
  this.http.get<any>(this.appUrl + 'api/userrole/' + gp.id).subscribe((data) => {
    this.getUserRole = data;
  });
  this.userModel = {
    id: gp.id,
    nume: gp.nume,
    prenume: gp.prenume,
    email: gp.email,
    cost: gp.cost,
    rol: gp.rol
  }
}

ChangeRoleResource(fEditCategory: NgForm){

  this.changeRoleModel = {
    id: this.getUserRole.id,
    roleId: Number(fEditCategory.value.Rol),
    userId: this.getUserRole.userId
  }

  return this.http.put(this.appUrl + 'api/UserRole/' + this.changeRoleModel.id, this.changeRoleModel).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Adauga proiect
addModal(content: any, gp: any) {
  this.modalService.open(content);
  this.clientId = gp.id;
}

AddResource(fAddCategory: NgForm){

  if(fAddCategory.value.Prioritate == ""){
    this.message = "Alege prioritatea proiectului!";
    return;
  }
  else if(fAddCategory.value.Status == ""){
    this.message = "Alege statusul proiectului!";
    return;
  }

  if(!fAddCategory.value.Locatie){
    console.log("asdasd")
    this.addProiectModel.locatie = "Locatie";
  }
  else{
    this.addProiectModel.locatie = fAddCategory.value.Locatie;
  }
  if(!fAddCategory.value.valoareProduse){
    console.log("asdasd")

    this.addProiectModel.valoareProduse = "1";
  }
  else{
    this.addProiectModel.valoareProduse = fAddCategory.value.ValoareProduse;
  }
  if(!fAddCategory.value.valoareServicii){
    console.log("asdasd")

    this.addProiectModel.valoareServicii = "1";
  }
  else{
    this.addProiectModel.valoareServicii = fAddCategory.value.ValoareServicii;
  }
  if(!fAddCategory.value.valoareTotala){
    console.log("asdasd")

    this.addProiectModel.valoareTotala = "1";
  }
  else{
    this.addProiectModel.valoareTotala = fAddCategory.value.ValoareTotala;
  }
  if(!fAddCategory.value.numarContract){
    this.addProiectModel.numarContract = "111111";
  }
  else{
    this.addProiectModel.numarContract = fAddCategory.value.NumarContract;
  }
  if(!fAddCategory.value.numePersoanaContact){
    this.addProiectModel.numePersoanaContact = "Popescu";
  }
  else{
    this.addProiectModel.numePersoanaContact = fAddCategory.value.NumePersoanaContact;
  }
  if(!fAddCategory.value.prenumePersoanaContact){
    this.addProiectModel.prenumePersoanaContact = "Ion";
  }
  else{
    this.addProiectModel.prenumePersoanaContact = fAddCategory.value.PrenumePersoanaContact;
  }
  if(!fAddCategory.value.telefon){
    this.addProiectModel.telefon = "076";
  }
  else{
    this.addProiectModel.telefon = fAddCategory.value.Telefon;
  }

  if(!fAddCategory.value.email){
    this.addProiectModel.email = "aaa@gmail.com";
  }
  else{
    this.addProiectModel.email = fAddCategory.value.Email;
  }
  this.addProiectModel.denumireProiect = fAddCategory.value.DenumireProiect;
  this.addProiectModel.prioritate = fAddCategory.value.Prioritate;
  this.addProiectModel.status = fAddCategory.value.Status;
  this.addProiectModel.userId = this.clientId;


  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/proiect/', this.addProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Adauga proiect2
addModal2(content: any, gp: any) {
  this.modalService.open(content);
  this.clientId = gp.id;
}

AddResource2(fAddCategory2: NgForm){

  if(fAddCategory2.value.Stare == ""){
    this.message = "Alege starea proiectului!";
    return;
  }

  if(!fAddCategory2.value.ValoareVanzareMateriale){
    this.addProiectModel.valoareVanzareMateriale = 0;
  }
  else{
    this.addProiectModel.valoareVanzareMateriale = fAddCategory2.value.ValoareVanzareMateriale;
  }

  if(!fAddCategory2.value.ValoareVanzareManopera){
    this.addProiectModel.valoareVanzareManopera = 0;
  }
  else{
    this.addProiectModel.valoareVanzareManopera = fAddCategory2.value.ValoareVanzareManopera;
  }

  if(!fAddCategory2.value.ValoareTotala){
    this.addProiectModel.valoareTotala = 0;
  }
  else{
    this.addProiectModel.valoareTotala = fAddCategory2.value.ValoareTotala;
  }

  if(!fAddCategory2.value.ValoareMaterialeDescarcate){
    this.addProiectModel.valoareMaterialeDescarcate = 0;
  }
  else{
    this.addProiectModel.valoareMaterialeDescarcate = fAddCategory2.value.ValoareMaterialeDescarcate;
  }

  if(!fAddCategory2.value.ValoareManoperaUtilizata){
    this.addProiectModel.valoareManoperaUtilizata = 0;
  }
  else{
    this.addProiectModel.valoareManoperaUtilizata = fAddCategory2.value.ValoareManoperaUtilizata;
  }

  if(!fAddCategory2.value.DiferentaValoareMateraile){
    this.addProiectModel.diferentaValoareMateraile = 0;
  }
  else{
    this.addProiectModel.diferentaValoareMateraile = fAddCategory2.value.DiferentaValoareMateraile;
  }

  if(!fAddCategory2.value.DiferentaValoareManopera){
    this.addProiectModel.diferentaValoareManopera = 0;
  }
  else{
    this.addProiectModel.diferentaValoareManopera = fAddCategory2.value.DiferentaValoareManopera;
  }

  let numeUser = "";

  this.sessionData.users.forEach((element: any) => {
    if(element.id == fAddCategory2.value.Manager){
      numeUser = element.prenume + " " + element.nume;
    }
  });

  this.addProiectModel.numeProiect = fAddCategory2.value.NumeProiect;
  this.addProiectModel.stare = fAddCategory2.value.Stare;
  this.addProiectModel.managerId = fAddCategory2.value.Manager;
  this.addProiectModel.numeManager = numeUser;
  this.addProiectModel.userId = this.clientId;


  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/proiect2/', this.addProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

goToDashboard(client: any){
  this.sessionData.clientId = client.id;
  localStorage.setItem('ClientId',client.id);
  localStorage.setItem('ClientName',client.nume + " " + client.prenume);

  this.router.navigate(['/dashboard-client']);
}

  Search(){
  if(this.denumire != ""){
    this.sessionData.users = this.sessionData.users.filter((res: { nume: string; prenume: string; }) => {
      return res.nume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.prenume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
    }else if(this.denumire == ""){
      this.authService.getAllUsers();
    }
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  

}
