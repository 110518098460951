import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-retetar',
  templateUrl: './gestiune-retetar.component.html',
  styleUrls: ['./gestiune-retetar.component.css']
})
export class GestiuneRetetarComponent implements OnInit {

   //#region Declarari
   userId: number | undefined;
   appUrl: string = environment.appUrl;
   selectedfile: File | undefined;
   message: string | undefined;
   denumire: any;
   gPrincipala : any;
   p: number = 1;
   buttonDisable: boolean = false;
   gRetetarModel: any = {
    gestiuneRetetarId: '',
    cantitate: '',
    userId: '',
    data: ''
   }
   gPrincipalaModel: any;
   addGTransferModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     userId: '',
     numeUser: '',
     proiectId: ''
   };
   addRezervareModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     proiectId: '',
     numeUser: ''
   };
   addGVanzareModel: any = {
     gestiunePrincipalaId: '',
     cantitate: '',
     numeUser: '',
     mentiuni: ''
   };
   fileName= 'ExcelSheet.xlsx';
   userName : any;
   file : any = 'file';
   buffer: any;
   gestiuneRetetarAux: any;
   idProiectAles: any;
   idContractAles: any;
   proiectFlag: any = false;
   idSectiuneAleasa: any;
   contractFlag: any = false;
   addGProductieModel: any;
 //#endregion
 
   constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
   }
 
   async ngOnInit(): Promise<void> {
     this.sessionData.current_page_title = "GESTIUNE RETETAR";
     this.buttonDisable = false;
 
     const gestiunePrincipala = this.authService.getGestiuneRetetar();
     await lastValueFrom(gestiunePrincipala);
     
     this.gestiuneRetetarAux = this.sessionData.gestiuneRetetar;
   }
 
    //functie pentru fisierul incarcat
    onFS(event: any){
     if(<File>event.target.files[0] != null){
       this.selectedfile = <File>event.target.files[0];
     }
   }
 
   //#region Adauga produs
 addModal(content: any) {
   this.modalService.open(content);
 }
 
 AddResource(fAddCategory: NgForm){
 
   this.buttonDisable = true;
   let myDate = new Date(Date.now());
   fAddCategory.value.File = "file";
   fAddCategory.value.Files = this.selectedfile;
   const formData = new FormData();
   formData.append("CodIntern", fAddCategory.value.CodIntern.toString());
   formData.append("CodExtern", fAddCategory.value.CodExtern.toString());
   formData.append("Denumire", fAddCategory.value.Denumire.toString());
   formData.append("Producator", fAddCategory.value.Producator.toString());
   formData.append("Cantitate", fAddCategory.value.Cantitate);
   formData.append("Data", myDate.toDateString());
   formData.append("NumeUser", (this.sessionData.user.nume + " " + this.sessionData.user.prenume).toString());
   formData.append("CantitateReferinta", fAddCategory.value.CantitateReferinta);
   formData.append("Locatie", fAddCategory.value.Locatie.toString());
   if(fAddCategory.value.Critic == true){
     formData.append("Critic", "true");
   }
   else{
     formData.append("Critic", "false");
   }
   formData.append("NumarFacturaAchizitie", fAddCategory.value.NumarFacturaAchizitie.toString());
   formData.append("Image", fAddCategory.value.File.toString());
   formData.append("Images",  fAddCategory.value.Files);
 
   var header = {
     headers: new HttpHeaders()
       .set('Authorization',  `Bearer ${this.authService.getToken()}`)
   }
   return this.http.post(this.appUrl + 'api/gestiuneRetetar/', formData, header).subscribe(
     (response) => {    
       window.location.reload();
     },
     (error) => { 
       console.error('error caught in component')
       this.message = "Eroare"
     }
   );
 
 }
 //#endregion
 
 //#region Editare produs
 
   editModal(content: any, gp: any) {
     this.modalService.open(content);
     this.gPrincipalaModel.id = gp.id;
     this.gPrincipalaModel.denumire = gp.denumire;
     this.gPrincipalaModel.images = gp.images;
     this.gPrincipalaModel.image = gp.image;
     this.gPrincipalaModel.codIntern = gp.codIntern;
     this.gPrincipalaModel.codExtern = gp.codExtern;
     this.gPrincipalaModel.producator = gp.producator;
     this.gPrincipalaModel.cantitate = gp.cantitate;
     this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
     this.gPrincipalaModel.locatie = gp.locatie;
     this.gPrincipalaModel.critic = gp.critic;
     this.gPrincipalaModel.data = gp.data;
     this.gPrincipalaModel.numeUser = gp.numeUser;
     this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
   }
 
   EditResource(fEditCategory: NgForm){
     this.buttonDisable = true;
     fEditCategory.value.File = "file";
     const formData1 = new FormData();
     if(fEditCategory.value.Files != ""){
       fEditCategory.value.Files = this.selectedfile;
       formData1.append("Id", this.gPrincipalaModel.id);
       formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
       formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
       formData1.append("Denumire", fEditCategory.value.Denumire.toString());
       formData1.append("Producator", fEditCategory.value.Producator.toString());
       formData1.append("Cantitate", fEditCategory.value.Cantitate);
       formData1.append("Data", this.gPrincipalaModel.data);
       formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
       formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
       formData1.append("Locatie", fEditCategory.value.Locatie.toString());
       if(fEditCategory.value.Critic == true){
         formData1.append("Critic", "true");
       }
       else{
         formData1.append("Critic", "false");
       }
      
       formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
       formData1.append("Image", fEditCategory.value.File.toString());
       formData1.append("Images",  fEditCategory.value.Files);
     }
     else{
       formData1.append("Id", this.gPrincipalaModel.id);
       formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
       formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
       formData1.append("Denumire", fEditCategory.value.Denumire.toString());
       formData1.append("Producator", fEditCategory.value.Producator.toString());
       formData1.append("Data", this.gPrincipalaModel.data);
       formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
       formData1.append("Cantitate", fEditCategory.value.Cantitate);
       formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
       formData1.append("Locatie", fEditCategory.value.Locatie.toString());
       if(fEditCategory.value.Critic == true){
         formData1.append("Critic", "true");
       }
       else{
         formData1.append("Critic", "false");
       }
       formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
       formData1.append("Image", this.gPrincipalaModel.image);
     }
     var header = {
       headers: new HttpHeaders()
         .set('Authorization',  `Bearer ${this.authService.getToken()}`)
     }
 
     return this.http.put(this.appUrl + 'api/gestiuneRetetar/' + this.gPrincipalaModel.id, formData1, header).subscribe(
       (response) => {    
         // this.message = "Produsul a fost editat!"
         window.location.reload();
       },
       (error) => { 
         console.error('error caught in component')
         this.message = "Eroare"
       }
     );
   }
 
 //#endregion
 
 //#region Stergere Produs
   deleteModal(content: any, category: any) {
     this.modalService.open(content);
     this.gPrincipalaModel.id = category.id;
   }
   delete(event: any){
     this.buttonDisable = true;
 
     var header = {
       headers: new HttpHeaders()
         .set('Authorization',  `Bearer ${this.authService.getToken()}`)
     }
     this.http.delete(this.appUrl + 'api/gestiuneRetetar/' + this.gPrincipalaModel.id, header).subscribe(
       (response) => {    
         window.location.reload();
       },
       (error) => { 
         console.error('error caught in component')
         this.message = "Eroare"
       }
     );
   }
 //#endregion

  //#region Transfera in Gestiune Productie

async addToGProductieModal(content: any, gp: any) {

  this.modalService.open(content);
  this.gRetetarModel = {
    cod: gp.codIntern + " - " + gp.denumire,
    gestiuneRetetarId: gp.id,
    cantitate: gp.cantitate,
    userId: this.sessionData.user.id,
    data: null
   }
}

AddGProductieResource(fAddGProductie: NgForm){

  this.buttonDisable = true;

  if(fAddGProductie.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else{

    this.addGProductieModel = {
      gestiuneRetetarId: this.gRetetarModel.gestiuneRetetarId,
      cantitate: fAddGProductie.value.Cantitate,
      userId: this.sessionData.user.id,
      data: Date.now
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    console.log(this.addGProductieModel);

  return this.http.post(this.appUrl + 'api/GestiuneRetetar/addGestiuneProductie', this.addGProductieModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      this.message = "Cantitate insuficienta in Gestiune Componente"
    }
  );
  }


}

//#endregion


 goToDetails(product: any){
  this.sessionData.productId = product.id;
  localStorage.setItem('ProductId',product.id);
  localStorage.setItem('ProductName',product.denumire);
  this.router.navigate(['/gestiune-retetar-componente']);
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
