import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-comanda',
  templateUrl: './gestiune-comanda.component.html',
  styleUrls: ['./gestiune-comanda.component.css']
})
export class GestiuneComandaComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  comandId: any;
  comandName: any;
  model: any = {
    id: '',
    cod: '',
    cantitate: '',
  }
  modelGestiuneComenzi: any;
  addRezervareModel: any = {
    id: '',
    cantitate: '',
    ofertareGestiuneProduse: '',
    proiectId: '',
    numeUser: ''
  };
 addToLista: any = {
   id: '',
   aprovizionareComandaId: '',
   aprovizionareComandaProduseId: '',
   ofertareGestiuneProduseId: '',
   cantitateProdus: ''
 }

 editModel: any = {
   id: '',
   cantitate: ''
 }

  produsGestiuneComandaId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  }
  
  ngOnInit(): void {
    this.sessionData.current_page_title =  "GESTIUNE COMENZI";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizionareGestiuneComenzi/getGestiuneComenzi', header).subscribe((data) => {
       this.sessionData.listaGestiuneComenzi = data;
    });
  }

//#region Transfera in Rezervare pe Proiect

  async addToRezervareModal(content: any, gp: any) {

    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);

    this.modalService.open(content);
    this.model = {
      id: gp.id,
      cod: gp.ofertareGestiuneProduse.cod,
      denumire: gp.ofertareGestiuneProduse.denumire,
      cantitate: gp.cantitate,
    }

    this.sessionData.listaGestiuneComenzi.forEach((element: { id: any; }) => {
      if(element.id == this.model.id){
        this.modelGestiuneComenzi = element;
      }
    });
}

AddRezervareResource(fAddGTransfer: NgForm){

  this.addRezervareModel = {
    id: this.model.id,
    cantitate: fAddGTransfer.value.Cantitate,
    ofertareGestiuneProduse: this.modelGestiuneComenzi.ofertareGestiuneProduse,
    proiectId: fAddGTransfer.value.Proiect,
    numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume
  };
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/AprovizionareGestiuneComenzi/addToAprovizionareRezervari', this.addRezervareModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.produsGestiuneComandaId = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/AprovizionareGestiuneComenzi/' + this.produsGestiuneComandaId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Transfera in Rezervare pe Proiect

addToListaModal(content: any, gp: any) {
  this.modalService.open(content);
  this.model = {
    id: gp.id,
    cod: gp.ofertareGestiuneProduse.cod,
    denumire: gp.ofertareGestiuneProduse.denumire,
    cantitate: gp.cantitate,
    aprovizionareComandaId: gp.aprovizionareComandaId,
    aprovizionareComandaProduseId: gp.aprovizionareComandaProduse.id,
    ofertareGestiuneProduseId: gp.ofertareGestiuneProduse.id,
  }

  this.sessionData.listaGestiuneComenzi.forEach((element: { id: any; }) => {
    if(element.id == this.model.id){
      this.modelGestiuneComenzi = element;
    }
  });
}

AddListaResource(fAddLista: NgForm){

  this.addToLista = {
    id: this.model.id,
    aprovizionareComandaId: this.model.aprovizionareComandaId,
    aprovizionareComandaProduseId: this.model.aprovizionareComandaProduseId,
    ofertareGestiuneProduseId: this.model.ofertareGestiuneProduseId,
    cantitateProdus: this.model.cantitate
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/AprovizionareGestiuneComenzi/addToAprovizionareLista', this.addToLista, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}

//#endregion

//#region Transfera in Rezervare pe Proiect

editModal(content: any, gp: any) {
  this.modalService.open(content);
  console.log(gp);
  this.model = {
    id: gp.id,
    cod: gp.ofertareGestiuneProduse.cod,
    denumire: gp.ofertareGestiuneProduse.denumire,
    cantitate: gp.cantitate,
  }

  this.sessionData.listaGestiuneComenzi.forEach((element: { id: any; }) => {
    if(element.id == this.model.id){
      this.modelGestiuneComenzi = element;
    }
  });
}

EditResource(fEdit: NgForm){

  this.editModel = {
    id: this.model.id,
    cantitate: fEdit.value.Cantitate,
  };

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/AprovizionareGestiuneComenzi/editAprovizionareComenzi', this.editModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}

//#endregion


refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
