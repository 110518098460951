import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-proiecte',
  templateUrl: './dashboard-proiecte.component.html',
  styleUrls: ['./dashboard-proiecte.component.css']
})
export class DashboardProiecteComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  taskId: any;
  taskName: any;
  fisierId: any;
  proiecte: any = [];
  messageModel: any = {
    data: '',
    mesaj: '',
    numeUser: '',
    userId: ''
  }

  messageModelRequest: any = {
    data: '',
    mesaj: '',
    userId: '',
    proiectTaskId: ''
  }

  dataNull: any = "&nbsp";
  listaStatusuri: any = [];
  proiectId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.taskId = localStorage.getItem('TaskId');
    this.taskName = localStorage.getItem('TaskName');
    datepipe = new DatePipe('ro-RO');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "STARI PROIECTE";
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getDashboardFazeProiect/', header).subscribe((data) => {
      this.proiecte = data.sort((a: { denumireProiect: string; }, b: { denumireProiect: string; }) => (a.denumireProiect < b.denumireProiect ? -1 : 1));
      console.log(this.proiecte);
    });
  }

  goToProiect(item: any){
    this.sessionData.productId = item.proiectId;
    // localStorage.setItem('ProjectId',item.proiectId);
    // localStorage.setItem('ProjectName',item.denumireProiect);

    this.router.navigate(['/task/' + item.proiectId + '/' + item.denumireProiect]);

  }

  //#region Editare Status Proiect Prev
  editStatusPrevModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectId = gp.proiectId;
    if(gp.culoare == "DataOfertare"){
      this.listaStatusuri = [];
    }
    else if(gp.culoare == "DataValidare"){
      this.listaStatusuri = ["Ofertare"];
    }
    else if(gp.culoare == "DataComercial"){
      this.listaStatusuri = ["Ofertare", "Validare"];
    }
    else if(gp.culoare == "DataProiectare"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial"];
    }
    else if(gp.culoare == "DataAchizitie"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare"];
    }
    else if(gp.culoare == "DataUzinare"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie"];
    }
    else if(gp.culoare == "DataMontajBMS"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare"];
    }
    else if(gp.culoare == "DataMontajPV"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS"];
    }
    else if(gp.culoare == "DataPIFProgramare"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV"];
    }
    else if(gp.culoare == "DataSuplimentare"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare"];
    }
    else if(gp.culoare == "DataFinalizare"){
      this.listaStatusuri = ["Ofertare", "Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare"];
    }
  }

  EditStatusPrevResource(fEditStatusPrevCategory: NgForm){
    
    var proiectModel = {
      proiectId: this.proiectId,
      statusProiect: fEditStatusPrevCategory.value.Status,
      userId: this.sessionData.user.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/Proiect/editStatusPrev', proiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Editare Status Proiect Forward
  editStatusForwModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectId = gp.proiectId;

    if(gp.culoare == "DataFinalizare"){
      this.listaStatusuri = [];
    }
    else if(gp.culoare == "DataSuplimentare"){
      this.listaStatusuri = ["Finalizat"];
    }
    else if(gp.culoare == "DataPIFProgramare"){
      this.listaStatusuri = ["Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataMontajPV"){
      this.listaStatusuri = ["PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataMontajBMS"){
      this.listaStatusuri = ["Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataUzinare"){
      this.listaStatusuri = ["Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataAchizitie"){
      this.listaStatusuri = ["Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataProiectare"){
      this.listaStatusuri = ["Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataComercial"){
      this.listaStatusuri = ["Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataValidare"){
      this.listaStatusuri = ["Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
    else if(gp.culoare == "DataOfertare"){
      this.listaStatusuri = ["Validare", "Comercial", "Proiectare", "Achizitie", "Uzinare", "Montaj BMS", "Montaj PV", "PIF/Programare", "Suplimentare", "Finalizat"];
    }
  }

  EditStatusForwResource(fEditStatusForwCategory: NgForm){
    
    var proiectModel = {
      proiectId: this.proiectId,
      statusProiect: fEditStatusForwCategory.value.Status,
      userId: this.sessionData.user.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/Proiect/editStatusForw', proiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
}
