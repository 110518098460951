import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-angajati',
  templateUrl: './lista-angajati.component.html',
  styleUrls: ['./lista-angajati.component.css']
})
export class ListaAngajatiComponent implements OnInit {

  //#region Declarari
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  userModel: any = {
    id: '',
    nume: '',
    prenume: '',
    email: '',
    cost: '',
    rol: ''
  }

  getEditUser: any;
  getUserRole: any;

  addProiectModel: any = {
    denumireProiect: '',
    locatie: '',
    numeUseri: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: ''
  }

  editUserModel: any = {
    id: '',
    nume: '',
    prenume: '',
    email: '',
    cost: '',
    password: '',
    refreshToken: '',
    refreshTokenExpiryTime: ''
  }

  changeRoleModel: any = {
    id: '',
    roleId: '',
    userId: ''
  }

  fileName= 'ExcelSheet.xlsx';
  userName : any;
//#endregion

constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
}
ngOnInit(): void {
  this.sessionData.current_page_title = "USERI"
  this.http.get<any>(this.appUrl + 'api/Role').subscribe((data) => {
    this.sessionData.roles = data;
  });
}

//#region Editare user

editModal(content: any, gp: any) {
  this.modalService.open(content);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.get<any>(this.appUrl + 'api/user/' + gp.id, header).subscribe((data) => {
    this.getEditUser = data;
  });
  this.userModel = {
    id: gp.id,
    nume: gp.nume,
    prenume: gp.prenume,
    email: gp.email,
    cost: gp.cost,
    rol: gp.rol,
    vizibilitateProgramareEchipe: gp.vizibilitateProgramareEchipe
  }
}

EditResource(fEditCategory: NgForm){

  this.editUserModel = {
    id: this.userModel.id,
    nume: fEditCategory.value.Nume,
    prenume: fEditCategory.value.Prenume,
    email: fEditCategory.value.Email,
    cost: fEditCategory.value.Cost,
    password: this.getEditUser.password,
    refreshToken:  this.getEditUser.refreshToken,
    refreshTokenExpiryTime:  this.getEditUser.refreshTokenExpiryTime,
    vizibilitateProgramareEchipe: fEditCategory.value.VizibilitateProgramareEchipe
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/User/updateUser', this.editUserModel, header).subscribe(
    (response) => {    
      this.message = "Userul a fost editat!"
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region Stergere user
deleteModal(content: any, category: any) {
this.modalService.open(content);
this.userModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
this.http.delete(this.appUrl + 'api/User/' + this.userModel.id, header).subscribe(
  (response) => {    
    this.message = "Userul a fost stears!"
  },
  (error) => { 
    console.error('error caught in component')
    this.message = "Eroare"
  }
);
}
//#endregion

//#region Editare Rol User

changeRoleModal(content: any, gp: any) {
  this.modalService.open(content);

  this.http.get<any>(this.appUrl + 'api/UserRole/GetUserRoleById/' + gp.id).subscribe((data) => {
    this.getUserRole = data;
  });
  this.userModel = {
    id: gp.id,
    nume: gp.nume,
    prenume: gp.prenume,
    email: gp.email,
    cost: gp.cost,
    rol: gp.rol,
  }
}

ChangeRoleResource(fEditCategory: NgForm){

  this.changeRoleModel = {
    id: this.getUserRole.id,
    roleId: Number(fEditCategory.value.Rol),
    userId: this.getUserRole.userId
  }
  console.log(this.changeRoleModel);

  return this.http.put(this.appUrl + 'api/UserRole/' + this.changeRoleModel.id, this.changeRoleModel).subscribe(
    (response) => {    
      this.message = "Rolul a fost editat!"
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion


  Search(){
  if(this.denumire != ""){
    this.sessionData.users = this.sessionData.users.filter((res: { nume: string; prenume: string; }) => {
      return res.nume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.prenume.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.authService.getAllUsers();
  }

  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

}