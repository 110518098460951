import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accesorii-unitati-functionale',
  templateUrl: './accesorii-unitati-functionale.component.html',
  styleUrls: ['./accesorii-unitati-functionale.component.css']
})
export class AccesoriiUnitatiFunctionaleComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  addAccesoriuModel: any = {
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    adaos:'',
    pretUnitarVanzare: '',
    gabarit: '',
    gabaritTotal: ''
  }
  updateAccesoriuModel: any = {
    id: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    adaos:'',
    pretUnitarVanzare: '',
    gabarit: '',
    gabaritTotal: ''
  }
  searchModel: any = {
    cuvant: ''
  };
  denumire: any;
  produseOfertare: any;
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  ngOnInit(): void {
    this.sessionData.current_page_title = "OFERTARE - ACCESORII UNITATI FUNCTIONALE";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareAccesoriiUnitatiFunctionaleTablou/', header).subscribe((data) => {
      this.sessionData.ofertareAccesoriiUnitatiFunctionaleTablou = data;
    });
  }

  pretTotalFunction(item1: any, item2: any){
    return parseFloat(item1) * parseFloat(item2);
  }

addModal(content: any, currentTable: any, tableType: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.searchModel.cuvant = this.denumire;

  this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProduseBySearch', this.searchModel, header).subscribe((data) => {
    this.produseOfertare = data;
  });
}

addProductFromSearch(content: any, gp: any) {
  this.modalService.open(content);
  this.updateAccesoriuModel = {
    id: gp.id,
    cod: 'Cod',
    denumire: gp.denumire,
    cantitate: gp.cantitate,
    pretUnitarAchizitie: gp.pretUnitarAchizitie,
    adaos: gp.adaos,
    pretUnitarVanzare: gp.pretUnitarVanzare,
    gabarit: 0,
    gabaritTotal: 0
  }
}

AddProductResource(fEditCategory: NgForm){

  this.addAccesoriuModel = {
    denumire: fEditCategory.value.Denumire,
    cod: fEditCategory.value.Cod,
    cantitate: fEditCategory.value.Cantitate,
    pretUnitarAchizitie: fEditCategory.value.PretUnitarAchizitie,
    adaos: fEditCategory.value.Adaos,
    pretUnitarVanzare: fEditCategory.value.PretUnitarVanzare,
    gabarit: fEditCategory.value.Gabarit,
    gabaritTotal: fEditCategory.value.GabaritTotal
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/OfertareAccesoriiUnitatiFunctionaleTablou/', this.addAccesoriuModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Editare produs

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.updateAccesoriuModel = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      cantitate: gp.cantitate,
      pretUnitarAchizitie: gp.pretUnitarAchizitie,
      adaos: gp.adaos,
      pretUnitarVanzare: gp.pretUnitarVanzare,
      gabarit: gp.gabarit,
      gabaritTotal: gp.gabaritTotal
    }
  }

  EditResource(fEditCategory: NgForm){

    this.updateAccesoriuModel.cod = fEditCategory.value.Cod;
    this.updateAccesoriuModel.denumire = fEditCategory.value.Denumire;
    this.updateAccesoriuModel.cantitate = fEditCategory.value.Cantitate;
    this.updateAccesoriuModel.pretUnitarAchizitie = fEditCategory.value.PretUnitarAchizitie;
    this.updateAccesoriuModel.adaos = fEditCategory.value.Adaos;
    this.updateAccesoriuModel.pretUnitarVanzare = fEditCategory.value.PretUnitarVanzare;
    this.updateAccesoriuModel.gabarit = fEditCategory.value.Gabarit;
    this.updateAccesoriuModel.gabaritTotal = fEditCategory.value.GabaritTotal;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/OfertareAccesoriiUnitatiFunctionaleTablou/' + this.updateAccesoriuModel.id, this.updateAccesoriuModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.updateAccesoriuModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/OfertareAccesoriiUnitatiFunctionaleTablou/' + this.updateAccesoriuModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
