import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-aprovizionare-comanda',
  templateUrl: './gestiune-aprovizionare-comanda.component.html',
  styleUrls: ['./gestiune-aprovizionare-comanda.component.css']
})
export class GestiuneAprovizionareComandaComponent implements OnInit, OnDestroy {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  comandId: any;
  comandName: any;
  comandStatus: any;
  aprovizionareModelUpdate: any = {
    id: '',
    cantitateOfertaPrincipala: '',
    cantitateProdus: '',
    status: '',
    ofertareGestiuneProduseId: '',
    aprovizionareComandaId: ''
  }
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.comandId = localStorage.getItem('ComandId');
    this.comandName = localStorage.getItem('ComandName');
    this.comandStatus = localStorage.getItem('ComandStatus');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title =  this.comandName;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizionareComandaProduse/getProduseByComandaId/' + this.comandId, header).subscribe((data) => {
       this.sessionData.listaProduseComenzi = data;
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('ComandId');
    localStorage.removeItem('ComandName');
    localStorage.removeItem('ComandStatus');
  }

  //#region Editare status
 editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.aprovizionareModelUpdate = {
    id: gp.id,
    cantitateOfertaPrincipala: gp.cantitateOfertaPrincipala,
    cantitateProdus: gp.cantitateProdus,
    status: gp.status,
    ofertareGestiuneProduseId: gp.ofertareGestiuneProdusId,
    aprovizionareComandaId: this.comandId
  }
  
}

EditResource(fEditCategory: NgForm){
  this.aprovizionareModelUpdate.status = fEditCategory.value.Status
  console.log(this.aprovizionareModelUpdate);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/AprovizionareComandaProduse/' + this.aprovizionareModelUpdate.id, this.aprovizionareModelUpdate, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Terminare tablou
  addFinishModal(content: any) {
    this.modalService.open(content);
  }

  AddFinishResource(fAddFinishCategory: NgForm){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/AprovizionareComandaProduse/UpdateComandaById/' + this.comandId, '', header).subscribe(
      (response) => {    
        localStorage.setItem('ComandStatus', "Finalizata");
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion

  exportExcel() : void {

    let url = this.appUrl + 'api/AprovizionareComandaProduse/Raport/' + this.comandId;
    window.open(url);
  }

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
