import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-proiect',
  templateUrl: './dashboard-proiect.component.html',
  styleUrls: ['./dashboard-proiect.component.css']
})
export class DashboardProiectComponent implements OnInit {
  //#region Declarari
  projectOption: any = "Toate";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id: '',
    numeContract: '',
    proiectId: ''
  }

  addProiectModel: any ={
    numeContract: '',
    proiectId: '',
  }

  editProiectModel: any = {
    id: '',
    numeContract: '',
    proiectId: '',
  }
  
  fileName= 'ExcelSheet.xlsx';
  userName : any;
//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    sessionData.proiect2Id = localStorage.getItem('ProjectId');
  }
  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "CONTRACTE - " + localStorage.getItem('ProjectName');
    
    const proiecte = this.authService.getProiecte2Contracte(this.sessionData.proiect2Id);
    await lastValueFrom(proiecte);
  }

  ngOnDestroy() {
    localStorage.removeItem('ProjectId');
    localStorage.removeItem('ProjectName');
  }

  goToSectiuni(project: any){
    this.sessionData.contractId = project.id;
    localStorage.setItem('ContractId',project.id);
    localStorage.setItem('ContractName',project.numeContract);
    
    this.router.navigate(['/dashboard-contracte']);
  }

  //#region Editare proiect

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectModel = {
      id: gp.id,
      numeContract: gp.numeContract,
      proiectId: this.sessionData.proiect2Id
    }
  }

  EditResource(fEditCategory: NgForm){

    this.editProiectModel = {
      id: this.proiectModel.id,
      numeContract: fEditCategory.value.NumeContract,
      proiectId: this.sessionData.proiect2Id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/Proiect2/updateProiect2Contract/' + this.editProiectModel.id, this.editProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

  //#region Adauga Contract
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.addProiectModel ={
    numeContract: fAddCategory.value.NumeContract,
    proiectId: this.sessionData.proiect2Id
  }
  
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/proiect2/addProiect2Contract', this.addProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

    //#region Stergere Proiect
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/Proiect2/deleteProiect2Contract/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
}}

}
