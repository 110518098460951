import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { parse } from 'path';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rapoarte-user-prezenta-pdf-periodice',
  templateUrl: './rapoarte-user-prezenta-pdf-periodice.component.html',
  styleUrls: ['./rapoarte-user-prezenta-pdf-periodice.component.css']
})
export class RapoarteUserPrezentaPdfPeriodiceComponent implements OnInit {
//#region Declarari
userId: number | undefined;
appUrl: string = environment.appUrl;
projectOption: any = "Caută după Proiect";
rapoarte: any;
sesizareId: any;
message: any;
excelProiectModel: any = {
tip: '',
proiectId: '',
dataInceput: '',
dataSfarsit: ''
}
proiectId: any;
rapoarteAux: any;
denumire: any
excelUserId: any;

excelAngajatModel: any = {
  userId: '',
  dataInceput: '',
  dataSfarsit: ''
 }
//#endregion
constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
 }

 async ngOnInit(): Promise<void> {
   this.sessionData.current_page_title = "RAPOARTE USER PREZENTA PERIODICE";
   
   const proiecte = this.authService.getProiecte();
   await lastValueFrom(proiecte);

   var header = {
     headers: new HttpHeaders()
       .set('Authorization',  `Bearer ${this.authService.getToken()}`)
   }
    this.http.get<any>(this.appUrl + 'api/UserPrezenta/GetRaportUserPrezentaPdfPeriodic', header).subscribe((data) => {
      this.rapoarte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));;
      this.rapoarteAux = this.rapoarte;
      console.log(this.rapoarte);
    });
 }

}
