import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fotovoltaice-produse',
  templateUrl: './fotovoltaice-produse.component.html',
  styleUrls: ['./fotovoltaice-produse.component.css']
})
export class FotovoltaiceProduseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
