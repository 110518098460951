import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabel-sesizari-tehnice',
  templateUrl: './tabel-sesizari-tehnice.component.html',
  styleUrls: ['./tabel-sesizari-tehnice.component.css']
})
export class TabelSesizariTehniceComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  projectOption: any = "Caută după Proiect";
  sesizariTehnice: any;
  sesizariTehniceAux: any;
  sesizareId: any;
  message: any;

  //#endregion
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

   ngOnInit(): void {
    this.sessionData.current_page_title = "SESIZARI TEHNICE";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.get<any>(this.appUrl + 'api/SesizareTehnica/getSesizareTehnica', header).subscribe((data) => {
       this.sesizariTehnice = data.sort((a: any, b: any) => (a.data < b.data) ? 1 : -1);
       this.sesizariTehniceAux = this.sesizariTehnice;
     });
  }

  //#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.sesizareId = category.id;
  }
  delete(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/SesizareTehnica/' + this.sesizareId, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

selectChangeHandler(event: any){
  this.sesizariTehnice = this.sesizariTehniceAux;
  if(event.target.value != ""){
    this.sesizariTehnice = this.sesizariTehnice.filter((res: any) => {
      return res.denumireProiect.toLocaleLowerCase().match(event.target.value.toLocaleLowerCase());
    });
  }else if(event.target.value == ""){
    this.ngOnInit();
  }
}

}
