import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { OfertareService } from 'src/app/services/ofertare.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

export interface ProductInterface {
  position: number;
  cod: string;
  denumire: string;
  cantitate: string;
  pretUnitarAchizitie: string;
  pretUnitarVanzare: string;
  pretTotal: string;
  cantitateRezervate: string;
  totalRezervate: string;
  cantitateDescarcate: string;
  totalDescarcate: string;
}

@Component({
  selector: 'app-dashboard-financiar-proiect',
  templateUrl: './dashboard-financiar-proiect.component.html',
  styleUrls: ['./dashboard-financiar-proiect.component.css']
})
export class DashboardFinanciarProiectComponent implements OnInit {

  @ViewChild('table') table: MatTable<ProductInterface> | any;
  displayedColumns: string[] = ['position', 'cod', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'pretUnitarVanzare','pretTotal','cantitateRezervate','totalRezervate','cantitateDescarcate','totalDescarcate','delete'];
  displayedColumnsServicii: string[] = ['denumire', 'total','delete'];

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  routeSub: any;
  projectId: any;
  projectName: any;
  tables: any = [];
  serviciiTable: any = [];
  selectedfile: File | undefined;
  message: string | undefined;
  codProduct: string | undefined;
  getProdusByCodModel: any = {
    cod: ''
  }
  getProdusByCodDashboardFinanciarModel: any = {
    cod: '',
    proiectId: ''
  }
  productModel: any = {
    id: '',
    position: '',
    producator: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    pretUnitarVanzare: '',
    pretTotal: '',
    cantitateRezervate: '',
    totalRezervate: '',
    cantitateDescarcate: '',
    totalDescarcate: ''
  }
  denumire: any;
  produseOfertare: any;
  searchModel: any = {
    cuvant: ''
  };
  totalContractatEchipamente: any = 0;
  totalAccesoriiMontaj: any = 0;
  totalManoperaMontaj: any = 0;
  totalProgramarePunereFunctiune: any = 0;
  currentTable: any;

  constructor(public ofertareService: OfertareService, public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService, private route: ActivatedRoute) {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectName = params['projectName'];
    });
  }

  ngOnInit(): void {
  }

  addProduct(currentTable: any){
    this.getProdusByCodDashboardFinanciarModel.cod = this.codProduct;
    this.getProdusByCodDashboardFinanciarModel.proiectId = this.projectId;
    this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProdusByCodDashboardFinanciar', this.getProdusByCodDashboardFinanciarModel).subscribe((element: any) => {
      this.productModel = {
        id: element.id,
        position: 11,
        producator: element.producator,
        cod: element.cod,
        denumire: element.denumire,
        cantitate: element.cantitate,
        pretUnitarAchizitie: element.pretUnitarAchizitie,
        pretUnitarVanzare: parseFloat(element.pretUnitarVanzare).toFixed(2),
        pretTotal: (parseFloat(element.cantitate) * parseFloat(element.pretUnitarVanzare)).toFixed(2),
        cantitateRezervate: element.cantitateRezervate,
        totalRezervate: element.totalRezervate,
        cantitateDescarcate: element.cantitateDescarcate,
        totalDescarcate: element.totalDescarcate
      }
      this.tables[currentTable].dataSource.push(this.productModel);
      this.tables[currentTable].totalContractatEchipamente = parseFloat(this.tables[currentTable].totalContractatEchipamente) + parseFloat(this.productModel.pretTotal);
      this.tables[currentTable].totalConsumatEchipamente = parseFloat(this.tables[currentTable].totalConsumatEchipamente) + parseFloat(this.productModel.totalDescarcate);
      this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];

      console.log(this.tables[currentTable].totalContractatEchipamente);
      console.log(this.tables[currentTable].totalConsumatEchipamente)
    });
  }

  addProductFromSearch(item: any){
    this.productModel = {
      id: item.id,
      position: 1,
      producator: item.producator,
      cod: item.cod,
      denumire: item.denumire,
      cantitate: item.cantitate,
      pretUnitarAchizitie: item.pretUnitarAchizitie,
      adaos: item.adaos,
      pretUnitarVanzare: parseFloat(item.pretUnitarVanzare).toFixed(2),
      pretTotal: (parseFloat(item.cantitate) * parseFloat(item.pretUnitarVanzare)).toFixed(2),
      descriereProdus: item.descriereProdus,
      accesoriiUzinare: '0',
      accesoriiMontajCamp: '0',
      manopera: '0',
      gabarit: '0',
      gabaritTot: '0',
      checked: false
    }

    this.tables[this.currentTable].dataSource.push(this.productModel);
    this.tables[this.currentTable].totalEchipamente = parseFloat(this.tables[this.currentTable].totalEchipamente) + parseFloat(this.productModel.pretTotal);
    this.tables[this.currentTable].totalAccesoriiMontaj = parseFloat(this.tables[this.currentTable].totalAccesoriiMontaj) + (parseFloat(this.productModel.cantitate) * parseFloat(this.productModel.accesoriiMontajCamp));
    this.tables[this.currentTable].dataSource = [...this.tables[this.currentTable].dataSource];
    this.modalService.dismissAll();
  }

  //#region cauta produs
addModal(content: any, currentTable: any) {
  this.modalService.open(content);
  this.currentTable = currentTable;
}

AddResource(fAddCategory: NgForm){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.searchModel.cuvant = this.denumire;

  this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProduseBySearch', this.searchModel, header).subscribe((data) => {
    this.produseOfertare = data;
  });
}
//#endregion

checkProdus(element: any, currentTable: any){
    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalProgramarePunereFunctiune = 0;
    let isChecked: any;
    if(element.checked == false){
      isChecked = true;
    }
    else{
      isChecked = false;
    }
    this.tables[currentTable].dataSource.forEach((item: any) => {
      if(element.id == item.id){
        if(isChecked == true){
          this.tables[currentTable].totalProgramarePunereFunctiune = parseFloat(this.tables[currentTable].totalProgramarePunereFunctiune) + (parseFloat(item.cantitate) * parseFloat(item.manopera));
        }
      }
      else{
        if(item.checked == true){
          this.tables[currentTable].totalProgramarePunereFunctiune = parseFloat(this.tables[currentTable].totalProgramarePunereFunctiune) + (parseFloat(item.cantitate) * parseFloat(item.manopera));
        }
       
      }
    });
}

deleteProdus(element: any, currentTable: any){
  let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
  this.tables[currentTable].dataSource.splice(index,1)
  this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
  this.tables[currentTable].totalEchipamente = 0;
  this.tables[currentTable].totalAccesoriiMontaj = 0;
  this.tables[currentTable].totalManoperaMontaj = 0;
  this.tables[currentTable].totalProgramarePunereFunctiune = 0;
  let accesoriiMontajFlag = false;
  this.tables[currentTable].dataSource.forEach((element: any) => {
    this.tables[currentTable].totalEchipamente = parseFloat(this.tables[currentTable].totalEchipamente) + parseFloat(element.pretTotal);
    this.tables[currentTable].totalAccesoriiMontaj = parseFloat(this.tables[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp));
    this.tables[currentTable].totalManoperaMontaj = parseFloat(this.tables[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
    if(element.checked == true){
      this.tables[currentTable].totalProgramarePunereFunctiune = parseFloat(this.tables[currentTable].totalProgramarePunereFunctiune) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
    }
    if(element.accesoriiMontajCamp != "0"){
      accesoriiMontajFlag = true;
    }
  });
  if(accesoriiMontajFlag == false){
    this.tables[currentTable].accesoriiMontaj = ""; 
  }
}

async editProdus(element: any, tip: string, currentTable: any){
  if(tip == 'cantitate'){
    console.log(currentTable);
    element.pretTotal = parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate);
    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalEchipamente = 0;
    this.tables[currentTable].totalAccesoriiMontaj = 0;
    this.tables[currentTable].totalManoperaMontaj = 0;
    this.tables[currentTable].totalProgramarePunereFunctiune = 0;
    this.tables[currentTable].dataSource.forEach((element: any) => {
      this.tables[currentTable].totalEchipamente = parseFloat(this.tables[currentTable].totalEchipamente) + parseFloat(element.pretTotal);
      this.tables[currentTable].totalAccesoriiMontaj = parseFloat(this.tables[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp));
      this.tables[currentTable].totalManoperaMontaj = parseFloat(this.tables[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
      if(element.checked == true){
        this.tables[currentTable].totalProgramarePunereFunctiune = parseFloat(this.tables[currentTable].totalProgramarePunereFunctiune) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
      }
    });
  }
  else if(tip == 'pretUnitarAchizitie'){
    element.pretUnitarVanzare = parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100));
    element.pretTotal = parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate);
    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalEchipamente = 0;
    this.tables[currentTable].dataSource.forEach((element: any) => {
      this.tables[currentTable].totalEchipamente = parseFloat(this.tables[currentTable].totalEchipamente) + parseFloat(element.pretTotal);
    });
  }
  else if(tip == 'adaos'){
    element.pretUnitarVanzare = parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100));
    element.pretTotal = parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate);
    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalEchipamente = 0;
    this.tables[currentTable].dataSource.forEach((element: any) => {
      this.tables[currentTable].totalEchipamente = parseFloat(this.tables[currentTable].totalEchipamente) + parseFloat(element.pretTotal);
    });
  }
  else if(tip == 'accesoriiMontajCamp'){

    const gestiunePrincipala = this.ofertareService.getOfertareAccesoriiMontaj();
    await lastValueFrom(gestiunePrincipala);
    let i = 1;
    let totalAccesoriiMontajTabel = 0;
    this.sessionData.ofertareAccesoriiMontaj.forEach((element: any) => {
      element.position = i;
      element.pretTotal = element.pretUnitarVanzare * element.cantitate;
      totalAccesoriiMontajTabel = totalAccesoriiMontajTabel + element.pretTotal;
      i = i + 1;
    });
    
    this.tables[currentTable].accesoriiMontaj = this.sessionData.ofertareAccesoriiMontaj;
    this.tables[currentTable].totalAccesoriiMontajTabel = totalAccesoriiMontajTabel;

    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalAccesoriiMontaj = 0;
    this.tables[currentTable].dataSource.forEach((element: any) => {
      this.tables[currentTable].totalAccesoriiMontaj = parseFloat(this.tables[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp));
    });
  }
  else if(tip == 'manopera'){
    let index: number = this.tables[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tables[currentTable].dataSource[index] = element;
    this.tables[currentTable].dataSource = [...this.tables[currentTable].dataSource];
    this.tables[currentTable].totalManoperaMontaj = 0;
    this.tables[currentTable].totalProgramarePunereFunctiune = 0;
    this.tables[currentTable].dataSource.forEach((element: any) => {
      this.tables[currentTable].totalManoperaMontaj = parseFloat(this.tables[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
      if(element.checked == true){
        this.tables[currentTable].totalProgramarePunereFunctiune = parseFloat(this.tables[currentTable].totalProgramarePunereFunctiune) + (parseFloat(element.cantitate) * parseFloat(element.manopera));
      }
    });
  }
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

//Add categorie produse
addCategorieProduse(content: any) {
  this.modalService.open(content);
}

AddCategorieProduseResource(fAddCategory: NgForm){

  let tableModel: any = {
        denumireCategorie: fAddCategory.value.DenumireCategorie,
        dataSource: [],
        accesoriiMontaj: [],
        totalContractatEchipamente: 0,
        totalConsumatEchipamente: 0,
        totalManoperaMontaj: 0,
        totalProgramarePunereFunctiune: 0
      }
  this.tables.push(tableModel);
  this.modalService.dismissAll();
}

//Add serviciu
addServiciu(content: any) {
  this.modalService.open(content);
}

AddServiciuResource(fAddServiciu: NgForm){
  let serviciuModel: any = {
    denumire: fAddServiciu.value.DenumireServiciu,
    total: fAddServiciu.value.TotalServiciu
  }

  this.serviciiTable.push(serviciuModel);
  this.serviciiTable = [...this.serviciiTable];

  this.modalService.dismissAll();
}


}
