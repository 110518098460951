import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-vanzari',
  templateUrl: './gestiune-vanzari.component.html',
  styleUrls: ['./gestiune-vanzari.component.css']
})
export class GestiuneVanzariComponent implements OnInit {

   //#region Declarari
   userId: number | undefined;
   appUrl: string = environment.appUrl;
   selectedfile: File | undefined;
   message: string | undefined;
   numeUser: any;
   denumire: any;
   projectOption: any = "Caută după Proiect";
   gPrincipala : any;
   p: number = 1;
   gVanzariModel: any = {
     id: '',
     cantitate: '',
     data: '',
     gestiunePrincipala: '',
     mentiuni: '',
     numeUser: ''
   }
   returGPrincipalaModel: any = {
     gestiunePrincipalaId: '',
     gestiuneVanzariId: '',
     numeUser: '',
     cantitate: ''
   };
   excelModel: any = {
    dataInceput: '',
    dataSfarsit: ''
  }

   fileName= 'ExcelSheet.xlsx';
   userName : any;
   isChecked: any = false;
   buffer: any;
   gestiuneAux: any;
   buttonDisable: boolean = false;

 //#endregion

   constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
    }

    ngOnInit(): void {
      this.buttonDisable = false;
     this.sessionData.current_page_title = "GESTIUNE VANZARI"
     this.nav.show();
     var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.get<any>(this.appUrl + 'api/GestiuneVanzari/getGestiuneVanzari', header).subscribe((data) => {
       this.sessionData.gestiuneVanzari = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
       this.gestiuneAux = this.sessionData.gestiuneVanzari;
     });

   }


 //#region Retur Produs
   async returModal(content: any, gp: any) {
    
     this.modalService.open(content);
     this.gVanzariModel = {
       id: gp.id,
       cantitate: gp.cantitate,
       data: gp.data,
       gestiunePrincipala: gp.gestiunePrincipala,
       mentiuni: gp.mentiuni,
       numeUser: gp.numeUser
     }
   }
   DeleteResource(fReturProdus: any){

    this.buttonDisable = true;
       this.returGPrincipalaModel = {
        gestiunePrincipalaId: this.gVanzariModel.gestiunePrincipala.id,
        gestiuneVanzariId: this.gVanzariModel.id,
         numeUser: this.gVanzariModel.numeUser,
         cantitate: fReturProdus.value.Cantitate
       }
       this.message = "Produsul a fost returnat!";
       var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
       this.http.post(this.appUrl + 'api/GestiuneVanzari/deleteToGestiunePrincipala/' + this.gVanzariModel.id,  this.returGPrincipalaModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
       );

   }
 //#endregion
  
//#region excel proiect
exportExcel(content: any) : void {
  this.modalService.open(content);
}

ExportExcelResource(fexportExcel: any){
 
    this.excelModel = {
      dataInceput: this.adjustDateForTimeOffset(fexportExcel.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcel.value.DataSfarsit)
    }

  this.http.post(this.appUrl + 'api/GestiuneVanzari/raportGestiuneProiectDupaProiect', this.excelModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion

onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}
 Search(){
  this.sessionData.gestiuneVanzari = this.gestiuneAux;

   if(this.denumire != ""){
     this.sessionData.gestiuneVanzari = this.sessionData.gestiuneVanzari.filter((res: any) => {
       return res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.numeUser.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
     });
   }else if(this.denumire == ""){
     this.ngOnInit();
   }

 }

 //enable proiecte
 checkValue(event: any){
 }

 refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/GestiuneVanzari/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion

}
