import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { parse } from 'path';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rapoarte-user-prezenta-pdf',
  templateUrl: './rapoarte-user-prezenta-pdf.component.html',
  styleUrls: ['./rapoarte-user-prezenta-pdf.component.css']
})
export class RapoarteUserPrezentaPdfComponent implements OnInit {
//#region Declarari
userId: number | undefined;
appUrl: string = environment.appUrl;
projectOption: any = "Caută după Proiect";
rapoarte: any;
sesizareId: any;
message: any;
excelProiectModel: any = {
tip: '',
proiectId: '',
dataInceput: '',
dataSfarsit: ''
}
proiectId: any;
rapoarteAux: any;
denumire: any
excelUserId: any;

excelAngajatModel: any = {
  userId: '',
  dataInceput: '',
  dataSfarsit: ''
 }
//#endregion
constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
 }

 async ngOnInit(): Promise<void> {
   this.sessionData.current_page_title = "RAPOARTE USER PREZENTA PERSONALIZATE";
   
   const proiecte = this.authService.getProiecte();
   await lastValueFrom(proiecte);

   var header = {
     headers: new HttpHeaders()
       .set('Authorization',  `Bearer ${this.authService.getToken()}`)
   }
    this.http.get<any>(this.appUrl + 'api/UserPrezenta/GetRaportUserPrezentaPdfPersonalizate', header).subscribe((data) => {
      this.rapoarte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));;
      this.rapoarteAux = this.rapoarte;
      console.log(this.rapoarte);
    });
 }

 //#region excel angajat PDF
exportExcelAngajatPDF(content: any) : void {
  this.modalService.open(content);
}

ExportExcelAngajatPDFResource(fexportExcelAngajatPDF: any){
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportExcelAngajatPDF.value.User){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: fexportExcelAngajatPDF.value.DataInceput,
    dataSfarsit: fexportExcelAngajatPDF.value.DataSfarsit
  }

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/UserPrezenta/RaportPdfUserPrezenta', this.excelAngajatModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

}
