import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-procese-verbale',
  templateUrl: './procese-verbale.component.html',
  styleUrls: ['./procese-verbale.component.css']
})
export class ProceseVerbaleComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  projectOption: any = "Caută după Proiect";

  //#endregion
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit(): void {
    this.sessionData.current_page_title = "PROCESE VERBALE";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.get<any>(this.appUrl + 'api/ClientFisier/GetFisiereClienti', header).subscribe((data) => {
       this.sessionData.fisiereClienti = data;
     });
  }

   //enable proiecte
  checkValue(event: any){
  }
  selectChangeHandler (event: any) {
  if(event.target.value == "Toate"){
    this.projectOption = "Caută după Proiect";
  }
  this.projectOption = event.target.value;
  }

}
