import { SessionDataService } from './../../session-data.service';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavbarService } from 'src/app/services/navbar.service';
import { ChartType } from 'chart.js';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { extend } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
import {
  ScheduleComponent, EventSettingsModel, GroupModel, DayService, WeekService, MonthService, DragAndDropService, View, TimelineMonthService, ResizeService, ActionEventArgs
} from '@syncfusion/ej2-angular-schedule';
import { resourceData, timelineResourceData } from '../../../data';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad';
import { lastValueFrom } from 'rxjs';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // encapsulation: ViewEncapsulation.None,
  providers: [TimelineMonthService, ResizeService, DragAndDropService]

})
export class HomeComponent implements OnInit {

  view: any;
  view1: any;
  view2: any;
  view3: any;
  @HostListener('window:resize', ['$event'])
  sizeChange(event : any) {
    if(event.target.innerWidth < 800){
      this.view = [innerWidth / 1.3, 250];
      this.view1 = [innerWidth / 1.3, 250];
      this.view2 = [innerWidth / 1.3, 250];
      this.view3 = [innerWidth / 1.3, 250];

    }
    else{
      this.view = [innerWidth / 7, 250];
      this.view1 = [innerWidth / 7, 250];
      this.view2 = [innerWidth / 7, 250];
      this.view3 = [innerWidth / 5, 170];

    }
  }
  //#region Declarari dashboard
  appUrl: string = environment.appUrl;
  legend: boolean = true;
  legendPosition: any = 'below';
  cardColor: string = '#232837';
  colorScheme = {
    domain: ['#5AA454']
  };
  legend1: boolean = true;
  legendPosition1: any = 'below';

  colorScheme1 = {
    domain: ['#E44D25']
  };
  legend2: boolean = true;
  legendPosition2: any = 'below';
  colorScheme2 = {
    domain: ['#7aa3e5']
  };


  codProduct: string | undefined;


  // options
  gradient3: boolean = true;
  showLegend3: boolean = true;
  showLabels3: boolean = true;
  isDoughnut3: boolean = false;
  legendPosition3: any = 'below';

  colorScheme3 = {
    domain: ['#5AA454', '#A10A28', '#C7B42C']
  };

  //#endregion
  
  proiectUserModel : any = {
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }

  proiectUserModelUpdate : any = {
    id: '',
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }
  listaClienti: any = [];
  listaProiecte: any = [];
  idClientAles: any;
  numeClientAles: any;
  productModel: any = {
    id: '',
    cod: '',
    denumire: '',
    cantitate: '',
  }
  personModel: any = {
    nume: '',
    semnatura: ''
  }
  personModelList: any = [];
  proiectId: any;
  fieldArray: any = [];
  externalDataRetrievedFromServer: any = [[{text: 'Nr. Crt.', style: 'tableHeader', alignment: 'center'},{text: 'Cod', style: 'tableHeader', alignment: 'center'},{text: 'Denumire', style: 'tableHeader', alignment: 'center'}, {text: 'Cantitate', style: 'tableHeader', alignment: 'center'}]];
  externalDataRetrievedFromServerPubsh: any;
  nrCrtListaProduse: number = 0;
  openAviz: boolean = false;
  message: string | undefined;
  semnatura1: any;
  semnatura2: any;  
  semnatura3: any;
  semnatura4: any;
  semnatura5: any;
  openProces: boolean = false;
  imageSrc1 = this.appUrl + 'assets/procesverbal.png';
  numePersoana: any;
  client: any;
  selectedfile: File | undefined;
  numarProcesVerbal: any;
  numeProiectAles: any;
  avizModel: any = {
    userId: '',
    proiectId: '',
    pdfFile: ''
  }
  token: any;
  mesajTaskId: any;
  public selectedDate: Date = new Date();

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
    }
    
  async ngOnInit(): Promise<void> {
    var newDate = new Date().toLocaleString();
    var splitted1 = newDate.split(".", 3);
    var splitted2 = splitted1[2].split(",", 2);
    this.selectedDate =new Date(parseFloat(splitted2[0]), parseFloat(splitted1[1])-1, parseFloat(splitted1[0]));
   
    if(innerWidth < 800){
      this.view = [innerWidth / 1.3, 250];
      this.view1 = [innerWidth / 1.3, 250];
      this.view2 = [innerWidth / 1.3, 250];
      this.view3 = [innerWidth / 1.3, 250];

    }
    else{
      this.view = [innerWidth / 7, 250];
      this.view1 = [innerWidth / 7, 250];
      this.view2 = [innerWidth / 7, 250];
      this.view3 = [innerWidth / 5, 170];
    }
    this.nav.show();
    this.sessionData.current_page_title = "DASHBOARD";

    this.token = localStorage.getItem('Token');
    var decoded = jwt_decode(this.token) as any;
  
    const tasksUser = this.authService.getTasksBuUserId(decoded.Id);
    await lastValueFrom(tasksUser);

    const gestiuneAnunturi = this.authService.getGestiuneAnunturi();
    await lastValueFrom(gestiuneAnunturi);
  }

    //functie pentru fisierul incarcat
    onFS(event: any){
      if(<File>event.target.files[0] != null){
        this.selectedfile = <File>event.target.files[0];
      }
    }

//#region Asignare user
async addModal(content: any) {
  const proiecte = this.authService.getProiecte();
  await lastValueFrom(proiecte);

  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){
  
  var dataInceput =  new Date(fAddCategory.value.DataInceput);
  dataInceput.setHours(dataInceput.getHours()+5);
  var dataSfarsit =  new Date(fAddCategory.value.DataSfarsit);
  dataSfarsit.setHours(dataSfarsit.getHours()+5);
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  console.log(new Date(dataInceput).toUTCString());
  formData.append("ProiectId", fAddCategory.value.Proiect.toString());
  formData.append("UserId", fAddCategory.value.UserName.toString());
  formData.append("DataInceput", new Date(dataInceput).toUTCString());
  formData.append("DataSfarsit", new Date(dataSfarsit).toUTCString());
  formData.append("Descriere", fAddCategory.value.Descriere);
  formData.append("Image", fAddCategory.value.File.toString());
  formData.append("Images",  fAddCategory.value.Files);

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ProiectUser/', formData, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

async pdfToBase64(pdfDocGenerator: any){
  return new Promise((resolve,reject) => {
    this.avizModel.pdfFile =  pdfDocGenerator.getBase64((data: any) => {
      resolve(data);
    });
  });
}

azureAction(){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/User/AzureAction', this.proiectUserModel, header).subscribe(
    (response) => {    
      console.log(response);
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#region Program Useri
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent | any;
  @ViewChild('scheduleObj') public scheduleObj1: ScheduleComponent | any;

  public currentView: View = 'TimelineMonth';
  public group: GroupModel = {
    enableCompactView: false,
    resources: ['Resources']
  };
  public resourceDataSource: Record<string, any>[] = this.generateResourceData(1, 300, 'Resource');
  public allowMultiple = true;
  public virtualScroll = true;
  public eventSettings: EventSettingsModel = {
    dataSource: this.generateStaticEvents(new Date(2021, 4, 1), 300, 12)
  };

  public generateStaticEvents(start: Date, resCount: number, overlapCount: number): Record<string, any>[] {
    const data: Record<string, any>[] = [];
    let id = 1;
    for (let i = 0; i < resCount; i++) {
      const randomCollection: number[] = [];
      let random = 0;
      for (let j = 0; j < overlapCount; j++) {
        random = Math.floor(Math.random() * (30));
        random = (random === 0) ? 1 : random;
        if (randomCollection.indexOf(random) !== -1 || randomCollection.indexOf(random + 2) !== -1 ||
          randomCollection.indexOf(random - 2) !== -1) {
          random += (Math.max.apply(null, randomCollection) + 10);
        }
        for (let k = 1; k <= 2; k++) {
          randomCollection.push(random + k);
        }
        let startDate: Date = new Date(start.getFullYear(), start.getMonth(), random);
        startDate = new Date(startDate.getTime() + (((random % 10) * 10) * (1000 * 60)));
        const endDate: Date = new Date(startDate.getTime() + ((1440 + 30) * (1000 * 60)));
        data.push({
          Id: id,
          Subject: 'Event #' + id,
          StartTime: startDate,
          EndTime: endDate,
          IsAllDay: (id % 10) ? false : true,
          ResourceId: i + 1
        });
        id++;
      }
    }
    return data;
  }

  public generateResourceData(startId: number, endId: number, text: string): Record<string, any>[] {
    const data: Record<string, any>[] = [];
    const colors: string[] = [
      '#ff8787', '#9775fa', '#748ffc', '#3bc9db', '#69db7c', '#fdd835', '#748ffc',
      '#9775fa', '#df5286', '#7fa900', '#fec200', '#5978ee', '#00bdae', '#ea80fc'
    ];
    for (let a: number = startId; a <= endId; a++) {
      const n: number = Math.floor(Math.random() * colors.length);
      data.push({ Id: a, Text: text + ' ' + a, Color: colors[n] });
    }
    return data;
  }

  public async onActionComplete(args: ActionEventArgs) {
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);

    if(args.requestType === 'eventChanged') {
      var x : any = args.data;
      this.sessionData.proiecte.forEach((element: { denumireProiect: any; id: any; }) => {
        if(element.denumireProiect == x[0].Subject){
          this.proiectId = element.id;
        }
      });
      var splitted = x[0].Description.split(" - <a href=", 2);
      this.proiectUserModelUpdate = {
        id: x[0].Id,
        proiectId : this.proiectId,
        userId : x[0].ResourceId,
        dataInceput: x[0].StartTime,
        dataSfarsit: x[0].EndTime,
        descriere: splitted[0],
        image: x[0].Imagine
      }
      
      console.log(x[0]);
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.put(this.appUrl + 'api/ProiectUser/' + this.proiectUserModelUpdate.id, this.proiectUserModelUpdate, header).subscribe();
    }
    else if(args.requestType === 'eventRemoved'){
      var x : any = args.data;
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.delete(this.appUrl + 'api/ProiectUser/' + x[0].Id, header).subscribe();
    }
    else{
      return;
    }
  }

  //#endregion

//#region PDF Aviz
getFurnizor(){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Furnizor: ', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Green Electric City', border: [false, false, false, false]},
  ];
  table.push(line1);
  var line2 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Nr. Ord. reg. com./an: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '123235646578656543', border: [false, false, false, false]}
  ];
  table.push(line2);
  var line3 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'C.I.F.: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO12341231', border: [false, false, false, false]}
  ];
  table.push(line3);
  var line4 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Sediul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Strada Vasile Lascar, Nr. 178', border: [false, false, false, false]}
  ];
  table.push(line4);
  var line5 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judetul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Bucuresti', border: [false, false, false, false]}
  ];
  table.push(line5);
  var line6 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO1B31007593840000', border: [false, false, false, false]}
  ];
  table.push(line6);
  var line7 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Banca Transilvania', border: [false, false, false, false]}
  ];
  table.push(line7);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getTitlu(){
  var subTable: any = [];
  var subLine1 = [
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Seria: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '12345', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Nr: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '656879u', border: [false, false, false, false]}
  ];
  subTable.push(subLine1);
  var subLine2 = [
    {style:'tableBody', colSpan: 2, alignment: 'center', text: 'Data (ziua, luna, anul):', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 2, alignment: 'center', text: '12.08.2021', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]}
  ];
  subTable.push(subLine2);
  var table: any = [];
  var line1 = [{style:'tableBody2', colSpan: 1, alignment: 'center', text: 'AVIZ DE INSOTIRE A MARFII', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{
    style: 'tableExample', table: { body: subTable, widths: ['25%', '25%', '25%', '25%'], border: [true, true, true, true]}
  }];
  table.push(line2);


  return { style: 'tableExample', table: { body: table, widths: ['100%']}}
}

getCumparator(client: any){
  var table: any = [];
  if(client.client.companie == null){
    var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cumparator: ', border: [false, false, false, false]},
                {style:'tableBody', colSpan: 1, alignment: 'center', text: client.nume + " " + client.prenume, border: [false, false, false, false]},];  
    table.push(line1);
  }
  else {
    var line11 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cumparator: ', border: [false, false, false, false]},
                 {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.companie, border: [false, false, false, false]},];  
    table.push(line11);
  }

  if(client.client.cui == null){
    var line2 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'CUI/CNP: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.cnp, border: [false, false, false, false]}
    ];
    table.push(line2);
  }
  else {
    var line2 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'CUI/CNP: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.cui, border: [false, false, false, false]}
    ];
    table.push(line2);
  }
  
  if(client.client.adresaCompanie == null){
    var line3 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Adresa: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.adresaBeneficiar, border: [false, false, false, false]}
    ];
    table.push(line3);
  }
  else{
    var line3 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Adresa: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.adresaCompanie, border: [false, false, false, false]}
    ];
    table.push(line3);
  }

  if(client.client.judet){
    var line5 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judet:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.judet, border: [false, false, false, false]}
    ];
    table.push(line5);
  }
  
  if(client.client.iban){
    var line6 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.iban, border: [false, false, false, false]}
    ];
    table.push(line6);
  }
  
  if(client.client.banca){
    var line7 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.banca, border: [false, false, false, false]}
    ];
    table.push(line7);
  }
  

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getSemnatura(NumeExp: any, SerieExp: any, NumarExp: any, NumeBen: any, SerieBen: any, NumarBen: any){
  
  var subTable1: any = [];
  var subLine1_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura expeditorului', border: [false, false, false, false]}];
  var subLine1_2 = [{image: this.semnatura1, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable1.push(subLine1_1);
  subTable1.push(subLine1_2);

  var subTable2: any = [];
  var subLine2_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele expeditorului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: NumeExp, border: [false, false, false, false]}];
  var subLine2_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + SerieExp, border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + NumarExp, border: [false, false, false, false]}];
  var subLine2_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine2_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy'), border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + new Date().toLocaleString('ro-RO', { hour: 'numeric', minute: 'numeric' , hour12: false }), border: [false, false, false, false]}];
  subTable2.push(subLine2_2);
  subTable2.push(subLine2_3);
  subTable2.push(subLine2_5);
  subTable2.push(subLine2_6);

  var subTable3: any = [];
  var subLine3_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele beneficiarului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: NumeBen, border: [false, false, false, false]}];
  var subLine3_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + SerieBen, border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + NumarBen, border: [false, false, false, false]}];
  var subLine3_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine3_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy'), border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + new Date().toLocaleString('ro-RO', { hour: 'numeric', minute: 'numeric' ,hour12: false }), border: [false, false, false, false]}];

  subTable3.push(subLine3_2);
  subTable3.push(subLine3_3);
  subTable3.push(subLine3_5);
  subTable3.push(subLine3_6);

  var subTable4: any = [];
  var subLine4_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura beneficiarului', border: [false, false, false, false]}];
  var subLine4_2 = [{image: this.semnatura2, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable4.push(subLine4_1);
  subTable4.push(subLine4_2);

  var table: any = [];
  var line = [{style: 'tableExample', table: { body: subTable2, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable1, widths: ['100%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable3, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable4, widths: ['100%'], border: [true, true, true, true]}}];
  table.push(line);

  return { style: 'tableExample', table: { body: table, widths: ['38%', '12%', '38%', '12%']}}

}
//#endregion

//#region Semnatura
  // @ViewChild(SignaturePad) signaturePad: SignaturePad | undefined;
  // @ViewChild(SignaturePad) signaturePad1: SignaturePad | undefined;
  @ViewChild('sigpad1') signaturePad: SignaturePad | undefined;
  @ViewChild('sigpad2') signaturePad2: SignaturePad | undefined;
  @ViewChild('sigpad3') signaturePad3: SignaturePad | undefined;
  @ViewChild('sigpad4') signaturePad4: SignaturePad | undefined;
  @ViewChild('sigpad5') signaturePad5: SignaturePad | undefined;


  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  private signaturePadOptions1: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad?.set('minWidth', 5); // set szimek/signature_pad options at runtime
  //   this.signaturePad?.clear(); // invoke functions from szimek/signature_pad API
  // }

  drawClear(){
    this.signaturePad?.clear();
  }
  drawComplete(){
    const base64 = this.signaturePad?.toDataURL('image/png');
    this.semnatura1 = base64;
  }
  drawClear1(){
    this.signaturePad2?.clear();
  }
  drawComplete1(){
    const base64 = this.signaturePad2?.toDataURL('image/png');
    this.semnatura2 = base64;
  }
  drawComplete2(){
    const base64 = this.signaturePad3?.toDataURL('image/png');
    this.semnatura3 = base64;
  }
  drawComplete3(){
    const base64 = this.signaturePad4?.toDataURL('image/png');
    this.semnatura4 = base64;
  }
  drawComplete5(){
    const base64 = this.signaturePad5?.toDataURL('image/png');
    this.semnatura5 = base64;
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }
  drawClear3(){
    this.signaturePad3?.clear();
  }
  drawClear4(){
    this.signaturePad4?.clear();
  }
  drawClear5(){
    this.signaturePad5?.clear();
  }
  //#endregion

//#region PDF Proces
getTitleProces(){
  var table: any = [];
  var line1 = [{style:'tableHeader', colSpan: 1, alignment: 'center', text: 'PROCES-VERBAL DE RECEPŢIE A LUCRARILOR', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [
    {style:'tableHeader', colSpan: 1, alignment: 'center', text: 'Nr. ' + this.numarProcesVerbal, border: [false, false, false, false]}];
  table.push(line2);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines',}
}
getSection1(denumireLucrare: any, numarContract: any){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Privind lucrarea ' + denumireLucrare + ' executata in cadrul contractului de prestari servicii nr. ' + numarContract, border: [false, false, false, false]}];
  table.push(line1);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines',}
}
getSection2(client: any, numeClient: any, serie: any, numarSerie: any, numeReprezentant: any, functie: any){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Încheiat între: ', border: [false, false, false, false]}];
  table.push(line1);
  if(client.client.adresaCompanie && client.client.cui && client.client.norc){
    var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: numeClient + ' persoană juridică / persoana fizica română cu sediul social / domiciliul in ' + client.client.adresaCompanie + ' înregistrată la Registrul Comerțului cu nr. ' + client.client.norc + ' legitimat cu CI seria ' + serie + ' nr. ' + numarSerie + ' cod unic de înregistrare ' + client.client.cui + ' CNP ' + client.client.cnp + ' reprezentata de ' + numeReprezentant + ' cu functia de ' + functie + ' in calitate de Beneficiar, ' , border: [false, false, false, false]}];
    table.push(line2);
  }
  else{
    var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: numeClient + ' persoană juridică / persoana fizica română cu sediul social / domiciliul in ' + client.client.adresaBeneficiar + ' legitimat cu CI seria ' + serie + ' nr. ' + numarSerie + ' CNP ' + client.client.cnp + ' reprezentata de ' + numeReprezentant + ' cu functia de ' + functie + ' in calitate de Beneficiar, ' , border: [false, false, false, false]}];
    table.push(line2);
  }
  
  var line3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'si', border: [false, false, false, false]}];
  table.push(line3);
  var line4 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'GREEN ELECTRIC CITY  SRL persoană juridică română cu sediul social in Bucuresti, Str. Vasile Lascar, nr.178, et.5, ap. 10, Sector 2 , înregistrată la Registrul Comerțului cu nr. J40/2817/2009, cod unic de înregistrare RO 25220494, reprezentata  de Laurentiu-Ionel Cojocaru, cu functia de Administrator, in calitate de Exécutant,', border: [false, false, false, false]}];
  table.push(line4);
  var line5 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'au convenit sa incheie prezentul contract cu respectarea urmatoarelor clauze : ', border: [false, false, false, false]}];
  table.push(line5);
  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection3(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: '1.	Comisia de recepţie din data de ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy') + ' fiind formată din: ', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]}];
  table.push(line1);
  this.personModelList.forEach((element: any) => {
    var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: element.nume, border: [false, false, false, false]},
                {image: element.semnatura, width: 40, height: 40,  alignment: 'left', border: [false, false, false, false]}];
    table.push(line2);
  });
  // var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Fratila Costin', border: [false, false, false, false]}];
  // table.push(line2);
  // var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Fratila Costin', border: [false, false, false, false]}];
  // table.push(line2);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}
getSection4(numeClientAles: any){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '2.	La recepţia serviciilor a participat din partea Prestatorului ' + this.sessionData.user.nume + " " + this.sessionData.user.prenume + ' în calitate de reprezentant legal al GREEN ELECTRIC CITY  si din partea Beneficiarului, ' + numeClientAles, border: [false, false, false, false]}];
  table.push(line1);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection5(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '3. Se constată şi consemnează urmatoarele: ', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	serviciile care au făcut obiectul contractului au fost prestate de Prestator cu respectarea cerinţelor, exigenţelor, cantitătilor şi în graficul de timp, prevăzute în contractul de servicii.', border: [false, false, false, false]}];
  table.push(line2);
  var line3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	prestatorul şi-a indeplinit toate obligaţiile asumate prin contractul încheiat între părţi.', border: [false, false, false, false]}];
  table.push(line3);
  var line4 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	sistemul functioneaza in parametrii tehnici normali.', border: [false, false, false, false]}];
  table.push(line4);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection6(client: any){
  var table: any = [];

  if(client.client.adresaCompanie){
    var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '4.	Prezentul proces-verbal, conţinând 1 fila a fost încheiat astăzi ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy') + ' la adresa ' + client.client.adresaCompanie + ' în 2  exemplare, din care unul pentru Beneficiar şi unul pentru Prestator.', border: [false, false, false, false]}];
    table.push(line1);
  }
  else{
    var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '4.	Prezentul proces-verbal, conţinând 1 fila a fost încheiat astăzi ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy') + ' la adresa ' + client.client.adresaBeneficiar + ' în 2  exemplare, din care unul pentru Beneficiar şi unul pentru Prestator.', border: [false, false, false, false]}];
    table.push(line1);
  }
 

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection7(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Executant', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'right', text: 'Beneficiar ', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{image: this.semnatura3, width: 40, height: 40,  alignment: 'left', border: [false, false, false, false]},
                {image: this.semnatura4, width: 40, height: 40,  alignment: 'right', border: [false, false, false, false]}];
  table.push(line2);
  
  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines'}
}
//#endregion

  goToFisiere(task: any){
    this.sessionData.taskId = task.id;
    localStorage.setItem('TaskId',task.id);
    localStorage.setItem('TaskName',task.titlu);    
    this.router.navigate(['/fisiere-task']);
  }

//#region Stergere Mesaj Task
deleteMesajTaskModal(content: any, mesaj: any) {
  this.modalService.open(content);
  this.mesajTaskId = mesaj.id;
}
deleteMesajTask(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/GestiuneAnunturi/' + this.mesajTaskId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}


}