import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-rol',
  templateUrl: './edit-rol.component.html',
  styleUrls: ['./edit-rol.component.css']
})
export class EditRolComponent implements OnInit {

  appUrl: string = environment.appUrl;
  rol: any;
  message: any;
  model: any = {
    id: '',
    Tip: false,
    Ofertare: false,
    GenerareOferta: false,
    OferteEmise: false,
        AdaugaOferta: false,
        EditeazaOferta: false,
        StergeOferta: false,
        CreazaComanda: false,
        RaportOferta: false,
    DescriereFunctiuni: false,
        AdaugaDescriereFunctiuni: false,
        EditeazaDescriereFunctiuni: false,
        StergeDescriereFunctiuni: false,
    DescriereEchipamente: false,
        AdaugaDescriereEchipamente: false,
        EditeazaDescriereEchipamente: false,
        StergeDescriereEchipamente: false,
    DescriereVizualizari: false,
        AdaugaDescriereVizualizari: false,
        EditeazaDescriereVizualizari: false,
        StergeDescriereVizualizari: false,
    BazaDeDateProduse: false,
        AdaugaProdusBazaDeDateProduse: false,
        UploadCSVBazaDeDateProduse: false,
        EditeazaBazaDeDateProduse: false,
        StergeBazaDeDateProduse: false,
    ArticoleManopera: false,
        EditeazaArticoleManopera: false,
        StergeArticoleManopera: false,
Aprovizionare: false,
    ListaComenzi: false,
        ModificaStatusListaComenzi: false,
        StergeListaComenzi: false,
        ProduseListaComenzi: false,
            GenerareRaportProduseListaComenzi: false,
            FinalizareComandaProduseListaComenzi: false,
            ModificaStatusProduseListaComenzi: false,
    GestiuneComenzi: false,
        RezervarePeProiectGestiuneComenzi: false,
        AdaugaListaDeComandatGestiuneComenzi: false,
        EditareCantitateGestiuneComenzi: false,
        StergeGestiuneComenzi: false,
    ListaDeComandat: false,
        GenerareRaportListaDeComandat: false,
        AdaugaGestiuneComenziListaDeComandat: false,
        StergeListaDeComandat: false,
    ListaStocCritic: false,
    ProduseSpreRezervare: false,
        RezerverePeProiectProduseSpreRezervare: false,
        ReturProduseSpreRezervare: false,
Materiale: false,
    GestiunePrincipala: false,
        AdaugaProdusGestiunePrincipala: false,
        GenerareRaportGestiunePrincipala: false,
        UploadCSVGestiunePrincipala: false,
        EditeazaGestiunePrincipala: false,
        MaresteCantitateaGestiunePrincipala: false,
        DetaliiGestiunePrincipala: false,
        StergeGestiunePrincipala: false,
        AdaugaGestiuneTransferGestiunePrincipala: false,
        RezervareGestiunePrincipala: false,
        AdaugaGestiuneVanzariGestiunePrincipala: false,
    GestiunePersonala  : false,
        ReturGestiunePersonala: false,
        AdaugaGestiuneUserGestiunePersonala: false,
        AdaugaGestiuneProiectGestiunePersonala: false,
    GestiuneProiect: false,
        ReturGestiuneProiect: false,
        GeneareRaportGestiuneProiect: false,
    GestiuneAngajati: false,
        ReturGestiuneAngajati: false,
        AdaugaGestiuneUserGestiuneAngajati: false,
        AdaugaGestiuneProiectGestiuneAngajati: false,
    GestiuneTransfer: false,
        VizualizareAdminGestiuneTransfer: false,
        GeneareRaportGestiuneTransfer: false,
        AcceptaProduseleGestiuneTransfer: false,
        AdaugaGestiuneTabloterieGestiuneTransfer: false,
        ReturGestiuneTransfer: false,
    GestiuneVanzari: false,
        GeneareRaportGestiuneVanzari: false,
        ReturGestiuneVanzari: false,
    GestiuneRezervarePeProiect: false,
        GeneareRaportGestiuneRezervarePeProiect: false,
        AdaugaGestiuneTransferGestiuneRezervarePeProiect: false,
        ReturGestiuneRezervarePeProiect: false,
Productie: false,
    GestiuneComponente: false,
        AdaugaGestiuneComponente: false,
        EditeazaGestiuneComponente: false,
        AdaugaCantitateGestiuneComponente: false,
        StergeGestiuneComponente: false,
    GestiuneRetetar: false,
        AdaugaGestiuneRetetar: false,
        EditeazaGestiuneRetetar: false,
        StergeGestiuneRetetar: false,
        AdaugaGestiuneProductieGestiuneRetetar: false,
    GestiuneRetetarComponente: false,
        AdaugaGestiuneRetetarComponente: false,
        EditeazaGestiuneRetetarComponente: false,
        StergeGestiuneRetetarComponente: false,
    GestiuneProductie: false,
        ReturGestiuneProductie: false,
        AdaugaGestiunePrincipalaGestiuneProductie: false,
Prezenta: false,
    TabelPrezenta: false,
        GeneareRaportTabelPrezenta: false,
        EditeazaTabelPrezenta: false,
        StergeTabelPrezenta: false,
    TabelPrezentaTabloterie: false,
        GeneareRaportTabelPrezentaTabloterie: false,
        EditeazaTabelPrezentaTabloterie: false,
        StergeTabelPrezentaTabloterie: false,
    AdaugaPrezentaProiect: false,
    AdaugaPrezentaAtelier: false,
    AdaugaPrezentaBirou: false,
Tabloterie: false,
    GestiuneTabloterie: false,
        GeneareRaportGestiuneTabloterie: false,
        StergeGestiuneTabloterie: false,
    ProiecteTabloterie: false,
        CreazaProiectProiecteTabloterie: false,
        EditeazaProiecteTabloterie: false,
        StergeProiecteTabloterie: false,
        TablouriProiecteTabloterie: false,
            CreazaTablouTablouriProiecteTabloterie: false,
            EditeazaTablouriProiecteTabloterie: false,
            StergeTablouriProiecteTabloterie: false,
            ProduseTablouriProiecteTabloterie: false,
                AdaugaProdusProduseTablouriProiecteTabloterie: false,
                GenerareRaportProduseTablouriProiecteTabloterie: false,
                FinalizareTablouProduseTablouriProiecteTabloterie: false,
                EditeazaProduseTablouriProiecteTabloterie: false,
                ReturProduseTablouriProiecteTabloterie: false,
                AdaugaFisiereProduseTablouriProiecteTabloterie: false,
    AdaugaPrezentaTabloterie: false,
    TablouriFinalizate: false,
Clienti: false,
    AdaugaClientNou: false,
    ProiecteClienti: false,
        RaportProiecteClienti: false,
        TaskuriProiecteClienti: false,
            AdaugaTaskuriProiecteClienti: false,
            MesajeTaskuriProiecteClienti: false,
            UseriAsignatiTaskuriProiecteClienti: false,
                AdaugaUseriAsignatiTaskuriProiecteClienti: false,
            InterventiiTaskuriProiecteClienti: false,
            EditTaskuriProiecteClienti: false,
            SchimbaStatusTaskuriProiecteClienti: false,
            InchidereDirectaTaskuriProiecteClienti: false,
            StergeTaskuriProiecteClienti: false,
        EditeazaProiecteClienti: false,
        StergeProiecteClienti: false,
        FisiereProiect: false,
            PlanuriArhitectura: false,
                AdaugaPlanuriArhitectura: false,
                StergePlanuriArhitectura: false,
            SchemeMonofilare: false,
                AdaugaSchemeMonofilare: false,
                StergeSchemeMonofilare: false,
            SchemeTablouri: false,
                AdaugaSchemeTablouri: false,
                StergeSchemeTablouri: false,
            FiseRelee: false,
                AdaugaFiseRelee: false,
                StergeFiseRelee: false,
    ListaClienti: false,
        DashboardListaClienti: false,
        AdaugaProiectListaClienti: false,
        EditeazaListaClienti: false,
        StergeListaClienti: false,
Useri: false,
    AdaugaUser: false,
    ListaUseri: false,
        EditeazaListaUseri: false,
        SchimbaRolListaUseri: false,
        StergeListaUseri: false,
    Roluri: false,
    ProtectiaMuncii: false,
    NumereTelefon: false,
ProceseVerbale: false,
    TabelProceseVerbale: false,
    AvizLivare: false,
    ProcesVerbalDeReceptie: false,
    SesizareTehnica: false,
    TabelSesizareTehnica: false,
Dashboard: false,
    CharturiDashboard: false,
    AsignareProiectUserDashboard: false,
    CalendarProiecteDashboard: false,
    TaskuriAdminDashboard: false,
    ProiecteAdminDashboard: false,
    GrupuriDeComunicareDashboard: false,
    ProfitabilitateProiecteDashboard: false,
    AsigneazaUserGrupuriDeComunicareDashboard: false, 
    StergeUserGrupuriDeComunicareDashboard: false,
    VizualizareAnunturiDashboard: false,
    AdaugaAnunturiDashboard: false,
    StergeAnunturiDashboard: false
  }

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    sessionData.rolId = localStorage.getItem('RolId');
   }

  ngOnInit(): void {
    this.http.get<any>(this.appUrl + 'api/role/' + this.sessionData.rolId, this.sessionData.rolId).subscribe((data) => {
      this.rol = data;
      console.log(this.rol);
      this.model = {
        Tip: this.rol.tip,
        Ofertare: this.rol.ofertare,
        GenerareOferta: this.rol.generareOferta,
        OferteEmise: this.rol.oferteEmise,
        AdaugaOferta: this.rol.adaugaOferta,
        EditeazaOferta: this.rol.editeazaOferta,
        StergeOferta: this.rol.stergeOferta,
        CreazaComanda: this.rol.creazaComanda,
        RaportOferta: this.rol.raportOferta,
        DescriereFunctiuni: this.rol.descriereFunctiuni,
        AdaugaDescriereFunctiuni: this.rol.adaugaDescriereFunctiuni,
        EditeazaDescriereFunctiuni: this.rol.editeazaDescriereFunctiuni,
        StergeDescriereFunctiuni: this.rol.stergeDescriereFunctiuni,
        DescriereEchipamente: this.rol.descriereEchipamente,
        AdaugaDescriereEchipamente: this.rol.adaugaDescriereEchipamente,
        EditeazaDescriereEchipamente: this.rol.editeazaDescriereEchipamente,
        StergeDescriereEchipamente: this.rol.stergeDescriereEchipamente,
        DescriereVizualizari: this.rol.descriereVizualizari,
        AdaugaDescriereVizualizari: this.rol.adaugaDescriereVizualizari,
        EditeazaDescriereVizualizari: this.rol.editeazaDescriereVizualizari,
        StergeDescriereVizualizari: this.rol.stergeDescriereVizualizari,
        BazaDeDateProduse: this.rol.bazaDeDateProduse,
        AdaugaProdusBazaDeDateProduse: this.rol.adaugaProdusBazaDeDateProduse,
        UploadCSVBazaDeDateProduse: this.rol.uploadCSVBazaDeDateProduse,
        EditeazaBazaDeDateProduse: this.rol.editeazaBazaDeDateProduse,
        StergeBazaDeDateProduse: this.rol.stergeBazaDeDateProduse,
        ArticoleManopera: this.rol.articoleManopera,
        EditeazaArticoleManopera: this.rol.editeazaArticoleManopera,
        StergeArticoleManopera: this.rol.stergeArticoleManopera,
        Aprovizionare: this.rol.aprovizionare,
        ListaComenzi: this.rol.listaComenzi,
        ModificaStatusListaComenzi: this.rol.modificaStatusListaComenzi,
        StergeListaComenzi: this.rol.stergeListaComenzi,
        ProduseListaComenzi: this.rol.produseListaComenzi,
        GenerareRaportProduseListaComenzi: this.rol.generareRaportProduseListaComenzi,
        FinalizareComandaProduseListaComenzi: this.rol.finalizareComandaProduseListaComenzi,
        ModificaStatusProduseListaComenzi: this.rol.modificaStatusProduseListaComenzi,
        GestiuneComenzi: this.rol.gestiuneComenzi,
        RezervarePeProiectGestiuneComenzi: this.rol.rezervarePeProiectGestiuneComenzi,
        AdaugaListaDeComandatGestiuneComenzi: this.rol.adaugaListaDeComandatGestiuneComenzi,
        EditareCantitateGestiuneComenzi: this.rol.editareCantitateGestiuneComenzi,
        StergeGestiuneComenzi: this.rol.stergeGestiuneComenzi,
        ListaDeComandat: this.rol.listaDeComandat,
        GenerareRaportListaDeComandat: this.rol.generareRaportListaDeComandat,
        AdaugaGestiuneComenziListaDeComandat: this.rol.adaugaGestiuneComenziListaDeComandat,
        StergeListaDeComandat: this.rol.stergeListaDeComandat,
        ListaStocCritic: this.rol.listaStocCritic,
        ProduseSpreRezervare: this.rol.produseSpreRezervare,
        RezerverePeProiectProduseSpreRezervare: this.rol.rezerverePeProiectProduseSpreRezervare,
        ReturProduseSpreRezervare: this.rol.returProduseSpreRezervare,
        Materiale: this.rol.materiale,
        GestiunePrincipala: this.rol.gestiunePrincipala,
        AdaugaProdusGestiunePrincipala: this.rol.adaugaProdusGestiunePrincipala,
        GenerareRaportGestiunePrincipala: this.rol.generareRaportGestiunePrincipala,
        UploadCSVGestiunePrincipala: this.rol.uploadCSVGestiunePrincipala,
        EditeazaGestiunePrincipala: this.rol.editeazaGestiunePrincipala,
        MaresteCantitateaGestiunePrincipala: this.rol.maresteCantitateaGestiunePrincipala,
        DetaliiGestiunePrincipala: this.rol.detaliiGestiunePrincipala,
        StergeGestiunePrincipala: this.rol.stergeGestiunePrincipala,
        AdaugaGestiuneTransferGestiunePrincipala: this.rol.adaugaGestiuneTransferGestiunePrincipala,
        RezervareGestiunePrincipala: this.rol.rezervareGestiunePrincipala,
        AdaugaGestiuneVanzariGestiunePrincipala: this.rol.adaugaGestiuneVanzariGestiunePrincipala,
        GestiunePersonala  : this.rol.gestiunePersonala,
        ReturGestiunePersonala: this.rol.returGestiunePersonala,
        AdaugaGestiuneUserGestiunePersonala: this.rol.adaugaGestiuneUserGestiunePersonala,
        AdaugaGestiuneProiectGestiunePersonala: this.rol.adaugaGestiuneProiectGestiunePersonala,
        GestiuneProiect: this.rol.gestiuneProiect,
        ReturGestiuneProiect: this.rol.returGestiuneProiect,
        GeneareRaportGestiuneProiect: this.rol.geneareRaportGestiuneProiect,
        GestiuneAngajati: this.rol.gestiuneAngajati,
        ReturGestiuneAngajati: this.rol.returGestiuneAngajati,
        AdaugaGestiuneUserGestiuneAngajati: this.rol.adaugaGestiuneUserGestiuneAngajati,
        AdaugaGestiuneProiectGestiuneAngajati: this.rol.adaugaGestiuneProiectGestiuneAngajati,
        GestiuneTransfer: this.rol.gestiuneTransfer,
        VizualizareAdminGestiuneTransfer: this.rol.vizualizareAdminGestiuneTransfer,
        GeneareRaportGestiuneTransfer: this.rol.geneareRaportGestiuneTransfer,
        AcceptaProduseleGestiuneTransfer: this.rol.acceptaProduseleGestiuneTransfer,
        AdaugaGestiuneTabloterieGestiuneTransfer: this.rol.adaugaGestiuneTabloterieGestiuneTransfer,
        ReturGestiuneTransfer: this.rol.returGestiuneTransfer,
        GestiuneVanzari: this.rol.gestiuneVanzari,
        GeneareRaportGestiuneVanzari: this.rol.geneareRaportGestiuneVanzari,
        ReturGestiuneVanzari: this.rol.returGestiuneVanzari,
        GestiuneRezervarePeProiect: this.rol.gestiuneRezervarePeProiect,
        GeneareRaportGestiuneRezervarePeProiect: this.rol.geneareRaportGestiuneRezervarePeProiect,
        AdaugaGestiuneTransferGestiuneRezervarePeProiect: this.rol.adaugaGestiuneTransferGestiuneRezervarePeProiect,
        ReturGestiuneRezervarePeProiect: this.rol.returGestiuneRezervarePeProiect,
        Productie: this.rol.productie,
        GestiuneComponente: this.rol.gestiuneComponente,
        AdaugaGestiuneComponente: this.rol.adaugaGestiuneComponente,
        EditeazaGestiuneComponente: this.rol.editeazaGestiuneComponente,
        AdaugaCantitateGestiuneComponente: this.rol.adaugaCantitateGestiuneComponente,
        StergeGestiuneComponente: this.rol.stergeGestiuneComponente,
        GestiuneRetetar: this.rol.gestiuneRetetar,
        AdaugaGestiuneRetetar: this.rol.adaugaGestiuneRetetar,
        EditeazaGestiuneRetetar: this.rol.editeazaGestiuneRetetar,
        StergeGestiuneRetetar: this.rol.stergeGestiuneRetetar,
        AdaugaGestiuneProductieGestiuneRetetar: this.rol.adaugaGestiuneProductieGestiuneRetetar,
        GestiuneRetetarComponente: this.rol.gestiuneRetetarComponente,
        AdaugaGestiuneRetetarComponente: this.rol.adaugaGestiuneRetetarComponente,
        EditeazaGestiuneRetetarComponente: this.rol.editeazaGestiuneRetetarComponente,
        StergeGestiuneRetetarComponente: this.rol.stergeGestiuneRetetarComponente,
        GestiuneProductie: this.rol.gestiuneProductie,
        ReturGestiuneProductie: this.rol.returGestiuneProductie,
        AdaugaGestiunePrincipalaGestiuneProductie: this.rol.adaugaGestiunePrincipalaGestiuneProductie,
        Prezenta: this.rol.prezenta,
        TabelPrezenta: this.rol.tabelPrezenta,
        GeneareRaportTabelPrezenta: this.rol.geneareRaportTabelPrezenta,
        EditeazaTabelPrezenta: this.rol.editeazaTabelPrezenta,
        StergeTabelPrezenta: this.rol.stergeTabelPrezenta,
        TabelPrezentaTabloterie: this.rol.tabelPrezentaTabloterie,
        GeneareRaportTabelPrezentaTabloterie: this.rol.geneareRaportTabelPrezentaTabloterie,
        EditeazaTabelPrezentaTabloterie: this.rol.editeazaTabelPrezentaTabloterie,
        StergeTabelPrezentaTabloterie: this.rol.stergeTabelPrezentaTabloterie,
        AdaugaPrezentaProiect: this.rol.adaugaPrezentaProiect,
        AdaugaPrezentaAtelier: this.rol.adaugaPrezentaAtelier,
        AdaugaPrezentaBirou: this.rol.adaugaPrezentaBirou,
        Tabloterie: this.rol.tabloterie,
        GestiuneTabloterie: this.rol.gestiuneTabloterie,
        GeneareRaportGestiuneTabloterie: this.rol.geneareRaportGestiuneTabloterie,
        StergeGestiuneTabloterie: this.rol.stergeGestiuneTabloterie,
        ProiecteTabloterie: this.rol.proiecteTabloterie,
        CreazaProiectProiecteTabloterie: this.rol.creazaProiectProiecteTabloterie,
        EditeazaProiecteTabloterie: this.rol.editeazaProiecteTabloterie,
        StergeProiecteTabloterie: this.rol.stergeProiecteTabloterie,
        TablouriProiecteTabloterie: this.rol.tablouriProiecteTabloterie,
        CreazaTablouTablouriProiecteTabloterie: this.rol.creazaTablouTablouriProiecteTabloterie,
        EditeazaTablouriProiecteTabloterie: this.rol.editeazaTablouriProiecteTabloterie,
        StergeTablouriProiecteTabloterie: this.rol.stergeTablouriProiecteTabloterie,
        ProduseTablouriProiecteTabloterie: this.rol.produseTablouriProiecteTabloterie,
        AdaugaProdusProduseTablouriProiecteTabloterie: this.rol.adaugaProdusProduseTablouriProiecteTabloterie,
        GenerareRaportProduseTablouriProiecteTabloterie: this.rol.generareRaportProduseTablouriProiecteTabloterie,
        FinalizareTablouProduseTablouriProiecteTabloterie: this.rol.finalizareTablouProduseTablouriProiecteTabloterie,
        EditeazaProduseTablouriProiecteTabloterie: this.rol.editeazaProduseTablouriProiecteTabloterie,
        ReturProduseTablouriProiecteTabloterie: this.rol.returProduseTablouriProiecteTabloterie,
        AdaugaFisiereProduseTablouriProiecteTabloterie: this.rol.adaugaFisiereProduseTablouriProiecteTabloterie,
        AdaugaPrezentaTabloterie: this.rol.adaugaPrezentaTabloterie,
        TablouriFinalizate: this.rol.tablouriFinalizate,
        Clienti: this.rol.clienti,
        AdaugaClientNou: this.rol.adaugaClientNou,
        ProiecteClienti: this.rol.proiecteClienti,
        RaportProiecteClienti: this.rol.raportProiecteClienti,
        TaskuriProiecteClienti: this.rol.taskuriProiecteClienti,
        AdaugaTaskuriProiecteClienti: this.rol.adaugaTaskuriProiecteClienti,
        MesajeTaskuriProiecteClienti: this.rol.mesajeTaskuriProiecteClienti,
        UseriAsignatiTaskuriProiecteClienti: this.rol.useriAsignatiTaskuriProiecteClienti,
        AdaugaUseriAsignatiTaskuriProiecteClienti: this.rol.adaugaUseriAsignatiTaskuriProiecteClienti,
        InterventiiTaskuriProiecteClienti: this.rol.interventiiTaskuriProiecteClienti,
        EditTaskuriProiecteClienti: this.rol.editTaskuriProiecteClienti,
        SchimbaStatusTaskuriProiecteClienti: this.rol.schimbaStatusTaskuriProiecteClienti,
        InchidereDirectaTaskuriProiecteClienti: this.rol.inchidereDirectaTaskuriProiecteClienti,
        StergeTaskuriProiecteClienti: this.rol.stergeTaskuriProiecteClienti,
        EditeazaProiecteClienti: this.rol.editeazaProiecteClienti,
        StergeProiecteClienti: this.rol.stergeProiecteClienti,
        FisiereProiect: this.rol.fisiereProiect,
        PlanuriArhitectura: this.rol.planuriArhitectura,
        AdaugaPlanuriArhitectura: this.rol.adaugaPlanuriArhitectura,
        StergePlanuriArhitectura: this.rol.stergePlanuriArhitectura,
        SchemeMonofilare: this.rol.schemeMonofilare,
        AdaugaSchemeMonofilare: this.rol.adaugaSchemeMonofilare,
        StergeSchemeMonofilare: this.rol.stergeSchemeMonofilare,
        SchemeTablouri: this.rol.schemeTablouri,
        AdaugaSchemeTablouri: this.rol.adaugaSchemeTablouri,
        StergeSchemeTablouri: this.rol.stergeSchemeTablouri,
        FiseRelee: this.rol.fiseRelee,
        AdaugaFiseRelee: this.rol.adaugaFiseRelee,
        StergeFiseRelee: this.rol.stergeFiseRelee,
        ListaClienti: this.rol.listaClienti,
        DashboardListaClienti: this.rol.dashboardListaClienti,
        AdaugaProiectListaClienti: this.rol.adaugaProiectListaClienti,
        EditeazaListaClienti: this.rol.editeazaListaClienti,
        StergeListaClienti: this.rol.stergeListaClienti,
        Useri: this.rol.useri,
        AdaugaUser: this.rol.adaugaUser,
        ListaUseri: this.rol.listaUseri,
        EditeazaListaUseri: this.rol.editeazaListaUseri,
        SchimbaRolListaUseri: this.rol.schimbaRolListaUseri,
        StergeListaUseri: this.rol.stergeListaUseri,
        Roluri: this.rol.roluri,
        ProtectiaMuncii: this.rol.protectiaMuncii,
        NumereTelefon: this.rol.numereTelefon,
        ProceseVerbale: this.rol.proceseVerbale,
        TabelProceseVerbale: this.rol.tabelProceseVerbale,
        AvizLivare: this.rol.avizLivare,
        ProcesVerbalDeReceptie: this.rol.procesVerbalDeReceptie,
        SesizareTehnica: this.rol.sesizareTehnica,
        TabelSesizareTehnica: this.rol.tabelSesizareTehnica,
        Dashboard: this.rol.dashboard,
        CharturiDashboard: this.rol.charturiDashboard,
        AsignareProiectUserDashboard: this.rol.asignareProiectUserDashboard,
        CalendarProiecteDashboard: this.rol.calendarProiecteDashboard,
        TaskuriAdminDashboard: this.rol.taskuriAdminDashboard,
        ProiecteAdminDashboard: this.rol.proiecteAdminDashboard,
        GrupuriDeComunicareDashboard: this.rol.grupuriDeComunicareDashboard,
        ProfitabilitateProiecteDashboard: this.rol.profitabilitateProiecteDashboard,
        AsigneazaUserGrupuriDeComunicareDashboard: this.rol.asigneazaUserGrupuriDeComunicareDashboard,
        StergeUserGrupuriDeComunicareDashboard: this.rol.stergeUserGrupuriDeComunicareDashboard,
        VizualizareAnunturiDashboard: this.rol.vizualizareAnunturiDashboard,
        AdaugaAnunturiDashboard: this.rol.adaugaAnunturiDashboard,
        StergeAnunturiDashboard: this.rol.stergeAnunturiDashboard
     }
    });
  }

  onSubmit(fRole: any){
    this.model.Tip = fRole.value.Tip;
    this.model.Id = this.sessionData.rolId;

    return this.http.put(this.appUrl + 'api/role/' + this.sessionData.rolId, this.model).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
  }

  checkEvent(event: any, value: any){

      switch(value){
          case 'GenerareOferta':
              if(event.target.checked == true){
                  this.model.GenerareOferta = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.OferteEmise == false && this.model.DescriereFunctiuni == false && this.model.DescriereEchipamente == false && this.model.DescriereVizualizari == false && this.model.BazaDeDateProduse == false && this.model.ArticoleManopera == false){
                  this.model.GenerareOferta = false;
                  this.model.Ofertare = false;
              }
              else if(event.target.checked == false){
                  this.model.GenerareOferta = false;
              }
              break;
          case 'OferteEmise':
              if(event.target.checked == true){
                  this.model.OferteEmise = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.DescriereFunctiuni == false && this.model.DescriereEchipamente == false && this.model.DescriereVizualizari == false && this.model.BazaDeDateProduse == false && this.model.ArticoleManopera == false){
                  this.model.OferteEmise = false;
                  this.model.Ofertare = false;
                  this.model.AdaugaOferta = false;
                  this.model.EditeazaOferta = false;
                  this.model.StergeOferta = false;
                  this.model.CreazaComanda = false;
                  this.model.RaportOferta = false;
              }
              else if(event.target.checked == false){
                  this.model.OferteEmise = false;
                  this.model.AdaugaOferta = false;
                  this.model.EditeazaOferta = false;
                  this.model.StergeOferta = false;
                  this.model.CreazaComanda = false;
                  this.model.RaportOferta = false;
              }
              break;
          case 'Ofertare':
              if(event.target.checked == true){
                  this.model.Ofertare = true;
              }
              else{
                  this.model.Ofertare = false;
                  this.model.GenerareOferta = false;
                  this.model.OferteEmise = false;
                  this.model.AdaugaOferta = false;
                  this.model.EditeazaOferta = false;
                  this.model.StergeOferta = false;
                  this.model.CreazaComanda = false;
                  this.model.RaportOferta = false;
                  this.model.DescriereFunctiuni = false;
                  this.model.AdaugaDescriereFunctiuni = false;
                  this.model.EditeazaDescriereFunctiuni = false;
                  this.model.StergeDescriereFunctiuni = false;
                  this.model.DescriereEchipamente = false;
                  this.model.AdaugaDescriereEchipamente = false;
                  this.model.EditeazaDescriereEchipamente = false;
                  this.model.StergeDescriereEchipamente = false;
                  this.model.DescriereVizualizari = false;
                  this.model.AdaugaDescriereVizualizari = false;
                  this.model.EditeazaDescriereVizualizari = false;
                  this.model.StergeDescriereVizualizari = false;
                  this.model.BazaDeDateProduse = false;
                  this.model.AdaugaProdusBazaDeDateProduse = false;
                  this.model.UploadCSVBazaDeDateProduse = false;
                  this.model.EditeazaBazaDeDateProduse = false;
                  this.model.StergeBazaDeDateProduse = false;
                  this.model.ArticoleManopera = false;
                  this.model.EditeazaArticoleManopera = false;
                  this.model.StergeArticoleManopera = false;
              }
              break;
          case 'AdaugaOferta':
              if(event.target.checked == true){
                  this.model.AdaugaOferta = true;
                  this.model.Ofertare = true;
                  this.model.OferteEmise = true;
              }
              else{
                  this.model.AdaugaOferta = false;
              }
              break;
          case 'EditeazaOferta':
              if(event.target.checked == true){
                  this.model.EditeazaOferta = true;
                  this.model.Ofertare = true;
                  this.model.OferteEmise = true;
              }
              else{
                  this.model.EditeazaOferta = false;
              }
              break;
          case 'StergeOferta':
              if(event.target.checked == true){
                  this.model.StergeOferta = true;
                  this.model.Ofertare = true;
                  this.model.OferteEmise = true;
              }
              else{
                  this.model.StergeOferta = false;
              }
              break;
          case 'CreazaComanda':
              if(event.target.checked == true){
                  this.model.CreazaComanda = true;
                  this.model.Ofertare = true;
                  this.model.OferteEmise = true;
              }
              else{
                  this.model.CreazaComanda = false;
              }
              break;
          case 'RaportOferta':
              if(event.target.checked == true){
                  this.model.RaportOferta = true;
                  this.model.Ofertare = true;
                  this.model.OferteEmise = true;
              }
              else{
                  this.model.RaportOferta = false;
              }
              break;
          case 'DescriereFunctiuni':
              if(event.target.checked == true){
                  this.model.DescriereFunctiuni = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.OferteEmise == false && this.model.DescriereEchipamente == false && this.model.DescriereVizualizari == false && this.model.BazaDeDateProduse == false && this.model.ArticoleManopera == false){
                  this.model.DescriereFunctiuni = false;
                  this.model.Ofertare = false;
                  this.model.AdaugaDescriereFunctiuni = false;
                  this.model.EditeazaDescriereFunctiuni = false;
                  this.model.StergeDescriereFunctiuni = false;
              }
              else if(event.target.checked == false){
                  this.model.DescriereFunctiuni = false;
                  this.model.AdaugaDescriereFunctiuni = false;
                  this.model.EditeazaDescriereFunctiuni = false;
                  this.model.StergeDescriereFunctiuni = false;
              }
              break;
          case 'AdaugaDescriereFunctiuni':
              if(event.target.checked == true){
                  this.model.AdaugaDescriereFunctiuni = true;
                  this.model.Ofertare = true;
                  this.model.DescriereFunctiuni = true;
              }
              else{
                  this.model.AdaugaDescriereFunctiuni = false;
              }
              break;
          case 'EditeazaDescriereFunctiuni':
              if(event.target.checked == true){
                  this.model.EditeazaDescriereFunctiuni = true;
                  this.model.Ofertare = true;
                  this.model.DescriereFunctiuni = true;
              }
              else{
                  this.model.EditeazaDescriereFunctiuni = false;
              }
              break;
          case 'StergeDescriereFunctiuni':
              if(event.target.checked == true){
                  this.model.StergeDescriereFunctiuni = true;
                  this.model.Ofertare = true;
                  this.model.DescriereFunctiuni = true;
              }
              else{
                  this.model.StergeDescriereFunctiuni = false;
              }
              break;
          case 'DescriereEchipamente':
              if(event.target.checked == true){
                  this.model.DescriereEchipamente = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.OferteEmise == false && this.model.DescriereFunctiuni == false && this.model.DescriereVizualizari == false && this.model.BazaDeDateProduse == false && this.model.ArticoleManopera == false){
                  this.model.DescriereEchipamente = false;
                  this.model.Ofertare = false;
                  this.model.AdaugaDescriereEchipamente = false;
                  this.model.EditeazaDescriereEchipamente = false;
                  this.model.StergeDescriereEchipamente = false;
              }
              else if(event.target.checked == false){
                  this.model.DescriereEchipamente = false;
                  this.model.AdaugaDescriereEchipamente = false;
                  this.model.EditeazaDescriereEchipamente = false;
                  this.model.StergeDescriereEchipamente = false;
              }
              break;
          case 'AdaugaDescriereEchipamente':
              if(event.target.checked == true){
                  this.model.AdaugaDescriereEchipamente = true;
                  this.model.Ofertare = true;
                  this.model.DescriereEchipamente = true;
              }
              else{
                  this.model.AdaugaDescriereEchipamente = false;
              }
              break;
          case 'EditeazaDescriereEchipamente':
              if(event.target.checked == true){
                  this.model.EditeazaDescriereEchipamente = true;
                  this.model.Ofertare = true;
                  this.model.DescriereEchipamente = true;
              }
              else{
                  this.model.EditeazaDescriereEchipamente = false;
              }
              break;
          case 'StergeDescriereEchipamente':
              if(event.target.checked == true){
                  this.model.StergeDescriereEchipamente = true;
                  this.model.Ofertare = true;
                  this.model.DescriereEchipamente = true;
              }
              else{
                  this.model.StergeDescriereEchipamente = false;
              }
              break;
          case 'DescriereVizualizari':
              if(event.target.checked == true){
                  this.model.DescriereVizualizari = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.OferteEmise == false && this.model.DescriereFunctiuni == false && this.model.DescriereEchipamente == false && this.model.BazaDeDateProduse == false && this.model.ArticoleManopera == false){
                  this.model.DescriereVizualizari = false;
                  this.model.Ofertare = false;
                  this.model.AdaugaDescriereVizualizari = false;
                  this.model.EditeazaDescriereVizualizari = false;
                  this.model.StergeDescriereVizualizari = false;
              }
              else if(event.target.checked == false){
                  this.model.DescriereVizualizari = false;
                  this.model.AdaugaDescriereVizualizari = false;
                  this.model.EditeazaDescriereVizualizari = false;
                  this.model.StergeDescriereVizualizari = false;
              }
              break;
          case 'AdaugaDescriereVizualizari':
              if(event.target.checked == true){
                  this.model.AdaugaDescriereVizualizari = true;
                  this.model.Ofertare = true;
                  this.model.DescriereVizualizari = true;
              }
              else{
                  this.model.AdaugaDescriereVizualizari = false;
              }
              break;
          case 'EditeazaDescriereVizualizari':
              if(event.target.checked == true){
                  this.model.EditeazaDescriereVizualizari = true;
                  this.model.Ofertare = true;
                  this.model.DescriereVizualizari = true;
              }
              else{
                  this.model.EditeazaDescriereVizualizari = false;
              }
              break;
          case 'StergeDescriereVizualizari':
              if(event.target.checked == true){
                  this.model.StergeDescriereVizualizari = true;
                  this.model.Ofertare = true;
                  this.model.DescriereVizualizari = true;
              }
              else{
                  this.model.StergeDescriereVizualizari = false;
              }
              break;
          case 'BazaDeDateProduse':
              if(event.target.checked == true){
                  this.model.BazaDeDateProduse = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.OferteEmise == false && this.model.DescriereFunctiuni == false && this.model.DescriereEchipamente == false && this.model.DescriereVizualizari == false && this.model.ArticoleManopera == false){
                  this.model.BazaDeDateProduse = false;
                  this.model.Ofertare = false;
                  this.model.AdaugaProdusBazaDeDateProduse = false;
                  this.model.UploadCSVBazaDeDateProduse = false;
                  this.model.EditeazaBazaDeDateProduse = false;
                  this.model.StergeBazaDeDateProduse = false;
              }
              else if(event.target.checked == false){
                  this.model.BazaDeDateProduse = false;
                  this.model.AdaugaProdusBazaDeDateProduse = false;
                  this.model.UploadCSVBazaDeDateProduse = false;
                  this.model.EditeazaBazaDeDateProduse = false;
                  this.model.StergeBazaDeDateProduse = false;
              }
              break;
          case 'AdaugaProdusBazaDeDateProduse':
              if(event.target.checked == true){
                  this.model.AdaugaProdusBazaDeDateProduse = true;
                  this.model.Ofertare = true;
                  this.model.BazaDeDateProduse = true;
              }
              else{
                  this.model.AdaugaProdusBazaDeDateProduse = false;
              }
              break;
          case 'UploadCSVBazaDeDateProduse':
              if(event.target.checked == true){
                  this.model.UploadCSVBazaDeDateProduse = true;
                  this.model.Ofertare = true;
                  this.model.BazaDeDateProduse = true;
              }
              else{
                  this.model.UploadCSVBazaDeDateProduse = false;
              }
              break;
          case 'EditeazaBazaDeDateProduse':
              if(event.target.checked == true){
                  this.model.EditeazaBazaDeDateProduse = true;
                  this.model.Ofertare = true;
                  this.model.BazaDeDateProduse = true;
              }
              else{
                  this.model.EditeazaBazaDeDateProduse = false;
              }
              break;
          case 'StergeBazaDeDateProduse':
              if(event.target.checked == true){
                  this.model.StergeBazaDeDateProduse = true;
                  this.model.Ofertare = true;
                  this.model.BazaDeDateProduse = true;
              }
              else{
                  this.model.StergeBazaDeDateProduse = false;
              }
              break;
          case 'ArticoleManopera':
              if(event.target.checked == true){
                  this.model.ArticoleManopera = true;
                  this.model.Ofertare = true;
              }
              else if(event.target.checked == false && this.model.GenerareOferta == false && this.model.OferteEmise == false && this.model.DescriereFunctiuni == false && this.model.DescriereEchipamente == false && this.model.DescriereVizualizari == false && this.model.BazaDeDateProduse == false){
                  this.model.ArticoleManopera = false;
                  this.model.Ofertare = false;
                  this.model.EditeazaArticoleManopera = false;
                  this.model.StergeArticoleManopera = false;
              }
              else if(event.target.checked == false){
                  this.model.ArticoleManopera = false;
                  this.model.EditeazaArticoleManopera = false;
                  this.model.StergeArticoleManopera = false;
              }
              break;
          case 'EditeazaArticoleManopera':
              if(event.target.checked == true){
                  this.model.EditeazaArticoleManopera = true;
                  this.model.Ofertare = true;
                  this.model.ArticoleManopera = true;
              }
              else{
                  this.model.EditeazaArticoleManopera = false;
              }
              break;
          case 'StergeArticoleManopera':
              if(event.target.checked == true){
                  this.model.StergeArticoleManopera = true;
                  this.model.Ofertare = true;
                  this.model.ArticoleManopera = true;
              }
              else{
                  this.model.StergeArticoleManopera = false;
              }
              break;
          case 'Aprovizionare':
              if(event.target.checked == true){
                  this.model.Aprovizionare = true;
              }
              else{
                  this.model.Aprovizionare = false;
                  this.model.ListaComenzi = false;
                  this.model.ModificaStatusListaComenzi = false;
                  this.model.StergeListaComenzi = false;
                  this.model.ProduseListaComenzi = false;
                  this.model.GenerareRaportProduseListaComenzi = false;
                  this.model.FinalizareComandaProduseListaComenzi = false;
                  this.model.ModificaStatusProduseListaComenzi = false;
                  this.model.GestiuneComenzi = false;
                  this.model.RezervarePeProiectGestiuneComenzi = false;
                  this.model.AdaugaListaDeComandatGestiuneComenzi = false;
                  this.model.EditareCantitateGestiuneComenzi = false;
                  this.model.StergeGestiuneComenzi = false;
                  this.model.ListaDeComandat = false;
                  this.model.GenerareRaportListaDeComandat = false;
                  this.model.AdaugaGestiuneComenziListaDeComandat = false;
                  this.model.StergeListaDeComandat = false;
                  this.model.ListaStocCritic = false;
                  this.model.ProduseSpreRezervare = false;
                  this.model.RezerverePeProiectProduseSpreRezervare = false;
                  this.model.ReturProduseSpreRezervare = false;
              }
              break;
          case 'ListaComenzi':
              if(event.target.checked == true){
                  this.model.ListaComenzi = true;
                  this.model.Aprovizionare = true;
              }
              else if(event.target.checked == false && this.model.GestiuneComenzi == false && this.model.ListaDeComandat == false && this.model.ListaStocCritic == false && this.model.ProduseSpreRezervare == false){
                  this.model.ListaComenzi = false;
                  this.model.Aprovizionare = false;
                  this.model.ModificaStatusListaComenzi = false;
                  this.model.StergeListaComenzi = false;
                  this.model.ProduseListaComenzi = false;
                  this.model.GenerareRaportProduseListaComenzi = false;
                  this.model.FinalizareComandaProduseListaComenzi = false;
                  this.model.ModificaStatusProduseListaComenzi = false;
              }
              else if(event.target.checked == false){
                  this.model.ListaComenzi = false;
                  this.model.ModificaStatusListaComenzi = false;
                  this.model.StergeListaComenzi = false;
                  this.model.ProduseListaComenzi = false;
                  this.model.GenerareRaportProduseListaComenzi = false;
                  this.model.FinalizareComandaProduseListaComenzi = false;
                  this.model.ModificaStatusProduseListaComenzi = false;
              }
              break;
          case 'ModificaStatusListaComenzi':
              if(event.target.checked == true){
                  this.model.ModificaStatusListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
              }
              else{
                  this.model.ModificaStatusListaComenzi = false;
              }
              break;
          case 'StergeListaComenzi':
              if(event.target.checked == true){
                  this.model.StergeListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
              }
              else{
                  this.model.StergeListaComenzi = false;
              }
              break;
          case 'ProduseListaComenzi':
              if(event.target.checked == true){
                  this.model.ProduseListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
              }
              else{
                  this.model.ProduseListaComenzi = false;
                  this.model.GenerareRaportProduseListaComenzi = false;
                  this.model.FinalizareComandaProduseListaComenzi = false;
                  this.model.ModificaStatusProduseListaComenzi = false;
              }
              break;
          case 'GenerareRaportProduseListaComenzi':
              if(event.target.checked == true){
                  this.model.GenerareRaportProduseListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
                  this.model.ProduseListaComenzi = true;
              }
              else{
                  this.model.GenerareRaportProduseListaComenzi = false;
              }
              break;
          case 'FinalizareComandaProduseListaComenzi':
              if(event.target.checked == true){
                  this.model.FinalizareComandaProduseListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
                  this.model.ProduseListaComenzi = true;
              }
              else{
                  this.model.FinalizareComandaProduseListaComenzi = false;
              }
              break;
          case 'ModificaStatusProduseListaComenzi':
              if(event.target.checked == true){
                  this.model.ModificaStatusProduseListaComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaComenzi = true;
                  this.model.ProduseListaComenzi = true;
              }
              else{
                  this.model.ModificaStatusProduseListaComenzi = false;
              }
              break;
          case 'GestiuneComenzi':
              if(event.target.checked == true){
                  this.model.GestiuneComenzi = true;
                  this.model.Aprovizionare = true;
              }
              else if(event.target.checked == false && this.model.ListaComenzi == false && this.model.ListaDeComandat == false && this.model.ListaStocCritic == false && this.model.ProduseSpreRezervare == false){
                  this.model.GestiuneComenzi = false;
                  this.model.Aprovizionare = false;
                  this.model.RezervarePeProiectGestiuneComenzi = false;
                  this.model.AdaugaListaDeComandatGestiuneComenzi = false;
                  this.model.EditareCantitateGestiuneComenzi = false;
                  this.model.StergeGestiuneComenzi = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneComenzi = false;
                  this.model.RezervarePeProiectGestiuneComenzi = false;
                  this.model.AdaugaListaDeComandatGestiuneComenzi = false;
                  this.model.EditareCantitateGestiuneComenzi = false;
                  this.model.StergeGestiuneComenzi = false;
              }
              break;
          case 'RezervarePeProiectGestiuneComenzi':
              if(event.target.checked == true){
                  this.model.RezervarePeProiectGestiuneComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.GestiuneComenzi = true;
              }
              else{
                  this.model.RezervarePeProiectGestiuneComenzi = false;
              }
              break;
          case 'AdaugaListaDeComandatGestiuneComenzi':
              if(event.target.checked == true){
                  this.model.AdaugaListaDeComandatGestiuneComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.GestiuneComenzi = true;
              }
              else{
                  this.model.AdaugaListaDeComandatGestiuneComenzi = false;
              }
              break;
          case 'EditareCantitateGestiuneComenzi':
              if(event.target.checked == true){
                  this.model.EditareCantitateGestiuneComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.GestiuneComenzi = true;
              }
              else{
                  this.model.EditareCantitateGestiuneComenzi = false;
              }
              break;
          case 'StergeGestiuneComenzi':
              if(event.target.checked == true){
                  this.model.StergeGestiuneComenzi = true;
                  this.model.Aprovizionare = true;
                  this.model.GestiuneComenzi = true;
              }
              else{
                  this.model.StergeGestiuneComenzi = false;
              }
              break;
          case 'ListaDeComandat':
              if(event.target.checked == true){
                  this.model.ListaDeComandat = true;
                  this.model.Aprovizionare = true;
              }
              else if(event.target.checked == false && this.model.ListaComenzi == false && this.model.GestiuneComenzi == false && this.model.ListaStocCritic == false && this.model.ProduseSpreRezervare == false){
                  this.model.ListaDeComandat = false;
                  this.model.Aprovizionare = false;
                  this.model.GenerareRaportListaDeComandat = false;
                  this.model.AdaugaGestiuneComenziListaDeComandat = false;
                  this.model.StergeListaDeComandat = false;
              }
              else if(event.target.checked == false){
                  this.model.ListaDeComandat = false;
                  this.model.GenerareRaportListaDeComandat = false;
                  this.model.AdaugaGestiuneComenziListaDeComandat = false;
                  this.model.StergeListaDeComandat = false;
              }
              break;
          case 'GenerareRaportListaDeComandat':
              if(event.target.checked == true){
                  this.model.GenerareRaportListaDeComandat = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaDeComandat = true;
              }
              else{
                  this.model.GenerareRaportListaDeComandat = false;
              }
              break;
          case 'AdaugaGestiuneComenziListaDeComandat':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneComenziListaDeComandat = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaDeComandat = true;
              }
              else{
                  this.model.AdaugaGestiuneComenziListaDeComandat = false;
              }
              break;
          case 'StergeListaDeComandat':
              if(event.target.checked == true){
                  this.model.StergeListaDeComandat = true;
                  this.model.Aprovizionare = true;
                  this.model.ListaDeComandat = true;
              }
              else{
                  this.model.StergeListaDeComandat = false;
              }
              break;
          case 'ListaStocCritic':
              if(event.target.checked == true){
                  this.model.ListaStocCritic = true;
                  this.model.Aprovizionare = true;
              }
              else if(event.target.checked == false && this.model.ListaComenzi == false && this.model.GestiuneComenzi == false && this.model.ListaDeComandat == false && this.model.ProduseSpreRezervare == false){
                  this.model.ListaStocCritic = false;
                  this.model.Aprovizionare = false;
              }
              else if(event.target.checked == false){
                  this.model.ListaStocCritic = false;
              }
              break;
          case 'ProduseSpreRezervare':
              if(event.target.checked == true){
                  this.model.ProduseSpreRezervare = true;
                  this.model.Aprovizionare = true;
              }
              else if(event.target.checked == false && this.model.ListaComenzi == false && this.model.GestiuneComenzi == false && this.model.ListaDeComandat == false && this.model.ListaStocCritic == false){
                  this.model.ProduseSpreRezervare = false;
                  this.model.Aprovizionare = false;
                  this.model.RezerverePeProiectProduseSpreRezervare = false;
                  this.model.ReturProduseSpreRezervare = false;
              }
              else if(event.target.checked == false){
                  this.model.ProduseSpreRezervare = false;
                  this.model.RezerverePeProiectProduseSpreRezervare = false;
                  this.model.ReturProduseSpreRezervare = false;
              }
              break;
          case 'RezerverePeProiectProduseSpreRezervare':
              if(event.target.checked == true){
                  this.model.RezerverePeProiectProduseSpreRezervare = true;
                  this.model.Aprovizionare = true;
                  this.model.ProduseSpreRezervare = true;
              }
              else{
                  this.model.RezerverePeProiectProduseSpreRezervare = false;
              }
              break;
          case 'ReturProduseSpreRezervare':
              if(event.target.checked == true){
                  this.model.ReturProduseSpreRezervare = true;
                  this.model.Aprovizionare = true;
                  this.model.ProduseSpreRezervare = true;
              }
              else{
                  this.model.ReturProduseSpreRezervare = false;
              }
              break;
          case 'Materiale':
              if(event.target.checked == true){
                  this.model.Materiale = true;
              }
              else{
                  this.model.Materiale = false;
                  this.model.GestiunePrincipala = false;
                  this.model.AdaugaProdusGestiunePrincipala = false;
                  this.model.GenerareRaportGestiunePrincipala = false;
                  this.model.UploadCSVGestiunePrincipala = false;
                  this.model.EditeazaGestiunePrincipala = false;
                  this.model.MaresteCantitateaGestiunePrincipala = false;
                  this.model.DetaliiGestiunePrincipala = false;
                  this.model.StergeGestiunePrincipala = false;
                  this.model.AdaugaGestiuneTransferGestiunePrincipala = false;
                  this.model.RezervareGestiunePrincipala = false;
                  this.model.AdaugaGestiuneVanzariGestiunePrincipala = false;
                  this.model.GestiunePersonala   = false;
                  this.model.ReturGestiunePersonala = false;
                  this.model.AdaugaGestiuneUserGestiunePersonala = false;
                  this.model.AdaugaGestiuneProiectGestiunePersonala = false;
                  this.model.GestiuneProiect = false;
                  this.model.ReturGestiuneProiect = false;
                  this.model.GeneareRaportGestiuneProiect = false;
                  this.model.GestiuneAngajati = false;
                  this.model.ReturGestiuneAngajati = false;
                  this.model.AdaugaGestiuneUserGestiuneAngajati = false;
                  this.model.AdaugaGestiuneProiectGestiuneAngajati = false;
                  this.model.GestiuneTransfer = false;
                  this.model.GeneareRaportGestiuneTransfer = false;
                  this.model.AcceptaProduseleGestiuneTransfer = false;
                  this.model.AdaugaGestiuneTabloterieGestiuneTransfer = false;
                  this.model.ReturGestiuneTransfer = false;
                  this.model.GestiuneVanzari = false;
                  this.model.GeneareRaportGestiuneVanzari = false;
                  this.model.ReturGestiuneVanzari = false;
                  this.model.GestiuneRezervarePeProiect = false;
                  this.model.GeneareRaportGestiuneRezervarePeProiect = false;
                  this.model.AdaugaGestiuneTransferGestiuneRezervarePeProiect = false;
                  this.model.ReturGestiuneRezervarePeProiect = false;
              }
              break;
          case 'GestiunePrincipala':
              if(event.target.checked == true){
                  this.model.GestiunePrincipala = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePersonala == false && this.model.GestiuneProiect == false && this.model.GestiuneAngajati == false && this.model.GestiuneTransfer == false && this.model.GestiuneVanzari == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiunePrincipala = false;
                  this.model.Materiale = false;
                  this.model.AdaugaProdusGestiunePrincipala = false;
                  this.model.GenerareRaportGestiunePrincipala = false;
                  this.model.UploadCSVGestiunePrincipala = false;
                  this.model.EditeazaGestiunePrincipala = false;
                  this.model.MaresteCantitateaGestiunePrincipala = false;
                  this.model.DetaliiGestiunePrincipala = false;
                  this.model.StergeGestiunePrincipala = false;
                  this.model.AdaugaGestiuneTransferGestiunePrincipala = false;
                  this.model.RezervareGestiunePrincipala = false;
                  this.model.AdaugaGestiuneVanzariGestiunePrincipala = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiunePrincipala = false;
                  this.model.AdaugaProdusGestiunePrincipala = false;
                  this.model.GenerareRaportGestiunePrincipala = false;
                  this.model.UploadCSVGestiunePrincipala = false;
                  this.model.EditeazaGestiunePrincipala = false;
                  this.model.MaresteCantitateaGestiunePrincipala = false;
                  this.model.DetaliiGestiunePrincipala = false;
                  this.model.StergeGestiunePrincipala = false;
                  this.model.AdaugaGestiuneTransferGestiunePrincipala = false;
                  this.model.RezervareGestiunePrincipala = false;
                  this.model.AdaugaGestiuneVanzariGestiunePrincipala = false;
              }
              break;
          case 'AdaugaProdusGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.AdaugaProdusGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.AdaugaProdusGestiunePrincipala = false;
              }
              break;
          case 'GenerareRaportGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.GenerareRaportGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.GenerareRaportGestiunePrincipala = false;
              }
              break;
          case 'UploadCSVGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.UploadCSVGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.UploadCSVGestiunePrincipala = false;
              }
              break;
          case 'EditeazaGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.EditeazaGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.EditeazaGestiunePrincipala = false;
              }
              break;
          case 'MaresteCantitateaGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.MaresteCantitateaGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.MaresteCantitateaGestiunePrincipala = false;
              }
              break;
          case 'DetaliiGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.DetaliiGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.DetaliiGestiunePrincipala = false;
              }
              break;
          case 'StergeGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.StergeGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.StergeGestiunePrincipala = false;
              }
              break;
          case 'AdaugaGestiuneTransferGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneTransferGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.AdaugaGestiuneTransferGestiunePrincipala = false;
              }
              break;
          case 'RezervareGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.RezervareGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.RezervareGestiunePrincipala = false;
              }
              break;
          case 'AdaugaGestiuneVanzariGestiunePrincipala':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneVanzariGestiunePrincipala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePrincipala = true;
              }
              else{
                  this.model.AdaugaGestiuneVanzariGestiunePrincipala = false;
              }
              break;
          case 'GestiunePersonala':
              if(event.target.checked == true){
                  this.model.GestiunePersonala = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiuneProiect == false && this.model.GestiuneAngajati == false && this.model.GestiuneTransfer == false && this.model.GestiuneVanzari == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiunePersonala = false;
                  this.model.Materiale = false;
                  this.model.ReturGestiunePersonala = false;
                  this.model.AdaugaGestiuneUserGestiunePersonala = false;
                  this.model.AdaugaGestiuneProiectGestiunePersonala = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiunePersonala = false;
                  this.model.ReturGestiunePersonala = false;
                  this.model.AdaugaGestiuneUserGestiunePersonala = false;
                  this.model.AdaugaGestiuneProiectGestiunePersonala = false;
              }
              break;
          case 'ReturGestiunePersonala':
              if(event.target.checked == true){
                  this.model.ReturGestiunePersonala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePersonala = true;
              }
              else{
                  this.model.ReturGestiunePersonala = false;
              }
              break;
          case 'AdaugaGestiuneUserGestiunePersonala':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneUserGestiunePersonala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePersonala = true;
              }
              else{
                  this.model.AdaugaGestiuneUserGestiunePersonala = false;
              }
              break;
          case 'AdaugaGestiuneProiectGestiunePersonala':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneProiectGestiunePersonala = true;
                  this.model.Materiale = true;
                  this.model.GestiunePersonala = true;
              }
              else{
                  this.model.AdaugaGestiuneProiectGestiunePersonala = false;
              }
              break;
          case 'GestiuneProiect':
              if(event.target.checked == true){
                  this.model.GestiuneProiect = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiunePersonala == false && this.model.GestiuneAngajati == false && this.model.GestiuneTransfer == false && this.model.GestiuneVanzari == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiuneProiect = false;
                  this.model.Materiale = false;
                  this.model.ReturGestiuneProiect = false;
                  this.model.GeneareRaportGestiuneProiect = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneProiect = false;
                  this.model.ReturGestiuneProiect = false;
                  this.model.GeneareRaportGestiuneProiect = false;
              }
              break;
          case 'ReturGestiuneProiect':
              if(event.target.checked == true){
                  this.model.ReturGestiuneProiect = true;
                  this.model.Materiale = true;
                  this.model.GestiuneProiect = true;
              }
              else{
                  this.model.ReturGestiuneProiect = false;
              }
              break;
          case 'GeneareRaportGestiuneProiect':
              if(event.target.checked == true){
                  this.model.GeneareRaportGestiuneProiect = true;
                  this.model.Materiale = true;
                  this.model.GestiuneProiect = true;
              }
              else{
                  this.model.GeneareRaportGestiuneProiect = false;
              }
              break;
          case 'GestiuneAngajati':
              if(event.target.checked == true){
                  this.model.GestiuneAngajati = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiunePersonala == false && this.model.GestiuneProiect == false && this.model.GestiuneTransfer == false && this.model.GestiuneVanzari == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiuneAngajati = false;
                  this.model.Materiale = false;
                  this.model.ReturGestiuneAngajati = false;
                  this.model.AdaugaGestiuneUserGestiuneAngajati = false;
                  this.model.AdaugaGestiuneProiectGestiuneAngajati = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneAngajati = false;
                  this.model.ReturGestiuneAngajati = false;
                  this.model.AdaugaGestiuneUserGestiuneAngajati = false;
                  this.model.AdaugaGestiuneProiectGestiuneAngajati = false;
              }
              break;
          case 'ReturGestiuneAngajati':
              if(event.target.checked == true){
                  this.model.ReturGestiuneAngajati = true;
                  this.model.Materiale = true;
                  this.model.GestiuneAngajati = true;
              }
              else{
                  this.model.ReturGestiuneAngajati = false;
              }
              break;
          case 'AdaugaGestiuneUserGestiuneAngajati':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneUserGestiuneAngajati = true;
                  this.model.Materiale = true;
                  this.model.GestiuneAngajati = true;
              }
              else{
                  this.model.AdaugaGestiuneUserGestiuneAngajati = false;
              }
              break;
          case 'AdaugaGestiuneProiectGestiuneAngajati':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneProiectGestiuneAngajati = true;
                  this.model.Materiale = true;
                  this.model.GestiuneAngajati = true;
              }
              else{
                  this.model.AdaugaGestiuneProiectGestiuneAngajati = false;
              }
              break;
          case 'GestiuneTransfer':
              if(event.target.checked == true){
                  this.model.GestiuneTransfer = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiunePersonala == false && this.model.GestiuneProiect == false && this.model.GestiuneAngajati == false && this.model.GestiuneVanzari == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiuneTransfer = false;
                  this.model.Materiale = false;
                  this.model.VizualizareAdminGestiuneTransfer = false;
                  this.model.GeneareRaportGestiuneTransfer = false;
                  this.model.AcceptaProduseleGestiuneTransfer = false;
                  this.model.AdaugaGestiuneTabloterieGestiuneTransfer = false;
                  this.model.ReturGestiuneTransfer = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneTransfer = false;
                  this.model.VizualizareAdminGestiuneTransfer = false;
                  this.model.GeneareRaportGestiuneTransfer = false;
                  this.model.AcceptaProduseleGestiuneTransfer = false;
                  this.model.AdaugaGestiuneTabloterieGestiuneTransfer = false;
                  this.model.ReturGestiuneTransfer = false;
              }
              break;
            case 'VizualizareAdminGestiuneTransfer':
                if(event.target.checked == true){
                    this.model.VizualizareAdminGestiuneTransfer = true;
                    this.model.Materiale = true;
                    this.model.GestiuneTransfer = true;
                }
                else{
                    this.model.VizualizareAdminGestiuneTransfer = false;
                }
                break;
          case 'GeneareRaportGestiuneTransfer':
              if(event.target.checked == true){
                  this.model.GeneareRaportGestiuneTransfer = true;
                  this.model.Materiale = true;
                  this.model.GestiuneTransfer = true;
              }
              else{
                  this.model.GeneareRaportGestiuneTransfer = false;
              }
              break;
          case 'AcceptaProduseleGestiuneTransfer':
              if(event.target.checked == true){
                  this.model.AcceptaProduseleGestiuneTransfer = true;
                  this.model.Materiale = true;
                  this.model.GestiuneTransfer = true;
              }
              else{
                  this.model.AcceptaProduseleGestiuneTransfer = false;
              }
              break;
          case 'AdaugaGestiuneTabloterieGestiuneTransfer':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneTabloterieGestiuneTransfer = true;
                  this.model.Materiale = true;
                  this.model.GestiuneTransfer = true;
              }
              else{
                  this.model.AdaugaGestiuneTabloterieGestiuneTransfer = false;
              }
              break;
          case 'ReturGestiuneTransfer':
              if(event.target.checked == true){
                  this.model.ReturGestiuneTransfer = true;
                  this.model.Materiale = true;
                  this.model.GestiuneTransfer = true;
              }
              else{
                  this.model.ReturGestiuneTransfer = false;
              }
              break;
          case 'GestiuneVanzari':
              if(event.target.checked == true){
                  this.model.GestiuneVanzari = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiunePersonala == false && this.model.GestiuneProiect == false && this.model.GestiuneAngajati == false && this.model.GestiuneTransfer == false && this.model.GestiuneRezervarePeProiect == false){
                  this.model.GestiuneVanzari = false;
                  this.model.Materiale = false;
                  this.model.GeneareRaportGestiuneVanzari = false;
                  this.model.ReturGestiuneVanzari = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneVanzari = false;
                  this.model.GeneareRaportGestiuneVanzari = false;
                  this.model.ReturGestiuneVanzari = false;
              }
              break;
          case 'GeneareRaportGestiuneVanzari':
              if(event.target.checked == true){
                  this.model.GeneareRaportGestiuneVanzari = true;
                  this.model.Materiale = true;
                  this.model.GestiuneVanzari = true;
              }
              else{
                  this.model.GeneareRaportGestiuneVanzari = false;
              }
              break;
          case 'ReturGestiuneVanzari':
              if(event.target.checked == true){
                  this.model.ReturGestiuneVanzari = true;
                  this.model.Materiale = true;
                  this.model.GestiuneVanzari = true;
              }
              else{
                  this.model.ReturGestiuneVanzari = false;
              }
              break;
          case 'GestiuneRezervarePeProiect':
              if(event.target.checked == true){
                  this.model.GestiuneRezervarePeProiect = true;
                  this.model.Materiale = true;
              }
              else if(event.target.checked == false && this.model.GestiunePrincipala == false && this.model.GestiunePersonala == false && this.model.GestiuneProiect == false && this.model.GestiuneAngajati == false && this.model.GestiuneTransfer == false && this.model.GestiuneVanzari == false){
                  this.model.GestiuneRezervarePeProiect = false;
                  this.model.Materiale = false;
                  this.model.GeneareRaportGestiuneVanzari = false;
                  this.model.ReturGestiuneVanzari = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneRezervarePeProiect = false;
                  this.model.GeneareRaportGestiuneVanzari = false;
                  this.model.ReturGestiuneVanzari = false;
              }
              break;
          case 'GeneareRaportGestiuneRezervarePeProiect':
              if(event.target.checked == true){
                  this.model.GeneareRaportGestiuneRezervarePeProiect = true;
                  this.model.Materiale = true;
                  this.model.GestiuneRezervarePeProiect = true;
              }
              else{
                  this.model.GeneareRaportGestiuneRezervarePeProiect = false;
              }
              break;
          case 'AdaugaGestiuneTransferGestiuneRezervarePeProiect':
              if(event.target.checked == true){
                  this.model.AdaugaGestiuneTransferGestiuneRezervarePeProiect = true;
                  this.model.Materiale = true;
                  this.model.GestiuneRezervarePeProiect = true;
              }
              else{
                  this.model.AdaugaGestiuneTransferGestiuneRezervarePeProiect = false;
              }
              break;
          case 'ReturGestiuneRezervarePeProiect':
              if(event.target.checked == true){
                  this.model.ReturGestiuneRezervarePeProiect = true;
                  this.model.Materiale = true;
                  this.model.GestiuneRezervarePeProiect = true;
              }
              else{
                  this.model.ReturGestiuneRezervarePeProiect = false;
              }
              break;
          case 'Productie':
            if(event.target.checked == true){
                this.model.Productie = true;
            }
            else{
                this.model.Productie = false;
                this.model.GestiuneComponente = false;
                this.model.AdaugaGestiuneComponente = false;
                this.model.EditeazaGestiuneComponente = false;
                this.model.AdaugaCantitateGestiuneComponente = false;
                this.model.StergeGestiuneComponente = false;
                this.model.GestiuneRetetar = false;
                this.model.AdaugaGestiuneRetetar = false;
                this.model.EditeazaGestiuneRetetar = false;
                this.model.StergeGestiuneRetetar = false;
                this.model.AdaugaGestiuneProductieGestiuneRetetar = false;
                this.model.GestiuneRetetarComponente = false;
                this.model.AdaugaGestiuneRetetarComponente = false;
                this.model.EditeazaGestiuneRetetarComponente = false;
                this.model.StergeGestiuneRetetarComponente = false;
                this.model.GestiuneProductie = false;
                this.model.ReturGestiuneProductie = false;
                this.model.AdaugaGestiunePrincipalaGestiuneProductie = false;
            }
            break;
          case 'GestiuneComponente':
            if(event.target.checked == true){
                    this.model.GestiuneComponente = true;
                }
                else{
                    this.model.GestiuneComponente = false;
                }
          break;
          case 'AdaugaGestiuneComponente':
            if(event.target.checked == true){
                    this.model.AdaugaGestiuneComponente = true;
                }
                else{
                    this.model.AdaugaGestiuneComponente = false;
                }
          break;
          case 'EditeazaGestiuneComponente':
            if(event.target.checked == true){
                    this.model.EditeazaGestiuneComponente = true;
                }
                else{
                    this.model.EditeazaGestiuneComponente = false;
                }
          break;
          case 'AdaugaCantitateGestiuneComponente':
            if(event.target.checked == true){
                    this.model.AdaugaCantitateGestiuneComponente = true;
                }
                else{
                    this.model.AdaugaCantitateGestiuneComponente = false;
                }
          break;
          case 'StergeGestiuneComponente':
            if(event.target.checked == true){
                    this.model.StergeGestiuneComponente = true;
                }
                else{
                    this.model.StergeGestiuneComponente = false;
                }
          break;
          case 'GestiuneRetetar':
            if(event.target.checked == true){
                    this.model.GestiuneRetetar = true;
                }
                else{
                    this.model.GestiuneRetetar = false;
                }
          break;
          case 'AdaugaGestiuneRetetar':
            if(event.target.checked == true){
                    this.model.AdaugaGestiuneRetetar = true;
                }
                else{
                    this.model.AdaugaGestiuneRetetar = false;
                }
          break;
          case 'EditeazaGestiuneRetetar':
            if(event.target.checked == true){
                    this.model.EditeazaGestiuneRetetar =true;
                }
                else{
                    this.model.EditeazaGestiuneRetetar = false;
                }
          break;
          case 'StergeGestiuneRetetar':
            if(event.target.checked == true){
                    this.model.StergeGestiuneRetetar = true;
                }
                else{
                    this.model.StergeGestiuneRetetar = false;
                }
          break;
          case 'AdaugaGestiuneProductieGestiuneRetetar':
            if(event.target.checked == true){
                    this.model.AdaugaGestiuneProductieGestiuneRetetar = true;
                }
                else{
                    this.model.AdaugaGestiuneProductieGestiuneRetetar = false;
                }
          break;
          case 'GestiuneRetetarComponente':
            if(event.target.checked == true){
                    this.model.GestiuneRetetarComponente = true;
                }
                else{
                    this.model.GestiuneRetetarComponente = false;
                }
          break;
          case 'AdaugaGestiuneRetetarComponente':
            if(event.target.checked == true){
                    this.model.AdaugaGestiuneRetetarComponente = true;
                }
                else{
                    this.model.AdaugaGestiuneRetetarComponente = false;
                }
          break;
          case 'EditeazaGestiuneRetetarComponente':
            if(event.target.checked == true){
                    this.model.EditeazaGestiuneRetetarComponente = true;
                }
                else{
                    this.model.EditeazaGestiuneRetetarComponente = false;
                }
          break;
          case 'StergeGestiuneRetetarComponente':
            if(event.target.checked == true){
                    this.model.StergeGestiuneRetetarComponente = true;
                }
                else{
                    this.model.StergeGestiuneRetetarComponente = false;
                }
          break;
          case 'GestiuneProductie':
            if(event.target.checked == true){
                    this.model.GestiuneProductie = true;
                }
                else{
                    this.model.GestiuneProductie = false;
                }
          break;
          case 'ReturGestiuneProductie':
            if(event.target.checked == true){
                    this.model.ReturGestiuneProductie = true;
                }
                else{
                    this.model.ReturGestiuneProductie = false;
                }
          break;
          case 'AdaugaGestiunePrincipalaGestiuneProductie':
            if(event.target.checked == true){
                    this.model.AdaugaGestiunePrincipalaGestiuneProductie = true;
                }
                else{
                    this.model.AdaugaGestiunePrincipalaGestiuneProductie = false;
                }
          break;
          case 'Prezenta':
              if(event.target.checked == true){
                  this.model.Prezenta = true;
              }
              else{
                  this.model.Prezenta = false;
                  this.model.TabelPrezenta = false;
                  this.model.GeneareRaportTabelPrezenta = false;
                  this.model.EditeazaTabelPrezenta = false;
                  this.model.StergeTabelPrezenta = false;
                  this.model.TabelPrezentaTabloterie = false;
                  this.model.GeneareRaportTabelPrezentaTabloterie = false;
                  this.model.EditeazaTabelPrezentaTabloterie = false;
                  this.model.StergeTabelPrezentaTabloterie = false;
                  this.model.AdaugaPrezentaProiect = false;
                  this.model.AdaugaPrezentaAtelier = false;
                  this.model.AdaugaPrezentaBirou = false;
              }
              break;
          case 'TabelPrezenta':
              if(event.target.checked == true){
                  this.model.TabelPrezenta = true;
                  this.model.Prezenta = true;
              }
              else if(event.target.checked == false && this.model.TabelPrezentaTabloterie == false && this.model.AdaugaPrezentaProiect == false && this.model.AdaugaPrezentaAtelier == false && this.model.AdaugaPrezentaBirou == false){
                  this.model.TabelPrezenta = false;
                  this.model.Prezenta = false;
                  this.model.GeneareRaportTabelPrezenta = false;
                  this.model.EditeazaTabelPrezenta = false;
                  this.model.StergeTabelPrezenta = false;
              }
              else if(event.target.checked == false){
                  this.model.TabelPrezenta = false;
                  this.model.GeneareRaportTabelPrezenta = false;
                  this.model.EditeazaTabelPrezenta = false;
                  this.model.StergeTabelPrezenta = false;
              }
              break;
          case 'GeneareRaportTabelPrezenta':
              if(event.target.checked == true){
                  this.model.GeneareRaportTabelPrezenta = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezenta = true;
              }
              else{
                  this.model.GeneareRaportTabelPrezenta = false;
              }
              break;
          case 'EditeazaTabelPrezenta':
              if(event.target.checked == true){
                  this.model.EditeazaTabelPrezenta = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezenta = true;
              }
              else{
                  this.model.EditeazaTabelPrezenta = false;
              }
              break;
          case 'StergeTabelPrezenta':
              if(event.target.checked == true){
                  this.model.StergeTabelPrezenta = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezenta = true;
              }
              else{
                  this.model.StergeTabelPrezenta = false;
              }
              break;
          case 'TabelPrezentaTabloterie':
              if(event.target.checked == true){
                  this.model.TabelPrezentaTabloterie = true;
                  this.model.Prezenta = true;
              }
              else if(event.target.checked == false && this.model.TabelPrezenta == false && this.model.AdaugaPrezentaProiect == false && this.model.AdaugaPrezentaAtelier == false && this.model.AdaugaPrezentaBirou == false){
                  this.model.TabelPrezentaTabloterie = false;
                  this.model.Prezenta = false;
                  this.model.GeneareRaportTabelPrezentaTabloterie = false;
                  this.model.EditeazaTabelPrezentaTabloterie = false;
                  this.model.StergeTabelPrezentaTabloterie = false;
              }
              else if(event.target.checked == false){
                  this.model.TabelPrezentaTabloterie = false;
                  this.model.GeneareRaportTabelPrezentaTabloterie = false;
                  this.model.EditeazaTabelPrezentaTabloterie = false;
                  this.model.StergeTabelPrezentaTabloterie = false;
              }
              break;
          case 'GeneareRaportTabelPrezentaTabloterie':
              if(event.target.checked == true){
                  this.model.GeneareRaportTabelPrezentaTabloterie = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezentaTabloterie = true;
              }
              else{
                  this.model.GeneareRaportTabelPrezentaTabloterie = false;
              }
              break;
          case 'EditeazaTabelPrezentaTabloterie':
              if(event.target.checked == true){
                  this.model.EditeazaTabelPrezentaTabloterie = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezentaTabloterie = true;
              }
              else{
                  this.model.EditeazaTabelPrezentaTabloterie = false;
              }
              break;
          case 'StergeTabelPrezentaTabloterie':
              if(event.target.checked == true){
                  this.model.StergeTabelPrezentaTabloterie = true;
                  this.model.Prezenta = true;
                  this.model.TabelPrezentaTabloterie = true;
              }
              else{
                  this.model.StergeTabelPrezentaTabloterie = false;
              }
              break;
          case 'AdaugaPrezentaProiect':
              if(event.target.checked == true){
                  this.model.AdaugaPrezentaProiect = true;
                  this.model.Prezenta = true;
              }
              else if(event.target.checked == false && this.model.TabelPrezenta == false && this.model.TabelPrezentaTabloterie == false && this.model.AdaugaPrezentaAtelier == false && this.model.AdaugaPrezentaBirou == false){
                  this.model.AdaugaPrezentaProiect = false;
                  this.model.Prezenta = false;
              }
              else if(event.target.checked == false){
                  this.model.AdaugaPrezentaProiect = false;
              }
              break;
          case 'AdaugaPrezentaAtelier':
              if(event.target.checked == true){
                  this.model.AdaugaPrezentaAtelier = true;
                  this.model.Prezenta = true;
              }
              else if(event.target.checked == false && this.model.TabelPrezenta == false && this.model.TabelPrezentaTabloterie == false && this.model.AdaugaPrezentaProiect == false && this.model.AdaugaPrezentaBirou == false){
                  this.model.AdaugaPrezentaAtelier = false;
                  this.model.Prezenta = false;
              }
              else if(event.target.checked == false){
                  this.model.AdaugaPrezentaAtelier = false;
              }
              break;
          case 'AdaugaPrezentaBirou':
              if(event.target.checked == true){
                  this.model.AdaugaPrezentaBirou = true;
                  this.model.Prezenta = true;
              }
              else if(event.target.checked == false && this.model.TabelPrezenta == false && this.model.TabelPrezentaTabloterie == false && this.model.AdaugaPrezentaProiect == false && this.model.AdaugaPrezentaAtelier == false){
                  this.model.AdaugaPrezentaBirou = false;
                  this.model.Prezenta = false;
              }
              else if(event.target.checked == false){
                  this.model.AdaugaPrezentaBirou = false;
              }
              break;
          case 'Tabloterie':
              if(event.target.checked == true){
                  this.model.Tabloterie = true;
              }
              else{
                  this.model.Tabloterie = false;
                  this.model.GestiuneTabloterie = false;
                  this.model.GeneareRaportGestiuneTabloterie = false;
                  this.model.StergeGestiuneTabloterie = false;
                  this.model.ProiecteTabloterie = false;
                  this.model.CreazaProiectProiecteTabloterie = false;
                  this.model.EditeazaProiecteTabloterie = false;
                  this.model.StergeProiecteTabloterie = false;
                  this.model.TablouriProiecteTabloterie = false;
                  this.model.CreazaTablouTablouriProiecteTabloterie = false;
                  this.model.EditeazaTablouriProiecteTabloterie = false;
                  this.model.StergeTablouriProiecteTabloterie = false;
                  this.model.ProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaPrezentaTabloterie = false;
              }
              break;
          case 'GestiuneTabloterie':
              if(event.target.checked == true){
                  this.model.GestiuneTabloterie = true;
                  this.model.Tabloterie = true;
              }
              else if(event.target.checked == false && this.model.ProiecteTabloterie == false && this.model.AdaugaPrezentaTabloterie == false){
                  this.model.GestiuneTabloterie = false;
                  this.model.Tabloterie = false;
                  this.model.GeneareRaportGestiuneTabloterie = false;
                  this.model.StergeGestiuneTabloterie = false;
              }
              else if(event.target.checked == false){
                  this.model.GestiuneTabloterie = false;
                  this.model.GeneareRaportGestiuneTabloterie = false;
                  this.model.StergeGestiuneTabloterie = false;
              }
              break;
          case 'GeneareRaportGestiuneTabloterie':
              if(event.target.checked == true){
                  this.model.GeneareRaportGestiuneTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.GestiuneTabloterie = true;
              }
              else{
                  this.model.GeneareRaportGestiuneTabloterie = false;
              }
              break;
          case 'StergeGestiuneTabloterie':
              if(event.target.checked == true){
                  this.model.StergeGestiuneTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.GestiuneTabloterie = true;
              }
              else{
                  this.model.StergeGestiuneTabloterie = false;
              }
              break;
          case 'ProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.ProiecteTabloterie = true;
                  this.model.Tabloterie = true;
              }
              else if(event.target.checked == false && this.model.GestiuneTabloterie == false && this.model.AdaugaPrezentaTabloterie == false){
                  this.model.ProiecteTabloterie = false;
                  this.model.Tabloterie = false;
                  this.model.CreazaProiectProiecteTabloterie = false;
                  this.model.EditeazaProiecteTabloterie = false;
                  this.model.StergeProiecteTabloterie = false;
                  this.model.TablouriProiecteTabloterie = false;
                  this.model.CreazaTablouTablouriProiecteTabloterie = false;
                  this.model.EditeazaTablouriProiecteTabloterie = false;
                  this.model.StergeTablouriProiecteTabloterie = false;
                  this.model.ProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
              }
              else if(event.target.checked == false){
                  this.model.ProiecteTabloterie = false;
                  this.model.CreazaProiectProiecteTabloterie = false;
                  this.model.EditeazaProiecteTabloterie = false;
                  this.model.StergeProiecteTabloterie = false;
                  this.model.TablouriProiecteTabloterie = false;
                  this.model.CreazaTablouTablouriProiecteTabloterie = false;
                  this.model.EditeazaTablouriProiecteTabloterie = false;
                  this.model.StergeTablouriProiecteTabloterie = false;
                  this.model.ProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'CreazaProiectProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.CreazaProiectProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.CreazaProiectProiecteTabloterie = false;
              }
              break;
          case 'EditeazaProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.EditeazaProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.EditeazaProiecteTabloterie = false;
              }
              break;
          case 'StergeProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.StergeProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.StergeProiecteTabloterie = false;
              }
              break;
          case 'TablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.TablouriProiecteTabloterie = false;
                  this.model.CreazaTablouTablouriProiecteTabloterie = false;
                  this.model.EditeazaTablouriProiecteTabloterie = false;
                  this.model.StergeTablouriProiecteTabloterie = false;
                  this.model.ProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'CreazaTablouTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.CreazaTablouTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.CreazaTablouTablouriProiecteTabloterie = false;
              }
              break;
          case 'EditeazaTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.EditeazaTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.EditeazaTablouriProiecteTabloterie = false;
              }
              break;
          case 'StergeTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.StergeTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.StergeTablouriProiecteTabloterie = false;
              }
              break;
          case 'ProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.ProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
              }
              else{
                  this.model.ProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'AdaugaProdusProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.AdaugaProdusProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'GenerareRaportProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.GenerareRaportProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'FinalizareTablouProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.FinalizareTablouProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'EditeazaProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.EditeazaProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.EditeazaProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'ReturProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.ReturProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.ReturProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'AdaugaFisiereProduseTablouriProiecteTabloterie':
              if(event.target.checked == true){
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = true;
                  this.model.Tabloterie = true;
                  this.model.TablouriProiecteTabloterie = true;
                  this.model.ProiecteTabloterie = true;
                  this.model.ProduseTablouriProiecteTabloterie = true;
              }
              else{
                  this.model.AdaugaFisiereProduseTablouriProiecteTabloterie = false;
              }
              break;
          case 'AdaugaPrezentaTabloterie':
              if(event.target.checked == true){
                  this.model.AdaugaPrezentaTabloterie = true;
                  this.model.Tabloterie = true;
              }
              else if(event.target.checked == false && this.model.GestiuneTabloterie == false && this.model.ProiecteTabloterie == false){
                  this.model.AdaugaPrezentaTabloterie = false;
                  this.model.Tabloterie = false;
              }
              else if(event.target.checked == false){
                  this.model.AdaugaPrezentaTabloterie = false;
              }
              break;
          case 'TablouriFinalizate':
              if(event.target.checked == true){
                  this.model.TablouriFinalizate = true;
                  this.model.Tabloterie = true;
              }
              else if(event.target.checked == false && this.model.GestiuneTabloterie == false && this.model.ProiecteTabloterie == false){
                  this.model.TablouriFinalizate = false;
                  this.model.Tabloterie = false;
              }
              else if(event.target.checked == false){
                  this.model.TablouriFinalizate = false;
              }
              break;
          case 'Clienti':
              if(event.target.checked == true){
                  this.model.Clienti = true;
              }
              else{
                this.model.Clienti = false;
                this.model.AdaugaClientNou = false;
                this.model.ProiecteClienti = false;
                this.model.EditeazaProiecteClienti = false;
                this.model.StergeProiecteClienti = false;
                this.model.ListaClienti = false;
                this.model.DashboardListaClienti = false;
                this.model.AdaugaProiectListaClienti = false;
                this.model.EditeazaListaClienti = false;
                this.model.StergeListaClienti = false;
                this.model.RaportProiecteClienti = false,
                this.model.TaskuriProiecteClienti = false;
                this.model.AdaugaTaskuriProiecteClienti = false;
                this.model.MesajeTaskuriProiecteClienti = false;
                this.model.UseriAsignatiTaskuriProiecteClienti = false;
                this.model.AdaugaUseriAsignatiTaskuriProiecteClienti = false;
                this.model.InterventiiTaskuriProiecteClienti = false;
                this.model.EditTaskuriProiecteClienti = false;
                this.model.SchimbaStatusTaskuriProiecteClienti = false;
                this.model.InchidereDirectaTaskuriProiecteClienti = false;
                this.model.StergeTaskuriProiecteClienti = false;
              }
              break;
          case 'AdaugaClientNou':
              if(event.target.checked == true){
                  this.model.AdaugaClientNou = true;
                  this.model.Clienti = true;
              }
              else if(event.target.checked == false && this.model.ProiecteClienti == false && this.model.ListaClienti == false){
                  this.model.AdaugaClientNou = false;
                  this.model.Clienti = false;
              }
              else if(event.target.checked == false){
                  this.model.AdaugaClientNou = false;
              }
              break;
          case 'ProiecteClienti':
              if(event.target.checked == true){
                  this.model.ProiecteClienti = true;
                  this.model.Clienti = true;
              }
              else if(event.target.checked == false && this.model.AdaugaClientNou == false && this.model.ListaClienti == false){
                  this.model.ProiecteClienti = false;
                  this.model.Clienti = false;
                  this.model.EditeazaProiecteClienti = false;
                  this.model.StergeProiecteClienti = false;
              }
              else if(event.target.checked == false){
                  this.model.ProiecteClienti = false;
                  this.model.EditeazaProiecteClienti = false;
                  this.model.StergeProiecteClienti = false;
              }
              break;
        case 'RaportProiecteClienti':
            if(event.target.checked == true){
                this.model.RaportProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.RaportProiecteClienti = false;
            }
            break;
        case 'TaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.TaskuriProiecteClienti = false;
                this.model.AdaugaTaskuriProiecteClienti = false;
                this.model.MesajeTaskuriProiecteClienti = false;
                this.model.UseriAsignatiTaskuriProiecteClienti = false;
                this.model.AdaugaUseriAsignatiTaskuriProiecteClienti = false;
                this.model.InterventiiTaskuriProiecteClienti = false;
                this.model.EditTaskuriProiecteClienti = false;
                this.model.SchimbaStatusTaskuriProiecteClienti = false;
                this.model.StergeTaskuriProiecteClienti = false;
            }
            break;
        case 'AdaugaTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.AdaugaTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.AdaugaTaskuriProiecteClienti = false;
            }
            break;
        case 'MesajeTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.MesajeTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.MesajeTaskuriProiecteClienti = false;
            }
            break;
        case 'UseriAsignatiTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.UseriAsignatiTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.UseriAsignatiTaskuriProiecteClienti = false;
                this.model.AdaugaUseriAsignatiTaskuriProiecteClienti = false;
            }
            break;
        case 'AdaugaUseriAsignatiTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.AdaugaUseriAsignatiTaskuriProiecteClienti = true;
                this.model.UseriAsignatiTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.AdaugaUseriAsignatiTaskuriProiecteClienti = false;
            }
            break;
        case 'InterventiiTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.InterventiiTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.InterventiiTaskuriProiecteClienti = false;
            }
            break;
        case 'EditTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.EditTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.EditTaskuriProiecteClienti = false;
            }
            break;
        case 'SchimbaStatusTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.SchimbaStatusTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.SchimbaStatusTaskuriProiecteClienti = false;
            }
            break;
        case 'InchidereDirectaTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.InchidereDirectaTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.InchidereDirectaTaskuriProiecteClienti = false;
            }
            break;
        case 'StergeTaskuriProiecteClienti':
            if(event.target.checked == true){
                this.model.StergeTaskuriProiecteClienti = true;
                this.model.TaskuriProiecteClienti = true;
                this.model.Clienti = true;
                this.model.ProiecteClienti = true;
            }
            else{
                this.model.StergeTaskuriProiecteClienti = false;
            }
            break;
          case 'EditeazaProiecteClienti':
              if(event.target.checked == true){
                  this.model.EditeazaProiecteClienti = true;
                  this.model.Clienti = true;
                  this.model.ProiecteClienti = true;
              }
              else{
                  this.model.EditeazaProiecteClienti = false;
              }
              break;
          case 'StergeProiecteClienti':
              if(event.target.checked == true){
                  this.model.StergeProiecteClienti = true;
                  this.model.Clienti = true;
                  this.model.ProiecteClienti = true;
              }
              else{
                  this.model.StergeProiecteClienti = false;
              }
              break;
        case 'FisiereProiect':
            if(event.target.checked == true){
                this.model.FisiereProiect = true;
            }
            else{
                this.model.FisiereProiect = false;
            }
            break;
        case 'PlanuriArhitectura':
            if(event.target.checked == true){
                this.model.PlanuriArhitectura = true;
            }
            else{
                this.model.PlanuriArhitectura = false;
            }
            break;
        case 'AdaugaPlanuriArhitectura':
            if(event.target.checked == true){
                this.model.AdaugaPlanuriArhitectura = true;
            }
            else{
                this.model.AdaugaPlanuriArhitectura = false;
            }
            break;
        case 'StergePlanuriArhitectura':
            if(event.target.checked == true){
                this.model.StergePlanuriArhitectura = true;
            }
            else{
                this.model.StergePlanuriArhitectura = false;
            }
            break;
        case 'SchemeMonofilare':
            if(event.target.checked == true){
                this.model.SchemeMonofilare = true;
            }
            else{
                this.model.SchemeMonofilare = false;
            }
            break;
        case 'AdaugaSchemeMonofilare':
            if(event.target.checked == true){
                this.model.AdaugaSchemeMonofilare = true;
            }
            else{
                this.model.AdaugaSchemeMonofilare = false;
            }
            break;
        case 'StergeSchemeMonofilare':
            if(event.target.checked == true){
                this.model.StergeSchemeMonofilare = true;
            }
            else{
                this.model.StergeSchemeMonofilare = false;
            }
            break;
        case 'SchemeTablouri':
            if(event.target.checked == true){
                this.model.SchemeTablouri = true;
            }
            else{
                this.model.SchemeTablouri = false;
            }
            break;
        case 'AdaugaSchemeTablouri':
            if(event.target.checked == true){
                this.model.AdaugaSchemeTablouri = true;
            }
            else{
                this.model.AdaugaSchemeTablouri = false;
            }
            break;
        case 'StergeSchemeTablouri':
            if(event.target.checked == true){
                this.model.StergeSchemeTablouri = true;
            }
            else{
                this.model.StergeSchemeTablouri = false;
            }
            break;
        case 'FiseRelee':
            if(event.target.checked == true){
                this.model.FiseRelee = true;
            }
            else{
                this.model.FiseRelee = false;
            }
            break;
        case 'AdaugaFiseRelee':
            if(event.target.checked == true){
                this.model.AdaugaFiseRelee = true;
            }
            else{
                this.model.AdaugaFiseRelee = false;
            }
            break;
        case 'StergeFiseRelee':
            if(event.target.checked == true){
                this.model.StergeFiseRelee = true;
            }
            else{
                this.model.StergeFiseRelee = false;
            }
            break;
          case 'ListaClienti':
              if(event.target.checked == true){
                  this.model.ListaClienti = true;
                  this.model.Clienti = true;
              }
              else if(event.target.checked == false && this.model.AdaugaClientNou == false && this.model.ProiecteClienti == false){
                  this.model.ListaClienti = false;
                  this.model.Clienti = false;
                  this.model.DashboardListaClienti = false;
                  this.model.AdaugaProiectListaClienti = false;
                  this.model.EditeazaListaClienti = false;
                  this.model.StergeListaClienti = false;
              }
              else if(event.target.checked == false){
                  this.model.ListaClienti = false;
                  this.model.DashboardListaClienti = false;
                  this.model.AdaugaProiectListaClienti = false;
                  this.model.EditeazaListaClienti = false;
                  this.model.StergeListaClienti = false;
              }
              break;
          case 'DashboardListaClienti':
              if(event.target.checked == true){
                  this.model.DashboardListaClienti = true;
                  this.model.Clienti = true;
                  this.model.ListaClienti = true;
              }
              else{
                  this.model.DashboardListaClienti = false;
              }
              break;
          case 'AdaugaProiectListaClienti':
              if(event.target.checked == true){
                  this.model.AdaugaProiectListaClienti = true;
                  this.model.Clienti = true;
                  this.model.ListaClienti = true;
              }
              else{
                  this.model.AdaugaProiectListaClienti = false;
              }
              break;
          case 'EditeazaListaClienti':
              if(event.target.checked == true){
                  this.model.EditeazaListaClienti = true;
                  this.model.Clienti = true;
                  this.model.ListaClienti = true;
              }
              else{
                  this.model.EditeazaListaClienti = false;
              }
              break;
          case 'StergeListaClienti':
              if(event.target.checked == true){
                  this.model.StergeListaClienti = true;
                  this.model.Clienti = true;
                  this.model.ListaClienti = true;
              }
              else{
                  this.model.StergeListaClienti = false;
              }
              break;
          case 'Useri':
              if(event.target.checked == true){
                  this.model.Useri = true;
              }
              else{
                  this.model.Useri = false;
                  this.model.AdaugaUser = false;
                  this.model.ListaUseri = false;
                  this.model.EditeazaListaUseri = false;
                  this.model.SchimbaRolListaUseri = false;
                  this.model.StergeListaUseri = false;
                  this.model.Roluri = false;
                  this.model.NumereTelefon = false;
              }
              break;
          case 'AdaugaUser':
              if(event.target.checked == true){
                  this.model.AdaugaUser = true;
                  this.model.Useri = true;
              }
              else if(event.target.checked == false && this.model.ListaUseri == false && this.model.Roluri == false){
                  this.model.AdaugaUser = false;
                  this.model.Useri = false;       
              }
              else if(event.target.checked == false){
                  this.model.AdaugaUser = false;
              }
              break;
          case 'ListaUseri':
              if(event.target.checked == true){
                  this.model.ListaUseri = true;
                  this.model.Useri = true;
              }
              else if(event.target.checked == false && this.model.AdaugaUser == false && this.model.Roluri == false){
                  this.model.ListaUseri = false;
                  this.model.Useri = false;     
                  this.model.EditeazaListaUseri = false;
                  this.model.SchimbaRolListaUseri = false;
                  this.model.StergeListaUseri = false;  
              }
              else if(event.target.checked == false){
                  this.model.ListaUseri = false;
                  this.model.EditeazaListaUseri = false;
                  this.model.SchimbaRolListaUseri = false;
                  this.model.StergeListaUseri = false;
              }
              break;
          case 'EditeazaListaUseri':
              if(event.target.checked == true){
                  this.model.EditeazaListaUseri = true;
                  this.model.Useri = true;
                  this.model.ListaUseri = true;
              }
              else{
                  this.model.EditeazaListaUseri = false;
              }
              break;
          case 'SchimbaRolListaUseri':
              if(event.target.checked == true){
                  this.model.SchimbaRolListaUseri = true;
                  this.model.Useri = true;
                  this.model.ListaUseri = true;
              }
              else{
                  this.model.SchimbaRolListaUseri = false;
              }
              break;
          case 'StergeListaUseri':
              if(event.target.checked == true){
                  this.model.StergeListaUseri = true;
                  this.model.Useri = true;
                  this.model.ListaUseri = true;
              }
              else{
                  this.model.StergeListaUseri = false;
              }
              break;
          case 'Roluri':
              if(event.target.checked == true){
                  this.model.Roluri = true;
                  this.model.Useri = true;
              }
              else if(event.target.checked == false && this.model.ListaUseri == false && this.model.ListaUseri == false){
                  this.model.Roluri = false;
                  this.model.Useri = false;       
              }
              else if(event.target.checked == false){
                  this.model.Roluri = false;
              }
              break;
          case 'ProtectiaMuncii':
              if(event.target.checked == true){
                  this.model.ProtectiaMuncii = true;
                  this.model.Useri = true;
              }
              else if(event.target.checked == false && this.model.ListaUseri == false && this.model.ListaUseri == false){
                  this.model.ProtectiaMuncii = false;
                  this.model.Useri = false;       
              }
              else if(event.target.checked == false){
                  this.model.ProtectiaMuncii = false;
              }
              break;
          case 'NumereTelefon':
              if(event.target.checked == true){
                  this.model.NumereTelefon = true;
                  this.model.Useri = true;
              }
              else if(event.target.checked == false && this.model.ListaUseri == false && this.model.ListaUseri == false){
                  this.model.NumereTelefon = false;
                  this.model.Useri = false;       
              }
              else if(event.target.checked == false){
                  this.model.NumereTelefon = false;
              }
              break;
          case 'ProceseVerbale':
              if(event.target.checked == true){
                  this.model.ProceseVerbale = true;
              }
              else{
                  this.model.ProceseVerbale = false;
                  this.model.TabelProceseVerbale =  false;
                  this.model.AvizLivare =  false;
                  this.model.ProcesVerbalDeReceptie =  false;
                  this.model.SesizareTehnica =  false;
                  this.model.TabelSesizareTehnica =  false;
              }
              break;
          case 'TabelProceseVerbale':
              if(event.target.checked == true){
                  this.model.TabelProceseVerbale = true;
                  this.model.ProceseVerbale = true;
              }
              else if(event.target.checked == false && this.model.AvizLivare == false && this.model.ProcesVerbalDeReceptie == false && this.model.SesizareTehnica == false && this.model.TabelSesizareTehnica == false){
                this.model.TabelProceseVerbale = false;
                  this.model.ProceseVerbale = false;       
              }
              else if(event.target.checked == false){
                  this.model.TabelProceseVerbale = false;
              }
              break;
          case 'AvizLivare':
              if(event.target.checked == true){
                  this.model.AvizLivare = true;
                  this.model.ProceseVerbale = true;
              }
              else if(event.target.checked == false && this.model.TabelProceseVerbale == false && this.model.ProcesVerbalDeReceptie == false && this.model.SesizareTehnica == false && this.model.TabelSesizareTehnica == false){
                this.model.AvizLivare = false;
                  this.model.ProceseVerbale = false;       
              }
              else if(event.target.checked == false){
                  this.model.AvizLivare = false;
              }
              break;
          case 'ProcesVerbalDeReceptie':
              if(event.target.checked == true){
                  this.model.ProcesVerbalDeReceptie = true;
                  this.model.ProceseVerbale = true;
              }
              else if(event.target.checked == false && this.model.AvizLivare == false && this.model.TabelProceseVerbale == false && this.model.SesizareTehnica == false && this.model.TabelSesizareTehnica == false){
                this.model.ProcesVerbalDeReceptie = false;
                  this.model.ProceseVerbale = false;       
              }
              else if(event.target.checked == false){
                  this.model.ProcesVerbalDeReceptie = false;
              }
              break;
            case 'SesizareTehnica':
                if(event.target.checked == true){
                    this.model.SesizareTehnica = true;
                    this.model.ProceseVerbale = true;
                }
                else if(event.target.checked == false && this.model.AvizLivare == false && this.model.TabelProceseVerbale == false && this.model.ProcesVerbalDeReceptie == false && this.model.TabelSesizareTehnica == false){
                    this.model.SesizareTehnica = false;
                    this.model.ProceseVerbale = false;       
                }
                else if(event.target.checked == false){
                    this.model.SesizareTehnica = false;
                }
                break;
            case 'TabelSesizareTehnica':
                if(event.target.checked == true){
                    this.model.TabelSesizareTehnica = true;
                    this.model.ProceseVerbale = true;
                }
                else if(event.target.checked == false && this.model.AvizLivare == false && this.model.TabelProceseVerbale == false && this.model.ProcesVerbalDeReceptie == false && this.model.SesizareTehnica == false){
                    this.model.TabelSesizareTehnica = false;
                    this.model.ProceseVerbale = false;       
                }
                else if(event.target.checked == false){
                    this.model.TabelSesizareTehnica = false;
                }
                break;
          case 'Dashboard':
              if(event.target.checked == true){
                  this.model.Dashboard = true;
              }
              else{
                  this.model.Dashboard = false;
                  this.model.CharturiDashboard =  false;
                  this.model.AsignareProiectUserDashboard =  false;
                  this.model.CalendarProiecteDashboard =  false;
                  this.model.TaskuriAdminDashboard =  false;
                  this.model.ProiecteAdminDashboard =  false;
                  this.model.GrupuriDeComunicareDashboard = false,
                  this.model.AsigneazaUserGrupuriDeComunicareDashboard = false, 
                  this.model.StergeUserGrupuriDeComunicareDashboard = false,
                  this.model.VizualizareAnunturiDashboard = false,
                  this.model.AdaugaAnunturiDashboard = false,
                  this.model.StergeAnunturiDashboard = false
              }
              break;
          case 'CharturiDashboard':
              if(event.target.checked == true){
                  this.model.CharturiDashboard = true;
                  this.model.Dashboard = true;
              }
              else if(event.target.checked == false && this.model.AsignareProiectUserDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.ProiecteAdminDashboard == false && this.model.GrupuriDeComunicareDashboard == false){
                  this.model.CharturiDashboard = false;
                  this.model.Dashboard = false;       
              }
              else if(event.target.checked == false){
                  this.model.CharturiDashboard = false;
              }
              break;
          case 'AsignareProiectUserDashboard':
              if(event.target.checked == true){
                  this.model.AsignareProiectUserDashboard = true;
                  this.model.Dashboard = true;
              }
              else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.ProiecteAdminDashboard == false && this.model.GrupuriDeComunicareDashboard == false){
                  this.model.AsignareProiectUserDashboard = false;
                  this.model.Dashboard = false;       
              }
              else if(event.target.checked == false){
                  this.model.AsignareProiectUserDashboard = false;
              }
              break;
          case 'CalendarProiecteDashboard':
              if(event.target.checked == true){
                  this.model.CalendarProiecteDashboard = true;
                  this.model.Dashboard = true;
              }
              else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.AsignareProiectUserDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.ProiecteAdminDashboard == false && this.model.GrupuriDeComunicareDashboard == false){
                  this.model.CalendarProiecteDashboard = false;
                  this.model.Dashboard = false;       
              }
              else if(event.target.checked == false){
                  this.model.CalendarProiecteDashboard = false;
              }
              break;
        case 'TaskuriAdminDashboard':
            if(event.target.checked == true){
                this.model.TaskuriAdminDashboard = true;
                this.model.Dashboard = true;
            }
            else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.AsignareProiectUserDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.ProiecteAdminDashboard == false && this.model.GrupuriDeComunicareDashboard == false){
                this.model.TaskuriAdminDashboard = false;
                this.model.Dashboard = false;       
            }
            else if(event.target.checked == false){
                this.model.TaskuriAdminDashboard = false;
            }
            break;
        case 'ProiecteAdminDashboard':
            if(event.target.checked == true){
                this.model.ProiecteAdminDashboard = true;
                this.model.Dashboard = true;
            }
            else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.AsignareProiectUserDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.GrupuriDeComunicareDashboard == false){
                this.model.ProiecteAdminDashboard = false;
                this.model.Dashboard = false;       
            }
            else if(event.target.checked == false){
                this.model.ProiecteAdminDashboard = false;
            }
            break;
        case 'GrupuriDeComunicareDashboard':
            if(event.target.checked == true){
                this.model.GrupuriDeComunicareDashboard = true;
                this.model.Dashboard = true;
            }
            else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.AsignareProiectUserDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.ProiecteAdminDashboard == false){
                this.model.GrupuriDeComunicareDashboard = false;
                this.model.Dashboard = false;  
                this.model.AsigneazaUserGrupuriDeComunicareDashboard = false; 
                this.model.StergeUserGrupuriDeComunicareDashboard = false;     
            }
            else if(event.target.checked == false){
                this.model.GrupuriDeComunicareDashboard = false;
                this.model.AsigneazaUserGrupuriDeComunicareDashboard = false; 
                this.model.StergeUserGrupuriDeComunicareDashboard = false;     
            }
            break;
        case 'ProfitabilitateProiecteDashboard':
            if(event.target.checked == true){
                this.model.ProfitabilitateProiecteDashboard = true;
                this.model.Dashboard = true;
            }
            else if(event.target.checked == false && this.model.CharturiDashboard == false && this.model.AsignareProiectUserDashboard == false && this.model.CalendarProiecteDashboard == false && this.model.TaskuriAdminDashboard == false && this.model.ProiecteAdminDashboard == false){
                this.model.ProfitabilitateProiecteDashboard = false;
                this.model.Dashboard = false;  
            }
            else if(event.target.checked == false){
                this.model.ProfitabilitateProiecteDashboard = false;
            }
            break;
        case 'AsigneazaUserGrupuriDeComunicareDashboard':
              if(event.target.checked == true){
                  this.model.AsigneazaUserGrupuriDeComunicareDashboard = true;
                  this.model.GrupuriDeComunicareDashboard = true;
                  this.model.Dashboard = true;
              }
              else{
                  this.model.AsigneazaUserGrupuriDeComunicareDashboard = false;
              }
              break;
        case 'StergeUserGrupuriDeComunicareDashboard':
            if(event.target.checked == true){
                this.model.StergeUserGrupuriDeComunicareDashboard = true;
                this.model.GrupuriDeComunicareDashboard = true;
                this.model.Dashboard = true;
            }
            else{
                this.model.StergeUserGrupuriDeComunicareDashboard = false;
            }
            break;
        case 'VizualizareAnunturiDashboard':
            if(event.target.checked == true){
                this.model.VizualizareAnunturiDashboard = true;
                this.model.Dashboard = true;
            }
            else{
                this.model.VizualizareAnunturiDashboard = false;
            }
            break;
        case 'AdaugaAnunturiDashboard':
            if(event.target.checked == true){
                this.model.AdaugaAnunturiDashboard = true;
                this.model.Dashboard = true;
            }
            else{
                this.model.AdaugaAnunturiDashboard = false;
            }
            break;
        case 'StergeAnunturiDashboard':
            if(event.target.checked == true){
                this.model.StergeAnunturiDashboard = true;
                this.model.Dashboard = true;
            }
            else{
                this.model.StergeAnunturiDashboard = false;
            }
            break;
      }
      
  }

}
