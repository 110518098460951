import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  denumire: any;
  taskModel: any = {
    titlu: '',
    descriere: '',
    userIdCreareTask: '',
    dataCreare: '',
    status: '',
    proiectId: '',
    tipTask: '',
    deadLine: '',
    userIdTargetat: '',
    userIdTargetat2: '',
    prioritate: ''
  }

  taskUpdateModel: any = {
    id: '',
    titlu: '',
    descriere: '',
    prioritate: ''
  }

  taskStatusUpdateModel: any = {
    id: '',
    status: '',
    userId: '',
  }

  taskId: any;
  userTaskId: any;
  useriTask: any;
  numeUser: any;
  mesajTaskId: any;
  routeSub: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService, private route: ActivatedRoute) {
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.projectName = params['projectName']
    });
  }

 ngOnInit(): void {
 
   this.sessionData.current_page_title = "TASK-URI" + " - " +  this.projectName;
   this.nav.show();
   var header = {
     headers: new HttpHeaders()
       .set('Authorization',  `Bearer ${this.authService.getToken()}`)
   }
   this.http.get<any>(this.appUrl + 'api/ProiectTask/getTaskByProjectId/' + this.projectId, header).subscribe((data) => {
     this.sessionData.taskProiect = data.sort((a: { prioritate: Number; }, b: { prioritate: Number; }) => (a.prioritate < b.prioritate ? -1 : 1));
   });
 }

   //#region Adauga Task
   addModal(content: any) {
    this.modalService.open(content);
  }

  AddResource(fAddCategory: NgForm){

    let myDate = new Date(Date.now());

    this.taskModel = {
      titlu: fAddCategory.value.Titlu,
      descriere: fAddCategory.value.Descriere,
      userIdCreareTask: this.sessionData.user.id,
      dataCreare: myDate,
      status: 'Activ',
      proiectId: this.projectId,
      tipTask: fAddCategory.value.TipTask,
      deadLine: new Date(fAddCategory.value.DataInceput),
      userIdTargetat: fAddCategory.value.UserName,
      userIdTargetat2: fAddCategory.value.UserName2,
      prioritate: fAddCategory.value.Prioritate
    }
    this.taskModel.deadLine.setDate(this.taskModel.deadLine.getDate() + 1);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/ProiectTask/', this.taskModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion

   //#region Stergere Task
    returModal(content: any, task: any) {
      this.modalService.open(content);
      this.taskId = task.id;
    }
    delete(event: any){
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.delete(this.appUrl + 'api/ProiectTask/' + this.taskId, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }
    //#endregion

  //#region Editare Task
  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.taskUpdateModel = {
      id: gp.id,
      titlu: gp.titlu,
      descriere: gp.descriere,
      deadLine: gp.deadLine,
      numeUserTargetat: gp.userIdTargetat,
      numeUserTargetat2: gp.userIdTargetat2,
      prioritate: gp.prioritate
    }
  }

  EditResource(fEditCategory: NgForm){
    
    this.taskUpdateModel.titlu = fEditCategory.value.Titlu;
    this.taskUpdateModel.descriere = fEditCategory.value.Descriere;
    this.taskUpdateModel.deadLine = fEditCategory.value.DataInceput;
    this.taskUpdateModel.userIdTargetat = fEditCategory.value.UserName;
    this.taskUpdateModel.userIdTargetat2 = fEditCategory.value.UserName2;
    this.taskUpdateModel.prioritate = fEditCategory.value.Prioritate;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/ProiectTask/updateTask', this.taskUpdateModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion
  
  //#region Editare Status Task
  editStatusModal(content: any, gp: any) {
    this.modalService.open(content);
    this.taskStatusUpdateModel = {
      id: gp.id,
      status: gp.status
    }
  }

  EditStatusResource(fEditStatusCategory: NgForm){
    
    if(fEditStatusCategory.value.Status == "InchidereDirecta"){
      this.taskStatusUpdateModel.status = "Inchis";
    }
    else{
      this.taskStatusUpdateModel.status = fEditStatusCategory.value.Status;
    }
    this.taskStatusUpdateModel.userId = this.sessionData.user.id;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/ProiectTask/editStatus', this.taskStatusUpdateModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Adauga Task
  addAfterModal(content: any) {
    this.modalService.open(content);
  }

  AddAfterResource(fAddAfterCategory: NgForm){

    this.taskStatusUpdateModel.status = "Inchis";
    this.taskStatusUpdateModel.userId = this.sessionData.user.id;

    let myDate = new Date(Date.now());

    this.taskModel = {
      titlu: fAddAfterCategory.value.Titlu,
      descriere: fAddAfterCategory.value.Descriere,
      userIdCreareTask: this.sessionData.user.id,
      dataCreare: myDate,
      status: 'Activ',
      proiectId: this.projectId,
      tipTask: fAddAfterCategory.value.TipTask,
      deadLine: new Date(fAddAfterCategory.value.DataInceput),
      userIdTargetat: fAddAfterCategory.value.UserName,
      userIdTargetat2: fAddAfterCategory.value.UserName2,
      prioritate: fAddAfterCategory.value.Prioritate
    }
    this.taskModel.deadLine.setDate(this.taskModel.deadLine.getDate() + 1);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/ProiectTask/', this.taskModel, header).subscribe(
      (response) => {    
         return this.http.put(this.appUrl + 'api/ProiectTask/editStatus', this.taskStatusUpdateModel, header).subscribe(
          (response) => {    
            window.location.reload();
          },
          (error) => { 
            console.error('error caught in component')
            this.message = "Eroare"
          }
        );
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion

  //#region Useri Asignati
    useriAsignatiModal(content: any, gp: any) {
      this.taskId = gp.id;
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.get<any>(this.appUrl + 'api/UserTask/getUseriTaskByTaskId/' + gp.id, header).subscribe((data) => {
        this.useriTask = data;
      });
      this.modalService.open(content);
      
    }
  
    useriAsignatiResource(fuseriAsignatiCategory: NgForm){
      
      let myDate = new Date(Date.now());
      var addUserTaskModel = {
        proiectTaskId: this.taskId,
        userId: fuseriAsignatiCategory.value.UserName,
        userIdAsignator: this.sessionData.user.id,
        data: myDate
      }

      this.sessionData.users.forEach((element: any) => {
        if(element.id == fuseriAsignatiCategory.value.UserName){
          this.numeUser = element.nume + " " + element.prenume;
        }
      });

      let addUserToUseriTask = {
        id: fuseriAsignatiCategory.value.UserName,
        numeUser: this.numeUser
      }
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.post(this.appUrl + 'api/UserTask/', addUserTaskModel, header).subscribe(
        (response) => {    
          this.useriTask.push(addUserToUseriTask);
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }
    //#endregion
  
 //#region Stergere User Task
 deleteUserTaskModal(content: any, user: any) {
  this.modalService.open(content);
  this.userTaskId = user.id;
}
deleteUserTask(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/UserTask/' + this.userTaskId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Stergere Mesaj Task
 deleteMesajTaskModal(content: any, mesaj: any) {
  this.modalService.open(content);
  this.mesajTaskId = mesaj.id;
}
deleteMesajTask(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/ProiectTaskChat/' + this.mesajTaskId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion


  goToInterventii(task: any){
    this.sessionData.taskId = task.id;
    localStorage.setItem('TaskId',task.id);
    localStorage.setItem('TaskName',task.titlu);    
    this.router.navigate(['/interventii-task']);
  }

  goToChat(task: any){
    this.sessionData.taskId = task.id;
    localStorage.setItem('TaskId',task.id);
    localStorage.setItem('TaskName',task.titlu);    
    this.router.navigate(['/task-chat']);
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

  Search(){
    if(this.denumire != ""){
      this.sessionData.taskProiect = this.sessionData.taskProiect.filter((res: { titlu: string}) => {
        return res.titlu.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
      });
    }else if(this.denumire == ""){
      this.ngOnInit();
    }
  
  }

}
