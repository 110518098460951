import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-grupuri',
  templateUrl: './dashboard-grupuri.component.html',
  styleUrls: ['./dashboard-grupuri.component.css']
})
export class DashboardGrupuriComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  projectId: any;
  projectName: any;
  token: any;
  denumire: any;
  grupuriInventar: any;
  listaUseriGrup: any;
  asignUserModel: any = {
    userId: '',
    grupComunicareId: ''
  };
  deleteUserGrup: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "GRUPURI DE COMUNICARE";

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GrupComunicare/getGrupuriInventar', header).subscribe((data) => {
      this.grupuriInventar = data;
    });
  }

  userList(item: any){
    this.listaUseriGrup = item.userList;
  }

  //#region Stergere User Grup
  deleteUserTaskModal(content: any, user: any) {
    this.modalService.open(content);
    this.deleteUserGrup = user;

  }
  deleteUserTask(event: any){

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/UserGrupComunicare/' + this.deleteUserGrup.userGrupId, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Adauga User
  addModal(content: any) {
    this.modalService.open(content);
  }

  AddResource(fAddCategory: NgForm){

    let myDate = new Date(Date.now());
    
    console.log(fAddCategory.value.User);
    console.log(fAddCategory.value.TipTask);

    this.sessionData.users.forEach((element: any) => {
      if(element.nume + " " + element.prenume == fAddCategory.value.User){
        this.asignUserModel.userId = element.id;
      }
    });

    this.grupuriInventar.forEach((element: any) => {
      if(element.denumire == fAddCategory.value.TipTask){
        this.asignUserModel.grupComunicareId = element.id;
      }
    });

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/UserGrupComunicare/', this.asignUserModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion


  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
}
