import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { parse } from 'path';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rapoarte-personalizate',
  templateUrl: './rapoarte-personalizate.component.html',
  styleUrls: ['./rapoarte-personalizate.component.css']
})
export class RapoartePersonalizateComponent implements OnInit {
  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  projectOption: any = "Caută după Proiect";
  rapoarte: any;
  sesizareId: any;
  message: any;
  excelProiectModel: any = {
  tip: '',
  proiectId: '',
  dataInceput: '',
  dataSfarsit: ''
  }
  proiectId: any;
  rapoarteAux: any;
  denumire: any
  //#endregion
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

   async ngOnInit(): Promise<void> {
     this.sessionData.current_page_title = "RAPOARTE PROIECTE PERSONALIZATE";
     
     const proiecte = this.authService.getProiecte();
     await lastValueFrom(proiecte);

     var header = {
       headers: new HttpHeaders()
         .set('Authorization',  `Bearer ${this.authService.getToken()}`)
     }
      this.http.get<any>(this.appUrl + 'api/Proiect/getRaportePersonalizateProiect', header).subscribe((data) => {
        this.rapoarte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));;
        this.rapoarteAux = this.rapoarte;
      });
   }

   //#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.sesizareId = category.id;
  }
  delete(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/Proiect/deleteRaportComplet/' + this.sesizareId, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

  checkValue(event: any){
  }

  //#region excel proiect
  async exportExcelProiect(content: any) : Promise<void> {
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    this.modalService.open(content);
}

ExportExcelProiectResource(fexportExcelProiect: any){
  
    if(fexportExcelProiect.value.raportComplet == true && fexportExcelProiect.value.raportPartial == true){
      this.message = "Eroare";
    }
    else{
      if(fexportExcelProiect.value.raportComplet == true){
        var data = new Date();
        this.excelProiectModel = {
          tip: 'RaportComplet',
          proiectId: parseInt(fexportExcelProiect.value.exampleDataListProiecte),
          dataInceput: this.adjustDateForTimeOffset(data),
          dataSfarsit: this.adjustDateForTimeOffset(data)
        }
      }
      else if(fexportExcelProiect.value.raportPartial == true){
        this.excelProiectModel = {
          tip: 'RaportPartial',
          proiectId: parseInt(fexportExcelProiect.value.exampleDataListProiecte),
          dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
          dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
        }
      }
      else{
        this.message = "Eroare";
      }
    }
    
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/Proiect/RaportProiecteSituatieSaptamanala', this.excelProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

}
//#endregion

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

Search(){
  this.rapoarte = this.rapoarteAux;
  if(this.denumire != ""){
    this.rapoarte = this.rapoarte.filter((res: { denumireProiect: string }) => {
      return res.denumireProiect.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase())
    });
  }else if(this.denumire == ""){
    this.ngOnInit();
  }
}

selectChangeHandler (event: any) {
  this.rapoarte = this.rapoarteAux;
  if(event.target.value == "Toate" || event.target.value == ""){
    this.projectOption = "Caută după Proiect";
    this.rapoarte = this.rapoarteAux;
  }
  else{
    this.projectOption = event.target.value;
    this.rapoarte = this.rapoarte.filter((res: any) => {
      return res.denumireProiect.toLocaleLowerCase().match(this.projectOption.toLocaleLowerCase());
    });
  }
  
}

}
