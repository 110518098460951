import { SessionDataService } from './../../session-data.service';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavbarService } from 'src/app/services/navbar.service';
import { ChartType } from 'chart.js';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { extend } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
import {
  ScheduleComponent, EventSettingsModel, GroupModel, DayService, WeekService, MonthService, DragAndDropService, View, TimelineMonthService, ResizeService, ActionEventArgs
} from '@syncfusion/ej2-angular-schedule';
import { resourceData, timelineResourceData } from '../../../data';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad';
import { lastValueFrom } from 'rxjs';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-profitabilitate-proiecte',
  templateUrl: './profitabilitate-proiecte.component.html',
  styleUrls: ['./profitabilitate-proiecte.component.css']
})
export class ProfitabilitateProiecteComponent implements OnInit {
  appUrl: string = environment.appUrl;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit(): void {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getProjectProfitability', header).subscribe((data) => {
      this.sessionData.projectProfitability = data;
      console.log(data);
    });
  }

}
