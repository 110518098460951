import { SessionDataService } from './../../session-data.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  appUrl: string = environment.appUrl;
  message: string | undefined;
  email: any;
  token: any;
  changePassword: any = {
    Email: '',
    Password: ''
  };
  constructor(
    public authService: AuthService,
    public toastr: ToastrService,
    public router: Router,
    public sessionData: SessionDataService,
    private http: HttpClient,
    private route: ActivatedRoute,
    public nav: NavbarService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.email = params.get("email");
      this.token = params.get("token");
    })
  }
  Login(f: NgForm) {
    if(f.value.password != f.value.confirmpassword){
      this.message = "Câmpurile nu sunt identice!";
      return null;
    }
    else{
      this.changePassword.Email = this.email;
      this.changePassword.Password = f.value.password;
      this.message = "Parola a fost schimbată!";
      return this.http.put(this.appUrl + 'api/user/changepassword', this.changePassword).subscribe();
    }
  }
}
