import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-task-chat',
  templateUrl: './task-chat.component.html',
  styleUrls: ['./task-chat.component.css']
})
export class TaskChatComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  taskId: any;
  taskName: any;
  fisierId: any;
  chatTask: any = [];
  fisiereTask: any;
  messageModel: any = {
    data: '',
    mesaj: '',
    numeUser: '',
    userId: ''
  }

  messageModelRequest: any = {
    data: '',
    mesaj: '',
    userId: '',
    proiectTaskId: ''
  }
  fisierTaskId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.taskId = localStorage.getItem('TaskId');
    this.taskName = localStorage.getItem('TaskName');
    datepipe = new DatePipe('ro-RO');
  }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "MESAGE - " + this.taskName;
    this.nav.show();
    var header1 = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    // this.http.get<any>(this.appUrl + 'api/ProiectTaskChat/getTaskChatList/' + this.taskId, header).subscribe((data) => {
    //   this.chatTask = data;
    // });
    const data1 = this.http.get<any>(this.appUrl + 'api/ProiectTaskChat/getTaskChatList/' + this.taskId, header1).pipe(map((data) => {
                    this.chatTask =  data;}));
    await lastValueFrom(data1);
  }

  ngOnDestroy() {
    localStorage.removeItem('TaskId');
    localStorage.removeItem('TaskName');
  }

  addMessage(message: any){
    let myDate = new Date(Date.now());

    this.messageModelRequest = {
      data: myDate,
      mesaj: message.value.Titlu,
      userId: this.sessionData.user.id,
      proiectTaskId: this.taskId
    }

    this.messageModel = {
      data: myDate,
      mesaj: message.value.Titlu,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume, 
      userId: this.sessionData.user.id
    }
    this.chatTask.push(this.messageModel);

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    const formData = new FormData();
    formData.append("Mesaj",  this.messageModelRequest.mesaj);
    formData.append("Data", myDate.toDateString());
    formData.append("File", "");
    formData.append("Files", "");  
    formData.append("UserId", this.sessionData.user.id);
    formData.append("ProiectTaskId", this.taskId);
    return this.http.post(this.appUrl + 'api/ProiectTaskChat/', formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

    
  }

  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

  //#region Adauga fisier
  addPictureModal(content: any) {
    this.modalService.open(content);
  }

  AddPictureResource(fAddPictureCategory: NgForm){

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    let myDate = new Date(Date.now());
    fAddPictureCategory.value.File = "file";
    fAddPictureCategory.value.Files = this.selectedfile;
    const formData = new FormData();
    formData.append("Mesaj", "");
    formData.append("Data", myDate.toDateString());
    formData.append("File", fAddPictureCategory.value.File.toString());
    formData.append("Files",  fAddPictureCategory.value.Files);  
    formData.append("UserId", this.sessionData.user.id);
    formData.append("ProiectTaskId", this.taskId);

    return this.http.post(this.appUrl + 'api/ProiectTaskChat/', formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  
  }
  //#endregion

  //#region Stergere Mesaj Task
  deleteMesajTaskModal(content: any, mesaj: any) {
    this.modalService.open(content);
    this.fisierTaskId = mesaj.id;
  }
  deleteMesajTask(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/ProiectTaskFisier/' + this.fisierTaskId, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
}
