import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-creare-aviz-livrare',
  templateUrl: './creare-aviz-livrare.component.html',
  styleUrls: ['./creare-aviz-livrare.component.css']
})
export class CreareAvizLivrareComponent implements OnInit {

  message: string | undefined;
  semnatura1: any;
  semnatura2: any;  
  semnatura3: any;
  semnatura4: any;
  semnatura5: any;
  proiectUserModel : any = {
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }
  appUrl: string = environment.appUrl;
  codProduct: string | undefined;

  proiectUserModelUpdate : any = {
    id: '',
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }
  listaProiecte: any = [];
  idClientAles: any;
  numeClientAles: any;
  productModel: any = {
    id: '',
    cod: '',
    denumire: '',
    cantitate: '',
  }
  personModel: any = {
    nume: '',
    semnatura: ''
  }
  personModelList: any = [];
  proiectId: any;
  fieldArray: any = [];
  externalDataRetrievedFromServer: any = [[{text: 'Nr. Crt.', style: 'tableHeader', alignment: 'center'},{text: 'Cod', style: 'tableHeader', alignment: 'center'},{text: 'Denumire', style: 'tableHeader', alignment: 'center'}, {text: 'Cantitate', style: 'tableHeader', alignment: 'center'}]];
  externalDataRetrievedFromServerPubsh: any;
  nrCrtListaProduse: number = 0;
  openAviz: boolean = false;
  openProces: boolean = false;
  imageSrc1 = this.appUrl + 'assets/procesverbal.png';
  numePersoana: any;
  client: any;
  numarProcesVerbal: any;
  numeProiectAles: any;
  avizModel: any = {
    userId: '',
    proiectId: '',
    pdfFile: ''
  }

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }
   

  ngOnInit(): void {
   
  }

  //#region Adauga produs
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.proiectUserModel = {
    proiectId : fAddCategory.value.Proiect,
    userId : fAddCategory.value.UserName,
    dataInceput: fAddCategory.value.DataInceput,
    dataSfarsit: fAddCategory.value.DataSfarsit,
    descriere: fAddCategory.value.Descriere
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ProiectUser/', this.proiectUserModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  //#region PDF Aviz
getFurnizor(){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Furnizor: ', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Green Electric City', border: [false, false, false, false]},
  ];
  table.push(line1);
  var line2 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Nr. Ord. reg. com./an: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '123235646578656543', border: [false, false, false, false]}
  ];
  table.push(line2);
  var line3 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'C.I.F.: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO12341231', border: [false, false, false, false]}
  ];
  table.push(line3);
  var line4 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Sediul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Strada Vasile Lascar, Nr. 178', border: [false, false, false, false]}
  ];
  table.push(line4);
  var line5 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judetul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Bucuresti', border: [false, false, false, false]}
  ];
  table.push(line5);
  var line6 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO1B31007593840000', border: [false, false, false, false]}
  ];
  table.push(line6);
  var line7 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Banca Transilvania', border: [false, false, false, false]}
  ];
  table.push(line7);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getTitlu(){
  var subTable: any = [];
  var subLine1 = [
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Seria: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '12345', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Nr: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '656879u', border: [false, false, false, false]}
  ];
  subTable.push(subLine1);
  var subLine2 = [
    {style:'tableBody', colSpan: 2, alignment: 'center', text: 'Data (ziua, luna, anul):', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 2, alignment: 'center', text: '12.08.2021', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]}
  ];
  subTable.push(subLine2);
  var table: any = [];
  var line1 = [{style:'tableBody2', colSpan: 1, alignment: 'center', text: 'AVIZ DE INSOTIRE A MARFII', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{
    style: 'tableExample', table: { body: subTable, widths: ['25%', '25%', '25%', '25%'], border: [true, true, true, true]}
  }];
  table.push(line2);


  return { style: 'tableExample', table: { body: table, widths: ['100%']}}
}

getCumparator(client: any){
  var table: any = [];
  if(client.client.companie == null){
    var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cumparator: ', border: [false, false, false, false]},
                {style:'tableBody', colSpan: 1, alignment: 'center', text: client.nume + " " + client.prenume, border: [false, false, false, false]},];  
    table.push(line1);
  }
  else {
    var line11 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cumparator: ', border: [false, false, false, false]},
                 {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.companie, border: [false, false, false, false]},];  
    table.push(line11);
  }

  if(client.client.cui == null){
    var line2 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'CUI/CNP: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.cnp, border: [false, false, false, false]}
    ];
    table.push(line2);
  }
  else {
    var line2 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'CUI/CNP: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.cui, border: [false, false, false, false]}
    ];
    table.push(line2);
  }
  
  if(client.client.adresaCompanie == null){
    var line3 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Adresa: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.adresaBeneficiar, border: [false, false, false, false]}
    ];
    table.push(line3);
  }
  else{
    var line3 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Adresa: ', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.adresaCompanie, border: [false, false, false, false]}
    ];
    table.push(line3);
  }

  if(client.client.judet){
    var line5 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judet:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.judet, border: [false, false, false, false]}
    ];
    table.push(line5);
  }
  
  if(client.client.iban){
    var line6 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.iban, border: [false, false, false, false]}
    ];
    table.push(line6);
  }
  
  if(client.client.banca){
    var line7 = [
      {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
      {style:'tableBody', colSpan: 1, alignment: 'center', text: client.client.banca, border: [false, false, false, false]}
    ];
    table.push(line7);
  }
  

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getSemnatura(NumeExp: any, SerieExp: any, NumarExp: any, NumeBen: any, SerieBen: any, NumarBen: any){
  
  var subTable1: any = [];
  var subLine1_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura expeditorului', border: [false, false, false, false]}];
  var subLine1_2 = [{image: this.semnatura1, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable1.push(subLine1_1);
  subTable1.push(subLine1_2);

  var subTable2: any = [];
  var subLine2_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele expeditorului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: NumeExp, border: [false, false, false, false]}];
  var subLine2_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + SerieExp, border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + NumarExp, border: [false, false, false, false]}];
  var subLine2_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine2_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy'), border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + new Date().toLocaleString('ro-RO', { hour: 'numeric', minute: 'numeric' , hour12: false }), border: [false, false, false, false]}];
  subTable2.push(subLine2_2);
  subTable2.push(subLine2_3);
  subTable2.push(subLine2_5);
  subTable2.push(subLine2_6);

  var subTable3: any = [];
  var subLine3_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele beneficiarului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: NumeBen, border: [false, false, false, false]}];
  var subLine3_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + SerieBen, border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + NumarBen, border: [false, false, false, false]}];
  var subLine3_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine3_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + this.datepipe.transform(new Date(), 'dd-MM-yyyy'), border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + new Date().toLocaleString('ro-RO', { hour: 'numeric', minute: 'numeric' ,hour12: false }), border: [false, false, false, false]}];

  subTable3.push(subLine3_2);
  subTable3.push(subLine3_3);
  subTable3.push(subLine3_5);
  subTable3.push(subLine3_6);

  var subTable4: any = [];
  var subLine4_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura beneficiarului', border: [false, false, false, false]}];
  var subLine4_2 = [{image: this.semnatura2, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable4.push(subLine4_1);
  subTable4.push(subLine4_2);

  var table: any = [];
  var line = [{style: 'tableExample', table: { body: subTable2, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable1, widths: ['100%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable3, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable4, widths: ['100%'], border: [true, true, true, true]}}];
  table.push(line);

  return { style: 'tableExample', table: { body: table, widths: ['38%', '12%', '38%', '12%']}}

}
//#endregion

//#region Semnatura

  @ViewChild('sigpad1') signaturePad: SignaturePad | undefined;
  @ViewChild('sigpad2') signaturePad2: SignaturePad | undefined;
  @ViewChild('sigpad3') signaturePad3: SignaturePad | undefined;
  @ViewChild('sigpad4') signaturePad4: SignaturePad | undefined;
  @ViewChild('sigpad5') signaturePad5: SignaturePad | undefined;


  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  private signaturePadOptions1: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad?.set('minWidth', 5); // set szimek/signature_pad options at runtime
  //   this.signaturePad?.clear(); // invoke functions from szimek/signature_pad API
  // }

  drawClear(){
    this.signaturePad?.clear();
  }
  drawComplete(){
    const base64 = this.signaturePad?.toDataURL('image/png');
    this.semnatura1 = base64;
  }
  drawClear1(){
    this.signaturePad2?.clear();
  }
  drawComplete1(){
    const base64 = this.signaturePad2?.toDataURL('image/png');
    this.semnatura2 = base64;
  }
  drawComplete2(){
    const base64 = this.signaturePad3?.toDataURL('image/png');
    this.semnatura3 = base64;
  }
  drawComplete3(){
    const base64 = this.signaturePad4?.toDataURL('image/png');
    this.semnatura4 = base64;
  }
  drawComplete5(){
    const base64 = this.signaturePad5?.toDataURL('image/png');
    this.semnatura5 = base64;
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }
  drawClear3(){
    this.signaturePad3?.clear();
  }
  drawClear4(){
    this.signaturePad4?.clear();
  }
  drawClear5(){
    this.signaturePad5?.clear();
  }
  //#endregion

  async pdfToBase64(pdfDocGenerator: any){
    return new Promise((resolve,reject) => {
      this.avizModel.pdfFile =  pdfDocGenerator.getBase64((data: any) => {
        resolve(data);
      });
    });
  }
  //#region Adauga aviz

  async changeUser(){
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    
    this.listaProiecte = [];
    this.sessionData.clienti.forEach((element: { nume: string; prenume: string; id: any; }) => {
      if(element.nume + " " + element.prenume == this.numeClientAles){
        this.idClientAles = element.id;
        this.client = element;
      }
    }); 
    this.sessionData.proiecte.forEach((element: any) => {
      if(element.userId == this.idClientAles){
        this.listaProiecte.push(element);
      }
    });
  }
  addProduct(){
    this.sessionData.getGestiunePersonalaById.forEach((element: any) => {
      if(element.gestiunePrincipala.codIntern == this.codProduct){
        this.productModel = {
          id: element.id,
          cod: element.gestiunePrincipala.codIntern,
          denumire: element.gestiunePrincipala.denumire,
          cantitate: element.cantitate,
        }
         this.fieldArray.push(this.productModel);
      }
    });
  }
    deleteFieldValue(index: number) {
      this.fieldArray.splice(index, 1);
    }
    async AvizResource(fAvizCategory: NgForm){
  
    this.fieldArray.forEach((element: any) => {
      this.nrCrtListaProduse = this.nrCrtListaProduse + 1;
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: this.nrCrtListaProduse}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cod}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.denumire}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cantitate}]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    });
  
    var pdf: any = {
      pageMargins: [40, 30, 40, 120],
      footer: [
        this.getSemnatura(fAvizCategory.value.NumeExp, fAvizCategory.value.SerieExp, fAvizCategory.value.NumarExp, fAvizCategory.value.NumeBen, fAvizCategory.value.SerieBen, fAvizCategory.value.NumarBen)
      ],
      content: [
        {
          columns: [
            this.getFurnizor(),
            this.getTitlu(),
            this.getCumparator(this.client)
          ],
          
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['5%', '30%', '30%', '10%'],
            body: this.externalDataRetrievedFromServer,
  
          },
          layout: 'lightHorizontalLines',
        },
        
      ],
      styles: {
        subheader: {
          fontSize: 10,
          margin: [0, 0, 0, 0],
          bold: true,
          alignment: 'center',
        },
        subheader1: {
          fontSize: 10,
          margin: [0, 0, 0, 9],
          bold: true,
          alignment: 'center',
        },
        tableExample: {
          margin: [2, 5, 2, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 8,
          color: 'black'
        },
        tableBody: {
          fontSize: 7
        },
        footer: {
          fontSize: 8,
          bold: true,
          margin: [15, 20, 15, 10]
        },
        tableBody1: {
          fontSize: 7,
          bold: true
        },
        tableBody2: {
          fontSize: 11,
          bold: true
        },
        header: {
          fontSize: 15,
          bold: true,
          alignment: 'center',
          margin: [0, 5, 0, 5]
        },
        titluDescriere: {
          fontSize: 15,
          bold: true,
        },
        textDescriere: {
          fontSize: 12,
          bold: true,
        },
        textEchipament: {
          fontSize: 9,
          bold: true,
        }
      },
    }
  
    pdfMake.createPdf(pdf).open();
  
    const pdfDocGenerator = pdfMake.createPdf(pdf);
    this.avizModel = {
      userId: this.client.id,
      proiectId: this.numeProiectAles,
      pdfFile: await this.pdfToBase64(pdfDocGenerator)
    }
     return this.http.post(this.appUrl + 'api/ClientFisier/AddAvizLivrare', this.avizModel).subscribe(
      (response) => {    
        this.message = "Asignarea a fost adaugată!"
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  
  }
  //#endregion
  


}
