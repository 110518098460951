import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiect2-sectiune-manopera',
  templateUrl: './proiect2-sectiune-manopera.component.html',
  styleUrls: ['./proiect2-sectiune-manopera.component.css']
})
export class Proiect2SectiuneManoperaComponent implements OnInit {

  //#region Declarari
  projectOption: any = "Toate";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id : '',
    denumireServiciu : '',
    numarOre : '',
    pretPeOra : '',
    pretTotal : '',
    orePontate : '',
    diferentaOre : '',
    proiect2ContractSectiuneId : ''
  }

  addProiectModel: any ={
    denumireServiciu : '',
    numarOre : '',
    pretPeOra : '',
    pretTotal : '',
    orePontate : '',
    diferentaOre : '',
    proiect2ContractSectiuneId : ''
  }

  editProiectModel: any = {
    id : '',
    denumireServiciu : '',
    numarOre : '',
    pretPeOra : '',
    pretTotal : '',
    orePontate : '',
    diferentaOre : '',
    proiect2ContractSectiuneId : ''
  }
  
  fileName= 'ExcelSheet.xlsx';
  userName : any;
//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    sessionData.sectiuneId = localStorage.getItem('SectiuneId');
  }
  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "MANOPERA - " + localStorage.getItem('SectiuneName');
    
    const proiecte = this.authService.getProiecte2ContracteSectiuniManopera(this.sessionData.sectiuneId);
    await lastValueFrom(proiecte);
  }

  ngOnDestroy() {
    localStorage.removeItem('SectiuneId');
    localStorage.removeItem('SectiuneName');
  }

  //#region Editare proiect

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectModel = {
      id : gp.id,
      denumireServiciu : gp.denumireServiciu,
      numarOre : gp.numarOre,
      pretPeOra : gp.pretPeOra,
      pretTotal : gp.pretTotal,
      orePontate : gp.orePontate,
      diferentaOre : gp.diferentaOre,
      proiect2ContractSectiuneId : gp.proiect2ContractSectiuneId
    }
  }

  EditResource(fEditCategory: NgForm){

    this.editProiectModel = {
      id: this.proiectModel.id,
      proiect2ContractSectiuneId: this.sessionData.sectiuneId,
      denumireServiciu : fEditCategory.value.DenumireServiciu,
      numarOre : fEditCategory.value.NumarOre,
      pretPeOra : fEditCategory.value.PretPeOra,
      pretTotal : fEditCategory.value.PretTotal,
      orePontate : fEditCategory.value.OrePontate,
      diferentaOre : fEditCategory.value.DiferentaOre,
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/Proiect2/updateProiect2ContractSectiuneManopera/' + this.editProiectModel.id, this.editProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

  //#region Adauga Contract
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.addProiectModel ={
    proiect2ContractSectiuneId: this.sessionData.sectiuneId,
    denumireServiciu : fAddCategory.value.DenumireServiciu,
    numarOre : fAddCategory.value.NumarOre,
    pretPeOra : fAddCategory.value.PretPeOra,
    pretTotal : fAddCategory.value.PretTotal,
    orePontate : fAddCategory.value.OrePontate,
    diferentaOre : fAddCategory.value.DiferentaOre,
  }
  
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }

  return this.http.post(this.appUrl + 'api/proiect2/addProiect2ContractSectiuneManopera', this.addProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

  //#region Stergere Proiect
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/Proiect2/deleteProiect2ContractSectiuneManopera/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
}}
}
