import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rezervare-proiecte2',
  templateUrl: './rezervare-proiecte2.component.html',
  styleUrls: ['./rezervare-proiecte2.component.css']
})
export class RezervareProiecte2Component implements OnInit {

  //#region Declarari
  projectOption : any = "Caută după Proiect";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  gRezervareProiectModel: any = {
    id: '',
    cantitate: '',
    data: '',
    codIntern: '',
    denumireProdus: '',
    gestiunePrincipalaId: '',
    proiectId: ''
  }
  returGPrincipalaModel: any = {
    gestiunePrincipalaId: '',
    gestiuneRezervareId: '',
    numeUser: '',
    cantitate: ''
  };
  addGTransferModel: any = {
    gestiuneRezervareProiectId: '',
    gestiunePrincipalaId: '',
    cantitate: '',
    userId: '',
    numeUser: ''
  };
  excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
  fileName= 'ExcelSheet.xlsx';
  isChecked: any = false;
  proiectId: any;
  buffer: any;
  userName : any;
  gPrincipalaModel: any = {
    id: '',
    codIntern: '',
    codExtern: '',
    denumire: '',
    producator: '',
    cantitate: '',
    cantitateReferinta: '',
    locatie: '',
    data: '',
    numeUser: '',
    critic: '',
    images: '',
    image: '',
    numarFacturaAchizitie: ''
  }
  addGVanzareModel: any = {
    id: '',
    gestiunePrincipalaId: '',
    cantitate: '',
    numeUser: '',
    mentiuni: ''
  };
  buttonDisable: boolean = false;
//#endregion

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  async ngOnInit(): Promise<void> {
    this.buttonDisable = false;
    this.sessionData.current_page_title = "GESTIUNE REZERVARE PROIECT";
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiuneRezervareProiect2/getGestiuneRezervarePeProiect', header).subscribe((data) => {
      this.sessionData.gestiuneRezervareProiect2 = data;
    });
  }

    //functie pentru fisierul incarcat
    onFS(event: any){
      if(<File>event.target.files[0] != null){
        this.selectedfile = <File>event.target.files[0];
      }
    }
  
  
  //#region Retur Produs
  returModal(content: any, gp: any) {
    this.modalService.open(content);
    this.gRezervareProiectModel.id = gp.id;
    this.gRezervareProiectModel.cantitate = gp.cantitate;
    this.gRezervareProiectModel.data = gp.data;
    this.gRezervareProiectModel.gestiunePrincipalaId = gp.gestiunePrincipalaId;
    this.gRezervareProiectModel.proiectId = gp.proiectId;
    this.gRezervareProiectModel.codIntern = gp.codIntern;
    this.gRezervareProiectModel.denumireProdus = gp.denumireProdus;
  }
  DeleteResource(fReturProdus: any){
    this.buttonDisable = true;
  
      if(fReturProdus.value.gestiunePrincipala == true && fReturProdus.value.rezervareProiect == true){
        this.message = "Alege o singura optiune!"
      }
      this.returGPrincipalaModel = {
        gestiunePrincipalaId: this.gRezervareProiectModel.gestiunePrincipalaId,
        gestiuneRezervareId: this.gRezervareProiectModel.id,
        numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume,
        cantitate: this.gRezervareProiectModel.cantitate
      }
  
      if(fReturProdus.value.gestiunePrincipala == true){
        var header = {
          headers: new HttpHeaders()
            .set('Authorization',  `Bearer ${this.authService.getToken()}`)
        }
        this.http.post(this.appUrl + 'api/GestiuneRezervareProiect2/deleteToGestiunePrincipala/' + this.gRezervareProiectModel.id,  this.returGPrincipalaModel, header).subscribe(
          (response) => {    
            window.location.reload();
          },
          (error) => { 
            console.error('error caught in component')
            this.message = "Eroare"
          }
        );
      }
      else if(fReturProdus.value.rezervareProiect == true){
        var header = {
          headers: new HttpHeaders()
            .set('Authorization',  `Bearer ${this.authService.getToken()}`)
        }
        this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/deleteToAprovizionareRezervari/',  this.returGPrincipalaModel, header).subscribe(
          (response) => {    
            window.location.reload();
          },
          (error) => { 
            console.error('error caught in component')
            this.message = "Eroare"
          }
        );
      }
      
  }
  //#endregion
  
  //#region Transfera in Gestiune Transfer
  
  async addToGTransferModal(content: any, gp: any) {
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    
    this.modalService.open(content);
    this.gRezervareProiectModel.id = gp.id;
    this.gRezervareProiectModel.cantitate = gp.cantitate;
    this.gRezervareProiectModel.data = gp.data;
    this.gRezervareProiectModel.gestiunePrincipalaId = gp.gestiunePrincipalaId;
    this.gRezervareProiectModel.proiectId = gp.proiectId;
    this.gRezervareProiectModel.codIntern = gp.codIntern;
    this.gRezervareProiectModel.denumireProdus = gp.denumireProdus;
  }
  
  AddGTransferResource(fAddGTransfer: NgForm){
    this.buttonDisable = true;
  
    if(fAddGTransfer.value.Cantitate < 0){
      this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
      return;
    }
    else if(fAddGTransfer.value.Cantitate > this.gRezervareProiectModel.cantitate){
      this.message = "Valoarea introdusa este prea mare";
      return;
    }
    else{
      this.addGTransferModel = {
        gestiuneRezervareProiectId: this.gRezervareProiectModel.id,
        gestiunePrincipalaId: this.gRezervareProiectModel.gestiunePrincipalaId,
        cantitate: fAddGTransfer.value.Cantitate,
        userId: fAddGTransfer.value.UserName,
        numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume,
        proiectId: fAddGTransfer.value.Proiect
      }
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.post(this.appUrl + 'api/GestiuneRezervareProiect2/addGestiuneTransfer', this.addGTransferModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
  }
  
  
  }
  
  //#endregion
  
  //#region Transfera in Gestiune Vanzari
  
  addToGVanzareModal(content: any, gp: any) {
    this.modalService.open(content);
    this.gPrincipalaModel.id = gp.id;
    this.gPrincipalaModel.denumire = gp.denumire;
    this.gPrincipalaModel.images = gp.images;
    this.gPrincipalaModel.image = gp.image;
    this.gPrincipalaModel.codIntern = gp.codIntern;
    this.gPrincipalaModel.codExtern = gp.codExtern;
    this.gPrincipalaModel.producator = gp.producator;
    this.gPrincipalaModel.cantitate = gp.cantitate;
    this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
    this.gPrincipalaModel.locatie = gp.locatie;
    this.gPrincipalaModel.critic = gp.critic;
    this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
    this.gPrincipalaModel.gPrincipalaId = gp.gestiunePrincipalaId;
    console.log(gp);
  }
  
  AddGVanzareResource(fAddGTransfer: NgForm){
    this.buttonDisable = true;
  
    if(fAddGTransfer.value.Cantitate < 0){
      this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
      return;
    }
    else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
      this.message = "Valoarea introdusa este prea mare";
      return;
    }
    else{
  
      this.sessionData.users.forEach((element: { id: any; nume: any; prenume: any; }) => {
        if(element.id == fAddGTransfer.value.UserName){
          this.userName = element.nume + " " + element.prenume;
        }
      });
  
      this.addGVanzareModel = {
        id: this.gPrincipalaModel.id,
        gestiunePrincipalaId: this.gPrincipalaModel.gPrincipalaId,
        cantitate: fAddGTransfer.value.Cantitate,
        numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume,
        mentiuni: fAddGTransfer.value.Mentiuni,
      }
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
    return this.http.post(this.appUrl + 'api/GestiuneRezervareProiect2/addGestiuneVanzare', this.addGVanzareModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
    }
  
  
  }
  
  //#endregion
  
  
  
  //generare excel
  exportExcel() : void {
  
    let url = this.appUrl + 'api/GestiunePrincipala/RaportGestiunePrincipala';
    window.open(url);
  }
  
  //enable proiecte
  checkValue(event: any){
  }
  
  selectChangeHandler (event: any) {
    //update the ui
    this.projectOption = event.target.value;
    let list: any = [];
    this.sessionData.gestiuneRezervareProiect.forEach((element: any) => {
      if(element.denumireProiect == this.projectOption){
        list.push(element);
      }
    });
  
    this.sessionData.gestiuneRezervareProiect = list;
  
    if(this.projectOption == ''){
      this.projectOption = "Caută după Proiect";
      this.ngOnInit();
    }
  }
  
  
  //#region excel proiect
  exportExcelProiect(content: any) : void {
    this.modalService.open(content);
  
  }
  
  ExportExcelProiectResource(fexportExcelProiect: any){
    if(fexportExcelProiect.value.gestiunePrincipala == true && fexportExcelProiect.value.rezervareProiect == true){
      this.message = "Alege o singura optiune!"
    }
  
    else if(fexportExcelProiect.value.gestiunePrincipala == true){
      this.excelProiectModel = {
        proiectId: 0,
        dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
        dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
      }
  
    }
    else if(fexportExcelProiect.value.rezervareProiect == true){
  
      this.sessionData.proiecte.forEach((element: any) => {
        if(element.denumireProiect == fexportExcelProiect.value.Proiect){
          this.proiectId = element.id;
        }
      });
      this.excelProiectModel = {
        proiectId: this.proiectId,
        dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
        dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
      }
    }
    this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/raportGestiuneRezervareProiectDupaProiect', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream'});
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });
  
  }
  //#endregion
  
  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  
  adjustDateForTimeOffset(dateToAdjust: any) {
    var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
    return new Date(dateToAdjust.getTime() - offsetMs);
    }

}
