import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-useri-telefon',
  templateUrl: './useri-telefon.component.html',
  styleUrls: ['./useri-telefon.component.css']
})
export class UseriTelefonComponent implements OnInit {

  //#region Declarari
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  userName : any;
  useriTelefon: any;
  addTelefon: any = {
    numarTelefon: '',
    userId: '',
    numeUser: ''
  }
  telefonId: any;
//#endregion

constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  config.backdrop = 'static';
  config.keyboard = false;
}
ngOnInit(): void {
  this.sessionData.current_page_title = "USERI TELEFON"
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.get<any>(this.appUrl + 'api/UserTelefon', header).subscribe((data) => {
    this.useriTelefon = data;
    console.log(data);
  });
}

//#region Stergere telefon
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.telefonId = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/UserTelefon/' + this.telefonId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Adauga telefon
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.addTelefon = {
    numarTelefon: fAddCategory.value.Telefon,
    userId: fAddCategory.value.UserName,
    numeUser: 'Nume'
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/userTelefon/', this.addTelefon, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
