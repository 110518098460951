import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-tabloterie',
  templateUrl: './gestiune-tabloterie.component.html',
  styleUrls: ['./gestiune-tabloterie.component.css']
})
export class GestiuneTabloterieComponent implements OnInit {
  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  p: number = 1;

  gTabloterie: any = {
    denumire: '',
    codIntern: '',
    gestiunePrincipalaId: '',
    gestiunePersonalaId: '',
    userId: '',
    numeUser: '',
    cantitate: ''
  }

  gtabloterieDelete: any = {
    gestiunePrincipalaId: '',
    gestiunePersonalaId: '',
    userId: '',
    numeUser: '',
    cantitate: ''
  }

  excelProiectModel: any = {
    dataInceput: '',
    dataSfarsit: ''
  }
  //#endregion
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "GESTIUNE TABLOTERIE";
    const gestiuneTabloterie = this.authService.getTabloterieGestiuneTabloterie();
    await lastValueFrom(gestiuneTabloterie);
  }

//#region Retur Produs
deleteModal(content: any, gp: any) {
  this.modalService.open(content);
  console.log(gp);
  this.gTabloterie = {
    denumire: gp.gestiunePrincipala.denumire,
    codIntern: gp.gestiunePrincipala.codIntern,
    gestiunePrincipalaId: gp.gestiunePrincipala.id,
    gestiunePersonalaId: gp.id,
    userId: gp.user.id,
    numeUser: gp.user.nume + " " + gp.user.prenume,
    cantitate: gp.cantitate,
    proiectId: gp.proiectId
  }
}
DeleteResource(fReturProdus: any){

  this.gtabloterieDelete = {
    gestiunePrincipalaId: this.gTabloterie.gestiunePrincipalaId,
    gestiunePersonalaId: this.gTabloterie.gestiunePersonalaId,
    userId: this.gTabloterie.userId,
    numeUser: this.gTabloterie.numeUser,
    cantitate: fReturProdus.value.Cantitate,
    proiectId: this.gTabloterie.proiectId
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
    this.http.post(this.appUrl + 'api/TabloterieGestiuneTabloterie/deleteToGestiuneTransfer/',  this.gtabloterieDelete, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );



}
//#endregion

//#region excel proiect
exportExcelProiect(content: any) : void {
  this.modalService.open(content);
}

ExportExcelProiectResource(fexportExcelProiect: any){

    this.excelProiectModel = {
      dataInceput: fexportExcelProiect.value.DataInceput,
      dataSfarsit: fexportExcelProiect.value.DataSfarsit
    }
  
    this.http.post(this.appUrl + 'api/TabloterieGestiuneTabloterie/raport', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream'});
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });

}
//#endregion

  Search(){
    if(this.denumire != ""){
      this.sessionData.gestiuneTabloterie = this.sessionData.gestiuneTabloterie.filter((res: any) => {
        return res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
      });
    }else if(this.denumire == ""){
      this.ngOnInit();
    }
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
}
