import { OfertareService } from './../../../services/ofertare.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { NgForm } from '@angular/forms';


export interface PeriodicElement {
  position: number;
  cod: string;
  denumire: string;
  cantitate: string;
  pretUnitarAchizitie: string;
  adaos: string;
  pretUnitarVanzare: string;
  pretTotal: string;
  accesoriiUzinare: string;
  accesoriiMontajCamp: string;
  manopera: string;
  gabarit: string;
  gabaritTot: string;
}

@Component({
  selector: 'app-ofertare-creare-oferta2',
  templateUrl: './ofertare-creare-oferta2.component.html',
  styleUrls: ['./ofertare-creare-oferta2.component.css']
})
export class OfertareCreareOferta2Component implements OnInit {

  @ViewChild('table') table: MatTable<PeriodicElement> | any;
  displayedColumns: string[] = ['position', 'cod', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'adaos', 'pretUnitarVanzare','pretTotal','accesoriiUzinare','accesoriiMontajCamp','manopera', 'delete'];
  displayedColumnsTablou: string[] = ['position', 'cod', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'adaos', 'pretUnitarVanzare','pretTotal','accesoriiUzinare','accesoriiMontajCamp','manopera', 'gabarit', 'gabaritTotal', 'delete'];
  displayedColumnsAccesorii: string[] = ['position', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'adaos', 'pretUnitarVanzare', 'pretTotal', 'pretFinal'];
  displayedColumnsServicii: string[] = ['cod', 'denumire', 'cantitate', 'pret', 'pretTotal', 'delete'];
  displayedColumnsAccesoriiUzinare: string[] = ['position', 'cod', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'adaos', 'pretUnitarVanzare', 'pretTotal', 'pretFinal', 'gabarit', 'gabaritTotal'];
  displayedColumnsAccesoriiMontajTablou: string[] = ['position', 'denumire', 'cantitate', 'pretUnitarAchizitie', 'adaos', 'pretUnitarVanzare', 'pretTotal', 'pretFinal'];

  //dataSource: any = [];
  tablesProduse: any = [];
  tablesTablouri: any = [];
  
  //#region Declarari
  modelOferta: any = {
    Numar: '',
    Beneficiar: '',
    DenumireOferta: '',
    UserId: '',
    DenumireProiect: '',
    Status: '',
    Zona: '',
    SectiuneProduse: '',
    SectiuneTablouri: '',
    Servicii: '',
    ServiciiIndirecte: '',
    ServiciiPrincipale: '',
    ServiciiSecundare: '',
    TotalGeneral: '',
    DetaliiSuplimentare: '',
    DescrieriOferta: '',
    EchipamenteOferta: '',
    VizualizariOferta: ''
  };
  details: any = '';
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  codProduct: string | undefined;
  getProdusByCodModel: any = {
    cod: ''
  }
  productModel: any = {
    id: '',
    position: '',
    producator: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    adaos: '',
    pretUnitarVanzare: '',
    pretTotal: '',
    accesoriiUzinare: '',
    accesoriiMontajCamp: '',
    manopera: '',
    gabarit: '',
    gabaritTot: ''
  }
  denumire: any;
  produseOfertare: any;
  searchModel: any = {
    cuvant: ''
  };
  totalEchipamente: any = 0;
  totalAccesoriiMontaj: any = 0;
  totalManoperaMontaj: any = 0;
  currentTable: any;
  tableType: any;
  totalAccesoriiUzinareTabel: any = 0;
  totalOreManoperaMontaj: any = 0;
  totalEchipamenteSiProgramare: any = 0;
  totalServicii: any = 0;
  totalServiciiIndirecte: any = 0;
  totalServiciiPrincipale: any = 0;
  totalServiciiSecundare: any = 0;
  totalGeneral: any = 0;
  pretServiciuUzinareTablou: any;
  pretServiciuMontajEchipamente: any;
  pretServiciuProgramareSisteme: any;
  modOferta: string = "intern";
  serviciiPrincipaleTable: any = [];
  serviciiSecundareTable: any = [];
  serviciiTable: any = [];
  serviciiIndirecteTable: any = [];
  listaIdServiciiPrincipale: any = [];
  numar: any;
  beneficiar: any;
  denumireOferta: any;
  numeProiect: any;
  zona: any;
  status: any;
  ofertareGestiuneDescriereAdd: any = {
    id: '',
    image: '',
    nume: '',
    descriere: ''
  }
  ofertareGestiuneDescriereAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    descriere: '',
    imageBase64: ''
  }
  ofertareGestiuneDescriereAddPdf: any = [];

  ofertareGestiuneEchipamentAdd: any = {
    id: '',
    image: '',
    nume: '',
    descriere: ''
  }
  ofertareGestiuneEchipamentAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    descriere: '',
    imageBase64: ''
  }
  ofertareGestiuneEchipamentAddPdf: any = [];
  ofertareGestiuneVizualizareAdd: any = {
    id: '',
    image: '',
    nume: '',
  }
  ofertareGestiuneVizualizareAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    imageBase64: ''
  }
  ofertareGestiuneVizualizareAddPdf: any = [];
  descrieriFieldArray: any = [];
  echipamenteFieldArray: any = [];
  vizualizariFieldArray: any = [];
  nameDescription: string | undefined;
  nameEchipament: string | undefined;
  nameVizualizare: string | undefined;
  pretServiciuModel: any = {
    id: '',
    zona: ''
  }
  //#endregion
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService ,public authService: AuthService, public ofertareService: OfertareService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "OFERTARE 2";
    this.numar = localStorage.getItem('NumarOferta');
    this.beneficiar = localStorage.getItem('NumeClient');
    this.denumireOferta = localStorage.getItem('DenumireOferta');
    this.numeProiect = localStorage.getItem('NumeProiect');
    this.zona = localStorage.getItem('Zona');
    const ofertareServicii = this.ofertareService.getOfertareServicii(this.zona);
    await lastValueFrom(ofertareServicii);

    const ofertareServiciiIndirecte = this.ofertareService.getOfertareServiciiIndirecte(this.zona);
    await lastValueFrom(ofertareServiciiIndirecte);

    this.serviciiTable = this.sessionData.serviciiTable;
    this.serviciiIndirecteTable = this.sessionData.serviciiIndirecteTable;

    this.totalServiciiIndirecte = 0;
    this.serviciiIndirecteTable.forEach((element: any) => {
      this.totalServiciiIndirecte = parseFloat(this.totalServiciiIndirecte) + parseFloat(element.pretTotal);
    });

    this.serviciiTable.forEach((element: any) => {
      this.totalServicii = (parseFloat(this.totalServicii) + parseFloat(element.pretTotal)).toFixed(1);
      if(element.denumire == "Uzinare"){
        this.pretServiciuUzinareTablou = element.pret;
      }
      else if(element.denumire == "Montaj"){
        this.pretServiciuMontajEchipamente = element.pret;
      }
    });
    this.serviciiTable.splice(1,1)
    this.pretServiciuProgramareSisteme = 0;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getInventarOfertare', header).subscribe((data) => {
      this.sessionData.inventarOfertare = data;
    });
  }

  dropTable(event: CdkDragDrop<string[]>, index: any) {
    const prevIndex = this.tablesProduse[index].dataSource.findIndex((d: any) => d === event.item.data);
    moveItemInArray(this.tablesProduse[index].dataSource, prevIndex, event.currentIndex);
    this.tablesProduse[index].dataSource = [...this.tablesProduse[index].dataSource];
  }
  dropTableTablouri(event: CdkDragDrop<string[]>, index: any) {
    const prevIndex = this.tablesTablouri[index].dataSource.findIndex((d: any) => d === event.item.data);
    moveItemInArray(this.tablesTablouri[index].dataSource, prevIndex, event.currentIndex);
    this.tablesTablouri[index].dataSource = [...this.tablesTablouri[index].dataSource];
  }

  addProduct(currentTable: any, tableType: any){
    this.getProdusByCodModel.cod = this.codProduct;
    this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProdusByCod', this.getProdusByCodModel).subscribe((element: any) => {
      this.productModel = {
        id: element.id,
        position: 11,
        producator: element.producator,
        cod: element.cod,
        denumire: element.denumire,
        cantitate: element.cantitate,
        pretUnitarAchizitie: element.pretUnitarAchizitie,
        adaos: 35,
        pretUnitarVanzare: (parseFloat(element.pretUnitarAchizitie) * 0.35 + parseFloat(element.pretUnitarAchizitie)).toFixed(1),
        pretTotal: (parseFloat(element.cantitate) * parseFloat(element.pretUnitarVanzare)).toFixed(1),
        accesoriiUzinare: '0',
        accesoriiMontajCamp: '0',
        manopera: '0',
        gabarit: '0',
        gabaritTot: '0',
      }

      if(tableType == 'tablesProduse'){
        this.tablesProduse[this.currentTable].dataSource.push(this.productModel);
        this.tablesProduse[this.currentTable].totalEchipamente = (parseFloat(this.tablesProduse[this.currentTable].totalEchipamente) + parseFloat(this.productModel.pretTotal)).toFixed(1);
        this.tablesProduse[this.currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesProduse[this.currentTable].totalAccesoriiMontaj) + (parseFloat(this.productModel.cantitate) * parseFloat(this.productModel.accesoriiMontajCamp))).toFixed(1);
        this.tablesProduse[this.currentTable].dataSource = [...this.tablesProduse[this.currentTable].dataSource];
        this.tablesProduse[this.currentTable].totalSectiune = (parseFloat(this.tablesProduse[this.currentTable].totalEchipamente) + parseFloat(this.tablesProduse[this.currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[this.currentTable].totalOreMontaj)).toFixed(1);
      }
      else if(tableType == 'tablesTablouri'){
        this.tablesTablouri[this.currentTable].dataSource.push(this.productModel);
        this.tablesTablouri[this.currentTable].totalEchipamente = (parseFloat(this.tablesTablouri[this.currentTable].totalEchipamente) + parseFloat(this.productModel.pretTotal)).toFixed(1);
        this.tablesTablouri[this.currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiMontaj) + (parseFloat(this.productModel.cantitate) * parseFloat(this.productModel.accesoriiMontajCamp)));
        this.tablesTablouri[this.currentTable].dataSource = [...this.tablesTablouri[this.currentTable].dataSource];
        this.tablesTablouri[this.currentTable].totalTablou = (parseFloat(this.tablesTablouri[this.currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiMontajTabel)).toFixed(1);
      }
    });
  }

  addProductFromSearch(item: any){
    let pretUnitarVanzare = (parseFloat(item.pretUnitarAchizitie) * 0.35 + parseFloat(item.pretUnitarAchizitie)).toFixed(1);
    this.productModel = {
      id: item.id,
      position: 1,
      producator: item.producator,
      cod: item.cod,
      denumire: item.denumire,
      cantitate: item.cantitate,
      pretUnitarAchizitie: item.pretUnitarAchizitie,
      adaos: 35,
      pretUnitarVanzare: pretUnitarVanzare,
      pretTotal: (parseFloat(item.cantitate) * parseFloat(pretUnitarVanzare)).toFixed(1),
      accesoriiUzinare: '0',
      accesoriiMontajCamp: '0',
      manopera: '0',
      gabarit: '0',
      gabaritTotal: '0',
    }

    if(this.tableType == 'tablesProduse'){
      this.tablesProduse[this.currentTable].dataSource.push(this.productModel);
      this.tablesProduse[this.currentTable].totalEchipamente = (parseFloat(this.tablesProduse[this.currentTable].totalEchipamente) + parseFloat(this.productModel.pretTotal)).toFixed(1);
      this.tablesProduse[this.currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesProduse[this.currentTable].totalAccesoriiMontaj) + (parseFloat(this.productModel.cantitate) * parseFloat(this.productModel.accesoriiMontajCamp))).toFixed(1);
      this.tablesProduse[this.currentTable].dataSource = [...this.tablesProduse[this.currentTable].dataSource];
      this.tablesProduse[this.currentTable].totalSectiune = (parseFloat(this.tablesProduse[this.currentTable].totalEchipamente) + parseFloat(this.tablesProduse[this.currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[this.currentTable].totalOreMontaj)).toFixed(1);
    }
    else if(this.tableType == 'tablesTablouri'){
      this.tablesTablouri[this.currentTable].dataSource.push(this.productModel);
      this.tablesTablouri[this.currentTable].totalEchipamente = (parseFloat(this.tablesTablouri[this.currentTable].totalEchipamente) + parseFloat(this.productModel.pretTotal)).toFixed(1);
      this.tablesTablouri[this.currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiMontaj) + (parseFloat(this.productModel.cantitate) * parseFloat(this.productModel.accesoriiMontajCamp)));
      this.tablesTablouri[this.currentTable].dataSource = [...this.tablesTablouri[this.currentTable].dataSource];
      this.tablesTablouri[this.currentTable].totalTablou = (parseFloat(this.tablesTablouri[this.currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[this.currentTable].totalAccesoriiMontajTabel)).toFixed(1);
    }
    this.modalService.dismissAll();

  }

  //#region cauta produs
addModal(content: any, currentTable: any, tableType: any) {
  this.modalService.open(content);
  this.currentTable = currentTable;
  this.tableType = tableType;
}

AddResource(fAddCategory: NgForm){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.searchModel.cuvant = this.denumire;

  this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProduseBySearch', this.searchModel, header).subscribe((data) => {
    this.produseOfertare = data;
  });
}
//#endregion

  deleteProdus(element: any, currentTable: any){
    let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
    this.tablesProduse[currentTable].dataSource.splice(index,1)
    this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
    this.tablesProduse[currentTable].totalEchipamente = 0;
    this.tablesProduse[currentTable].totalAccesoriiMontaj = 0;
    this.tablesProduse[currentTable].totalManoperaMontaj = 0;
    let accesoriiMontajFlag = false;
    this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
      this.tablesProduse[currentTable].totalEchipamente = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
      this.tablesProduse[currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp)));
      this.tablesProduse[currentTable].totalManoperaMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.manopera))).toFixed(1);
      if(element.accesoriiMontajCamp != "0"){
        accesoriiMontajFlag = true;
      }
    });
    if(accesoriiMontajFlag == false){
      this.tablesProduse[currentTable].accesoriiMontaj = ""; 
    }
    this.tablesTablouri[currentTable].totalOreMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) * parseFloat(this.pretServiciuMontajEchipamente)).toFixed(1);
    this.tablesProduse[currentTable].totalSectiune = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[currentTable].totalOreMontaj)).toFixed(1);
  }

  async editProdus(element: any, tip: string, currentTable: any){
    if(tip == 'cantitate'){
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesProduse[currentTable].dataSource[index] = element;
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      this.tablesProduse[currentTable].totalEchipamente = 0;
      this.tablesProduse[currentTable].totalAccesoriiMontaj = 0;
      this.tablesProduse[currentTable].totalManoperaMontaj = 0;
      this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
        this.tablesProduse[currentTable].totalEchipamente = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
        this.tablesProduse[currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp))).toFixed(1);
        this.tablesProduse[currentTable].totalManoperaMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp) * parseFloat(element.manopera))).toFixed(1);
      });
      this.tablesProduse[currentTable].totalOreMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) * parseFloat(this.pretServiciuMontajEchipamente)).toFixed(1);
      this.tablesProduse[this.currentTable].totalSectiune = (parseFloat(this.tablesProduse[this.currentTable].totalEchipamente) + parseFloat(this.tablesProduse[this.currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[this.currentTable].totalOreMontaj)).toFixed(1);
    
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      //let indexServ2: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Uzinare");
      this.serviciiTable[indexServ1].cantitate = 0;
     // this.serviciiTable[indexServ2].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];
      

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
          //this.serviciiTable[indexServ2].cantitate = parseFloat(this.serviciiTable[indexServ2].cantitate) + parseFloat(element.totalManoperaUzinareTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
      this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });
    }
    else if(tip == 'pretUnitarAchizitie'){
      element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100))).toFixed(1);
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesProduse[currentTable].dataSource[index] = element;
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      this.tablesProduse[currentTable].totalEchipamente = 0;
      this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
        this.tablesProduse[currentTable].totalEchipamente = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
      });
      this.tablesProduse[currentTable].totalSectiune = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[currentTable].totalOreMontaj)).toFixed(1);
    }
    else if(tip == 'adaos'){
      element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100))).toFixed(1);
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesProduse[currentTable].dataSource[index] = element;
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      this.tablesProduse[currentTable].totalEchipamente = 0;
      this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
        this.tablesProduse[currentTable].totalEchipamente = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
      });
      this.tablesProduse[currentTable].totalSectiune = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[currentTable].totalOreMontaj)).toFixed(1);
    }
    else if(tip == 'accesoriiMontajCamp'){

      const gestiunePrincipala = this.ofertareService.getOfertareAccesoriiMontaj();
      await lastValueFrom(gestiunePrincipala);
      let i = 1;
      let totalAccesoriiMontajTabel = 0;

      let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesProduse[currentTable].dataSource[index] = element;
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      this.tablesProduse[currentTable].totalAccesoriiMontaj = 0;
      this.tablesProduse[currentTable].totalManoperaMontaj = 0;
      this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
        this.tablesProduse[currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp))).toFixed(1);
        this.tablesProduse[currentTable].totalManoperaMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp) * parseFloat(element.manopera))).toFixed(1);
      });
      
      this.sessionData.ofertareAccesoriiMontaj.forEach((element: any) => {
        element.position = i;
        element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + parseFloat(element.pretUnitarAchizitie) * parseFloat(element.adaos) / 100).toFixed(1);
        element.pretTotal = (element.pretUnitarVanzare * element.cantitate).toFixed(1);
        element.pretFinal = (parseFloat(element.pretTotal) * parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontaj)).toFixed(1);
        totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1)) + parseFloat(element.pretFinal);

        i = i + 1;
      });
    
      this.tablesProduse[currentTable].accesoriiMontaj = this.sessionData.ofertareAccesoriiMontaj;
      this.tablesProduse[currentTable].totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1));
      this.tablesProduse[currentTable].totalSectiune = (parseFloat(this.tablesProduse[currentTable].totalEchipamente) + parseFloat(this.tablesProduse[currentTable].totalAccesoriiMontajTabel) + parseFloat(this.tablesProduse[currentTable].totalOreMontaj)).toFixed(1);
      
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      // let indexServ2: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Uzinare");
      this.serviciiTable[indexServ1].cantitate = 0;
      // this.serviciiTable[indexServ2].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
          // this.serviciiTable[indexServ2].cantitate = parseFloat(this.serviciiTable[indexServ2].cantitate) + parseFloat(element.totalManoperaUzinareTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        console.log("ok1");
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
      this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });

      //Verificare zero accesorii montaj
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      let isZero = true;
      for(var t = 0; t < this.tablesProduse[currentTable].dataSource.length; t++) {
        if(parseFloat(this.tablesProduse[currentTable].dataSource[t].accesoriiMontajCamp) > 0) {
          isZero = false;
          break;
        }
      }
      if(isZero == true){
        this.tablesProduse[currentTable].accesoriiMontaj = [];
      }
    }
    else if(tip == 'manopera'){
      let index: number = this.tablesProduse[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesProduse[currentTable].dataSource[index] = element;
      this.tablesProduse[currentTable].dataSource = [...this.tablesProduse[currentTable].dataSource];
      this.tablesProduse[currentTable].totalManoperaMontaj = 0;
      this.tablesProduse[currentTable].dataSource.forEach((element: any) => {
        this.tablesProduse[currentTable].totalManoperaMontaj = (parseFloat(this.tablesProduse[currentTable].totalManoperaMontaj) + (parseFloat(element.cantitate) * parseFloat(element.accesoriiMontajCamp) * parseFloat(element.manopera))).toFixed(1);
      });
      
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      //let indexServ2: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Uzinare");
      this.serviciiTable[indexServ1].cantitate = 0;
      //this.serviciiTable[indexServ2].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
          //this.serviciiTable[indexServ2].cantitate = parseFloat(this.serviciiTable[indexServ2].cantitate) + parseFloat(element.totalManoperaUzinareTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
      this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });
    }
  }

  async editProdusTablou(element: any, tip: string, currentTable: any){
    if(tip == 'cantitate'){
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      element.gabaritTotal = (parseFloat(element.gabarit) * parseFloat(element.cantitate));
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].totalEchipamente = 0;
      this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = 0;
      this.tablesTablouri[currentTable].totalAccesoriiUzinare = 0;
      this.tablesTablouri[currentTable].totalAccesoriiMontaj = 0;
      this.tablesTablouri[currentTable].totalManoperaUzinareTablou = 0;
      this.tablesTablouri[currentTable].totalOreUnitatiFunctionale = 0;
      this.tablesTablouri[currentTable].totalManoperaMontajTablou = 0;
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalEchipamente = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
        this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = (parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(element.gabaritTotal));
        this.tablesTablouri[currentTable].totalAccesoriiUzinare = (parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinare) + (parseFloat(element.accesoriiUzinare) * parseFloat(element.cantitate)));
        this.tablesTablouri[currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontaj) + (parseFloat(element.accesoriiMontajCamp) * parseFloat(element.cantitate)));
        this.tablesTablouri[currentTable].totalManoperaUzinareTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) + (parseFloat(element.accesoriiUzinare) * parseFloat(element.cantitate) * parseFloat(element.manopera))).toFixed(1);
        this.tablesTablouri[currentTable].totalManoperaMontajTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) + (parseFloat(element.accesoriiMontajCamp) * parseFloat(element.cantitate)) * parseFloat(element.manopera)).toFixed(1);
      });
      this.tablesTablouri[currentTable].totalOreUnitatiFunctionale = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) * parseFloat(this.pretServiciuUzinareTablou)).toFixed(1);
      this.tablesTablouri[currentTable].totalOreMontaj = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) * parseFloat(this.pretServiciuMontajEchipamente)).toFixed(1);
      this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive;

      let totalAccesoriiUzinareTabel = 0;
      let totalAccesoriiMontajTabel = 0;

      if(this.tablesTablouri[currentTable].accesoriiUzinare.length > 0){
        this.tablesTablouri[currentTable].accesoriiUzinare.forEach((element: any) => {
          element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + parseFloat(element.pretUnitarAchizitie) * parseFloat(element.adaos) / 100).toFixed(1);
          element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
          element.pretFinal = (parseFloat(element.pretTotal) * parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinare)).toFixed(1);
          element.gabaritTotal = (parseFloat(element.gabarit) * this.tablesTablouri[currentTable].totalAccesoriiUzinare);
          this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = this.tablesTablouri[currentTable].gabaritTotalEchipareTablou + parseFloat(element.gabaritTotal);
          this.tablesTablouri[currentTable].gabaritUnitatiFunctionale = this.tablesTablouri[currentTable].gabaritUnitatiFunctionale + parseFloat(element.gabaritTotal),
          totalAccesoriiUzinareTabel = parseFloat(totalAccesoriiUzinareTabel.toFixed(1)) + parseFloat(element.pretFinal);
        });
        this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel = parseFloat(totalAccesoriiUzinareTabel.toFixed(1));
      }
      if(this.tablesTablouri[currentTable].accesoriiMontaj.length > 0){
        this.tablesTablouri[currentTable].accesoriiMontaj.forEach((element: any) => {
          element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + parseFloat(element.pretUnitarAchizitie) * parseFloat(element.adaos) / 100).toFixed(1);
          element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
          element.pretFinal = (parseFloat(element.pretTotal) * parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontaj)).toFixed(1);
          totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1)) + parseFloat(element.pretFinal);
        });
        this.tablesTablouri[currentTable].totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1));
      }

      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
      
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      this.serviciiTable[indexServ1].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];
      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });
    }
    else if(tip == 'pretUnitarAchizitie'){
      element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100))).toFixed(1);
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].totalEchipamente = 0;
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalEchipamente = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
      });

      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
    }
    else if(tip == 'adaos'){
      element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100))).toFixed(1);
      element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].totalEchipamente = 0;
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalEchipamente = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(element.pretTotal)).toFixed(1);
      });

      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
    }
    else if(tip == 'uzinare'){
      const gestiunePrincipala = this.ofertareService.getOfertareAccesoriiUnitatiFunctionaleTablou();
      await lastValueFrom(gestiunePrincipala);
      let i = 1;
      let totalAccesoriiUzinareTabel = 0;
      this.tablesTablouri[currentTable].totalManoperaUzinareTablou = 0;
      this.tablesTablouri[currentTable].totalAccesoriiUzinare = 0;
      this.tablesTablouri[currentTable].accesoriiUzinare = this.sessionData.ofertareAccesoriiUnitatiFunctionaleTablou;
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalAccesoriiUzinare = (parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinare) + (parseFloat(element.accesoriiUzinare) * parseFloat(element.cantitate)));
        this.tablesTablouri[currentTable].totalManoperaUzinareTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) + (parseFloat(element.accesoriiUzinare) * parseFloat(element.cantitate) * parseFloat(element.manopera)));
      });
      this.tablesTablouri[currentTable].totalOreUnitatiFunctionale = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) * parseFloat(this.pretServiciuUzinareTablou)).toFixed(1);
      this.tablesTablouri[currentTable].gabaritUnitatiFunctionale = 0;
      this.sessionData.ofertareAccesoriiUnitatiFunctionaleTablou.forEach((element: any) => {
        element.position = i;
        element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + parseFloat(element.pretUnitarAchizitie) * parseFloat(element.adaos) / 100).toFixed(1);
        element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
        element.pretFinal = (parseFloat(element.pretTotal) * parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinare)).toFixed(1);
        element.gabaritTotal = (parseFloat(element.gabarit) * this.tablesTablouri[currentTable].totalAccesoriiUzinare).toFixed(1);
        this.tablesTablouri[currentTable].gabaritUnitatiFunctionale = this.tablesTablouri[currentTable].gabaritUnitatiFunctionale + parseFloat(element.gabaritTotal),
        totalAccesoriiUzinareTabel = parseFloat(totalAccesoriiUzinareTabel.toFixed(1)) + parseFloat(element.pretFinal);
        i = i + 1;
      });
      this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(this.tablesTablouri[currentTable].gabaritUnitatiFunctionale);
      this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel = parseFloat(totalAccesoriiUzinareTabel.toFixed(1));
      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      this.serviciiTable[indexServ1].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });

      //Verificare zero accesorii uzinare
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      let isZero = true;
      for(var t = 0; t < this.tablesTablouri[currentTable].dataSource.length; t++) {
        if(parseFloat(this.tablesTablouri[currentTable].dataSource[t].accesoriiUzinare) > 0) {
          isZero = false;
          break;
        }
      }
      if(isZero == true){
        this.tablesTablouri[currentTable].accesoriiUzinare = [];
      }
    }
    else if(tip == 'accesoriiMontajCamp'){

      const gestiunePrincipala = this.ofertareService.getOfertareAccesoriiMontajTablou();
      await lastValueFrom(gestiunePrincipala);
      let i = 1;
      let totalAccesoriiMontajTabel = 0;
      this.tablesTablouri[currentTable].totalAccesoriiMontaj = 0;
      this.tablesTablouri[currentTable].totalManoperaMontajTablou = 0;
      this.tablesTablouri[currentTable].accesoriiMontaj = this.sessionData.ofertareAccesoriiMontajTablou;
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalAccesoriiMontaj = (parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontaj) + (parseFloat(element.accesoriiMontajCamp) * parseFloat(element.cantitate)));
        this.tablesTablouri[currentTable].totalManoperaMontajTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) + (parseFloat(element.accesoriiMontajCamp) * parseFloat(element.cantitate)) * parseFloat(element.manopera)).toFixed(1);
      });
      this.tablesTablouri[currentTable].totalOreMontaj = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) * parseFloat(this.pretServiciuMontajEchipamente)).toFixed(1);
      this.sessionData.ofertareAccesoriiMontajTablou.forEach((element: any) => {
        element.position = i;
        element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + parseFloat(element.pretUnitarAchizitie) * parseFloat(element.adaos) / 100).toFixed(1);
        element.pretTotal = (parseFloat(element.pretUnitarVanzare) * parseFloat(element.cantitate)).toFixed(1);
        element.pretFinal = (parseFloat(element.pretTotal) * parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontaj)).toFixed(1);
        totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1)) + parseFloat(element.pretFinal);
        i = i + 1;
      });
      this.tablesTablouri[currentTable].totalAccesoriiMontajTabel = parseFloat(totalAccesoriiMontajTabel.toFixed(1));


      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
      
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      this.serviciiTable[indexServ1].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
          //this.serviciiTable[indexServ2].cantitate = parseFloat(this.serviciiTable[indexServ2].cantitate) + parseFloat(element.totalManoperaUzinareTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });

      //Verificare zero accesorii montaj
       this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
       let isZero = true;
       for(var t = 0; t < this.tablesTablouri[currentTable].dataSource.length; t++) {
         if(parseFloat(this.tablesTablouri[currentTable].dataSource[t].accesoriiMontajCamp) > 0) {
           isZero = false;
           break;
         }
       }
       if(isZero == true){
         this.tablesTablouri[currentTable].accesoriiMontaj = [];
       }
    }
    else if(tip == 'manopera'){
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].totalManoperaUzinareTablou = 0;
      this.tablesTablouri[currentTable].totalManoperaMontajTablou = 0;
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].totalManoperaUzinareTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) + (parseFloat(element.accesoriiUzinare) * parseFloat(element.cantitate) * parseFloat(element.manopera))).toFixed(1);
        this.tablesTablouri[currentTable].totalManoperaMontajTablou = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) + (parseFloat(element.accesoriiMontajCamp) * parseFloat(element.cantitate)) * parseFloat(element.manopera)).toFixed(1);
      });
      this.tablesTablouri[currentTable].totalOreMontaj = (parseFloat(this.tablesTablouri[currentTable].totalManoperaMontajTablou) * parseFloat(this.pretServiciuMontajEchipamente)).toFixed(1);
      this.tablesTablouri[currentTable].totalOreUnitatiFunctionale = (parseFloat(this.tablesTablouri[currentTable].totalManoperaUzinareTablou) * parseFloat(this.pretServiciuUzinareTablou)).toFixed(1);
      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
      //Calcul tabel servicii
      let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
      //let indexServ2: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Uzinare");
      this.serviciiTable[indexServ1].cantitate = 0;
      //this.serviciiTable[indexServ2].cantitate = 0;
      this.tablesTablouri = [...this.tablesTablouri];

      if(this.tablesTablouri.length > 0){
        this.tablesTablouri.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
          //this.serviciiTable[indexServ2].cantitate = parseFloat(this.serviciiTable[indexServ2].cantitate) + parseFloat(element.totalManoperaUzinareTablou);
        });
      }
      if(this.tablesProduse.length > 0){
        this.tablesProduse.forEach((element: any) => {
          this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
        });
      }
      this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });
    }
    else if(tip == 'gabarit'){
      element.gabaritTotal = (parseFloat(element.gabarit) * parseFloat(element.cantitate));
      this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = 0;
      let index: number = this.tablesTablouri[currentTable].dataSource.findIndex((d: any) => d.id === element.id);
      this.tablesTablouri[currentTable].dataSource[index] = element;
      this.tablesTablouri[currentTable].dataSource = [...this.tablesTablouri[currentTable].dataSource];
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = (parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(element.gabaritTotal));
      });

      this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(this.tablesTablouri[currentTable].rezervaTablou);
      if(this.tablesTablouri[currentTable].accesoriiUzinare.length > 0){
        this.tablesTablouri[currentTable].accesoriiUzinare.forEach((element: any) => {
          this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = this.tablesTablouri[currentTable].gabaritTotalEchipareTablou + parseFloat(element.gabaritTotal);
        });
      }
      this.tablesTablouri[currentTable].totalTablou = (parseFloat(this.tablesTablouri[currentTable].totalEchipamente) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiUzinareTabel) + parseFloat(this.tablesTablouri[currentTable].totalAccesoriiMontajTabel)).toFixed(1);
    }
    else if(tip == 'rezervaTablou'){
      this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = 0;
      this.tablesTablouri[currentTable].dataSource.forEach((element: any) => {
        this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive = (parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(element.gabaritTotal));
      });
      this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = parseFloat(this.tablesTablouri[currentTable].gabaritTotalEchipamenteActive) + parseFloat(this.tablesTablouri[currentTable].rezervaTablou);
      if(this.tablesTablouri[currentTable].accesoriiUzinare.length > 0){
        this.tablesTablouri[currentTable].accesoriiUzinare.forEach((element: any) => {
          this.tablesTablouri[currentTable].gabaritTotalEchipareTablou = this.tablesTablouri[currentTable].gabaritTotalEchipareTablou + parseFloat(element.gabaritTotal);
        });
      }
    }
  }

  calculFinal(){
    this.totalGeneral = 0;

    this.tablesTablouri.forEach((element: any) => {
      this.totalGeneral = (parseFloat(this.totalGeneral) + parseFloat(element.totalTablou)).toFixed(1);
    });
    this.tablesProduse.forEach((element: any) => {
      this.totalGeneral = (parseFloat(this.totalGeneral) + parseFloat(element.totalSectiune)).toFixed(1);
    });

    this.totalGeneral = (parseFloat(this.totalGeneral) + parseFloat(this.totalServicii) + parseFloat(this.totalServiciiPrincipale) + parseFloat(this.totalServiciiSecundare) + parseFloat(this.totalServiciiIndirecte)).toFixed(1);
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

  //Add categorie produse
  addCategorieProduse(content: any) {
    this.modalService.open(content);
  }

  AddCategorieProduseResource(fAddCategory: NgForm){

    let tableModel: any = {
          denumireCategorie: fAddCategory.value.DenumireCategorie,
          dataSource: [],
          accesoriiMontaj: [],
          totalEchipamente: 0,
          totalAccesoriiMontaj: 0,
          totalManoperaMontaj: 0,
          totalAccesoriiMontajTabel: 0,
          totalOreMontaj: 0,
          totalSectiune: 0
        }
    this.tablesProduse.push(tableModel);
    this.tablesProduse = [...this.tablesProduse]
    this.modalService.dismissAll();
  }

  editServiciu(element: any, tip: string){
    if(tip == 'cantitate'){
      element.pretTotal = (parseFloat(element.pret) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.serviciiTable.findIndex((d: any) => d.id === element.id);
      this.serviciiTable[index] = element;
      this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
      });
    }
    else if(tip == 'cantitateServiciuPrincipal'){
      element.pretTotal = (parseFloat(element.pret) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.serviciiPrincipaleTable.findIndex((d: any) => d.id === element.id);
      this.serviciiPrincipaleTable[index] = element;
      this.serviciiPrincipaleTable = [...this.serviciiPrincipaleTable];

      this.totalServiciiPrincipale = 0;
      this.serviciiPrincipaleTable.forEach((element: any) => {
        this.totalServiciiPrincipale = parseFloat(this.totalServiciiPrincipale) + parseFloat(element.pretTotal);
      });
      

      if(this.serviciiSecundareTable.length > 0){
        this.serviciiSecundareTable.forEach((element: any) => {
          if(element.listaIdServiciiPrincipale.indexOf(element.id.toString()) > -1){
            element.cantitate = 0;
            element.listaIdServiciiPrincipale.forEach((item: any) => {
              let c = this.serviciiPrincipaleTable.find((x: { id: any; }) => x.id == item).cantitate;
              element.cantitate = parseFloat(element.cantitate) + parseFloat(c); 
              element.pretTotal = (parseFloat(element.cantitate) * parseFloat(element.pret)).toFixed(1); 
              this.serviciiSecundareTable = [...this.serviciiSecundareTable];
            });
          }
        });

        this.totalServiciiSecundare = 0;
        this.serviciiSecundareTable.forEach((element: any) => {
          this.totalServiciiSecundare = parseFloat(this.totalServiciiSecundare) + parseFloat(element.pretTotal);
        });
      }
    }
    else if(tip == 'cantitateServiciuIndirect'){
      element.pretTotal = (parseFloat(element.pret) * parseFloat(element.cantitate)).toFixed(1);
      let index: number = this.serviciiIndirecteTable.findIndex((d: any) => d.id === element.id);
      this.serviciiIndirecteTable[index] = element;
      this.serviciiIndirecteTable = [...this.serviciiIndirecteTable];
      
      this.totalServiciiIndirecte = 0;
      this.serviciiIndirecteTable.forEach((element: any) => {
        this.totalServiciiIndirecte = parseFloat(this.totalServiciiIndirecte) + parseFloat(element.pretTotal);
      });
    }
  }

  deleteServiciu(element: any, tip: any){
    if(tip == "Serviciu"){
      let index: number = this.serviciiTable.findIndex((d: any) => d.id === element.id);
      this.serviciiTable.splice(index,1)
      this.serviciiTable = [...this.serviciiTable];
      this.totalServicii = 0;
      this.serviciiTable.forEach((element: any) => {
        this.totalServicii = (parseFloat(this.totalServicii) + parseFloat(element.pretTotal)).toFixed(1);
      });
    }
    else if(tip == "ServiciuIndirect"){
      let index: number = this.serviciiIndirecteTable.findIndex((d: any) => d.id === element.id);
      this.serviciiIndirecteTable.splice(index,1)
      this.serviciiIndirecteTable = [...this.serviciiIndirecteTable];
      this.totalServiciiIndirecte = 0;
      this.serviciiIndirecteTable.forEach((element: any) => {
        this.totalServiciiIndirecte = (parseFloat(this.totalServiciiIndirecte) + parseFloat(element.pretTotal)).toFixed(1);
      });
    }
    else if(tip == "ServiciuPrincipal"){
      let index: number = this.serviciiPrincipaleTable.findIndex((d: any) => d.id === element.id);
      this.serviciiPrincipaleTable.splice(index,1)
      this.serviciiPrincipaleTable = [...this.serviciiPrincipaleTable];
      this.totalServiciiPrincipale = 0;
      this.serviciiPrincipaleTable.forEach((element: any) => {
        this.totalServiciiPrincipale = (parseFloat(this.totalServiciiPrincipale) + parseFloat(element.pretTotal)).toFixed(1);
      });
    }
    else if(tip == "ServiciuSecundar"){
      let index: number = this.serviciiSecundareTable.findIndex((d: any) => d.id === element.id);
      this.serviciiSecundareTable.splice(index,1)
      this.serviciiSecundareTable = [...this.serviciiSecundareTable];
      this.totalServiciiSecundare = 0;
      this.serviciiSecundareTable.forEach((element: any) => {
        this.totalServiciiSecundare = (parseFloat(this.totalServiciiSecundare) + parseFloat(element.pretTotal)).toFixed(1);
      });
    }
  }

  restabilireServicii(type: any){
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      if(type == "Servicii"){
        //de facut get servicii in functie de zona aleasa global
        this.http.get<any>(this.appUrl + 'api/OfertareServicii/getServicii/' + 1, header).subscribe((data) => {
          this.serviciiTable = data;
          this.totalServicii = 0;
          this.serviciiTable.forEach((element: any) => {
            this.totalServicii = (parseFloat(this.totalServicii) + parseFloat(element.pretTotal)).toFixed(1);
            if(element.denumire == "Uzinare"){
              this.pretServiciuUzinareTablou = element.pret;
            }
            else if(element.denumire == "Montaj"){
              this.pretServiciuMontajEchipamente = element.pret;
            }
          });
          this.serviciiTable.splice(1,1)
        });
      }
      else{
         //de facut get servicii in functie de zona aleasa global
        this.http.get<any>(this.appUrl + 'api/OfertareServiciiIndirecte/getServicii/' + 1, header).subscribe((data) => {
          this.serviciiIndirecteTable = data;
          this.totalServiciiIndirecte = 0;
          this.serviciiIndirecteTable.forEach((element: any) => {
            this.totalServiciiIndirecte = parseFloat(this.totalServiciiIndirecte) + parseFloat(element.pretTotal);
          });
      });
      }
  }

  //Add tablou
  addTablou(content: any) {
    this.modalService.open(content);
  }

  AddTablouResource(fAddCategory: NgForm){

    let tableModel: any = {
          denumireCategorie: fAddCategory.value.DenumireCategorie,
          dataSource: [],
          accesoriiMontaj: [],
          accesoriiUzinare: [],
          totalEchipamente: 0,
          gabaritTotalEchipamenteActive: 0,
          gabaritUnitatiFunctionale: 0,
          totalAccesoriiUzinare: 0,
          totalAccesoriiMontaj: 0,
          totalManoperaUzinareTablou: 0,
          totalManoperaMontajTablou: 0,
          totalAccesoriiUzinareTabel: 0,
          totalAccesoriiMontajTabel: 0,
          gabaritTotalEchipareTablou: 0,
          rezervaTablou: 0,
          totalTablou: 0,
          totalOreUnitatiFunctionale: 0,
          totalOreMontaj: 0
        }
    this.tablesTablouri.push(tableModel);
    this.tablesTablouri = [...this.tablesTablouri]
    this.modalService.dismissAll();
  }

  schimbaMod(){
    if(this.modOferta == "intern"){
      this.modOferta = "client";
    }
    else{
      this.modOferta = "intern";
    }
  }

async addSectiuneServiciiPrincipaleModal(content: any, gp: any) {
  const proiecte = this.ofertareService.getOfertareServiciiPrincipale(this.zona);
  await lastValueFrom(proiecte);
  this.modalService.open(content);
}

AddSectiuneServiciiPrincipalerResource(fAddSectiuneServiciiPrincipaler: NgForm){

  this.sessionData.serviciiPrincipaleTable.forEach((element: any) => {
    if(element.id == fAddSectiuneServiciiPrincipaler.value.Proiect){
      let serviciu = element;
      this.serviciiPrincipaleTable.push(serviciu);
      this.serviciiPrincipaleTable = [...this.serviciiPrincipaleTable];

      this.totalServiciiPrincipale = 0;
      this.serviciiPrincipaleTable.forEach((element: any) => {
        this.totalServiciiPrincipale = parseFloat(this.totalServiciiPrincipale) + parseFloat(element.pretTotal);
      });
    }
  });
  this.modalService.dismissAll();
}

async addSectiuneServiciiSecundareModal(content: any, gp: any) {
  const proiecte = this.ofertareService.getOfertareServiciiSecundare(this.zona);
  await lastValueFrom(proiecte);
  this.modalService.open(content);
  this.listaIdServiciiPrincipale = [];
}

AddSectiuneServiciiSecundareResource(fAddSectiuneServiciiSecundare: NgForm){

  this.sessionData.serviciiSecundareTable.forEach((element: any) => {
    if(element.id == fAddSectiuneServiciiSecundare.value.Proiect){
      let serviciu = element;
      serviciu.cantitate = 0;
      serviciu.listaIdServiciiPrincipale = this.listaIdServiciiPrincipale;
      serviciu.listaIdServiciiPrincipale.forEach((element: any) => {
        let c = this.serviciiPrincipaleTable.find((x: { id: any; }) => x.id == element).cantitate;
        serviciu.cantitate = parseFloat(serviciu.cantitate) + parseFloat(c); 
      });
      serviciu.pretTotal = (parseFloat(serviciu.cantitate) * parseFloat(serviciu.pret)).toFixed(1); 
      this.serviciiSecundareTable.push(serviciu);
      this.serviciiSecundareTable = [...this.serviciiSecundareTable];

      this.totalServiciiSecundare = 0;
        this.serviciiSecundareTable.forEach((element: any) => {
          this.totalServiciiSecundare = parseFloat(this.totalServiciiSecundare) + parseFloat(element.pretTotal);
        });
    }
  });
  this.modalService.dismissAll();
}

checkServiciiPrincipale(event: any){
  if(event.target.checked){
    this.listaIdServiciiPrincipale.push(event.target.name);
  }
  else{
    this.listaIdServiciiPrincipale = this.listaIdServiciiPrincipale.filter((obj: any) => { return obj !== event.target.name} );
  }
}

async getDescription(){
  this.sessionData.inventarOfertare.ofertareGestiuneDescriere.forEach(async (element: { id: string | undefined; image: string; nume: any; descriere: any; }) => {
    if (element.nume.includes(this.nameDescription)) {
      this.ofertareGestiuneDescriereAdd = {
        id: element.id,
        image: element.image,
        nume: element.nume,
        descriere: element.descriere
      }

      //adauga descriere in tabelul pentru ofertare
      this.ofertareGestiuneDescriereAddPdfPush = {
        id: element.id,
        image: element.image,
        nume: element.nume,
        descriere: element.descriere,
        imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
      }
      this.ofertareGestiuneDescriereAddPdf.push(this.ofertareGestiuneDescriereAddPdfPush);

      //adauga descriere in tabel
      this.descrieriFieldArray.push(this.ofertareGestiuneDescriereAdd);
   }
  });
}
async deleteFieldDescriere(index: number, item: any){
  this.descrieriFieldArray.splice(index, 1);
  this.ofertareGestiuneDescriereAdd = {
        id: item.id,
        image: item.image,
        nume: item.nume,
        descriere: item.descriere
  }

  this.ofertareGestiuneDescriereAddPdfPush = {
    id: item.id,
    image: item.image,
    nume: item.nume,
    descriere: item.descriere,
    imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
  }

  this.ofertareGestiuneDescriereAddPdf =  this.ofertareGestiuneDescriereAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneDescriereAddPdfPush.id)
}

async getEquipments(){
  this.sessionData.inventarOfertare.ofertareGestiuneEchipament.forEach(async (element: { id: string | undefined; image: string; nume: any; descriere: any; }) => {
    if (element.nume.includes(this.nameEchipament)) {

      this.ofertareGestiuneEchipamentAdd = {
        id: element.id,
        image: element.image,
        nume: element.nume,
        descriere: element.descriere
      }

    //adauga echipament in tabelul pentru ofertare
    this.ofertareGestiuneEchipamentAddPdfPush = {
      id: element.id,
      image: element.image,
      nume: element.nume,
      descriere: element.descriere,
      imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
    }

    this.ofertareGestiuneEchipamentAddPdf.push(this.ofertareGestiuneEchipamentAddPdfPush);

    //adauga echipament in tabel
    this.echipamenteFieldArray.push(this.ofertareGestiuneEchipamentAdd);
 }
});
}
async deleteFieldEchipament(index: number, item: any){
  this.echipamenteFieldArray.splice(index, 1);
  this.ofertareGestiuneEchipamentAdd = {
    id: item.id,
    image: item.image,
    nume: item.nume,
    descriere: item.descriere
  }

  this.ofertareGestiuneEchipamentAddPdfPush = {
    id: item.id,
    image: item.image,
    nume: item.nume,
    descriere: item.descriere,
    imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
  }

  this.ofertareGestiuneEchipamentAddPdf =  this.ofertareGestiuneEchipamentAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneEchipamentAddPdfPush.id)
}

async getVisualizations(){
  this.sessionData.inventarOfertare.ofertareGestiuneVizualizare.forEach(async (element: { id: string | undefined; image: string; nume: any; descriere: any; }) => {
    if (element.nume.includes(this.nameVizualizare)) {

      this.ofertareGestiuneVizualizareAdd = {
        id: element.id,
        image: element.image,
        nume: element.nume,
      }

      //adauga vizualizare in tabelul pentru ofertare
      this.ofertareGestiuneVizualizareAddPdfPush = {
        id: element.id,
        image: element.image,
        nume: element.nume,
        imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
      }
      this.ofertareGestiuneVizualizareAddPdf.push(this.ofertareGestiuneVizualizareAddPdfPush);

    //adauga vizualizare in tabel
    this.vizualizariFieldArray.push(this.ofertareGestiuneVizualizareAdd);
 }
});
}
async deleteFieldVizualizari(index: number, item: any){
  this.vizualizariFieldArray.splice(index, 1);
  this.ofertareGestiuneVizualizareAdd = {
        id: item.id,
        image: item.image,
        nume: item.nume,
      }

      this.ofertareGestiuneVizualizareAddPdfPush = {
        id: item.id,
        image: item.image,
        nume: item.nume,
        imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
      }

      this.ofertareGestiuneVizualizareAddPdf =  this.ofertareGestiuneVizualizareAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneVizualizareAddPdfPush.id)
  }

getBase64ImageFromURL(url: any) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}

genereazaOferta(){
  this.modelOferta.Numar = this.numar;
  this.modelOferta.Beneficiar = this.beneficiar;
  this.modelOferta.DenumireOferta = this.denumireOferta;
  this.modelOferta.UserId = this.sessionData.user.id;
  this.modelOferta.DenumireProiect = this.numeProiect;
  this.modelOferta.Status = this.status;
  this.modelOferta.Zona = this.zona;
  this.modelOferta.SectiuneProduse = JSON.stringify(this.tablesProduse);
  this.modelOferta.SectiuneTablouri = JSON.stringify(this.tablesTablouri);
  this.modelOferta.Servicii = JSON.stringify(this.serviciiTable);
  this.modelOferta.ServiciiIndirecte = JSON.stringify(this.serviciiIndirecteTable);
  this.modelOferta.ServiciiPrincipale = JSON.stringify(this.serviciiPrincipaleTable);
  this.modelOferta.ServiciiSecundare = JSON.stringify(this.serviciiSecundareTable);
  this.modelOferta.TotalGeneral = JSON.stringify(this.totalGeneral);
  this.modelOferta.DetaliiSuplimentare = JSON.stringify(this.details);
  this.modelOferta.DescrieriOferta = JSON.stringify(this.descrieriFieldArray);
  this.modelOferta.EchipamenteOferta = JSON.stringify(this.echipamenteFieldArray);
  this.modelOferta.VizualizariOferta = JSON.stringify(this.vizualizariFieldArray);

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/OfertareGestiuneOferte/creareOferta2', this.modelOferta, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

async schimbaZona(){
  this.totalServicii = 0;
  this.totalServiciiIndirecte = 0;
  this.totalServiciiPrincipale = 0;
  this.totalServiciiSecundare = 0;
  const ofertareServicii = this.ofertareService.getOfertareServicii(this.zona);
  await lastValueFrom(ofertareServicii);

  const ofertareServiciiIndirecte = this.ofertareService.getOfertareServiciiIndirecte(this.zona);
  await lastValueFrom(ofertareServiciiIndirecte);

  this.serviciiTable = this.sessionData.serviciiTable;
    this.serviciiIndirecteTable = this.sessionData.serviciiIndirecteTable;

    this.totalServiciiIndirecte = 0;
    this.serviciiIndirecteTable.forEach((element: any) => {
      this.totalServiciiIndirecte = parseFloat(this.totalServiciiIndirecte) + parseFloat(element.pretTotal);
    });

    this.serviciiTable.forEach((element: any) => {
      this.totalServicii = (parseFloat(this.totalServicii) + parseFloat(element.pretTotal)).toFixed(1);
      if(element.denumire == "Uzinare"){
        this.pretServiciuUzinareTablou = element.pret;
      }
      else if(element.denumire == "Montaj"){
        this.pretServiciuMontajEchipamente = element.pret;
      }
    });
    this.serviciiTable.splice(1,1);
    
    //Calcul tabel servicii
    let indexServ1: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Montaj");
    //let indexServ2: number = this.serviciiTable.findIndex((d: any) => d.denumire === "Uzinare");
    this.serviciiTable[indexServ1].cantitate = 0;
   // this.serviciiTable[indexServ2].cantitate = 0;
    this.tablesTablouri = [...this.tablesTablouri];
    

    if(this.tablesTablouri.length > 0){
      this.tablesTablouri.forEach((element: any) => {
        this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontajTablou);
      });
    }
    if(this.tablesProduse.length > 0){
      this.tablesProduse.forEach((element: any) => {
        this.serviciiTable[indexServ1].cantitate = parseFloat(this.serviciiTable[indexServ1].cantitate) + parseFloat(element.totalManoperaMontaj);
      });
    }
    this.serviciiTable[indexServ1].pretTotal = (parseFloat(this.serviciiTable[indexServ1].cantitate) * parseFloat(this.serviciiTable[indexServ1].pret)).toFixed(1);
this.serviciiTable = [...this.serviciiTable];
    this.totalServicii = 0;
    this.serviciiTable.forEach((element: any) => {
      this.totalServicii = parseFloat(this.totalServicii) + parseFloat(element.pretTotal);
    });
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.totalServiciiPrincipale = 0;
    this.serviciiPrincipaleTable.forEach((element: any) => {
      this.pretServiciuModel = {
        id: element.id,
        zona: this.zona
      }
      this.http.post<any>(this.appUrl + 'api/OfertareServiciiPrincipale/getPretServiciu', this.pretServiciuModel, header).subscribe((data) => {
        element.pret = data;
        element.pretTotal = parseFloat(element.pret) * parseFloat(element.cantitate);
        this.totalServiciiPrincipale = parseFloat(this.totalServiciiPrincipale) + parseFloat(element.pretTotal);
      });

    });

    this.totalServiciiSecundare = 0;
    this.serviciiSecundareTable.forEach((element: any) => {
      this.pretServiciuModel = {
        id: element.id,
        zona: this.zona
      }
      this.http.post<any>(this.appUrl + 'api/OfertareServiciiSecundare/getPretServiciu', this.pretServiciuModel, header).subscribe((data) => {
        element.pret = data;
        element.pretTotal = parseFloat(element.pret) * parseFloat(element.cantitate);
        this.totalServiciiSecundare = parseFloat(this.totalServiciiSecundare) + parseFloat(element.pretTotal);
      });

    });
}


}
