import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-oferte-dashboard',
  templateUrl: './oferte-dashboard.component.html',
  styleUrls: ['./oferte-dashboard.component.css']
})
export class OferteDashboardComponent implements OnInit {
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  clientId: any;
  proiectId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.clientId = localStorage.getItem('ClientId');
    this.proiectId = localStorage.getItem('ProjectId');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "OFERTE";
  }

  ngOnDestroy() {
    localStorage.removeItem('ProjectId');
  }

  updateStare(item: any){
    if(item.stare == false && this.sessionData.user.rol.tip == 'Client'){
      this.http.put<any>(this.appUrl + 'api/ClientFisier/updateStare/' + item.id, '').subscribe((data) => {
        location.reload();
      });
    }
  }

}
