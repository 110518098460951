import { AuthService } from 'src/app/services/auth.service';
import { Component, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { async, lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SessionDataService } from 'src/app/session-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],

})

export class SidebarComponent {

  isSidebarExpanded = true;
  isNavbarExpanded = false;
  message: any;
  isHandset$: boolean;
  showSubmenu: boolean = false;
  showSubmenu1: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;

  numarOferta: any;
  listaClienti: any = [];
  numeClientAles: any;
  idClientAles: any;
  listaProiecte: any = [];
  numeProiectAles: any;
  ofertaId: any;
  denumireOferta: any;
  today: any;
  todayInput: any;
  yesterday: any;
  appUrl: string = environment.appUrl;
  idProiectAles: any;
  prezentaModel: any = {
    data: '',
    tablouId: '',
    oreLucrate: '',
    userId: '',
    detalii: ''
  }
  idTablouAles: any;
  oreLucrate: number = 8;
  anuntModel: any;
  isChecked1: any;
  isChecked2: any;
  selectedfile:any;
  data: any;
  listaTablouri: any;
  zona: any;
  isCheckedOferta1: any = false;
  isCheckedOferta2: any = false;
  buttonDisable: boolean = false;

  constructor(private http: HttpClient, public datepipe: DatePipe, private modalService: NgbModal, private breakpointObserver: BreakpointObserver, private router: Router, public authService: AuthService, public sessionData: SessionDataService) {
    this.buttonDisable = false;
    if(window.innerWidth >= 940){
      this.isHandset$ = false;
    }
    else{
      this.isHandset$ = true;
    }
  }



  public onOptionClick(sidenav: { toggle: () => void; }){
    if(this.breakpointObserver.isMatched(
      "(max-width: 599px)"
    )){
      sidenav.toggle();
    }
  }
  public toggleNavbar(){
    this.isNavbarExpanded = !this.isNavbarExpanded;
  }

  logOut(){
    this.authService.logout();
    window.location.reload();
  }

  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }
  checkValueOferta1(event: any){
  }
  checkValueOferta2(event: any){
  }

  goToRegister(){
    localStorage.setItem('Provizoriu', "false");
    this.router.navigate(['/register']);
  }

  goToRegisterOfertare(){
    localStorage.setItem('Provizoriu', "true");
    this.router.navigate(['/register']);
  }

  //#region Adauga oferta
  async addModal(content: any) {
    
    const oferte = this.authService.getOferte();
    await lastValueFrom(oferte);
    
    if(this.sessionData.listaOferte.length != 0){
      this.numarOferta = this.sessionData.listaOferte[this.sessionData.listaOferte.length - 1].id + 1 + new Date().getFullYear().toString() + '/0';
    }
    else{
      this.numarOferta = 1 + new Date().getFullYear().toString() + '/0';
    }

    
    this.listaClienti = this.sessionData.clienti;
    this.modalService.open(content);
  }
  async changeUser(){
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    this.listaProiecte = [];
    this.listaClienti.forEach((element: { nume: string; prenume: string; id: any; }) => {
      if(element.nume + " " + element.prenume == this.numeClientAles){
        this.idClientAles = element.id;
      }
    }); 
    this.sessionData.proiecte.forEach((element: any) => {
      if(element.userId == this.idClientAles){
        this.listaProiecte.push(element);
      }
    });

  }
  AddResource(fAddCategory: NgForm){
    localStorage.setItem('NumarOferta', fAddCategory.value.NumarOferta);
    localStorage.setItem('NumeClient', this.numeClientAles);
    localStorage.setItem('NumeProiect', this.numeProiectAles);
    localStorage.setItem('DenumireOferta', this.denumireOferta);
    this.router.navigate(['/ofertare-creare-oferta']);
    this.modalService.dismissAll();
  }
//#endregion

//#region Adauga oferta 2
async add2Modal(content: any) {
    
  const oferte = this.authService.getOferte2();
  await lastValueFrom(oferte);
  if(this.sessionData.listaOferte.length != 0){
    this.numarOferta = this.sessionData.listaOferte[0].id + 1 + new Date().getFullYear().toString() + '/0';
  }
  else{
    this.numarOferta = 1 + new Date().getFullYear().toString() + '/0';
  }

  
  this.listaClienti = this.sessionData.clienti;
  this.modalService.open(content);
}

Add2Resource(fAddCategory: NgForm){
  if(fAddCategory.value.gestiunePrincipala == true && fAddCategory.value.rezervareProiect == true){
    this.message = "Alege o singura optiune!"
  }
  if(fAddCategory.value.gestiunePrincipala){
    localStorage.setItem('NumarOferta', fAddCategory.value.NumarOferta);
    localStorage.setItem('NumeClient', this.numeClientAles);
    localStorage.setItem('NumeProiect', this.numeProiectAles);
    localStorage.setItem('DenumireOferta', this.denumireOferta);
    localStorage.setItem('Zona', this.zona);
    this.router.navigate(['/ofertare-creare-oferta2']);
    this.modalService.dismissAll();
  }
  else if(fAddCategory.value.rezervareProiect){
    let model: any = {
      Nume: fAddCategory.value.Nume,
      Prenume: fAddCategory.value.Prenume,
      Cost: 1,
      Email: fAddCategory.value.Email,
      Password: "Password",
      ConfirmPassword: "Password",
      Rol: "Client",
      CNP: fAddCategory.value.CNP,
      Companie: null,
      CUI: null,
      NORC: null,
      AdresaBeneficiar: fAddCategory.value.Adresa,
      AdresaCompanie: null,
      Banca: null,
      SediuCentral: null,
      Judet: null,
      Localitate: null,
      Telefon: fAddCategory.value.Telefon,
      IBAN: null,
      Provizoriu: true
    };
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.post(this.appUrl + 'api/user/registerForOferatare', model).subscribe((data: any) => {
      let addProiectModel: any ={
        denumireProiect: this.denumireOferta,
        locatie: "Locatie",
        prioritate: "Mare",
        status: "Activ",
        valoareProduse: '1',
        valoareServicii: '1',
        valoareTotala: '1',
        numarContract: fAddCategory.value.NumarOferta,
        numePersoanaContact: data.nume,
        prenumePersoanaContact: data.prenume,
        telefon: "Telefon",
        email: data.email,
        userId: data.id
      }
      this.numeClientAles = data.nume + " " + data.prenume; 
      this.numeProiectAles = this.denumireOferta;
      this.http.post(this.appUrl + 'api/proiect/', addProiectModel, header).subscribe(
        (response) => {    
          localStorage.setItem('NumarOferta', fAddCategory.value.NumarOferta);
          localStorage.setItem('NumeClient', this.numeClientAles);
          localStorage.setItem('NumeProiect', this.numeProiectAles);
          localStorage.setItem('DenumireOferta', this.denumireOferta);
          localStorage.setItem('Zona', this.zona);
          this.router.navigate(['/ofertare-creare-oferta2']);
          this.modalService.dismissAll();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
    );
    });
  }
}
//#endregion


  //#region Adauga prezenta tablou
  addPrezentaModal(content: any) {
    this.modalService.open(content);
    this.sessionData.tabloterieProiecte = "";
    this.listaTablouri = "";
    this.todayInput = new Date;
    this.today = new Date;
    this.yesterday = new Date;
    this.yesterday.setDate(this.today.getDate() - 1);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieProiect/', header).subscribe((data) => {
      this.sessionData.tabloterieProiecte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
    });
  }
  changePrezenta(event: any){
    this.idProiectAles = event;
    this.listaTablouri = "";
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    this.http.get<any>(this.appUrl + 'api/TabloterieTablou/GetTabloterieTablouByProjectId/' + event, header).subscribe((data) => {
       this.listaTablouri = data;
    });

  }
  AddPrezentaResource(fAddPrezenta: NgForm){
    this.buttonDisable = true;

    this.prezentaModel = {
      data: new Date(fAddPrezenta.value.Data),
      tablouId:  fAddPrezenta.value.listaProiecteOferta,
      oreLucrate: fAddPrezenta.value.OreLucrate,
      userId: this.sessionData.user.id
    }

    if(fAddPrezenta.value.Detalii == ""){
      this.prezentaModel.detalii = "-";
    }
    else{
      this.prezentaModel.detalii = fAddPrezenta.value.Detalii;
    }
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.post(this.appUrl + 'api/TabloteriePrezenta', this.prezentaModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  //#endregion

  //#region Adauga Anunt
  async addAnuntModal(content: any) {
    this.modalService.open(content);
  }
 
  AddAnuntResource(fAddAnunt: NgForm){
    if(fAddAnunt.value.anuntInformativ == true && fAddAnunt.value.anuntObligatoriu == true){
      this.message = "Alege o singura optiune!"
    }
    this.anuntModel = {
      userId: this.sessionData.user.id,
      descriere: fAddAnunt.value.DescriereAnunt,
      data: new Date(fAddAnunt.value.Data)
    }
    
    fAddAnunt.value.File = "file";
    fAddAnunt.value.Files = this.selectedfile;
    const formData = new FormData();
    formData.append("UserId", this.sessionData.user.id);
    formData.append("Descriere", fAddAnunt.value.DescriereAnunt);
    formData.append("Data", this.anuntModel.data.toDateString());
    if(fAddAnunt.value.anuntInformativ == true){
      formData.append("Tip", "AnuntInformativ");
    }
    else if(fAddAnunt.value.anuntObligatoriu == true){
      formData.append("Tip", "AnuntObligatoriu");
    }
    formData.append("File", fAddAnunt.value.File.toString());
    formData.append("Files",  fAddAnunt.value.Files);

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.post(this.appUrl + 'api/GestiuneAnunturi',  formData, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
     );
  }
//#endregion


  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }

  checkValue1(){}
  checkValue2(){}

}
