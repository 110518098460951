import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  public user:any;
  public categories: any;
  public users: any = [];
  public userResourseId: any;
  public userRole: any;
  public roleByUserId: any;
  public resurse: any;
  public current_page_title: any;
  public productId : any;
  public gestiunePrincipalaInventory : any;
  public productDetails: any;
  public proiecte: any;
  public gestiuneTransfer : any;
  public gestiunePersonala: any = [];
  public gestiuneProiect: any;
  public gestiuneVanzari: any;
  public gestiuneRezervareProiect: any;
  public roles: any;
  public rolId: any;
  public userPrezenta: any;
  public ofertareGestiuneProduse: any;
  public totalStocksValue: any;
  public projectProfitability: any;
  public totalProjectsValue: any;
  public projectsByStatus: any;
  public criticStocks: any;
  public projectId: any;
  public projectDetails: any;
  public chat: any;
  public documentatie: any;
  public single: any;
  public singleTabloterie: any;
  public singleRezervare: any;
  public singlePersonala: any;
  public singleTransfer: any;
  public singleTotal: any;
  public single1: any;
  public single2: any;
  public single3: any;
  public single4: any;
  public projectDataSource: any = [];
  public resourceData : any = [];
  public eventSettings : any;
  public categoryDataSource : any = [];
  public resourceDataSource: any = [];
  public serviciiManopera: any = [];
  public clientId: any = [];
  public ofertareGestiuneDescriere: any = [];
  public ofertareGestiuneEchipamente: any = [];
  public ofertareGestiuneVizualizari: any = [];
  public inventarOfertare: any;
  public imageb64: any;
  public listaOferte: any;
  public ofertaId: any;
  public listaComenzi: any;
  public listaProduseComenzi: any;
  public comandId: any;
  public listaGestiuneComenzi: any;
  public gestiuneTabloterie: any;
  public tabloterieProiecte: any;
  public panelId: any;
  public listaTablouri: any;
  public listaProduseTablou: any;
  public prezentaTabloterie: any;
  public listaProduseComenziDeComandat: any;
  public listaProducatori: any;
  public proiecteTablouri: any;
  public dashboardClientInventar: any;
  public getGestiunePersonalaById: any;
  public clientProiectId: any;
  public showSubmenu: boolean = false;
  public showSubmenu1: boolean = false;
  public showSubmenu2: boolean = false;
  public showSubmenu3: boolean = false;
  public showSubmenu4: boolean = false;
  public showSubmenu5: boolean = false;
  public showSubmenu6: boolean = false;
  public showSubmenu9: boolean = false;
  public showSubmenu11: boolean = false;
  public showSubmenu12: boolean = false;
  public showSubmenu13: boolean = false;
  public stareOferte: any;
  public stareContracte: any; 
  public stareFacturi: any; 
  public stareRapoarteDeLucru: any; 
  public stareDiverse: any; 
  public stareDocumentatieTehnica: any;
  public gestiuneFotovoltaiceInventory: any;
  public listaGestiuneAprovizionareRezervari: any;
  public fisiereClienti: any;
  public avizLivrare: boolean = false;
  public procesVerbalReceptie: boolean = false;
  public clienti: any = [];
  public taskProiect: any;
  public taskId: any;
  public fisiereTask: any;
  public interventiiTask: any;
  public userTasks: any;
  public proiecteFinalizate: any;
  public gestiuneAnunturi: any;
  public anuntObligatoriu: any;
  public anuntObligatoriuId: any;
  public coduriOfertareProduse: any;
  public ofertareAccesoriiMontaj: any;
  public ofertareServicii: any;
  public ofertareAccesoriiMontajTablou: any;
  public ofertareAccesoriiUnitatiFunctionaleTablou: any;
  public serviciiTable: any;
  public serviciiIndirecteTable: any;
  public serviciiPrincipaleTable: any;
  public serviciiSecundareTable: any;
  public clientiOfertare: any = [];
  public ofertaCompleta2: any = [];
  public proiecte2: any = [];
  public proiecte2Contracte: any = [];
  public contractId: any;
  public sectiuneId: any;
  public proiect2Id: any;
  public proiecte2ContracteSectiuni: any;
  public proiecte2ContracteSectiuniMateriale: any = [];
  public proiecte2ContracteSectiuniManopera: any = [];
  public gestiuneRezervareProiect2: any = [];
  public productName: any;
  public gestiuneComponente : any;
  public gestiuneRetetar : any;
  public gestiuneRetetarComponente: any;
  public gestiuneProductie: any;
  public fisierePlanArhitectura: any;
  public fisaReleuId: any;

  constructor() { }
}
