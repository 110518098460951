import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-personala',
  templateUrl: './gestiune-personala.component.html',
  styleUrls: ['./gestiune-personala.component.css']
})
export class GestiunePersonalaComponent implements OnInit {

  //#region Declaration
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  addGPersonalaModel: any = {
    gestiunePersonalaId: '',
    cantitate: '',
    userId: '',
    numeUser: '',
    userIdTransfer: '',
    numeUserTransfer: '',
    gestiunePrincipalaId: ''
  };
  addGProiectModel: any = {
    gestiunePersonalaId: '',
    gestiunePrincipalaId: '',
    cantitate: '',
    proiectId: '',
    numeUser: '',
    userId: '',
    observatii: ''
  };
  gPersonalaModel: any = {
    id: '',
    activ: '',
    cantitate: '',
    data: '',
    gestiunePrincipala: '',
    numeUser: '',
    userId: '',
    proiectId: ''
  };
  returGTransferModel: any = {
    gestiunePrincipalaId: '',
    gestiunePersonalaId: '',
    numeUser: '',
    userId: '',
    cantitate: ''
  };
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  buttonDisable: boolean = false;
  idProiectAles: any;
  idContractAles: any;
  proiectFlag: any = false;
  idSectiuneAleasa: any;
  contractFlag: any = false;
  proiectRezerva: boolean = false;

//#endregion

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.buttonDisable = false;
    this.sessionData.current_page_title = "GESTIUNE PERSONALA"
    this.nav.show();
    // var header = {
    //     headers: new HttpHeaders()
    //       .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    //   }
    // this.http.get<any>(this.appUrl + 'api/GestiunePersonala/getGestiunePersonala', header).subscribe((data) => {
    //   this.sessionData.gestiunePersonala = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
    // });
  }


//#region Retur Produs
returModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId,
    proiectId: gp.proiectId
  }
}
DeleteResource(fReturProdus: any){

  this.buttonDisable = true;

    this.returGTransferModel = {
      gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
      gestiunePersonalaId: this.gPersonalaModel.id,
      numeUser: this.gPersonalaModel.numeUser,
      userId: this.gPersonalaModel.userId,
      cantitate: this.gPersonalaModel.cantitate,
      proiectId: this.gPersonalaModel.proiectId,
    }

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.post(this.appUrl + 'api/GestiunePersonala/deleteToGestiuneTransfer/' + this.gPersonalaModel.id,  this.returGTransferModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );



}
//#endregion

//#region Transfera in Gestiune Personala

addToGUserModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId
  }
}

AddGUserResource(fAddGTransfer: NgForm){

  this.buttonDisable = true;

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPersonalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.sessionData.users.forEach((element: { id: any; nume: any; prenume: any; }) => {
      if(element.id == fAddGTransfer.value.UserName){
        this.userName = element.nume + " " + element.prenume;
      }
    });


    this.addGPersonalaModel = {
      gestiunePersonalaId: this.gPersonalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      userId: this.gPersonalaModel.userId,
      numeUser: this.gPersonalaModel.numeUser,
      userIdTransfer: fAddGTransfer.value.UserName,
      numeUserTransfer: this.userName,
      gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiunePersonala/addGestiunePersonalaToGestiunePersonala', this.addGPersonalaModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Transfera in Gestiune Proiect

async addToGProiectModal(content: any, gp: any) {
  if(gp.denumireProiect == "Rezerva"){
    this.proiectRezerva = true;
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
  }

  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId,
    proiectId: gp.proiectId
  }
}

AddGProiectResource(fAddGTransfer: NgForm){

  this.buttonDisable = true;

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPersonalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{
    if(this.proiectRezerva == true){
      this.addGProiectModel = {
        gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
        gestiunePersonalaId: this.gPersonalaModel.id,
        cantitate: fAddGTransfer.value.Cantitate,
        proiectId: fAddGTransfer.value.Proiect,
        numeUser:  this.gPersonalaModel.numeUser,
        userId: this.gPersonalaModel.userId,
        observatii: fAddGTransfer.value.Observatii
      }
    }
    else{
      this.addGProiectModel = {
        gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
        gestiunePersonalaId: this.gPersonalaModel.id,
        cantitate: fAddGTransfer.value.Cantitate,
        proiectId: this.gPersonalaModel.proiectId,
        numeUser:  this.gPersonalaModel.numeUser,
        userId: this.gPersonalaModel.userId,
        observatii: fAddGTransfer.value.Observatii
      }
    }

    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiunePersonala/addGestiuneProiect', this.addGProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Transfera in Gestiune Proiect2

async addToGProiect2Modal(content: any, gp: any) {

  const proiecte = this.authService.getProiecte2();
  await lastValueFrom(proiecte);

  this.modalService.open(content);
  this.gPersonalaModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    userId: gp.userId
  }
}

AddGProiect2Resource(fAddGTransfer: NgForm){

  this.buttonDisable = true;

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPersonalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.addGProiectModel = {
      gestiunePrincipalaId: this.gPersonalaModel.gestiunePrincipala.id,
      gestiunePersonalaId: this.gPersonalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      proiectId: this.idProiectAles,
      contractId: this.idContractAles,
      sectiuneId: this.idSectiuneAleasa,
      numeUser:  this.gPersonalaModel.numeUser,
      userId: this.gPersonalaModel.userId,
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiunePersonala/addGestiuneProiect2', this.addGProiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

async changeProiect(){
  const proiecte = this.authService.getProiecte2Contracte(this.idProiectAles);
  await lastValueFrom(proiecte);
  this.proiectFlag = true;
}

async changeContract(){
  const proiecte = this.authService.getProiecte2ContracteSectiuni(this.idContractAles);
  await lastValueFrom(proiecte);
  this.contractFlag = true;
}

Search(){
  if(this.denumire != ""){
    this.sessionData.getGestiunePersonalaById = this.sessionData.getGestiunePersonalaById.filter((res: any ) => {
      return res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.denumireProiect?.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.authService.getGestiunePersonalaByUserId(this.sessionData.user.id);
  }

}
refresh(){
  this.proiectRezerva = false;
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
