import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { lastValueFrom } from 'rxjs';


(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ofertare-creare-oferta',
  templateUrl: './ofertare-creare-oferta.component.html',
  styleUrls: ['./ofertare-creare-oferta.component.css']
})
export class OfertareCreareOfertaComponent implements OnInit, OnDestroy {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  codProduct: string | undefined;
  nameDescription: string | undefined;
  nameEchipament: string | undefined;
  nameVizualizare: string | undefined;
  proiectId: any;
  category: any;
  details: any;
  numar: any;
  beneficiar: any;
  denumireOferta: any;
  numeProiect: any;
  status: any;
  productModel: any = {
    id: '',
    producator: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    adaos: '',
    pretUnitarVanzare: '',
    pretTotal: '',
    indiceConsultanta: '',
    indiceProiectare: '',
    indiceMontaj: '',
    indiceProgramareSisteme: '',
    indiceVizualizareAplicatatii: '',
    indiceMontajDiverseEchipamente: '',
    descriereProdus: '',
    category: ''
  }
  serviciiCantitate1: any = 0;
  serviciiCantitate2: any = 0;
  serviciiCantitate3: any = 0;
  serviciiCantitate4: any = 0;
  serviciiCantitate5: any = 0;
  serviciiCantitate6: any = 0;

  serviciiPU1: any;
  serviciiPU2: any;
  serviciiPU3: any;
  serviciiPU4: any;
  serviciiPU5: any;
  serviciiPU6: any;

  serviciiPT1: any = 0;
  serviciiPT2: any = 0;
  serviciiPT3: any = 0;
  serviciiPT4: any = 0;
  serviciiPT5: any = 0;
  serviciiPT6: any = 0;

  pretTotalFaraAdaos: number = 0;
  pretTotalCuAdaos: number = 0;
  adaos: number  = 0;
  addOfertaCompleta: any = {
    numar: '',
    user: '',
    proiectId: '',
    data: '',
    status: '',
    beneficiar: '',
    denumireOferta: '',
    ofertareProdusComplet: [],
    ofertareGestiuneDescriere: [],
    ofertareGestiuneEchipament: [],
    ofertareGestiuneVizualizare: [],
    ofertareCategorieProduse: [],
    ofertareDetaliiSuplimentare: [],
    addServiciuManoperaOferta: [],
    pdfFile: ''
  }
  ofertareProdusCompletAdd: any = {
    id: '',
    producator: '',
    cod: '',
    denumire: '',
    cantitate: '',
    pretUnitarAchizitie: '',
    adaos: '',
    pretUnitarVanzare: '',
    descriereProdus: '',
    indiceConsultanta: '',
    indiceProiectare: '',
    indiceMontaj: '',
    indiceProgramareSisteme: '',
    indiceVizualizareAplicatatii: '',
    indiceMontajDiverseEchipamente: ''
  }
  ofertareGestiuneDescriereAdd: any = {
    id: '',
    image: '',
    nume: '',
    descriere: ''
  }
  ofertareGestiuneDescriereAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    descriere: '',
    imageBase64: ''
  }
  ofertareGestiuneDescriereAddPdf: any = [];

  ofertareGestiuneEchipamentAdd: any = {
    id: '',
    image: '',
    nume: '',
    descriere: ''
  }
  ofertareGestiuneEchipamentAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    descriere: '',
    imageBase64: ''
  }
  ofertareGestiuneEchipamentAddPdf: any = [];
  ofertareGestiuneVizualizareAdd: any = {
    id: '',
    image: '',
    nume: '',
  }
  ofertareGestiuneVizualizareAddPdfPush: any = {
    id: '',
    image: '',
    nume: '',
    imageBase64: ''
  }
  ofertareGestiuneVizualizareAddPdf: any = [];
  ofertareCategorieProduseAdd: any = {
    denumire: '',
    pozitie: '',
    ofertaId: ''
  }
  ofertareDetaliiSuplimentareAdd: any = {
    denumire: '',
    ofertaId: ''
  }
  addServiciuManoperaOfertaAdd: any = {
    serviciuManoperaId: '',
    pretUnitar: '',
    pretTotal: ''
  }
  externalDataRetrievedFromServer: any = [[{text: 'Nr. Crt.', style: 'tableHeader', alignment: 'center'},{text: 'Denumire', style: 'tableHeader', alignment: 'center'}, {text: 'Cant.', style: 'tableHeader', alignment: 'center'},{text: 'Pret Unitar(Euro fara TVA)', style: 'tableHeader', alignment: 'center'},
                                          {text: 'Pret Total(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}, {text: 'Descriere produs', style: 'tableHeader', alignment: 'center'},{text: 'Producator', style: 'tableHeader', alignment: 'center'}]];
  externalDataRetrievedFromServerPubsh: any;

  tabelServicii: any =  [[{text: 'Denumire', style: 'tableHeader', alignment: 'center'},{text: 'Cant.', style: 'tableHeader', alignment: 'center'},
                        {text: 'Pret Unitar(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}, {text: 'Pret Total(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}]];
  tabelServiciiPush: any;

  tabelWhiteSpace: any = [[{text: '', style: 'tableHeader', alignment: 'center'}]];

  tabelDescrieri : any = [[{text: 'Denumire', style: 'tableHeader', alignment: 'center'},{text: 'Cant.', style: 'tableHeader', alignment: 'center'},
           {text: 'Pret Total(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}]];
  tabelDescrieriPush: any;

  tabelManopera : any = [[{text: 'Denumire', style: 'tableHeader', alignment: 'center'},{text: 'Cant.', style: 'tableHeader', alignment: 'center'},
                          {text: 'Pret Unitar(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}, {text: 'Pret Total(Euro fara TVA)', style: 'tableHeader', alignment: 'center'}]];
  tabelManoperaPush : any;

  imageSrc1 = this.appUrl + 'assets/sigla1Update.png';
  imageSrc2 = this.appUrl + 'assets/sigla2Update.png';
  imageSrc3 = this.appUrl + 'assets/sigla3Update.png';
  // imageSrc4 = this.appUrl + 'assets/sigla4.png';
  // imageSrc5 = this.appUrl + 'assets/sigla5.png';
  nrCrtListaProduse: number = 0;
  partialSumListaProduse: number = 0;
  totalSumListaProduse: number = 0;
  imgimg: any;
  fieldArray: any = [];

  descrieriFieldArray: any = [];

  echipamenteFieldArray: any = [];

  vizualizariFieldArray: any = [];
  justServices: any = false;
  justProducts: any = false;
  getProdusByCodModel: any = {
    cod: ''
  }
  //#endregion

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) { }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "CREARE OFERTĂ"

    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);

    this.numar = localStorage.getItem('NumarOferta');
    this.beneficiar = localStorage.getItem('NumeClient');
    this.denumireOferta = localStorage.getItem('DenumireOferta');
    this.numeProiect = localStorage.getItem('NumeProiect');
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getInventarOfertare', header).subscribe((data) => {
      this.sessionData.inventarOfertare = data;
      this.serviciiPU1 = data.serviciuManopera[0].costServiciu;
      this.serviciiPU2 = data.serviciuManopera[1].costServiciu;
      this.serviciiPU3 = data.serviciuManopera[2].costServiciu;
      this.serviciiPU4 = data.serviciuManopera[3].costServiciu;
      this.serviciiPU5 = data.serviciuManopera[4].costServiciu;
      this.serviciiPU6 = data.serviciuManopera[5].costServiciu;
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('NumarOferta');
    localStorage.removeItem('NumeClient');
    localStorage.removeItem('DenumireOferta');
    localStorage.removeItem('NumeProiect');

  }
 checkValue(event: any){
 }
 checkValue1(event: any){
}
  deleteFieldValue(index: number) {
      this.fieldArray.splice(index, 1);
  }
  addProduct(){
    this.getProdusByCodModel.cod = this.codProduct;
    this.http.post<any>(this.appUrl + 'api/OfertareGestiuneProduse/getProdusByCod', this.getProdusByCodModel).subscribe((element: any) => {
      this.productModel = {
        id: element.id,
        producator: element.producator,
        cod: element.cod,
        denumire: element.denumire,
        cantitate: element.cantitate,
        pretUnitarAchizitie: element.pretUnitarAchizitie,
        adaos: element.adaos,
        pretUnitarVanzare: parseFloat(element.pretUnitarVanzare).toFixed(2),
        pretTotal: (parseFloat(element.cantitate) * parseFloat(element.pretUnitarVanzare)).toFixed(2),
        indiceConsultanta: 1,
        indiceProiectare: 1,
        indiceMontaj: 1,
        indiceProgramareSisteme: 1,
        indiceVizualizareAplicatatii: 1,
        indiceMontajDiverseEchipamente: 1,
        descriereProdus: element.descriereProdus,
        category: ''
      }
      if(this.fieldArray.length == 0 && this.productModel.category == ""){
        alert("Trebuie adăugată o categorie!")
      }
      else{
        this.fieldArray.push(this.productModel);
      }
    });
    // this.sessionData.inventarOfertare.ofertareGestiuneProduse.forEach((element: { id: number | undefined; cod: string | undefined; producator: any; denumire: any; cantitate: any; pretUnitarAchizitie: any; adaos: any; pretUnitarVanzare: any; descriereProdus: any; }) => {
    //   if(element.cod == this.codProduct){
    //     this.productModel = {
    //       id: element.id,
    //       producator: element.producator,
    //       cod: element.cod,
    //       denumire: element.denumire,
    //       cantitate: element.cantitate,
    //       pretUnitarAchizitie: element.pretUnitarAchizitie,
    //       adaos: element.adaos,
    //       pretUnitarVanzare: element.pretUnitarVanzare,
    //       pretTotal: parseFloat(element.cantitate) * parseFloat(element.pretUnitarVanzare),
    //       indiceConsultanta: 1,
    //       indiceProiectare: 1,
    //       indiceMontaj: 1,
    //       indiceProgramareSisteme: 1,
    //       indiceVizualizareAplicatatii: 1,
    //       indiceMontajDiverseEchipamente: 1,
    //       descriereProdus: element.descriereProdus,
    //       category: ''
    //     }
    //     if(this.fieldArray.length == 0 && this.productModel.category == ""){
    //       alert("Trebuie adăugată o categorie!")
    //     }
    //     else{
    //       this.fieldArray.push(this.productModel);
    //     }
    //   }
    // });
  }
  updateTable(){

    if(this.justServices == false){
      this.pretTotalFaraAdaos = 0;
      this.pretTotalCuAdaos = 0;
      this.adaos = 0;
      this.serviciiCantitate1 = 0;
      this.serviciiCantitate2 = 0;
      this.serviciiCantitate3 = 0;
      this.serviciiCantitate4 = 0;
      this.serviciiCantitate5 = 0;
      this.serviciiCantitate6 = 0;
  
      this.fieldArray.forEach((element: {indiceMontajDiverseEchipamente: number; indiceVizualizareAplicatatii: number; indiceProgramareSisteme: number; indiceMontaj: number; indiceProiectare: number; indiceConsultanta: number; pretTotal: number; cantitate: string; pretUnitarVanzare: string; adaos: string; pretUnitarAchizitie: string }) => {
        if(element.pretUnitarAchizitie != ''){
          element.pretUnitarVanzare = (parseFloat(element.pretUnitarAchizitie) + (parseFloat(element.pretUnitarAchizitie) * (parseFloat(element.adaos) / 100))).toFixed(2);
          element.pretTotal = parseFloat((parseFloat(element.cantitate) * parseFloat(element.pretUnitarVanzare)).toFixed(2));
          this.pretTotalFaraAdaos = this.pretTotalFaraAdaos + parseFloat(element.cantitate) * parseFloat(element.pretUnitarAchizitie);
          this.pretTotalFaraAdaos = parseFloat(this.pretTotalFaraAdaos.toFixed(2));
          this.pretTotalCuAdaos = this.pretTotalCuAdaos + element.pretTotal;
          this.pretTotalCuAdaos = parseFloat(this.pretTotalCuAdaos.toFixed(2));
          this.adaos = this.pretTotalCuAdaos - this.pretTotalFaraAdaos;
          this.adaos = parseFloat(this.adaos.toFixed(2));
          this.serviciiCantitate1 = this.serviciiCantitate1 + (parseFloat(element.cantitate) * element.indiceConsultanta);
          this.serviciiCantitate2 = this.serviciiCantitate2 + (parseFloat(element.cantitate) * element.indiceProiectare);
          this.serviciiCantitate3 = this.serviciiCantitate3 + (parseFloat(element.cantitate) * element.indiceMontaj);
          this.serviciiCantitate4 = this.serviciiCantitate4 + (parseFloat(element.cantitate) * element.indiceProgramareSisteme);
          this.serviciiCantitate5 = this.serviciiCantitate5 + (parseFloat(element.cantitate) * element.indiceVizualizareAplicatatii);
          this.serviciiCantitate6 = this.serviciiCantitate6 + (parseFloat(element.cantitate) * element.indiceMontajDiverseEchipamente);
          this.serviciiPT1 = this.serviciiPU1 * this.serviciiCantitate1;
          this.serviciiPT2 = this.serviciiPU2 * this.serviciiCantitate2;
          this.serviciiPT3 = this.serviciiPU3 * this.serviciiCantitate3;
          this.serviciiPT4 = this.serviciiPU4 * this.serviciiCantitate4;
          this.serviciiPT5 = this.serviciiPU5 * this.serviciiCantitate5;
          this.serviciiPT6 = this.serviciiPU6 * this.serviciiCantitate6;
        }
      });
    }
    else{
      this.serviciiPT1 = this.serviciiPU1 * this.serviciiCantitate1;
      this.serviciiPT2 = this.serviciiPU2 * this.serviciiCantitate2;
      this.serviciiPT3 = this.serviciiPU3 * this.serviciiCantitate3;
      this.serviciiPT4 = this.serviciiPU4 * this.serviciiCantitate4;
      this.serviciiPT5 = this.serviciiPU5 * this.serviciiCantitate5;
      this.serviciiPT6 = this.serviciiPU6 * this.serviciiCantitate6;
    }

    

  }
  addCategory(){
    this.productModel = {
      producator: '',
      cod: '',
      denumire: '',
      cantitate: '',
      pretUnitarAchizitie: '',
      adaos:'',
      pretUnitarVanzare: '',
      pretTotal: '',
      indiceConsultanta: '',
      indiceProiectare: '',
      indiceMontaj: '',
      indiceProgramareSisteme: '',
      indiceVizualizareAplicatatii: '',
      indiceMontajDiverseEchipamente: '',
      descriereProdus: '',
      category: this.category
    }
    this.fieldArray.push(this.productModel);
  }

  async getDescription(){
    this.sessionData.inventarOfertare.ofertareGestiuneDescriere.forEach(async (element: any) => {
      if (element.nume.includes(this.nameDescription)) {
        console.log(element);
        this.ofertareGestiuneDescriereAdd = {
          id: element.id,
          image: element.image,
          nume: element.nume,
          descriere: element.descriere
        }
        this.addOfertaCompleta.ofertareGestiuneDescriere.push(this.ofertareGestiuneDescriereAdd)

        //adauga descriere in tabelul pentru ofertare
        this.ofertareGestiuneDescriereAddPdfPush = {
          id: element.id,
          image: element.image,
          nume: element.nume,
          descriere: element.descriere,
          imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
        }
        this.ofertareGestiuneDescriereAddPdf.push(this.ofertareGestiuneDescriereAddPdfPush);

        //adauga descriere in tabel
        this.descrieriFieldArray.push(this.ofertareGestiuneDescriereAdd);
     }
    });
  }
  async deleteFieldDescriere(index: number, item: any){
    this.descrieriFieldArray.splice(index, 1);
    this.ofertareGestiuneDescriereAdd = {
          id: item.id,
          image: item.image,
          nume: item.nume,
          descriere: item.descriere
    }
    this.addOfertaCompleta.ofertareGestiuneDescriere =  this.addOfertaCompleta.ofertareGestiuneDescriere.filter((obj: any) => obj.id !== this.ofertareGestiuneDescriereAdd.id)

    this.ofertareGestiuneDescriereAddPdfPush = {
      id: item.id,
      image: item.image,
      nume: item.nume,
      descriere: item.descriere,
      imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
    }

    this.ofertareGestiuneDescriereAddPdf =  this.ofertareGestiuneDescriereAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneDescriereAddPdfPush.id)
  }

  async getEquipments(){
    this.sessionData.inventarOfertare.ofertareGestiuneEchipament.forEach(async (element: { id: string | undefined; image: string; nume: any; descriere: any; }) => {
      if (element.nume.includes(this.nameEchipament)) {

        this.ofertareGestiuneEchipamentAdd = {
          id: element.id,
          image: element.image,
          nume: element.nume,
          descriere: element.descriere
        }
      this.addOfertaCompleta.ofertareGestiuneEchipament.push(this.ofertareGestiuneEchipamentAdd);

      //adauga echipament in tabelul pentru ofertare
      this.ofertareGestiuneEchipamentAddPdfPush = {
        id: element.id,
        image: element.image,
        nume: element.nume,
        descriere: element.descriere,
        imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
      }

      this.ofertareGestiuneEchipamentAddPdf.push(this.ofertareGestiuneEchipamentAddPdfPush);

      //adauga echipament in tabel
      this.echipamenteFieldArray.push(this.ofertareGestiuneEchipamentAdd);
   }
  });
  }
  async deleteFieldEchipament(index: number, item: any){
    this.echipamenteFieldArray.splice(index, 1);
    this.ofertareGestiuneEchipamentAdd = {
      id: item.id,
      image: item.image,
      nume: item.nume,
      descriere: item.descriere
    }
    this.addOfertaCompleta.ofertareGestiuneEchipament =  this.addOfertaCompleta.ofertareGestiuneEchipament.filter((obj: any) => obj.id !== this.ofertareGestiuneEchipamentAdd.id)

    this.ofertareGestiuneEchipamentAddPdfPush = {
      id: item.id,
      image: item.image,
      nume: item.nume,
      descriere: item.descriere,
      imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
    }

    this.ofertareGestiuneEchipamentAddPdf =  this.ofertareGestiuneEchipamentAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneEchipamentAddPdfPush.id)
  }

  async getVisualizations(){
    this.sessionData.inventarOfertare.ofertareGestiuneVizualizare.forEach(async (element: { id: string | undefined; image: string; nume: any; descriere: any; }) => {
      if (element.nume.includes(this.nameVizualizare)) {

        this.ofertareGestiuneVizualizareAdd = {
          id: element.id,
          image: element.image,
          nume: element.nume,
        }
        this.addOfertaCompleta.ofertareGestiuneVizualizare.push(this.ofertareGestiuneVizualizareAdd);

        //adauga vizualizare in tabelul pentru ofertare
        this.ofertareGestiuneVizualizareAddPdfPush = {
          id: element.id,
          image: element.image,
          nume: element.nume,
          imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + element.image)
        }
        this.ofertareGestiuneVizualizareAddPdf.push(this.ofertareGestiuneVizualizareAddPdfPush);

      //adauga vizualizare in tabel
      this.vizualizariFieldArray.push(this.ofertareGestiuneVizualizareAdd);
   }
  });
  }
  async deleteFieldVizualizari(index: number, item: any){
    this.vizualizariFieldArray.splice(index, 1);
    this.ofertareGestiuneVizualizareAdd = {
          id: item.id,
          image: item.image,
          nume: item.nume,
        }
        this.addOfertaCompleta.ofertareGestiuneVizualizare =  this.addOfertaCompleta.ofertareGestiuneVizualizare.filter((obj: any) => obj.id !== this.ofertareGestiuneVizualizareAdd.id)

        this.ofertareGestiuneVizualizareAddPdfPush = {
          id: item.id,
          image: item.image,
          nume: item.nume,
          imageBase64: await this.getBase64ImageFromURL(this.appUrl + 'assets/' + item.image)
        }

        this.ofertareGestiuneVizualizareAddPdf =  this.ofertareGestiuneVizualizareAddPdf.filter((obj: any) => obj.id !== this.ofertareGestiuneVizualizareAddPdfPush.id)
    }

  addServices(item1: any, item2: any, item3: any){
    this.addServiciuManoperaOfertaAdd = {
      serviciuManoperaId: item1,
      pretUnitar: item2,
      pretTotal: item3
    }
    this.addOfertaCompleta.addServiciuManoperaOferta.push(this.addServiciuManoperaOfertaAdd);
  }

  async generatePdf(){
    this.sessionData.proiecte.forEach((element: { denumireProiect: any; id: any;}) => {
      if(element.denumireProiect == this.numeProiect){
        this.proiectId = element.id;
      }
    });
    var denumireOfertaPdf = this.denumireOferta;
    this.addOfertaCompleta.numar = this.numar;
    this.addOfertaCompleta.user = this.sessionData.user;
    this.addOfertaCompleta.proiectId = this.proiectId;
    this.addOfertaCompleta.data = new Date(Date.now());
    this.addOfertaCompleta.status = this.status;
    this.addOfertaCompleta.beneficiar = this.beneficiar;
    this.addOfertaCompleta.denumireOferta = this.denumireOferta;
    let numar = this.numar;
    var dd1 :any = {
      footer: function(currentPage: any) {return [
        {canvas: [ { type: 'line', x1: 40, y1: 0, x2: 595-30, y2: 0, lineWidth: 1,color:'#55D567' } ]},
        {
            alignment: 'center',
            margin: [30, 5, 30, 0],
            columns: [
              {
                  text: 'www.green-electric.ro',
                  alignment: 'center',
                  style: 'footer',
                  width: '24%',
              },
              {
                  text: 'www.wireeo.ro',
                  alignment: 'center',
                  style: 'footer',
                  width: '24%',
              },
              {
                  text: 'office@green-electric.ro',
                  alignment: 'center',
                  style: 'footer',
                  width: '24%',
              },
              {
                  text: 'OFERTA NR: ' + numar.toString(),
                  alignment: 'center',
                  style: 'footer',
                  width: '24%',
              },
              {
                  text: currentPage.toString(),
                  alignment: 'center',
                  style: 'footer',
                  width: '4%',
              }
            ]},
        ]},
      content: [
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        {
          image: 'image1',
          width: 300,
          alignment: 'center'
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        {text: 'OFERTA NR ' + this.numar, style: 'headerrr'},
        {text: this.datepipe.transform(new Date(), 'dd-MM-yyyy'), style: 'headerrr'},
        {text: this.denumireOferta, style: 'headerr'},
        {text: this.beneficiar, style: 'headerb'},
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        {
          image: 'image2',
          width: 450,
          alignment: 'center'
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        {
          image: 'image3',
          width: 450,
          alignment: 'center'
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: this.tabelWhiteSpace
          },
          layout: 'lightHorizontalLines'
        },
        this.getTableDescrieri(),
        {text: 'OFERTA SISTEM AUTOMATIZARE', style: 'header', pageBreak: 'before'},
        this.getTableProduse(),
        this.getTableDetalii(),
        this.getTableEchipamente(),
        this.getTableVizualizari(),
      ],
      styles: {
        subheader: {
          fontSize: 15,
          margin: [0, 9, 0, 9],
          bold: false,
          alignment: 'center',
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 7,
          color: 'black'
        },
        tableBody: {
          fontSize: 7
        },
        footer: {
          fontSize: 7,
          bold: true
        },
        tableBody1: {
          fontSize: 7,
          bold: true
        },
        tableBody2: {
          fontSize: 7,
          bold: true
        },
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 9, 0, 25]    
        },
        headerb: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 9, 0, 25]    
        },
        headerr: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 35, 0, 25]
        },
        headerrr:{
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 0]
        },
        titluDescriere: {
          fontSize: 10,
          bold: true,
        },
        textDescriere: {
          fontSize: 11,
          bold: true,
        },
        textEchipament: {
          fontSize: 11,
          bold: true,
        }
      },
      defaultStyle: {
        // alignment: 'justify'
      },
      images: {
        image1: this.imageSrc1,
        image2: this.imageSrc2,
        image3: this.imageSrc3,
        // image4: this.imageSrc4,
        // image5: this.imageSrc5
      }

    }

    pdfMake.createPdf(dd1).open();
    const pdfDocGenerator = pdfMake.createPdf(dd1);
    this.addOfertaCompleta.pdfFile =  await this.pdfToBase64(pdfDocGenerator);

    localStorage.removeItem('NumarOferta');
    localStorage.removeItem('NumeClient');
    localStorage.removeItem('NumeProiect'); 
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/OfertareGestiuneOferte/addOfertareCompleta', this.addOfertaCompleta, header).subscribe(
      (response) => {
        this.router.navigate(['/ofertare-gestiune-oferte'])
          .then(() => {
            window.location.reload();
          });
      },
      (error) => {
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );


  }
 async pdfToBase64(pdfDocGenerator: any){
    return new Promise((resolve,reject) => {
      this.addOfertaCompleta.pdfFile =  pdfDocGenerator.getBase64((data: any) => {
        resolve(data);
      });
    });
  }
  getBase64ImageFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  getTableDescrieri() {

    if(this.ofertareGestiuneDescriereAddPdf.length != 0){
      var x = [{text: 'CE REPREZINTA SOLUTIA KNX?', style: 'header', pageBreak: 'before', colSpan: 3, border: [false, false, false, false]}, {text: '', border: [false, false, false, false]},{text: '', border: [false, false, false, false]}];
      var ttt: any = [];
      ttt.push(x)
      this.ofertareGestiuneDescriereAddPdf.forEach(async (element: { image: any; nume: any; descriere: any; imageBase64: any; }) => {
        var tt1 = [{image: element.imageBase64, width: 50,  alignment: 'right', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]},{style:'titluDescriere', colSpan: 2, alignment: 'left', text: '\n' + element.nume, border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]},{text: '', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]}];
        ttt.push(tt1);
        var tt2 = [{style:'textDescriere', colSpan: 3, alignment: 'center', text: element.descriere,border: [false, false, false, true]}, {text: '', border: [false, false, false, false]},{text: '', border: [false, false, false, false]}];
        ttt.push(tt2);
      });
      return { style: 'tableExample', table: { body: ttt, heights: 60, widths: ['33%', '33%', '33%']},
      layout: {
        hLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.body.length) ? '#55D567' : '#55D567';
        },
        vLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.widths.length) ? '#55D567' : '#55D567';
        },
      }
      }
    }
    else{
      return null;
    }
  }

  getTableEchipamente() {
    if(this.ofertareGestiuneEchipamentAddPdf.length != 0){
      var x = [{text: 'ECHIPAMENTE PROPUSE', style: 'header', colSpan: 3, pageBreak: 'before', border: [false, false, false, false]},{text: '', border: [false, false, false, false]},{text: '', border: [false, false, false, false]}];
      var ttt: any = [];
      ttt.push(x);
      this.ofertareGestiuneEchipamentAddPdf.forEach(async (element: { image: any; nume: any; descriere: any; imageBase64: any; }) => {
        var tt1 = [{image: element.imageBase64, width: 50, colSpan: 3, alignment: 'center', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]},{text: '', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]},{text: '', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]}];
        ttt.push(tt1);
        var tt2 = [{style:'titluDescriere', colSpan: 3, alignment: 'center', text: element.nume,border: [false, false, false, false]}, {text: '', border: [false, false, false, false]},{text: '', border: [false, false, false, false]}];
        ttt.push(tt2);
        var tt3 = [{style:'textEchipament', colSpan: 3, alignment: 'center', text: element.descriere,border: [false, false, false, true]}, {text: '', border: [false, false, false, false]},{text: '', border: [false, false, false, false]}];
        ttt.push(tt3);
      });
      return { style: 'tableExample', table: { body: ttt, heights: 30, widths: ['33%', '33%', '33%'] },
      layout: {
        hLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.body.length) ? '#55D567' : '#55D567';
        },
        vLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.widths.length) ? '#55D567' : '#55D567';
        },
      }
      }
    }
    else{
      return null;
    }
  }

  getTableVizualizari() {
    if(this.ofertareGestiuneVizualizareAddPdf.length != 0){
    var x = [{text: 'EXEMPLE VIZUALIZARE', colSpan: 2, border: [false, false, false, false], style: 'header', pageBreak: 'before'}, {text: '', border: [false, false, false, false]}];
    var ttt: any = [];
    ttt.push(x);
    this.ofertareGestiuneVizualizareAddPdf.forEach(async (element: { image: any; nume: any; imageBase64: any; }) => {
      var tt2 = [{style:'titluDescriere', colSpan: 2, alignment: 'center', text: element.nume,border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]}, {text: '', border: [false, false, false, false], margin: [ 0, 10, 0, 0 ]}];
      ttt.push(tt2);
      var tt1 = [{image: element.imageBase64, colSpan: 2, width: 150, alignment: 'center', border: [false, false, false, true]},{text: '', border: [false, false, false, false]}];
      ttt.push(tt1);
    });
    return { style: 'tableExample', table: { body: ttt, heights: 40, widths: ['50%', '50%'] },

    layout: {
      hLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.body.length) ? '#55D567' : '#55D567';
      },
      vLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.widths.length) ? '#55D567' : '#55D567';
      },
    }

    }
  }
  else{
    return null;
  }
  }

  getTableDetalii(){
    if(this.addOfertaCompleta.ofertareDetaliiSuplimentare[0] && this.addOfertaCompleta.ofertareDetaliiSuplimentare[0].denumire != undefined){
      var x = [{text: 'DETALII OFERTA', alignment: 'center', style: 'header', border: [false, false, false, false]}];
      var ttt: any = [];
      ttt.push(x);
      var tt2 = [{style:'titluDescriere', alignment: 'left', text: this.addOfertaCompleta.ofertareDetaliiSuplimentare[0].denumire,border: [false, false, false, false]}];
      ttt.push(tt2);
      return { style: 'tableExample', table: { body: ttt, widths: ['100%']  }}
    }
    else{
      return null;
    }
    
  }

  getTableProduse(){
    if(this.justServices == true){
      //#region ADAUGARE TABEL SERVICII MANOPERA
      this.ofertareDetaliiSuplimentareAdd = {
        denumire: this.details,
        ofertaId: '0'
      }
      this.addOfertaCompleta.ofertareDetaliiSuplimentare.push(this.ofertareDetaliiSuplimentareAdd);
      if(this.serviciiCantitate1 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE CONSULTANTA'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate1},
                                  {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU1}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT1}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      if(this.serviciiCantitate2 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROIECTARE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate2},
        {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU2}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT2}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      if(this.serviciiCantitate3 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate3},
        {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU3}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT3}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      if(this.serviciiCantitate4 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROGRAMARE SISTEME'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate4},
        {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU4}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT4}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      if(this.serviciiCantitate5 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE VIZUALIZARE SI CONFIGURARE APLICATII'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate5},
        {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU5}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT5}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      if(this.serviciiCantitate6 != 0){
        this.tabelManoperaPush = [{colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ DIVERSE ECHIPAMENTE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate6},
                                  {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU6}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT6}]
        this.tabelManopera.push(this.tabelManoperaPush);
      }
      
      this.tabelManoperaPush = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL SERVICII(Euro fara TVA):'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: (this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6).toFixed(2)}]
      this.tabelManopera.push(this.tabelManoperaPush);
      this.tabelManoperaPush = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [true, true, true, true], borderColor: ['#55D567', '#55D567', '#55D567', '#55D567'], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL GENERAL(Euro cu TVA):'}, {border: [true, true, true, true], borderColor: ['#55D567', '#55D567', '#55D567', '#55D567'], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                  {border: [true, true, true, true], borderColor: ['#55D567', '#55D567', '#55D567', '#55D567'], colSpan: 1, alignment: 'center', style:'tableBody2', text: ((this.totalSumListaProduse + this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6) + (this.totalSumListaProduse + this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6)*0.19).toFixed(2)}]
      this.tabelManopera.push(this.tabelManoperaPush);
      //#endregion

      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[0].id, this.serviciiPU1, this.serviciiPT1);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[1].id, this.serviciiPU2, this.serviciiPT2);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[2].id, this.serviciiPU3, this.serviciiPT3);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[3].id, this.serviciiPU4, this.serviciiPT4);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[4].id, this.serviciiPU5, this.serviciiPT5);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[5].id, this.serviciiPU6, this.serviciiPT6);
      return { style: 'tableExample', table: { body: this.tabelManopera, headerRows: 1, widths: ['25%', '25%', '25%', '25%'] }, layout: 'lightHorizontalLines'};
    }
    else if(this.justProducts == true){
      this.nrCrtListaProduse = 0;
      this.ofertareDetaliiSuplimentareAdd = {
        denumire: this.details,
        ofertaId: '0'
      }
      this.addOfertaCompleta.ofertareDetaliiSuplimentare.push(this.ofertareDetaliiSuplimentareAdd);
      this.fieldArray.forEach((element: {pretTotal: string, category: string; pretUnitarAchizitie: string; id: any; producator: any; cod: any; denumire: any; cantitate: any; adaos: any; pretUnitarVanzare: any; descriereProdus: any; indiceConsultanta: any; indiceProiectare: any; indiceMontaj: any; indiceProgramareSisteme: any; indiceVizualizareAplicatatii: any; indiceMontajDiverseEchipamente: any; }) => {
        if(element.pretUnitarAchizitie != ''){
          this.ofertareProdusCompletAdd = {
            id: element.id,
            producator: element.producator,
            cod: element.cod,
            denumire: element.denumire,
            cantitate: element.cantitate,
            pretUnitarAchizitie: element.pretUnitarAchizitie,
            adaos: element.adaos,
            pretUnitarVanzare: element.pretUnitarVanzare,
            descriereProdus: element.descriereProdus,
            indiceConsultanta: element.indiceConsultanta,
            indiceProiectare: element.indiceProiectare,
            indiceMontaj: element.indiceMontaj,
            indiceProgramareSisteme: element.indiceProgramareSisteme,
            indiceVizualizareAplicatatii: element.indiceVizualizareAplicatatii,
            indiceMontajDiverseEchipamente: element.indiceMontajDiverseEchipamente
          }
          this.addOfertaCompleta.ofertareProdusComplet.push(this.ofertareProdusCompletAdd);

          //DATE TABEL
          this.partialSumListaProduse += parseFloat(element.pretTotal);
          this.totalSumListaProduse += parseFloat(element.pretTotal);
          this.nrCrtListaProduse = this.nrCrtListaProduse + 1;
          this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: this.nrCrtListaProduse}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.denumire}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cantitate}, {style:'tableBody', colSpan: 1, alignment: 'center', text: parseFloat(element.pretUnitarVanzare).toFixed(2)}, {style:'tableBody', colSpan: 1, alignment: 'center', text: parseFloat(element.pretTotal).toFixed(2)}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.descriereProdus}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.producator}]
          this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
        }
        else{
          this.ofertareCategorieProduseAdd = {
            denumire: element.category,
            pozitie: this.fieldArray.indexOf(element),
            ofertaId: '0'
          }
          this.addOfertaCompleta.ofertareCategorieProduse.push(this.ofertareCategorieProduseAdd);

          //DATE TABEL
          if(this.partialSumListaProduse != 0){
            this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PARTIAL(Euro fara TVA):', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.partialSumListaProduse.toFixed(2), fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
            this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
          }
          this.partialSumListaProduse = 0;
          this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], colSpan: 6, style:'tableBody', alignment: 'center', text: element.category}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''}]
          this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
        }
      });

      //#region ADAUGARE PRET TOTAL PRIMUL TABEL
      
      this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PARTIAL(Euro fara TVA):', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.partialSumListaProduse.toFixed(2), fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

      this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, rowSpan: 2, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody1', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
      
      this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PRODUSE(Euro fara TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.totalSumListaProduse.toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

      this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PRODUSE(Euro cu TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: (this.totalSumListaProduse + this.totalSumListaProduse*0.19).toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

      
      //#endregion
      
       //#region ADAUGARE SERVICII LA OFERTARE

      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[0].id, this.serviciiPU1, this.serviciiPT1);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[1].id, this.serviciiPU2, this.serviciiPT2);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[2].id, this.serviciiPU3, this.serviciiPT3);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[3].id, this.serviciiPU4, this.serviciiPT4);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[4].id, this.serviciiPU5, this.serviciiPT5);
      this.addServices(this.sessionData.inventarOfertare.serviciuManopera[5].id, this.serviciiPU6, this.serviciiPT6);

    //#endregion

      
      return { style: 'tableExample', table: { body: this.externalDataRetrievedFromServer, headerRows: 1, widths: ['5%', '25%', '10%', '10%', '10%', '25%', '15%'] }, layout: 'lightHorizontalLines'};

    }
    else{
      this.nrCrtListaProduse = 0;
      this.ofertareDetaliiSuplimentareAdd = {
        denumire: this.details,
        ofertaId: '0'
      }
      this.addOfertaCompleta.ofertareDetaliiSuplimentare.push(this.ofertareDetaliiSuplimentareAdd);
      this.fieldArray.forEach((element: {pretTotal: string, category: string; pretUnitarAchizitie: string; id: any; producator: any; cod: any; denumire: any; cantitate: any; adaos: any; pretUnitarVanzare: any; descriereProdus: any; indiceConsultanta: any; indiceProiectare: any; indiceMontaj: any; indiceProgramareSisteme: any; indiceVizualizareAplicatatii: any; indiceMontajDiverseEchipamente: any; }) => {
        if(element.pretUnitarAchizitie != ''){
          this.ofertareProdusCompletAdd = {
            id: element.id,
            producator: element.producator,
            cod: element.cod,
            denumire: element.denumire,
            cantitate: element.cantitate,
            pretUnitarAchizitie: element.pretUnitarAchizitie,
            adaos: element.adaos,
            pretUnitarVanzare: element.pretUnitarVanzare,
            descriereProdus: element.descriereProdus,
            indiceConsultanta: element.indiceConsultanta,
            indiceProiectare: element.indiceProiectare,
            indiceMontaj: element.indiceMontaj,
            indiceProgramareSisteme: element.indiceProgramareSisteme,
            indiceVizualizareAplicatatii: element.indiceVizualizareAplicatatii,
            indiceMontajDiverseEchipamente: element.indiceMontajDiverseEchipamente
          }
          this.addOfertaCompleta.ofertareProdusComplet.push(this.ofertareProdusCompletAdd);

          //DATE TABEL
          this.partialSumListaProduse += parseFloat(element.pretTotal);
          this.totalSumListaProduse += parseFloat(element.pretTotal);
          this.nrCrtListaProduse = this.nrCrtListaProduse + 1;
          this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: this.nrCrtListaProduse}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.denumire}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cantitate}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.pretUnitarVanzare}, {style:'tableBody', colSpan: 1, alignment: 'center', text: parseFloat(element.pretTotal).toFixed(2)}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.descriereProdus}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.producator}]
          this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
        }
        else{
          this.ofertareCategorieProduseAdd = {
            denumire: element.category,
            pozitie: this.fieldArray.indexOf(element),
            ofertaId: '0'
          }
          this.addOfertaCompleta.ofertareCategorieProduse.push(this.ofertareCategorieProduseAdd);

          //DATE TABEL
          if(this.partialSumListaProduse != 0){
            this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PARTIAL(Euro fara TVA):', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.partialSumListaProduse.toFixed(2), fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
            this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
          }
          this.partialSumListaProduse = 0;
          this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], colSpan: 6, style:'tableBody', alignment: 'center', text: element.category}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''}]
          this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
        }
      });

    //#region ADAUGARE PRET TOTAL PRIMUL TABEL
    
    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PARTIAL(Euro fara TVA):', fillColor: '#F3F3F3',}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.partialSumListaProduse.toFixed(2), fillColor: '#F3F3F3'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, rowSpan: 2, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody1', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
                                            this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL PRODUSE(Euro fara TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: this.totalSumListaProduse.toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    // this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL produse(Euro cu TVA):'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
    //                                                     {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: (this.totalSumListaProduse + this.totalSumListaProduse*0.19).toFixed(2)}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
    //                                                     {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    // this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, rowSpan: 2, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody1', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    //#endregion

    //#region ADAUGARE TABEL SERVICII MANOPERA

    if(this.serviciiCantitate1 != 0){
      this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE CONSULTANTA'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate1},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU1}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT1}, {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {border: [false, false, false, false], colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    if(this.serviciiCantitate2 != 0){
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROIECTARE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate2},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU2}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT2}, {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    if(this.serviciiCantitate3 != 0){
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate3},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU3}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT3}, {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    if(this.serviciiCantitate4 != 0){
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE PROGRAMARE SISTEME'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate4},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU4}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT4}, {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    if(this.serviciiCantitate5 != 0){
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE VIZUALIZARE SI CONFIGURARE APLICATII'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate5},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU5}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT5}, {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    if(this.serviciiCantitate6 != 0){
      this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: ''}, {colSpan: 1, style:'tableBody', alignment: 'center', text: 'SERVICII DE MONTAJ DIVERSE ECHIPAMENTE'}, {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiCantitate6},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: this.serviciiPU6}, {colSpan: 1, alignment: 'center', style:'tableBody', text: this.serviciiPT6}, {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},
                                                          {colSpan: 1, style:'tableBody', alignment: 'center', text: ''},]
      this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    }
    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL SERVICII(Euro fara TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: (this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6).toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, rowSpan: 2, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody1', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, rowSpan: 2, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody1', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                  {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
                                              this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false],  colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL GENERAL(Euro fara TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: (this.totalSumListaProduse + this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6).toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: 'TOTAL GENERAL(Euro cu TVA):', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: '', fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, alignment: 'center', style:'tableBody2', text: ((this.totalSumListaProduse + this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6) + (this.totalSumListaProduse + this.serviciiPT1 + this.serviciiPT2 + this.serviciiPT3 + this.serviciiPT4 + this.serviciiPT5 + this.serviciiPT6)*0.19).toFixed(2), fillColor: '#55D567'}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);

    this.externalDataRetrievedFromServerPubsh = [{border: [false, false, false, false], style:'tableBody1', colSpan: 1, alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody2', alignment: 'center', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''}, {border: [true, true, true, true], colSpan: 1, alignment: 'center', style:'tableBody2', text: ''}, {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},
                                                        {border: [false, false, false, false], colSpan: 1, style:'tableBody1', alignment: 'center', text: ''},]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
    //#endregion

    //#region ADAUGARE SERVICII LA OFERTARE

    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[0].id, this.serviciiPU1, this.serviciiPT1);
    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[1].id, this.serviciiPU2, this.serviciiPT2);
    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[2].id, this.serviciiPU3, this.serviciiPT3);
    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[3].id, this.serviciiPU4, this.serviciiPT4);
    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[4].id, this.serviciiPU5, this.serviciiPT5);
    this.addServices(this.sessionData.inventarOfertare.serviciuManopera[5].id, this.serviciiPU6, this.serviciiPT6);

    //#endregion

    return { style: 'tableExample', table: { body: this.externalDataRetrievedFromServer, headerRows: 1, widths: ['5%', '25%', '10%', '10%', '10%', '25%', '15%'] }, layout: 'lightHorizontalLines'};
    }
  }
}

