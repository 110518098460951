import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-finalizate',
  templateUrl: './proiecte-finalizate.component.html',
  styleUrls: ['./proiecte-finalizate.component.css']
})
export class ProiecteFinalizateComponent implements OnInit {
  //#region Declarari
  projectOption: any = "Toate";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id: '',
    denumireProiect: '',
    locatie: '',
    numeUseri: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: ''
  }

  addProiectModel: any ={
    denumireProiect: '',
    locatie: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: '',
    numarContract: '',
    numePersoanaContact: '',
    prenumePersoanaContact: '',
    telefon: '',
    email: '',
    userId: ''
  }

  editProiectModel: any = {
    id: '',
    denumireProiect: '',
    locatie: '',
    prioritate: '',
    status: '',
    valoareProduse: '',
    valoareServicii: '',
    valoareTotala: '',
    numarContract: '',
    numePersoanaContact: '',
    prenumePersoanaContact: '',
    telefon: '',
    email: '',
    userId: ''
  }

  fileName= 'ExcelSheet.xlsx';
  userName : any;
//#endregion
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    sessionData.clientId = localStorage.getItem('ClientId');
  }
  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "PROIECTE";
    
    const proiecte = this.authService.getProiecteFinalizate();
    await lastValueFrom(proiecte);
  }

  //#region Adauga proiect
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  if(fAddCategory.value.Prioritate == ""){
    this.message = "Alege prioritatea proiectului!";
    return;
  }
  else if(fAddCategory.value.Status == ""){
    this.message = "Alege statusul proiectului!";
    return;
  }


  this.addProiectModel ={
    denumireProiect: fAddCategory.value.DenumireProiect,
    locatie: fAddCategory.value.Locatie,
    prioritate: fAddCategory.value.Prioritate,
    status: fAddCategory.value.Status,
    valoareProduse: fAddCategory.value.ValoareProduse, 
    valoareServicii: fAddCategory.value.ValoareServicii,
    valoareTotala: fAddCategory.value.ValoareTotala,
    numarContract: fAddCategory.value.NumarContract,
    numePersoanaContact: fAddCategory.value.NumePersoanaContact,
    prenumePersoanaContact: fAddCategory.value.PrenumePersoanaContact,
    telefon: fAddCategory.value.Telefon,
    email: fAddCategory.value.Email,
    userId: this.sessionData.clientId
  }
  // var header = {
  //   headers: new HttpHeaders()
  //     .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  // }
  // return this.http.post(this.appUrl + 'api/proiect/', this.addProiectModel, header).subscribe(
  //   (response) => {    
  //     window.location.reload();
  //   },
  //   (error) => { 
  //     console.error('error caught in component')
  //     this.message = "Eroare"
  //   }
  // );

}
//#endregion

//#region Editare proiect

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.proiectModel = {
      id: gp.id,
      denumireProiect: gp.denumireProiect,
      locatie: gp.locatie,
      prioritate: gp.prioritate,
      status: gp.status,
      valoareProduse: gp.valoareProduse,
      valoareServicii: gp.valoareServicii,
      valoareTotala: gp.valoareTotala,
      numarContract: gp.numarContract,
      numePersoanaContact: gp.numePersoanaContact,
      prenumePersoanaContact: gp.prenumePersoanaContact,
      telefon: gp.telefon,
      email: gp.email,
      userId: gp.userId,
    }
  }

  EditResource(fEditCategory: NgForm){

    this.editProiectModel = {
      id: this.proiectModel.id,
      denumireProiect: fEditCategory.value.DenumireProiect,
      locatie: fEditCategory.value.Locatie,
      prioritate: fEditCategory.value.Prioritate,
      status: fEditCategory.value.Status,
      valoareProduse: fEditCategory.value.ValoareProduse,
      valoareServicii: fEditCategory.value.ValoareServicii,
      valoareTotala: fEditCategory.value.ValoareTotala,
      numarContract: fEditCategory.value.NumarContract,
      numePersoanaContact: fEditCategory.value.NumePersoanaContact,
      prenumePersoanaContact: fEditCategory.value.PrenumePersoanaContact,
      telefon: fEditCategory.value.Telefon,
      email: fEditCategory.value.Email,
      userId: this.proiectModel.userId
    }
    console.log(this.editProiectModel);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/Proiect/' + this.editProiectModel.id, this.editProiectModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Proiect
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/Proiect/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

Search(){
  if(this.denumire != ""){
    this.sessionData.proiecte = this.sessionData.proiecte.filter((res: { denumireProiect: string; }) => {
      return res.denumireProiect.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.ngOnInit();
  }

}

selectChangeHandler (event: any) {
  //update the ui
  this.projectOption = event.target.value;
}

goToTask(project: any){
  this.sessionData.productId = project.id;
  // localStorage.setItem('ProjectId',project.id);
  // localStorage.setItem('ProjectName',project.denumireProiect);
  
  this.router.navigate(['/task/' + project.id + '/' + project.denumireProiect]);
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
}}

//#region excel angajat
exportExcelProiect(content: any) : void {

  this.modalService.open(content);
}

ExportExcelProiectResource(fexportExcelProiect: any){

  console.log(fexportExcelProiect.value.User);
  this.http.post(this.appUrl + 'api/Proiect/generateReportProiecte/' + fexportExcelProiect.value.User, fexportExcelProiect.value.User, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
//#endregion


}
