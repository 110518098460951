import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GestiunePrincipalaComponent } from './components/materiale/gestiune-principala/gestiune-principala.component';
import { GestiunePersonalaComponent } from './components/materiale/gestiune-personala/gestiune-personala.component';
import { GestiuneProiecteComponent } from './components/materiale/gestiune-proiecte/gestiune-proiecte.component';
import { GestiuneAngajatiComponent } from './components/materiale/gestiune-angajati/gestiune-angajati.component';
import { GestiuneTransferComponent } from './components/materiale/gestiune-transfer/gestiune-transfer.component';
import { GestiuneVanzariComponent } from './components/materiale/gestiune-vanzari/gestiune-vanzari.component';
import { RezervareProiecteComponent } from './components/materiale/rezervare-proiecte/rezervare-proiecte.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProductDetailComponent } from './components/materiale/product-detail/product-detail.component';
import { DatePipe } from '@angular/common'
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ProiecteComponent } from './components/clienti/proiecte/proiecte.component';
import { ListaAngajatiComponent } from './components/proiecte/lista-angajati/lista-angajati.component';
import { TabelPrezentaComponent } from './components/prezenta/tabel-prezenta/tabel-prezenta.component';
import { AddPrezentaBirouComponent } from './components/prezenta/add-prezenta-birou/add-prezenta-birou.component';
import { AddPrezentaAtelierComponent } from './components/prezenta/add-prezenta-atelier/add-prezenta-atelier.component';
import { AddPrezentaProiectComponent } from './components/prezenta/add-prezenta-proiect/add-prezenta-proiect.component';
import { OfertareGestiuneProduseComponent } from './components/ofertare/ofertare-gestiune-produse/ofertare-gestiune-produse.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { AuthGuard } from './guard/auth-guard.service';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { ArticoleManoperaComponent } from './components/ofertare/articole-manopera/articole-manopera.component';
import { ListaClientiComponent } from './components/clienti/lista-clienti/lista-clienti.component';
import { OfertareGestiuneOferteComponent } from './components/ofertare/ofertare-gestiune-oferte/ofertare-gestiune-oferte.component';
import { OfertareGestiuneDescrieriComponent } from './components/ofertare/ofertare-gestiune-descrieri/ofertare-gestiune-descrieri.component';
import { OfertareGestiuneEchipamenteComponent } from './components/ofertare/ofertare-gestiune-echipamente/ofertare-gestiune-echipamente.component';
import { OfertareGestiuneVizualizariComponent } from './components/ofertare/ofertare-gestiune-vizualizari/ofertare-gestiune-vizualizari.component';
import { OfertareCreareOfertaComponent } from './components/ofertare/ofertare-creare-oferta/ofertare-creare-oferta.component';
import { OfertareEditareOfertaComponent } from './components/ofertare/ofertare-editare-oferta/ofertare-editare-oferta.component';
import { GestiuneAprovizionareComponent } from './components/aprovizionare/gestiune-aprovizionare/gestiune-aprovizionare.component';
import { GestiuneComandaComponent } from './components/aprovizionare/gestiune-comanda/gestiune-comanda.component';
import { GestiuneAprovizionareComandaComponent } from './components/aprovizionare/gestiune-aprovizionare-comanda/gestiune-aprovizionare-comanda.component';
import { TabloterieProiecteComponent } from './components/tabloterie/tabloterie-proiecte/tabloterie-proiecte.component';
import { TabloterieTablouriComponent } from './components/tabloterie/tabloterie-tablouri/tabloterie-tablouri.component';
import { TabloterieProduseComponent } from './components/tabloterie/tabloterie-produse/tabloterie-produse.component';
import { GestiuneTabloterieComponent } from './components/tabloterie/gestiune-tabloterie/gestiune-tabloterie.component';
import { PrezentaTabloterieComponent } from './components/prezenta/prezenta-tabloterie/prezenta-tabloterie.component';
import { ListaProduseDecomandatComponent } from './components/aprovizionare/lista-produse-decomandat/lista-produse-decomandat.component';
import { SolicitaServiciileNoastreComponent } from './components/solicita-serviciile-noastre/solicita-serviciile-noastre.component';
import { DatePersonaleComponent } from './components/date-personale/date-personale.component';
import { DashboardClientComponent } from './components/clienti/dashboard-client/dashboard-client.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ProiecteClientiComponent } from './components/proiecte-clienti/proiecte-clienti.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { GestiuneAprovizionareRezervariComponent } from './components/aprovizionare/gestiune-aprovizionare-rezervari/gestiune-aprovizionare-rezervari.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { OferteDashboardComponent } from './components/clienti/oferte-dashboard/oferte-dashboard.component';
import { ContracteDashboardComponent } from './components/clienti/contracte-dashboard/contracte-dashboard.component';
import { FacturiDashboardComponent } from './components/clienti/facturi-dashboard/facturi-dashboard.component';
import { RapoartedelucruDashboardComponent } from './components/clienti/rapoartedelucru-dashboard/rapoartedelucru-dashboard.component';
import { DiverseDashboardComponent } from './components/clienti/diverse-dashboard/diverse-dashboard.component';
import { GestiuneFotovoltaiceComponent } from './components/fotovoltaice/gestiune-fotovoltaice/gestiune-fotovoltaice.component';
import { FotovoltaiceProiecteComponent } from './components/fotovoltaice/fotovoltaice-proiecte/fotovoltaice-proiecte.component';
import { FotovoltaiceProduseComponent } from './components/fotovoltaice/fotovoltaice-produse/fotovoltaice-produse.component';
import { PrezentaFotovoltaiceComponent } from './components/prezenta/prezenta-fotovoltaice/prezenta-fotovoltaice.component';
import { StocCriticComponent } from './components/aprovizionare/stoc-critic/stoc-critic.component';
import { ProceseVerbaleComponent } from './components/clienti/procese-verbale/procese-verbale.component';
import { CreareAvizLivrareComponent } from './components/clienti/creare-aviz-livrare/creare-aviz-livrare.component';
import { CreareProcesVerbalReceptieComponent } from './components/clienti/creare-proces-verbal-receptie/creare-proces-verbal-receptie.component';
import { DocumentatieTehnicaDashboardComponent } from './components/clienti/documentatie-tehnica-dashboard/documentatie-tehnica-dashboard.component';
import { FisiereClientComponent } from './components/clienti/fisiere-client/fisiere-client.component';
import { RoluriComponent } from './components/roluri/lista-roluri/lista-roluri.component';
import { AdaugaRolComponent } from './components/roluri/adauga-rol/adauga-rol.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { EditRolComponent } from './components/roluri/edit-rol/edit-rol.component';
import { TaskComponent } from './components/proiecte/task/task.component';
import { InterventiiTaskComponent } from './components/proiecte/interventii-task/interventii-task.component';
import { TaskChatComponent } from './components/proiecte/task-chat/task-chat.component';
import { DashboardProiecteComponent } from './components/dashboard-proiecte/dashboard-proiecte.component';
import { DashboardTaskComponent } from './components/dashboard-task/dashboard-task.component';
import { DashboardGrupuriComponent } from './components/dashboard-grupuri/dashboard-grupuri.component';
import { DashboardTaskUserComponent } from './components/dashboard-task-user/dashboard-task-user.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { SesizareTehnicaComponent } from './components/procese/sesizare-tehnica/sesizare-tehnica.component';
import { TabelSesizariTehniceComponent } from './components/procese/tabel-sesizari-tehnice/tabel-sesizari-tehnice.component';
import { ProiecteFinalizateComponent } from './components/clienti/proiecte-finalizate/proiecte-finalizate.component';
import { GestiuneAnunturiComponent } from './components/gestiune-anunturi/gestiune-anunturi.component';
import { AcceptaAnuntComponent } from './components/accepta-anunt/accepta-anunt.component';
import { ProtectiaMunciiComponent } from './components/protectia-muncii/protectia-muncii.component';
import { TablouriFinalizateComponent } from './components/tabloterie/tablouri-finalizate/tablouri-finalizate.component';
import { RapoarteProiectComponent } from './components/rapoarte-proiect/rapoarte-proiect.component';
import { RapoarteCompletePeProiectComponent } from './components/proiecte/rapoarte-complete-pe-proiect/rapoarte-complete-pe-proiect.component';
import { RapoarteCompleteComponent } from './components/rapoarte-proiecte/rapoarte-complete/rapoarte-complete.component';
import { RapoartePersonalizateComponent } from './components/rapoarte-proiecte/rapoarte-personalizate/rapoarte-personalizate.component';
import { RapoartePeriodiceComponent } from './components/rapoarte-proiecte/rapoarte-periodice/rapoarte-periodice.component';
import { UseriTelefonComponent } from './components/useri-telefon/useri-telefon.component';
import { OfertareCreareOferta2Component } from './components/ofertare2/ofertare-creare-oferta2/ofertare-creare-oferta2.component';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccesoriiMontajComponent } from './components/ofertare2/accesorii-montaj/accesorii-montaj.component';
import { AlteAnunturiComponent } from './components/alte-anunturi/alte-anunturi.component';
import { GestiuneProtectiaMunciiComponent } from './components/gestiune-protectia-muncii/gestiune-protectia-muncii.component';
import { GestiuneAlteAnunturiComponent } from './components/gestiune-alte-anunturi/gestiune-alte-anunturi.component';
import {MatListModule} from '@angular/material/list';
import { DashboardFinanciarProiectComponent } from './components/proiecte/dashboard-financiar-proiect/dashboard-financiar-proiect.component';
import { ProfitabilitateProiecteComponent } from './components/profitabilitate-proiecte/profitabilitate-proiecte.component';
import { ServiciiComponent } from './components/ofertare2/servicii/servicii.component';
import { AccesoriiMontajTablouComponent } from './components/ofertare2/accesorii-montaj-tablou/accesorii-montaj-tablou.component';
import { RapoarteUserPrezentaPdfComponent } from './components/rapoarte-user-prezenta-pdf/rapoarte-user-prezenta-pdf.component';
import { AccesoriiUnitatiFunctionaleComponent } from './components/ofertare2/accesorii-unitati-functionale/accesorii-unitati-functionale.component';
import { RapoarteUserPrezentaPdfPeriodiceComponent } from './components/rapoarte-user-prezenta-pdf-periodice/rapoarte-user-prezenta-pdf-periodice.component';
import { ServiciiPrincipaleComponent } from './components/ofertare2/servicii-principale/servicii-principale.component';
import { ServiciiSecundareComponent } from './components/ofertare2/servicii-secundare/servicii-secundare.component';
import { ServiciiIndirecteComponent } from './components/ofertare2/servicii-indirecte/servicii-indirecte.component';
import { RapoarteSituatieSaptamanalaComponent } from './components/rapoarte-proiecte/rapoarte-situatie-saptamanala/rapoarte-situatie-saptamanala.component';
import { OfertareGestiuneOfertare2Component } from './components/ofertare2/ofertare-gestiune-ofertare2/ofertare-gestiune-ofertare2.component';
import { ListaClientiOfertareComponent } from './components/clienti/lista-clienti-ofertare/lista-clienti-ofertare.component';
import { OfertareEditareOferta2Component } from './components/ofertare2/ofertare-editare-oferta2/ofertare-editare-oferta2.component';
import { DashboardProiectComponent } from './components/proiecte2/dashboard-proiect/dashboard-proiect.component';
import { Proiecte2Component } from './components/proiecte2/proiecte2/proiecte2.component';
import { DashboardContracteComponent } from './components/proiecte2/dashboard-contracte/dashboard-contracte.component';
import { DashboardSectiuneComponent } from './components/proiecte2/dashboard-sectiune/dashboard-sectiune.component';
import { Proiect2SectiuneMaterialeComponent } from './components/proiecte2/proiect2-sectiune-materiale/proiect2-sectiune-materiale.component';
import { Proiect2SectiuneManoperaComponent } from './components/proiecte2/proiect2-sectiune-manopera/proiect2-sectiune-manopera.component';
import { GestiuneProiect2Component } from './components/materiale/gestiune-proiect2/gestiune-proiect2.component';
import { RezervareProiecte2Component } from './components/materiale/rezervare-proiecte2/rezervare-proiecte2.component';
import { AddPrezentaProiect2Component } from './components/prezenta/add-prezenta-proiect2/add-prezenta-proiect2.component';
import { TabelPrezenta2Component } from './components/prezenta/tabel-prezenta2/tabel-prezenta2.component';
import { GestiuneComponenteComponent } from './components/productie/gestiune-componente/gestiune-componente.component';
import { GestiuneRetetarComponent } from './components/productie/gestiune-retetar/gestiune-retetar.component';
import { GestiuneRetetarComponenteComponent } from './components/productie/gestiune-retetar-componente/gestiune-retetar-componente.component';
import { GestiuneProductieComponent } from './components/productie/gestiune-productie/gestiune-productie.component';
import { ProiecteFisiereComponent } from './components/proiecte/proiecte-fisiere/proiecte-fisiere.component';
import { ProiectePlanuriArhitecturaComponent } from './components/proiecte/proiecte-planuri-arhitectura/proiecte-planuri-arhitectura.component';
import { ProiecteSchemeMonofilareComponent } from './components/proiecte/proiecte-scheme-monofilare/proiecte-scheme-monofilare.component';
import { ProiecteSchemeTablouriComponent } from './components/proiecte/proiecte-scheme-tablouri/proiecte-scheme-tablouri.component';
import { ProiecteFiseReleeComponent } from './components/proiecte/proiecte-fise-relee/proiecte-fise-relee.component';
import { ProiecteCreareFisaReleuComponent } from './components/proiecte/proiecte-creare-fisa-releu/proiecte-creare-fisa-releu.component';
import { ProiecteEditFisaReleuComponent } from './components/proiecte/proiecte-edit-fisa-releu/proiecte-edit-fisa-releu.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    SidebarComponent,
    GestiunePrincipalaComponent,
    GestiunePersonalaComponent,
    GestiuneProiecteComponent,
    GestiuneAngajatiComponent,
    GestiuneTransferComponent,
    GestiuneVanzariComponent,
    RezervareProiecteComponent,
    ProductDetailComponent,
    ProiecteComponent,
    ListaAngajatiComponent,
    TabelPrezentaComponent,
    AddPrezentaBirouComponent,
    AddPrezentaAtelierComponent,
    AddPrezentaProiectComponent,
    OfertareGestiuneProduseComponent,
    ArticoleManoperaComponent,
    ListaClientiComponent,
    OfertareGestiuneOferteComponent,
    OfertareGestiuneDescrieriComponent,
    OfertareGestiuneEchipamenteComponent,
    OfertareGestiuneVizualizariComponent,
    OfertareCreareOfertaComponent,
    OfertareEditareOfertaComponent,
    GestiuneAprovizionareComponent,
    GestiuneComandaComponent,
    GestiuneAprovizionareComandaComponent,
    GestiuneTabloterieComponent,
    TabloterieProiecteComponent,
    TabloterieTablouriComponent,
    TabloterieProduseComponent,
    PrezentaTabloterieComponent,
    ListaProduseDecomandatComponent,
    SolicitaServiciileNoastreComponent,
    DatePersonaleComponent,
    DashboardClientComponent,
    ProiecteClientiComponent,
    RegisterUserComponent,
    GestiuneAprovizionareRezervariComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    OferteDashboardComponent,
    ContracteDashboardComponent,
    FacturiDashboardComponent,
    RapoartedelucruDashboardComponent,
    DiverseDashboardComponent,
    GestiuneFotovoltaiceComponent,
    FotovoltaiceProiecteComponent,
    FotovoltaiceProduseComponent,
    PrezentaFotovoltaiceComponent,
    StocCriticComponent,
    ProceseVerbaleComponent,
    CreareAvizLivrareComponent,
    CreareProcesVerbalReceptieComponent,
    DocumentatieTehnicaDashboardComponent,
    FisiereClientComponent,
    RoluriComponent,
    AdaugaRolComponent,
    EditRolComponent,
    TaskComponent,
    InterventiiTaskComponent,
    TaskChatComponent,
    DashboardProiecteComponent,
    DashboardTaskComponent,
    DashboardGrupuriComponent,
    DashboardTaskUserComponent,
    SesizareTehnicaComponent,
    TabelSesizariTehniceComponent,
    ProiecteFinalizateComponent,
    GestiuneAnunturiComponent,
    AcceptaAnuntComponent,
    ProtectiaMunciiComponent,
    TablouriFinalizateComponent,
    RapoarteProiectComponent,
    RapoarteCompletePeProiectComponent,
    RapoarteCompleteComponent,
    RapoartePersonalizateComponent,
    RapoartePeriodiceComponent,
    UseriTelefonComponent,
    OfertareCreareOferta2Component,
    AccesoriiMontajComponent,
    AlteAnunturiComponent,
    GestiuneProtectiaMunciiComponent,
    GestiuneAlteAnunturiComponent,
    DashboardFinanciarProiectComponent,
    ProfitabilitateProiecteComponent,
    ServiciiComponent,
    AccesoriiMontajTablouComponent,
    RapoarteUserPrezentaPdfComponent,
    AccesoriiUnitatiFunctionaleComponent,
    RapoarteUserPrezentaPdfPeriodiceComponent,
    ServiciiPrincipaleComponent,
    ServiciiSecundareComponent,
    ServiciiIndirecteComponent,
    RapoarteSituatieSaptamanalaComponent,
    OfertareGestiuneOfertare2Component,
    ListaClientiOfertareComponent,
    OfertareEditareOferta2Component,
    DashboardProiectComponent,
    Proiecte2Component,
    DashboardContracteComponent,
    DashboardSectiuneComponent,
    Proiect2SectiuneMaterialeComponent,
    Proiect2SectiuneManoperaComponent,
    GestiuneProiect2Component,
    RezervareProiecte2Component,
    AddPrezentaProiect2Component,
    TabelPrezenta2Component,
    GestiuneComponenteComponent,
    GestiuneRetetarComponent,
    GestiuneRetetarComponenteComponent,
    GestiuneProductieComponent,
    ProiecteFisiereComponent,
    ProiectePlanuriArhitecturaComponent,
    ProiecteSchemeMonofilareComponent,
    ProiecteSchemeTablouriComponent,
    ProiecteFiseReleeComponent,
    ProiecteCreareFisaReleuComponent,
    ProiecteEditFisaReleuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgxChartsModule,
    ScheduleModule,
    MatListModule,
    SignaturePadModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableModule,
    DragDropModule,
    BrowserAnimationsModule
  ],
  providers: [DatePipe, AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
