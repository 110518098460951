import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-interventii-task',
  templateUrl: './interventii-task.component.html',
  styleUrls: ['./interventii-task.component.css']
})
export class InterventiiTaskComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  taskId: any;
  taskName: any;
  fisierId: any;
  
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    this.taskId = localStorage.getItem('TaskId');
    this.taskName = localStorage.getItem('TaskName');
  }

  ngOnInit(): void {
    this.sessionData.current_page_title = "INTERVENTII - " + this.taskName;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/ProiectTask/getIntervetiiTask/' + this.taskId, header).subscribe((data) => {
      this.sessionData.interventiiTask = data;
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('TaskId');
    localStorage.removeItem('TaskName');
  }


}
