import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accepta-anunt',
  templateUrl: './accepta-anunt.component.html',
  styleUrls: ['./accepta-anunt.component.css']
})
export class AcceptaAnuntComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;

  mesajTaskId: any;
  projectOption : any = "Caută după User";
  scrollBottom: boolean = false;
  clickFisierFlag: boolean = false;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    
  }

  ngOnInit(): void {
  }

  clickFisier(){
    this.clickFisierFlag = true;
  }

  protectiaMuncii(){
    let myDate = new Date(Date.now());

    var protectiaMunciiModel = {
      userId: this.sessionData.user.id,
      gestiuneAnunturiId: this.sessionData.anuntObligatoriu.id,
      data: myDate
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.post(this.appUrl + 'api/GestiuneAnunturi/addUserGestiuneAnunturiAccept', protectiaMunciiModel, header).subscribe(
      (response) => {    
        this.router.navigate(['/home']);
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

  onScroll(event: any){
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrollBottom = true;
    }
  }

}
