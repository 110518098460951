import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { lastValueFrom, take } from 'rxjs';
import { CompressImageService } from 'src/app/services/compress-image.service';

@Component({
  selector: 'app-sesizare-tehnica',
  templateUrl: './sesizare-tehnica.component.html',
  styleUrls: ['./sesizare-tehnica.component.css']
})
export class SesizareTehnicaComponent implements OnInit {

  grupuriInventar: any;
  appUrl: string = environment.appUrl;
  message: any;
  base64textString: any = {
    base64: '',
    name: ''
  }
  base64textStrings: any = [];
  selectedfile: File | any;
  urls: any = [];
  pictureModel: any = {
    data:'',
    file:'',
    files:'',
    sesizareTehnicaId: ''
  }
  picturesModel: any = [];
  userPrezentaModel: any = {
    denumireProiect: '',
    data: '',
    descriereSesizare: '',
    grupComunicareId: '',
    tipSesizare: '',
    userId: '',
    imaginiSesizareTehnica: ''
  }

  constructor(private compressImage: CompressImageService, public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  async ngOnInit(): Promise<void> {
    this.sessionData.current_page_title = "ADAUGĂ SESIZARE TEHNICA";
    const proiecte = this.authService.getProiecte();
    await lastValueFrom(proiecte);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GrupComunicare/getGrupuriInventar', header).subscribe((data) => {
      this.grupuriInventar = data;
    });
  }

  openImage(item: any){
    window.open(this.appUrl + "/assets/" + item.file);
  }

  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  refreshImage(){
      this.modalService.dismissAll();
  }

  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
      

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(a:any)=>{
        this.urls.push(a.target.result);
      }
    }
  }
  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.base64textString = {
      base64: btoa(binaryString),
      name: this.selectedfile.name
    }
    this.base64textStrings.push(this.base64textString);
   }
   //#region Adauga fisier
   addPictureModal(content: any) {
    this.modalService.open(content);
    this.message = "";
  }

  AddPictureResource(fAddPictureCategory: NgForm){
      this.compressImage.compress(this.selectedfile)
      .pipe(take(1))
      .subscribe((compressedImage: any) => {
        this.selectedfile = compressedImage;

        var reader = new FileReader();
        reader.onload =this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.selectedfile);
        this.message = "Imaginea a fost adaugată!"
      });
      this.modalService.dismissAll();
  }

  onSubmit(fEditCategory: any){
    let myDate = new Date(Date.now());
      this.base64textStrings.forEach((element: any) => {
        this.pictureModel = {
          data: myDate,
          file: element.name,
          files: element.base64,
          sesizareTehnicaId: this.sessionData.user.id,
        }
        this.picturesModel.push(this.pictureModel);
      });

  
      this.userPrezentaModel = {
        denumireProiect: fEditCategory.value.DenumireProiect,
        data: myDate,
        descriereSesizare: fEditCategory.value.DescriereSesizare,
        grupComunicareId:fEditCategory.value.DepartamentDestinatar,
        tipSesizare: fEditCategory.value.TipSesizare,
        userId: this.sessionData.user.id,
        imaginiSesizareTehnica: this.picturesModel
      }

      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.post(this.appUrl + 'api/SesizareTehnica/AddSesizareTehnica/',  this.userPrezentaModel, header).subscribe(
        (response) => {
          window.location.reload();
        },
        (error) => {
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
  }  
}
